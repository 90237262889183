import { Action } from '@ngrx/store';
import { ModelCollection } from '../../../_sdk/collection.model';
import { ParameterDataTypeModel } from '../../../_sdk/parameter-configuration/parameter-data-type.model';

export const LOAD = 'PARAMETER_DATA_TYPE_LOAD';
export const LOAD_COMPLETE = 'PARAMETER_DATA_TYPE_LOAD_COMPLETE';

export const INSERT = 'PARAMETER_DATA_TYPE_INSERT';
export const INSERT_COMPLETE = 'PARAMETER_DATA_TYPE_INSERT_COMPLETE';

export const UPDATE = 'PARAMETER_DATA_TYPE_UPDATE';
export const UPDATE_COMPLETE = 'PARAMETER_DATA_TYPE_UPDATE_COMPLETE';

export const DELETE = 'PARAMETER_DATA_TYPE_DELETE';
export const DELETE_COMPLETE = 'PARAMETER_DATA_TYPE_DELETE_COMPLETE';

export const DISPOSE = 'PARAMETER_DATA_TYPE_DISPOSE';

export class Load implements Action {
    public readonly type = LOAD;
}

export class LoadComplete implements Action {
    public readonly type = LOAD_COMPLETE;

    constructor(public payload: ModelCollection<ParameterDataTypeModel>) { }
}

export class Insert implements Action {
    public readonly type = INSERT;

    constructor(public payload: ParameterDataTypeModel) { }
}

export class InsertComplete implements Action {
    public readonly type = INSERT_COMPLETE;

    constructor(public payload: ParameterDataTypeModel) { }
}

export class Update implements Action {
    public readonly type = UPDATE;

    constructor(public payload: ParameterDataTypeModel) { }
}

export class UpdateComplete implements Action {
    public readonly type = UPDATE_COMPLETE;

    constructor(public payload: ParameterDataTypeModel) { }
}

export class Delete implements Action {
    public readonly type = DELETE;

    constructor(public payload: ParameterDataTypeModel) { }
}

export class DeleteComplete implements Action {
    public readonly type = DELETE_COMPLETE;

    constructor(public payload: ParameterDataTypeModel) { }
}

export class Dispose implements Action {
    public readonly type = DISPOSE;
}

export type Actions =
    Load | LoadComplete
    | Insert | InsertComplete
    | Update | UpdateComplete
    | Delete | DeleteComplete
    | Dispose;
