export enum BillingScheduleType {
    Unknown = 0,
    Scheduled = 1,
    Adhoc = 2
}

export const BillingScheduleTypeNames = new Map<number, string>([
    [BillingScheduleType.Unknown, 'Unknown'],
    [BillingScheduleType.Scheduled, 'Scheduled'],
    [BillingScheduleType.Adhoc, 'Adhoc']
]);