import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { BaseDirective } from '../../../_core/components/base.directive';
import { TypeConverter } from '../../../_core/type-converter';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[verifiableControl], [verifiableControlName]'
})
export class VerifiableControlDirective extends BaseDirective implements OnChanges {

    private _message: string = null;
    private _displayResultMessage = false;
    private _displayExternalMessage = false;

    private _messageContainer: any;

    @Input('verifiableControl')
    public verifiableControl: AbstractControl;

    @Input('verifiableControlName')
    public verifiableControlName: string;

    @Input()
    public verify: Function;
    
    @Input()
    public get message(): string {
        return this._message;
    }
    public set message(value: string) {
        this._message = value;
        this._displayExternalMessage = true;
    }

    @Input()
    public get displayResult(): boolean {
        return this._displayResultMessage;
    }
    public set displayResult(value: boolean) {
        this._displayResultMessage = value;
    }

    constructor(private readonly renderer: Renderer2, private readonly hostElement: ElementRef) {
        super();
    }

    private get controlName(): string {
        if(!String.isNullOrEmpty(this.verifiableControlName)) {
            return this.verifiableControlName;
        }

        if(this.verifiableControl) {
            return this._getControlName(this.verifiableControl);
        }

        return null;
    }

    private _getControlName(c: AbstractControl): string | null {
        const formGroup = c.parent.controls;
        return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    }

    private get _enabled(): boolean {
        if(!this.verify) {
            return false;
        }

        if(!this.verifiableControl && String.isNullOrEmpty(this.verifiableControlName)) {
            return false;
        }

        return true;
    }

    private addClass(): void {
        this.renderer.addClass(this.hostElement.nativeElement, 'verifiable');
    }

    private addMessage(resultMessage: string): void {
        
        if(!this._displayExternalMessage && !this._displayResultMessage) {
            return;
        }
        
        let message: string;

        if(this._displayResultMessage) {
            message = resultMessage;
        } else if(this._displayExternalMessage) {
            message = this.message;
        }

        if(String.isNullOrEmpty(message)) {
            return;
        }

        if(!this._messageContainer) {
            this._messageContainer = this.renderer.createElement('div');
            const messageText = this.renderer.createText(message);

            this.renderer.addClass(this._messageContainer, 'message')
            this.renderer.appendChild(this._messageContainer, messageText);
        }

        this.renderer.appendChild(this.hostElement.nativeElement, this._messageContainer);
    }

    private clear(): void {
        this.renderer.removeClass(this.hostElement.nativeElement, 'verifiable');
        if(this._messageContainer) {
            this.renderer.removeChild(this.hostElement.nativeElement, this._messageContainer);
        }
    }

    public ngOnChanges(): void {
        if(!this._enabled) {
            return;
        }
        
        const results = this.verify();

        if(results && Object.keys(results).any(p => p.equals(this.controlName))) {
            this.addClass();
            this.addMessage(results[this.controlName]);
        } else {
            this.clear();
        }
    }
}