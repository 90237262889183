import { Component, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { BaseComponent } from '../../../../_core/components/base.component';


// Based on the following
// https://github.com/ghinda/css-toggle-switch


@Component({
    selector: 'app-tri-switch',
    templateUrl: './tri-state-switch.component.html',
    styleUrls: ['./tri-state-switch.component.css']
})
export class TriStateSwitchComponent extends BaseComponent {

    private _onValue: string;
    private _offValue: string;
    private _naValue: string;
    private _selectedValue: string;

    @Input()
    public groupName: string;

    @Input()
    public id: string;

    @Input()
    public onText: string;

    @Input()
    public offText: string;

    @Input()
    public naText: string;

    @Input()
    set onValue(value: string) {
        this._onValue = value;
    }
    get onValue() {
        return this._onValue ? this._onValue : this.onText;
    }

    @Input()
    set offValue(value: string) {
        this._offValue = value;
    }
    get offValue() {
        return this._offValue ? this._offValue : this.offText;
    }

    @Input()
    set naValue(value: string) {
        this._naValue = value;
    }
    get naValue() {
        return this._naValue ? this._naValue : this.naText;
    }

    @Input()
    get value(): string {
        return this._selectedValue;
    }
    set value(val: string) {
        this._selectedValue = val;
        this.valueChange.emit(val);
    }

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter();


    constructor() {
        super();
    }

    public get onId(): string {
        return `${this.groupName}_on_${this.id}`;
    }

    public get offId(): string {
        return `${this.groupName}_off_${this.id}`;
    }

    public get naId(): string {
        return `${this.groupName}_na_${this.id}`;
    }

}
