import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";

import { notNull } from "../../../_core/rxjs.operators";
import { RouteData } from "./route-data";

@Injectable({
    providedIn: 'root'
})
export class RouteDataService {
    private readonly _currentRoute$ = new BehaviorSubject<ActivatedRoute>(null);
    public readonly currentRoute$ = this._currentRoute$.asObservable()
        .pipe(
            notNull(),
            shareReplay(1)
        )

    public readonly currentRouteData$: Observable<RouteData> = this.currentRoute$
        .pipe(
            switchMap(p => p.data),
            map(p => p as RouteData),
            shareReplay(1)
        );
        
    public setRoute(route: ActivatedRoute): void {
        this._currentRoute$.next(route);
    }
}