<div class="float" *ngIf="((isLoading$ | async) == false) && !(error$ |async)">
  <div class="row float-right p-3" *ngIf="tenantInvoice$ | async  as tenantInvoice">
    <button class="k-button mr-2" (click)="onDownloadInvoice()"> Download </button>
  </div>
</div>

<div class="pdf-container">
  <iframe #pdfviewer id="pdfviewer" [src]="" width="900px"> </iframe>
  <div class="error-block">
    <span *ngIf="(error$ |async)?.status == 404" class="notFoundIcon ml-4"></span>
    <span *ngIf="(error$ |async)?.status == 500" class="internalErrorIcon ml-4"></span>
    <span class="error-message">{{ (error$ |async)?.errorMessage }}</span>
  </div>

  <div class="loading-indicator k-i-loading position-absolute" *ngIf="isLoading$ | async"> </div>
</div>