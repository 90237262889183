import { Injectable } from '@angular/core';

declare let environmentResource: any;

@Injectable({
    providedIn: 'root'
})

export class GlobalConfig {
    public readonly localizationConfig: LocalizationConfig;
    public readonly reportServer: string;
    public readonly reportCenter: string;
    public readonly assetPath: string;

    private readonly _external: Map<string, any>;

    constructor() { 
        this.localizationConfig = {
            languageId: environmentResource.localization.languageId,
            logMissingResources: environmentResource.localization.logMissingResources
        };
       
        this.reportServer = environmentResource.reportServer.baseUri;
        this.reportCenter = environmentResource.reportCenter.baseUri;
        this.assetPath = environmentResource.assetPath.baseUri;

        this._external = new Map(Object.entries(environmentResource.external));
    }

    public api(key: string): ApiConfig {
        return this._external.get(key).api as ApiConfig;
    }
}

export interface ApiConfig {
    baseUri: string;
}

export interface LocalizationConfig {
    languageId: number;
    logMissingResources: boolean;
}
