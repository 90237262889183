import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

import { tap } from 'rxjs/operators';

import { BaseInputElement } from '../../../../_core/components/forms/base-input-element';
import { AsyncValidatorArray, ValidatorArray } from '../../../../_core/components/forms/validate';
import { DateTime, SmallDate, TimeSpan } from '../../../../_core/date';
import { TypeConverter } from '../../../../_core/type-converter';

let _identifier = 0;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'timespan-timepicker',
    styleUrls: ['timespan-timepicker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="d-flex flex-row" [formGroup]="editForm">
            <kendo-numerictextbox *ngIf="displayDays"
                format="0"
                placeholder="Days"
                formControlName="days"
                [spinners]="false"
                [class]="dayInputCssClass"
                [spinners]="false">
            </kendo-numerictextbox>
            <kendo-timepicker
                [id]="identifier"
                [format]="format"
                [placeholder]="placeholder"
                [formatPlaceholder]="formatPlaceholder"
                [min]="jsMin"
                [max]="jsMax"
                [nowButton]="nowEnabled"
                [steps]="steps"
                formControlName="jsValue"
                [class]="timeInputCssClass">
            </kendo-timepicker>
        </div>
    `,

    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TimeSpanTimePickerComponent),
        multi: true
    }]
})
export class TimeSpanTimePickerComponent extends BaseInputElement<TimeSpan> {

    public readonly identifier = `timespan-timepicker-${_identifier++}`;

    public readonly editForm: FormGroup;

    private _min: TimeSpan;
    private _max: TimeSpan;

    @ViewChild(NgModel)
    public model: NgModel;

    @Input()
    public format: string;

    @Input()
    public formatPlaceholder: string;

    @Input()
    public placeholder: string;

    @Input()
    public nowEnabled: boolean;

    @Input()
    public displayDays: boolean;

    @Input()
    public steps = 1;

    @Input()
    public dayInputCssClass: string = 'w-auto';
    
    @Input()
    public timeInputCssClass: string = 'w-auto';

    public get min(): TimeSpan | Date {
        return this._min;
    }
    @Input()
    public set min(value: TimeSpan | Date) {
        if (!value) {
            return;
        }

        const normalized = this.normalizeValue(value);

        this._min = normalized;
    }

    public get max(): TimeSpan | Date {
        return this._max;
    }
    @Input()
    public set max(value: TimeSpan | Date) {
        if (!value) {
            return;
        }

        const normalized = this.normalizeValue(value);

        this._max = normalized;
    }

    public get jsMin(): Date {
        this._min = this._min || TimeSpan.empty();
        if (this._min.totalHours <= 0) {
            this._min = TimeSpan.empty();
        }

        const date = DateTime.now().setTime(this._min).toDate();

        return date;
    }

    public get jsMax(): Date {
        this._max = this._max || TimeSpan.empty();
        if (this._max.totalHours >= 24) {
            this._max = TimeSpan.fromHours(24).addMinutes(-1);
        }

        const date = DateTime.now().setTime(this._max).toDate();

        return date;
    }

    public get jsDays(): number {
        const normalized = this.normalizeValue(this.value) || TimeSpan.empty();
        return Math.floor(normalized.totalDays);
    }

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: ValidatorArray,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorArray,
        private readonly formBuilder: FormBuilder
    ) {
        super(validators, asyncValidators);

        this.editForm = this.formBuilder.group({
            jsValue: null,
            days: 0
        });
        this.addSubscription(
            this.editForm.valueChanges
                .pipe(
                    tap(value => {
                        this.value = TimeSpan.fromDate(value.jsValue).addDays(value.days || 0);
                    })
                )
        );
        
        this.format = 'HH:mm';
        this.placeholder = this.format;
        this.formatPlaceholder = 'formatPattern';
        this._min = TimeSpan.empty();
        this._max = this._min.addHours(24).addMinutes(-1);
        this.nowEnabled = true;
    }
    public setDisabledState(isDisabled: boolean): void {
        super.setDisabledState(isDisabled);
    
        if(isDisabled) {
          this.editForm.disable();
        } else {
          this.editForm.enable();
        }
    }
    protected bindValue(value: TimeSpan) {
        value = this.normalizeValue(value || TimeSpan.empty());
        this.editForm.patchValue({ jsValue: value.toDate(), days: value.days });
    }

    private normalizeValue(value: TimeSpan | Date | SmallDate | DateTime | string | number): TimeSpan {
        if (!value) {
            return null;
        }

        if (TypeConverter.isNumber(value)) {
            return TimeSpan.fromMinutes(value);
        }

        return TimeSpan.convert(value);
    }
}
