import { EntityModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class SettingModel extends EntityModel {
    public Name: string = null;
    public Value: string = null;
}

export interface SettingFilter {
    Name?: string;
    Value?: string;
}

export type SettingFilterState = CompositeType<SettingFilter, FilterState>;
