import { Injectable } from '@angular/core';

import { of, Observable } from 'rxjs';
import { switchMap, finalize, map, tap } from 'rxjs/operators';

import { GlobalConfig } from '../../_core/global.config';
import { TypeConverter } from '../../_core/type-converter';
import { ApiService } from '../api.service';
import { BaseEntityService } from '../base.service';
import { ModelCollection } from '../../_sdk/collection.model';

import { UserModel, UserFilterState } from '../../_sdk/users/user.model';
import { UserSettingModel } from '../../_sdk/users/user-setting.model';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseEntityService<UserModel> {

    constructor(
        private readonly config: GlobalConfig,
        apiService: ApiService,
    ) {
        super(apiService);
    }

    protected get baseUri(): string {
        return `${this.config.api('portal').baseUri}/user`;
    }

    protected toEntity(source: any): UserModel {
        return TypeConverter.convert(UserModel, source);
    }

    public search(request?: UserFilterState): Observable<ModelCollection<UserModel>> {
        return super.search(request).pipe(
            tap(m => {
                // Ensure that the UserAclSummary property is ModelCollection.
                m.data.forEach((u: any) => u.UserAclSummary = new ModelCollection(u.UserAclSummary.Data, u.UserAclSummary.Total))
            })
        );
    }

    public usernameExists(username: string): Observable<boolean> {
        const uri = `${this.baseUri}/username/${username}/exists`;
        return this.apiService.querySingle<UserModel>(uri).pipe(map(r => !!r));
    }

    public systemNameExists(systemName: string): Observable<boolean> {
        const uri = `${this.baseUri}/systemname/${systemName}/exists`;
        return this.apiService.querySingle<UserModel>(uri).pipe(map(r => !!r));
    }

    public emailExists(email: string): Observable<boolean> {
        email = encodeURIComponent(email)
        const uri = `${this.baseUri}/email/checkExistingUserByEmail?email=${email}`;
        return this.apiService.querySingle<UserModel>(uri).pipe(map(r => !!r));
    }

    public updatePassword(userId: number, newPassword: string, confirmNewPassword: string, passwordToken: string): Observable<any> {
        const uri = `${this.baseUri}/${userId}/change-password`;
        const response = this.apiService.request(uri, 'put', { newPassword, confirmNewPassword, passwordToken });
        return response;
    }

    public updateProfile(model: UserModel): Observable<any> {
        const uri = `${this.baseUri}/update-profile`;

        return this.getRequest(
            this.apiService.update<UserModel>(uri, model)
                .pipe(
                    map(entity => this.toEntity(entity)),
                    tap(entity => this.changeEntity(entity))
                )
        );
    }

    public insert(model: UserModel): Observable<UserModel> {
        const uri = `${this.baseUri}/create`;

        return this.getRequest(
            this.apiService.insert<UserModel>(uri, model)
                .pipe(
                    map(entity => this.toEntity(entity)),
                    tap(entity => this.changeEntity(entity))
                )
        );
    }

    public update(model: UserModel): Observable<UserModel> {
        const uri = `${this.baseUri}/update`;

        return this.getRequest(
            this.apiService.update<UserModel>(uri, model)
                .pipe(
                    map(entity => this.toEntity(entity)),
                    tap(entity => this.changeEntity(entity))
                )
        );
    }

    public resendWelcomeMessage(email: string): Observable<any> {
        email = encodeURIComponent(email);
        const uri = `${this.baseUri}/resend-welcome-message?email=${email}`;
        return this.apiService.put<any>(uri, null);
    }

    public requestPasswordRecovery(email: string): Observable<any> {
        email = encodeURIComponent(email);
        const uri = `${this.baseUri}/request-password-recovery?email=${email}`;
        return this.apiService.put<any>(uri, null);
    }

    public validatePasswordToken(userId: number, token: string): Observable<{ userModel: UserModel, expires: string }> {
        const uri = `${this.baseUri}/validate-password-token/${userId}/${token}`;
        return this.apiService.querySingle(uri);
    }

}
