import { Injectable, OnDestroy, Type } from '@angular/core';

import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { fromBase64, toBase64 } from '../../_core/encode';
import { TypeConverter } from '../../_core/type-converter';
import { StorageService } from './storage.service';

const __PREFIX = 'genea.';

interface StorageValue {
    value: any;
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService extends StorageService implements OnDestroy {

    private readonly _storageMap = new Map<string, BehaviorSubject<any>>();

    constructor() {
        super();

        this.syncronize();

        this.start();
    }

    private syncronize(): void {
        const keys = Object.keys(localStorage);

        // Update all matching key values
        keys
            .iterate(key => {
                this.setValue(this.unwrapKey(key), this.unwrapValue(localStorage.getItem(key)));
            });

        // Set all non-matching keys to null...
        Array.from(this._storageMap.keys())
            .iterate(key => {
                if(!keys.any(k => this.wrapKey(key) === k)) {
                    this.setValue(key, null);
                }
            });
    }

    private ensureValue<T>(key: string): BehaviorSubject<T> {
        return this._storageMap.getValue(key, () => {
            return new BehaviorSubject<T>(null);
        });
    }

    private setValue<T>(key: string, data: T): Observable<T> {
        const value$ = this.ensureValue<T>(key);

        value$.next(data);

        return value$;
    }

    private getValue<T>(key: string, ctor: Type<T>): Observable<T> {
        const value$ = this.ensureValue<T>(key);

        return value$
            .pipe(
                map(value => {
                    if (value && ctor) {
                        return TypeConverter.convert(ctor, value);
                    }

                    return value as T;
                })
            )
    }

    public ngOnDestroy(): void {
        this.stop();
    }

    public put<T>(key: string, data: T): Observable<T> {
        const wrappedKey = this.wrapKey(key);

        if (!data) {
            localStorage.removeItem(wrappedKey);
        } else {
            localStorage.setItem(wrappedKey, this.wrapValue(data));
        }

        return this.setValue(key, data);
    }

    public get(key: string): Observable<any>;
    public get<T>(key: string, ctor?: Type<T>): Observable<T> {
        return this.getValue(key, ctor);
    }

    public clear(): void {
        localStorage.clear();
        this.syncronize();
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea === localStorage) {
            this.syncronize();
        }
    }

    private start(): void {
        window.addEventListener('storage', this.storageEventListener.bind(this));
    }

    private stop(): void {
        window.removeEventListener('storage', this.storageEventListener.bind(this));
        this._storageMap.iterate((v, k) => {
            v.complete();
        });
    }

    private wrapKey(key: string): string {
        if (!(key || '').startsWith(__PREFIX)) {
            key = `${__PREFIX}${key}`;
        }

        return key;
    }

    private unwrapKey(key: string): string {
        if (String.isNullOrEmpty(key)) {
            return null;
        }

        if ((key || '').startsWith(__PREFIX)) {
            key = key.substring(__PREFIX.length);
        }

        return key;
    }

    private wrapValue(value: any): string {
        return toBase64(JSON.stringify(<StorageValue>{ value }, (k, v) => {
            if (v === null || v === undefined) {
                return undefined;
              }
              return v;
        }));
    }

    private unwrapValue(value: any): any {
        if (TypeConverter.isNull(value)) {
            return null;
        }

        if (TypeConverter.isString(value)) {
            try {
                const storageValue = JSON.parse(fromBase64(value)) as StorageValue;

                return storageValue && storageValue.value;
            } catch (_) { }
        }

        return value && value.value;
    }
}
