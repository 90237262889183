export enum LeaseAreaInvoiceAdjustmentReason {
    None = 0,
    CreditDueToLeaseRestrictions,
    IncorrectLeaseHours,
    IncorrectBillingRate,
    MoveInWeekend,
    MoveOutWeekend,
    GoodwillGestureToTenant,
    CustomerDisputedService,
    ServiceNotReceived,
    PropertyManagerDrivenRequest,
    CancelledRequest,
    Other
} 

export const LeaseAreaInvoiceAdjustmentReasonNames = new Map<number, string>([
        [LeaseAreaInvoiceAdjustmentReason.CreditDueToLeaseRestrictions, 'Credit Due To Lease Restrictions'],
        [LeaseAreaInvoiceAdjustmentReason.IncorrectLeaseHours, 'Incorrect Lease Hours'],
        [LeaseAreaInvoiceAdjustmentReason.IncorrectBillingRate, 'Incorrect Billing Rate'],
        [LeaseAreaInvoiceAdjustmentReason.MoveInWeekend, 'Move In Weekend'],
        [LeaseAreaInvoiceAdjustmentReason.MoveOutWeekend, 'Move Out Weekend'],
        [LeaseAreaInvoiceAdjustmentReason.GoodwillGestureToTenant, 'Goodwill Gesture To Tenant'],
        [LeaseAreaInvoiceAdjustmentReason.CustomerDisputedService, 'Customer Disputed Service'],
        [LeaseAreaInvoiceAdjustmentReason.ServiceNotReceived, 'Service Not Received'],
        [LeaseAreaInvoiceAdjustmentReason.PropertyManagerDrivenRequest, 'Property Manager Driven Request'],
        [LeaseAreaInvoiceAdjustmentReason.CancelledRequest, 'Cancelled Request'],
        [LeaseAreaInvoiceAdjustmentReason.Other, 'Other']
]);
