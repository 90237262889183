export enum EmsDeviceControllerStatus {
				Live = 1,
				Simulated = 2,
				Staging = 3,
				Disabled = 4,
				Demo = 5
}

export const EmsDeviceControllerStatusNames = new Map<number, string>([
    [EmsDeviceControllerStatus.Live, 'Live'],
    [EmsDeviceControllerStatus.Simulated, 'Simulated'],
				[EmsDeviceControllerStatus.Staging, 'Staging'],
				[EmsDeviceControllerStatus.Disabled, 'Disabled'],
				[EmsDeviceControllerStatus.Demo, 'Demo']
]);
