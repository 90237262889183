<nav class="navbar navbar-light bg-light">
    <a class="navbar-brand" href="#" >
        <span alt="GENEA" class="geneaLogo" ></span>
    </a>
</nav>
<app-loading-indicator [loading]="loading$ | async">
</app-loading-indicator>
<div class="snooze-container" *ngIf="!(loading$ | async) && !error">
    <div class="content">
        <div class="align-self-center snooze-box">
            <div class="mb-3">
                <span class="no-notification"></span>
            </div>
            <h3 class="mb-3" *ngIf="!((snoozeResponse$ | async)?.IsAlreadyProcessed)">Email Reminders are stopped</h3>
            <h3 class="mb-3" *ngIf="((snoozeResponse$ | async)?.IsAlreadyProcessed)">Your response is saved.</h3>
            <div class="text-center" *ngIf="!((snoozeResponse$ | async)?.IsAlreadyProcessed)"> 
                <div [ngSwitch]="(snoozeResponse$ | async)?.NotificationId">
                    <div *ngSwitchCase="5">
                        <p class="mb-2">Thank you for adding all the meter reads, your email remainders</p>
                        <p class="mb-4">are stopped for the period ( {{(SnoozeEnd((snoozeResponse$ | async)?.SnoozeFromDate))}} - {{SnoozeStart((snoozeResponse$ | async)?.SnoozeTillDate)}} )</p>
                    </div>
                    <div *ngSwitchCase="6">
                        <p class="mb-2">Thank you for uploading all the bills, your email remainders</p>
                        <p class="mb-4">are stopped for the period ( {{(SnoozeEnd((snoozeResponse$ | async)?.SnoozeFromDate))}} - {{SnoozeStart((snoozeResponse$ | async)?.SnoozeTillDate)}} )</p>
                    </div>
                </div>
            </div>
            <span class="text-center mb-2" *ngIf="((snoozeResponse$ | async)?.IsAlreadyProcessed)">
                <div class="mb-2">
                    You have already opted out of this email notification for this period.
                </div>
                <div class="mb-4">Thank you.</div>
            </span>
            <button kendoButton primary="true" class="m-3" (click)="closeWindow()"> Close the window</button>
        </div>
    </div>
</div>
<div class="snooze-container" *ngIf="(loading$ | async)">
    <div class="content">
        <div class="align-self-center snooze-box">
            <div class="mb-3 p-3">
                <span class="loading-no-notification"></span>
            </div>
        </div>
    </div>
</div>
<div class="snooze-container" *ngIf="!(loading$ | async) && error">
    <div class="content">
        <div class="align-self-center snooze-box">
            <div class="mb-3">
                <span class="error-no-notification"></span>
            </div>
            <h3 class="mb-3">Something went wrong</h3>
            <span class="mb-4">Try after sometime or contact Genea</span>

            <button kendoButton primary="true" class="m-3" (click)="closeWindow()"> Close the window</button>
        </div>
    </div>
</div>