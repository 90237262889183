<div class="toastrs">
    <div class="toastr-wrapper"
        role="alert"
        *ngFor="let toastr of toastrs"
        [ngClass]="['alert', 'alert-dismissable', alertClass(toastr)]"
        [@flyInOut]>
        <div class="toastr-message" (mouseenter)="toastr.showIndicator = true" (mouseleave)="toastr.showIndicator = false">
            <ng-container *ngIf="toastr.allowClose && toastr.autoClose">
                <div class="row" *ngIf="toastr.showIndicator; else heightBalancer">
                    <div class="toastr-indicator-bar" [ngStyle]="{ 'width': toastr.indicator + '%' }">
                    </div>
                </div>
                <ng-template #heightBalancer>
                    <div class="row">
                        <div class="toastr-indicator-bar"></div>
                    </div>
                </ng-template>
            </ng-container>
            <div class="row">
                <div class="col-12">
                    <h4 class="alert-heading">
                        <span [ngClass]="['k-icon', iconClass(toastr)]"></span>
                        {{ title(toastr) }}
                        <a class="close" (click)="removeToastr(toastr)" *ngIf="toastr.allowClose">&times;</a>
                        <a class="close" (click)="handlePin(toastr)" *ngIf="toastr.autoClose">
                            <span [className]="'k-icon ' + (toastr.pinned ? 'k-i-pin' : 'k-i-unpin')"></span>
                        </a>
                    </h4>
                </div>
            </div>

            <ul class="list-unstyled">
                <ng-container *ngFor="let message of toastr.messages">
                    <li [innerHTML]="message"></li>
                </ng-container>
            </ul>

            <!-- <div class="row">
                <div class="col-12" [innerHTML]="toastr.message">
                    < !-- {{ toastr.message }} -- >
                </div>
            </div> -->
        </div>
    </div>
</div>
