import { EntityModel } from '../../../../_sdk/model';
import { DataObject, DataProperty, CompositeType } from '../../../../_core/type-converter';
import { AllowanceValueType } from '../../../../_sdk/billing/allowance-value-type.enum';
import { TimeframeType } from '../../../../_sdk/billing/timeframe.enum';
import { SortDirectionType } from '../../../../_sdk/sort-direction.enumeration';
import { FilterState } from '../../../../_sdk/page-state';
import { AllowanceLedgerModel } from './allowance-ledger.model';
import { AllowanceEntityType } from '../../../../_sdk/billing/allowance-entity-type.enum';
import { ServiceType } from '../../../../_sdk/leases/service-type.enum';
import { ModelCollection } from '../../../../_sdk/collection.model';
import { DateTime } from '../../../../_core/date';

@DataObject()
export class AllowanceDetailModel extends EntityModel {
    @DataProperty({ converter: (s) => new ModelCollection(s) })
    public Allowances: ModelCollection<AllowanceModel> = null;
}

@DataObject()
export class AllowanceModel extends EntityModel {

    public Type: AllowanceValueType = null;

    public Timeframe: TimeframeType = null;

    public ServiceTypeId: ServiceType = null;

    public EntityType: AllowanceEntityType = null;

    public VirtualMeterId: number = null;

    public TenantId: number = null;

    public LeaseId: number = null;

    public LeaseAreaId: number = null;

    public Amount: number = null;

    public Balance: number = null;

    @DataProperty()
    public StartDateUtc: DateTime = null;

    @DataProperty()
    public EndDateUtc: DateTime = null;

    public IsRolling: boolean = null;

    public Active: boolean = null;

    public DefaultLeaseExpiration: boolean = null;

    public IsCalendarYear: boolean = null;

    public Expires: boolean = null; // Convenience field only existing in Angular.

    public Ledgers: Array<AllowanceLedgerModel> = null;

    public Maximum: number = null;

    public Rate: number = null;
}

export interface AllowanceFilter {
    VirtualMeterId?: number;
    TenantId?: number;
    LeaseId?: number;
    LeaseAreaId?: number;
    SortField?: string;
    AllowanceType?: string;
    SortDirection?: SortDirectionType;
}

export type AllowanceFilterState = CompositeType<AllowanceFilter, FilterState>;
