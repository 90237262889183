
import { Directive, ElementRef } from '@angular/core';
import { BaseDirective } from '../../../_core/components/base.directive';

@Directive({
  selector: '[geneaContainerStyle]'
})
export class ContainerLayoutDirective  extends BaseDirective {


  public get htmlElement(): HTMLElement {
    return this.hostElement.nativeElement;
  }

  constructor(private readonly hostElement: ElementRef) {
    super();
  }

  public OnInit(): void {
    super.OnInit();

    this.addClass('container-layout');
  }

  private addClass(...tokens: Array<string>): void {
    this.htmlElement.classList.add(...tokens);
  }

}
