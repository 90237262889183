import { EntityModel } from '../model';
import { UserRoleModel } from '../users/user-role.model';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class PermissionRecordModel extends EntityModel {
    public Name: string = null;
    public SystemName: string = null;
    public Category: string = null;

    /*
    @DataProperty({ instanceType: UserRoleModel })
    public UserRoles: Array<UserRoleModel> = [];
    */
}

export interface PermissionRecordFilter {
    RoleId?: number;
    Name?: string;
    SystemName?: string;
    Category?: string;
}

export type PermissionRecordFilterState = CompositeType<PermissionRecordFilter, FilterState>;
