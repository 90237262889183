import { DataObject, CompositeType, DataProperty } from '../../_core/type-converter';
import { EntityModel } from '../model';
import { FilterState } from '../page-state';
import { BuildingModel } from '../buildings/building.model';
import { PointHandling, PrimaryZoneHandling, ZoneType, ZoneRole } from './zone.enums';
import { PointModel } from '../ems-devices/point.model';
import { EmsDeviceControllerModel } from '../ems-devices/ems-device-controller.model';

@DataObject()
export class ZoneModel extends EntityModel {

    // Used to differentiate what type of zone we're dealing with...
    public Type: ZoneType = ZoneType.PointZone;

    public Name: string = null;
    public Description: string = null;
    public Active = true;
    public Version: number = null;
    public Attachable = false;
    public Role = ZoneRole.Any;

    @DataProperty()
    public EmsDeviceController: EmsDeviceControllerModel = null;
    
    @DataProperty()
    public Building: BuildingModel = null;
    
    @DataProperty({ instanceType: BuildingModel })
    public Buildings: Array<BuildingModel> = [];



    // PointZoneModel
    public PointHandling: PointHandling = PointHandling.None;

    @DataProperty({ instanceType: PointModel })
    public Points: Array<PointModel> = [];




    // MinimumLoadZoneGroupModel, SequencedActivationZoneModel, MinimumLoadZoneModel
    public PrimaryZoneHandling: PrimaryZoneHandling = PrimaryZoneHandling.None;

    @DataProperty()
    public PrimaryZone: ZoneModel = null;

    @DataProperty()
    public TargetZone: ZoneModel = null;

    @DataProperty({ instanceType: ZoneModel })
    public Zones: Array<ZoneModel> = [];



    // MinimumLoadZoneModel
    public LoadCount: number = null;
}

export interface ZoneFilter {
    EmsDeviceControllerId?: number;
    BuildingId?: number;
    TenantId?: number;
    LeaseId?: number;
    LeaseAreaId?: number;
    ZoneGroupId?: number;
    BuildingName?: string;
    Type?: ZoneType;
    Role?: ZoneRole;
    Name?: string;
    Description?: string;
    Attachable?: boolean;
}

export type ZoneFilterState = CompositeType<ZoneFilter, FilterState>;
