import { EntityModel } from '../../../../../_sdk/model';
import { CompositeType, DataObject } from '../../../../../_core/type-converter';
import { DateTime } from '../../../../../_core/date';
import { FilterState } from '@progress/kendo-angular-treeview';

@DataObject()
export class UtilityBillUploadList extends EntityModel{
    public CustomerName?: string;
    public BuildingName?: string;
    public Id: number;
    public AccountNumber?: string;
    public Provider?: string;
    public ProviderMeterNumber?: string;
    public Type: number;
    public Status: number;
    public UtilityBillPeriod?: string;
    public InvoicePeriod?: string;
    public StartDate?: DateTime;
    public EndDate?: DateTime;
    public UoM?: number;
}
export class ViewUtilityBillUploadList extends EntityModel{
    public CustomerName?: string;
    public BuildingName?: string;
    public Id: number;
    public AccountNumber?: string;
    public Provider?: string;
    public ProviderMeterNumber?: string;
    public Type: number;
    public Status: number;
    public UtilityBillPeriod?: string;
    public InvoicePeriod?: string;
    public StartDate?: DateTime;
    public EndDate?: DateTime;
    public UoM?: number;
    public Files?: object;
    public Comment?: string;
    public Notes?: string;
    public TotalUsage?: number;
    public TotalCharges?: number;
}
export class UtilityBillListData extends EntityModel{
    public UtilityBillId? : number;
    public StartDate?: DateTime;
    public EndDate?: DateTime;
    public Bills?: FileList;
    public Comment?: string;
    public Notes?: string;
    public TotalUsage?: number;
    public TotalCharges?: number;
    public ProviderMeterNumber?: string;
}
export interface UtilityBillUploadListFilter {
    BuildingId?: number;
    CustomerId?: number;
    BuildingGroupId?: number;
    PageIndex?: number;
    PageSize?: number;
}

export type UtilityBillListFilterState = CompositeType<UtilityBillUploadListFilter, FilterState>;