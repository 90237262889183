import { ActivatedRoute } from '@angular/router';
import { TypeConverter } from './type-converter';
import { HttpParams } from '@angular/common/http';

function normalizeParameters(source: Array<{ key: string, value: any }>): any {
    const output: any = {};

    if (source.length === 0) {
        return output;
    }

    source.forEach(param => {
        if (TypeConverter.isNull(param.value) || (TypeConverter.isString(param.value) && String.isNullOrEmpty(param.value))) {
            // Don't add empty values.
            return;
        }

        output[param.key] = param.value;
    });

    return output;
}

export const returnUrlQueryStringParam = 'returnUrl';
export const externalUrlQueryStringParam = 'externalUrl';

export function createReturnUrl(returnUrl: string): any {
    const output = {};
    output[returnUrlQueryStringParam] = encodeURIComponent(returnUrl || '');
    return output;
}

export function getReturnUrl(route: ActivatedRoute): string {
    const url = route.snapshot.queryParams[returnUrlQueryStringParam] || '';

    if (String.isNullOrEmpty(url)) {
        return null;
    }

    return decodeURIComponent(url);
}

export function getExternalUrl(route: ActivatedRoute): string {
    const url = route.snapshot.queryParams[externalUrlQueryStringParam] || '';

    if (String.isNullOrEmpty(url)) {
        return null;
    }

    return decodeURIComponent(url);
}

export function toQueryString(source: any): string {
    if (!source) {
        return '';
    }

    if (TypeConverter.isString(source)) {
        // The source is already a string... No further processing can be done.
        return source;
    }

    if (TypeConverter.isArray(source)) {
        source = normalizeParameters(source);
    }

    const params: Array<string> = [];

    Object.keys(source)
        .forEach(key => {
            const value = source[key];

            if (TypeConverter.isNull(value)) {
                // Don't add null params
                return;
            }

            if (typeof value === 'function') {
                // Can't add functions
                return;
            }

            if (TypeConverter.isArray(value)) {
                value
                    .forEach(i => {
                        params.push(`${key}=${encodeURIComponent(i)}`);
                    });
            } else {
                let stringValue = value.toString();
                if(TypeConverter.isDate(value) || TypeConverter.isDateTime(value) || TypeConverter.isSmallDate(value)) {
                    stringValue = value.toJSON();
                }

                params.push(`${key}=${encodeURIComponent(stringValue)}`);
            }
        });

    return params.toDelimitedString('&');
}
export function convertToQueryString(Obj: any) {
    let params = new HttpParams();

    for (let key in Obj) {
        if (Obj.hasOwnProperty(key) && Obj[key] !== null && Obj[key] !== undefined) {
            if (Array.isArray(Obj[key])) {
                Obj[key].forEach((val) => {
                    params = params.set(key, Obj[key].join(','));
                });
            }
            else { params = params.set(key, Obj[key].toString()); }
        }
    }
    return params.toString();
}
