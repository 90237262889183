import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({ name: 'textToHtml' })
export class TextToHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(text: string) {
        const html = text.replace(/\n/gi, '<br />');
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
