import { Action } from '@ngrx/store';
import { EnumerationModel } from '../../../_sdk/common/enumeration.model';
import { ModelCollection } from '../../../_sdk/collection.model';

export const LOAD = 'ENUMERATION_LOAD';
export const LOAD_COMPLETE = 'ENUMERATION_LOAD_COMPLETE';

export const INSERT = 'ENUMERATION_INSERT';
export const INSERT_COMPLETE = 'ENUMERATION_INSERT_COMPLETE';

export const UPDATE = 'ENUMERATION_UPDATE';
export const UPDATE_COMPLETE = 'ENUMERATION_UPDATE_COMPLETE';

export const DELETE = 'ENUMERATION_DELETE';
export const DELETE_COMPLETE = 'ENUMERATION_DELETE_COMPLETE';

export const DISPOSE = 'ENUMERATION_DISPOSE';

export class Load implements Action {
    public readonly type = LOAD;
}

export class LoadComplete implements Action {
    public readonly type = LOAD_COMPLETE;

    constructor(public payload: ModelCollection<EnumerationModel>) { }
}

export class Insert implements Action {
    public readonly type = INSERT;

    constructor(public payload: EnumerationModel) { }
}

export class InsertComplete implements Action {
    public readonly type = INSERT_COMPLETE;

    constructor(public payload: EnumerationModel) { }
}

export class Update implements Action {
    public readonly type = UPDATE;

    constructor(public payload: EnumerationModel) { }
}

export class UpdateComplete implements Action {
    public readonly type = UPDATE_COMPLETE;

    constructor(public payload: EnumerationModel) { }
}

export class Delete implements Action {
    public readonly type = DELETE;

    constructor(public payload: EnumerationModel) { }
}

export class DeleteComplete implements Action {
    public readonly type = DELETE_COMPLETE;

    constructor(public payload: EnumerationModel) { }
}

export class Dispose implements Action {
    public readonly type = DISPOSE;
}

export type Actions =
    Load | LoadComplete
    | Insert | InsertComplete
    | Update | UpdateComplete
    | Delete | DeleteComplete
    | Dispose;

