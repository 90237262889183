
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { HttpRequestIndicatorService } from './http-request-indicator.service';

@Injectable()
export class HttpRequestIndicatorInterceptor implements HttpInterceptor {

    // https://github.com/angular/angular/issues/18224

    constructor(private readonly ajaxRequestService: HttpRequestIndicatorService) {
        
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.ajaxRequestService.addRequest(request.url);

        const clonedRequest = request.clone();

        return next.handle(clonedRequest).pipe(
            finalize(() => {
                this.ajaxRequestService.removeRequest(clonedRequest.url);
            }));
    }
}
