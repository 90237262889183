// https://github.com/toddmotto/angular-dynamic-forms

import { Component, EventEmitter, Injectable, Input, OnChanges, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';

import { Observable } from 'rxjs';

import { FieldConfig } from '../models/field-config.interface';
import { LayoutMode, DisplayMode } from '../models/modes';
import { BaseComponent } from '../../../../_core/components/base.component';
import { DynamicFormModule } from '../..';

@Component({
    exportAs: 'dynamicForm',
    // tslint:disable-next-line:component-selector
    selector: 'dynamic-form',
    styleUrls: ['dynamic-form.component.scss'],
    template: `
    <div class="w-100 dynamic-form" [formGroup]="form">
        <dynamic-layout [layoutMode]="layoutMode" [displayMode]="displayMode" [config]="config">
            <ng-container *horizontalLayout="let field">
                <label *ngIf="field.label" class="col-auto" [formControlName]="field.name">{{ field.label }}</label>
                <ng-container dynamicField [config]="field" [group]="form" class="col-auto"></ng-container>
                <small class="form-text text-muted" *ngIf="field.description">{{ field.description }}</small>
            </ng-container>
            <ng-container *verticalLayout="let field">
                <label geneaFormLabelStyle *ngIf="field.label" [formControlName]="field.name">{{ field.label }}</label>
                <div geneaFormInputStyle>
                    <ng-container dynamicField [config]="field" [group]="form"></ng-container>
                    <small class="form-text text-muted" *ngIf="field.description">{{ field.description }}</small>
                </div>
            </ng-container>
            <ng-container *listLayout="let field">
                <dt class="col-3"><label *ngIf="field.label" class="col-form-label" [formControlName]="field.name">{{ field.label }}</label></dt>
                <dd geneaFormInputStyle>
                    <ng-container dynamicField [config]="field" [group]="form"></ng-container>
                    <small class="form-text text-muted" *ngIf="field.description">{{ field.description }}</small>
                </dd>
            </ng-container>
        </dynamic-layout>
    </div>
  `
})
export class DynamicFormComponent extends BaseComponent implements OnChanges {

    @Input()
    public extra: any;

    @Input()
    public config: Array<FieldConfig> = [];

    @Input()
    public layoutMode: LayoutMode = 'vertical';

    @Input()
    public displayMode: DisplayMode = 'edit';

    @Output()
    public submit: EventEmitter<any> = new EventEmitter<any>();

    private set _config(value: Array<FieldConfig>) {
        this.config = value;
    }
    private get _config(): Array<FieldConfig> {
        return (this.config || [])
            .map(config => {
                if(this.displayMode === 'readonly') {
                    config.type = 'static';
                }

                return config;
            })
    }

    public readonly form: FormGroup;
    public readonly changes$: Observable<any>;

    public get controls(): Array<FieldConfig> { return (this._config && this._config.filter(({ type }) => type !== 'button')) || []; }
    public get valid(): boolean { return this.form.valid; }
    public get value(): any { return this.form.value; }

    constructor(protected readonly formBuilder: FormBuilder) {
        super();
        this.form = this.createGroup();
        this.changes$ = this.form.valueChanges;
    }

    OnInit() {
        this.controls.forEach(control => this.form.addControl(control.name, this.createControl(control)));

        super.OnInit();
    }

    ngOnChanges() {
        if (this.form) {
            const controls = Object.keys(this.form.controls);
            const configControls = this.controls.map((item) => item.name);

            controls
                .filter((control) => !configControls.includes(control))
                .forEach((control) => this.form.removeControl(control));

            configControls
                .forEach((name) => {
                    const config = this._config.find((control) => control.name === name);
                    if (this.form.contains(name)) {
                        // Check if the form control value has been modified by the user
                        const controlValue = this.form.controls[name].value;
                        const defaultValue = config['value'];
                        if (controlValue !== defaultValue) {
                            // Do not reset the value if it has been modified
                            return;
                        }
                        this.form.controls[name].setValue(config['value']);
                    } else {
                        this.form.addControl(name, this.createControl(config));
                    }
                });
        }
    }

    public createGroup(): FormGroup {
        const group = this.formBuilder.group({});
        this.controls.forEach(control => group.addControl(control.name, this.createControl(control)));
        return group;
    }

    public createControl(config: FieldConfig): FormControl {
        const { disabled, validation, value } = config;
        return this.formBuilder.control({ disabled, value }, validation);
    }

    public handleSubmit(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.submit.emit(this.value);
    }

    public setDisabled(name: string, disable: boolean): void {
        if (this.form.controls[name]) {
            const method = disable ? 'disable' : 'enable';
            this.form.controls[name][method]();
            return;
        }

        this._config = this._config.map((item) => {
            if (item.name === name) {
                item.disabled = disable;
            }

            return item;
        });
    }

    public setValue(name: string, value: any): void {
        this.form.controls[name].setValue(value, { emitEvent: true });
    }

    public resetForm(): void {
        this.controls.forEach(control => {
            this.form.controls[control.name].setValue(control.value, { emitEvent: false });
        });

        this.form.updateValueAndValidity({ emitEvent: true });
    }

    public resetValue(name: string): void {
        const config = this.controls.find(control => control.name === name);

        if (!config) {
            return;
        }

        if (this.form.controls[config.name] === null
            || this.form.controls[config.name] === undefined) {
            return;
        }

        this.form.controls[name].setValue(config.value, { emitEvent: true });
    }
}
