import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLanguage from './language/language.reducers';
import * as fromLocaleStringResource from './locale-string-resource/locale-string-resource.reducers';

export const FEATURE_NAME = 'localizationStore';

export interface FeatureState {
    languageState: fromLanguage.State;
    localeStringResourceState: fromLocaleStringResource.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    languageState: fromLanguage.reducer,
    localeStringResourceState: fromLocaleStringResource.reducer
};

export const featureSelector = createFeatureSelector<FeatureState>(FEATURE_NAME);

const getLanguageStateSelector = (state: FeatureState) => state.languageState;
export const getLanguageState = createSelector(featureSelector, getLanguageStateSelector);
export const getLanguages = createSelector(getLanguageState, s => s.languages);

const getLocaleStringResourceStateSelector = (state: FeatureState) => state.localeStringResourceState;
export const getLocaleStringResourceState = createSelector(featureSelector, getLocaleStringResourceStateSelector);
export const getLocaleStringResources = createSelector(getLocaleStringResourceState, s => s.resources);
