import { EntityModel, CoreEntity } from '../model';

import { DataProperty, DataObject } from '../../_core/type-converter';
import { CustomerModel } from '../customers/customer.model';
import { AddressModel } from '../common/address.model';
import { TimezoneModel } from '../common/timezone-info.model';
import { ServiceTypeModel } from '../leases/service-type.model';
import { RemittanceAddressModel } from '../billing/remittance-address.model';

import { CompositeType } from '../../_core/type-converter';
import { FilterContext } from '../page-state';
import { TimeSpan } from '../../_core/date';
import { BuildingAreaDefaultModel } from './building-area-default.model';
import { BuildingGroupModel } from './building-group.model';
import { BuildingProductModel } from '../products/building-product.model';
import { EntityType } from '../search/entity-type.enum';
import { EmsDeviceControllerModel } from '../ems-devices/ems-device-controller.model';
import { SubmeterBillingSettingsModel } from '../submeter-billing-settings/submeter-billing-settings-model';
import { BillingDefaultScale, BuildingClassification, BuildingType, MeterType, ReaderType } from '../../admin/platform/buildings/enumerations/submeter-billing-setting.enum';

@DataObject()
export class BuildingModel extends EntityModel implements CoreEntity {

    public readonly EntityType: EntityType = EntityType.Building;

    public Name: string = null;
    public Description: string = null;
    public CreatedOn: Date = null;
    public ModifiedOn: Date = null;
    public Active = false;
    public BuildingSupportNumber: string = null;

    public ExternalAccountingBuildingId: string = null;

    public ExternalAccountingHvacHourlyCost: number = null;

    public ExternalAccountingHvacCostReference: string = null;

    public OnDemandEarlyStartMinutes = 0;

    public LightingEarlyStartMinutes = 0;

    public CourtesyEarlyStartMinutes = 0;

    public MinimumRequestDurationMinutes = 0;

    public MaximumRequestDurationMinutes = 0;

    public IsSecurityUserLightingOnly = false;

    public EnableWelcomeNotificationToCustomerRoles = false;

    public EnableWelcomeNotificationToTenantRoles = false;

    public AccountingFileReportId:Array<number> = [];
    
    public EnableDexma = false;

    public DexmaSourceKey : string = null;

    public DexmaSourceToken : string = null;

    public BuildingType: BuildingType = null;

    public BuildingClassification: BuildingClassification = null;
    
    public ReaderType: ReaderType = null;

    public ReaderTypes: Array<ReaderType> = [];
    
    public MeterType: MeterType = null;

    public DifficultyScale: BillingDefaultScale = null;

    @DataProperty()
    public BuildingClassifyData: SubmeterBillingSettingsModel = null;

    @DataProperty({ instanceType: EmsDeviceControllerModel })
    public EmsDeviceControllers: Array<EmsDeviceControllerModel> = [];

    @DataProperty()
    public DefaultServiceType: ServiceTypeModel = null;

    @DataProperty()
    public Customer: CustomerModel = null;

    @DataProperty()
    public Address: AddressModel = null;

    @DataProperty()
    public TimeZoneInfo: TimezoneModel = null;

    @DataProperty()
    public RemittanceAddress: RemittanceAddressModel = null;

    @DataProperty()
    public BuildingGroup: BuildingGroupModel = null;

    @DataProperty()
    public BuildingAreaDefault: BuildingAreaDefaultModel = null;

    @DataProperty({ instanceType: BuildingProductModel })
    public BuildingProducts: Array<BuildingProductModel> = null;

    public get OnDemandEarlyStartMinutesComputed(): TimeSpan {
        return TimeSpan.fromMinutes(this.OnDemandEarlyStartMinutes || 0);
    }
    public set OnDemandEarlyStartMinutesComputed(value: TimeSpan) {
        this.OnDemandEarlyStartMinutes = Math.floor(value.totalMinutes);
    }

    public get LightingEarlyStartMinutesComputed(): TimeSpan {
        return TimeSpan.fromMinutes(this.LightingEarlyStartMinutes || 0);

    }
    public set LightingEarlyStartMinutesComputed(value: TimeSpan) {
        this.LightingEarlyStartMinutes = Math.floor(value.totalMinutes);
    }

    public get CourtesyEarlyStartMinutesComputed(): TimeSpan {
        return TimeSpan.fromMinutes(this.CourtesyEarlyStartMinutes || 0);

    }
    public set CourtesyEarlyStartMinutesComputed(value: TimeSpan) {
        this.CourtesyEarlyStartMinutes = Math.floor(value.totalMinutes);
    }

    public get MinimumRequestDurationMinutesComputed(): TimeSpan {
        return TimeSpan.fromMinutes(this.MinimumRequestDurationMinutes || 0);
    }
    public set MinimumRequestDurationMinutesComputed(value: TimeSpan) {
        this.MinimumRequestDurationMinutes = Math.floor(value.totalMinutes);
    }

    public get MaximumRequestDurationMinutesComputed(): TimeSpan {
        return TimeSpan.fromMinutes(this.MaximumRequestDurationMinutes || 0);
    }
    public set MaximumRequestDurationMinutesComputed(value: TimeSpan) {
        this.MaximumRequestDurationMinutes = Math.floor(value.totalMinutes);
    }
}

export type BuildingForStorage = Pick<BuildingModel, 'Name' | 'Id'>;

export interface BuildingFilter {
    Id?: number;
    CustomerId?: number;
    TenantId?: number;
    EmsDeviceControllerId?: number;
    Name?: string;
    CustomerName?: string;
    TimeZoneId?: string;
    BuildingGroupName?: string;
}

export type BuildingFilterState = CompositeType<BuildingFilter, FilterContext>;
