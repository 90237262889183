import { DataObject, DataProperty, TypeConverter } from '../../_core/type-converter';
import { EntityModel } from '../model';
import { TimeSpan } from '../../_core/date';
import { BuildingServiceHourModel } from './building-service-hour.model';
import { ServiceHoursModel } from '../leases/service-hours.model';
import { ServiceRequestDurationModel } from '../leases/service-request-duration.model';
import { ServiceRequestDurationsModel } from '../leases/service-request-durations.model';
import { ServiceHourModel } from '../leases/service-hour.model';

@DataObject()
export class BuildingAreaDefaultModel extends EntityModel {
    //#region Request default
    public EnableRequests: boolean = null;
    public EnableRecurringRequests: boolean = null;
    public EnableHVACRecurringRequests: boolean = null;
    public EnableLightingRecurringRequests: boolean = null;
    public EnableManagerRecurringRequests: boolean = null;
    public EnableUserRecurringRequests: boolean = null;
    public SeparateLeaseHoursPerServiceType: boolean = null;
    public RequirePlatformUserRequestReference: boolean = null;
    public RequireTenantUserRequestReference: boolean = null;
    public ControlHVACAndLightTogether: boolean = null;
    public NotifyRequester: boolean = null;
    public NotifyPropertyManager: boolean = null;
    public NotifyBuildingEngineer: boolean = null;
    public NotifyTenantManager: boolean = null;
    //#endregion

    public HVACAndLightRate: number = null;
    public LightOnlyRate: number = null;

    //#region Billing default
    public AdministrationMonthlyFee: number = null;
    public AdministrationMonthlyPercent: number = null;
    public StartupFee: number = null;
    public CancellationFee: number = null;

    @DataProperty()
    public MinimumBillTime: TimeSpan = null;

    @DataProperty()
    public BeforeLeaseGracePeriod: TimeSpan = null;
    public BeforeLeaseWithinGracePeriodStartupFee: number = null;

    @DataProperty()
    public AfterLeaseGracePeriod: TimeSpan = null;
    public AfterLeaseWithinGracePeriodStartupFee: number = null;

    @DataProperty()
    public BetweenRequestsGracePeriod: TimeSpan = null;
    public BetweenRequestsWithinGracePeriodStartupFee: number = null;

    @DataProperty()
    public UseRequestStartTimeGracePeriod: TimeSpan = null;
    //#endregion

    //#region Service Hours
    @DataProperty({ instanceType: ServiceHourModel })
    public ServiceRequestHours: Array<ServiceHourModel> = [];

    // Computed...
    // Projects an array based on the current lease area's hours.
    public get ServiceRequestHoursComputed(): Array<ServiceHoursModel> {
        if (!!this.ServiceRequestHours) {
            return this.ServiceRequestHours
                .filter(p => p.ServiceType)
                .orderBy(p => p.ServiceType.Name)
                .groupBy(p => p.ServiceType.Id)
                .map((leaseAreaHour, serviceTypeId) => {
                    return <ServiceHoursModel>{
                        serviceType: leaseAreaHour
                            .map(p => p.ServiceType)
                            .find(p => p.Id === serviceTypeId),
                        timeRanges: leaseAreaHour
                            .orderBy(p => p.DayOfWeek)
                            .map(p => p.timeRangeComputed)
                    };
                });
        }

        return [];
    }

    // Takes the incoming value and sets the lease area's hours value
    public set ServiceRequestHoursComputed(value: Array<ServiceHoursModel>) {
        // Default the property
        this.ServiceRequestHours = (this.ServiceRequestHours || []).filter(p => p.ServiceType);

        // Default the value
        value = value || [];

        if (!value.any()) {
            // No value... Clear out the property
            this.ServiceRequestHours.length = 0; // resets the array...
            return;
        }

        value.forEach(serviceHour => {
            serviceHour.timeRanges
                .forEach(timeRange => {
                    let byService = this.ServiceRequestHours.find(p =>
                        p.ServiceType.Id === serviceHour.serviceType.Id
                        && p.DayOfWeek === timeRange.dayOfWeek);

                    if (!byService) {
                        this.ServiceRequestHours.push(
                            // Little shortcut, here...
                            // Create the instance, set the 'byService' variable, and push it to the array...
                            byService = TypeConverter.convert(BuildingServiceHourModel, {
                                BuildingId: this.Id,
                                ServiceType: serviceHour.serviceType
                            })
                        );
                    }

                    // Pushes the timeRange value to the underlying lease area hour instance.
                    byService.timeRangeComputed = timeRange;
                });
        });
    }
    //#endregion Service Hours

    //#region Service Durations
    @DataProperty({ instanceType: ServiceRequestDurationModel })
    public ServiceRequestDurations: Array<ServiceRequestDurationModel> = [];

    public get ServiceRequestDurationsComputed(): Array<ServiceRequestDurationsModel> {
        if (!!this.ServiceRequestDurations) {
            return this.ServiceRequestDurations
                .filter(p => p.ServiceType)
                .orderBy(p => p.ServiceType.Name)
                .groupBy(p => p.ServiceType.Id)
                .map((leaseAreaRequestDuration, serviceTypeId) => {
                    return <ServiceRequestDurationsModel>{
                        serviceType: leaseAreaRequestDuration
                            .map(p => p.ServiceType)
                            .find(p => p.Id === serviceTypeId),
                        requestDurations: leaseAreaRequestDuration
                            .orderBy(p => p.UserRole.Name)
                    };
                });
        }

        return [];
    }

    public set ServiceRequestDurationsComputed(value: Array<ServiceRequestDurationsModel>) {
        this.ServiceRequestDurations = (this.ServiceRequestDurations || []).filter(p => p.ServiceType);

        // Default the value
        value = value || [];

        if (!value.any()) {
            // No value... Clear out the property
            this.ServiceRequestDurations.length = 0; // resets the array...
            return;
        }

        value.forEach(duration => {
            duration.requestDurations
                .forEach(requestDuration => {
                    let byService = this.ServiceRequestDurations.find(p =>
                        p.ServiceType.Id === duration.serviceType.Id
                        && p.UserRole.Id === requestDuration.UserRole.Id);

                    if (!byService) {
                        this.ServiceRequestDurations.push(
                            // Little shortcut, here...
                            // Create the instance, set the 'byService' variable, and push it to the array...
                            byService = TypeConverter.convert(ServiceRequestDurationModel, {
                                UserRole: requestDuration.UserRole,
                                ServiceType: duration.serviceType
                            })
                        );
                    }

                    byService.DurationHours = requestDuration.DurationHours;
                });
        });
    }
    //#endregion Service Durations
}
