import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from './_core/components/base.component';
import { GoogleAnalyticsService } from './_modules/analytics/google-analytics.service';
import { HotjarService } from './_modules/analytics/hotjar.service';
import { IntercomService } from './_modules/intercom/intercom.service';
import { VersionCheckService } from './_services/versioning/version-check.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent extends BaseComponent {
    constructor(
        private readonly router: Router,
        private readonly gaService: GoogleAnalyticsService, 
        private readonly intercomService: IntercomService,
        private readonly versionChecker: VersionCheckService,
        private readonly hotjar:HotjarService) {
        super(); 
        
        this.gaService.initialize(this.router);
        this.intercomService.initialize(this.router);
        this.hotjar.initialize(this.router)

        this.versionChecker.runCheckVersion(1000 * 5);
    }

    /* @HostListener('window:beforeunload', ['$event'])
    public beforeunloadHandler(_): void {
        this.authService.destroySession();
    } */
}
