import { Directive, ViewContainerRef } from "@angular/core";
import { NamedComponentContainerService } from "./named-component-container.service";

@Directive({
    selector: 'geneaNamedComponentContainer'
})
export class NamedComponentContainerDirective {
    constructor(container: ViewContainerRef, service: NamedComponentContainerService){
        service.container = container;
    }
}