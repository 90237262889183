import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GeneaFieldsetComponent } from './fieldset.component';
import { GeneaFieldsetContentDirective } from './fieldset-content.directive';
import { GeneaFieldsetLegendDirective } from './fieldset-legend.directive';
import { GeneaFieldsetDescriptionDirective } from './fieldset-description.directive';
import { GeneaFormLabelDirective } from './form-label.directive';
import { GeneaFormInputDirective } from './form-input.directive';

@NgModule({
    declarations: [
        
        GeneaFieldsetComponent,
        GeneaFieldsetContentDirective,
        GeneaFieldsetLegendDirective,
        GeneaFieldsetDescriptionDirective,

        GeneaFormLabelDirective,
        GeneaFormInputDirective,
        
    ],
    imports: [
        CommonModule
    ],
    exports: [
        GeneaFieldsetComponent,
        GeneaFieldsetContentDirective,
        GeneaFieldsetLegendDirective,
        GeneaFieldsetDescriptionDirective,
        
        GeneaFormLabelDirective,
        GeneaFormInputDirective
    ]
})
export class GeneaFieldsetModule {
    static exports(): Array<any> {
        return [
            GeneaFieldsetComponent,
            GeneaFieldsetContentDirective,
            GeneaFieldsetLegendDirective,
            GeneaFieldsetDescriptionDirective,
            
            GeneaFormLabelDirective,
            GeneaFormInputDirective
        ];
    }
 }
