export enum PointRestriction {
    None = 0,
    ReadOnly = 1,
    Manual = 2
}

export enum PointRole {
    None = 0,
    HVAC = 1,
    Lights = 2,
    Metering = 3
}

export const PointRestrictionNames = new Map<number, string>([
    [PointRestriction.None, 'None'],
    [PointRestriction.ReadOnly, 'Read Only'],
    [PointRestriction.Manual, 'Manual']
]);

export const PointRoleNames = new Map<number, string>([
    [PointRole.None, 'None'],
    [PointRole.HVAC, 'HVAC'],
    [PointRole.Metering, 'Metering'],
    [PointRole.Lights, 'Lights']
]);
