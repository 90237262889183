
import { Directive, ElementRef } from '@angular/core';

import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

import { tap } from 'rxjs/operators';

import { InputTouchDirective } from './input-touch.directive';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'kendo-dropdownlist'
})
export class KendoTouchDirective extends InputTouchDirective {
    constructor(private el: ElementRef, private readonly component: DropDownListComponent) {
        super();

        this.addSubscription(
            component.onFocus
                .pipe(
                    tap(() => super.onFocus())
                )
        );
        this.addSubscription(
            component.onBlur
                .pipe(
                    tap(() => super.onBlur())
                )
        );
    }
}
