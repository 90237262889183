import { DataObject, CompositeType, DataProperty } from '../../_core/type-converter';
import { EntityModel } from '../model';
import { FilterState } from '../page-state';
import { EnumerationItemModel } from './enumeration-item.model';

@DataObject()
export class EnumerationModel extends EntityModel {
    public Name: string = null;
    public Description: string = null;
    public SystemName: string = null;
    public Active = false;
    public Type: string = null;
    public AssemblyQualifiedName: string = null;

    @DataProperty({ instanceType: EnumerationItemModel })
    public EnumerationItems: Array<EnumerationItemModel> = []
}

export interface EnumerationFilter {
    Name?: string;
    SystemName?: string;
    Type?: string;
    AssemblyQualifiedName?: string;
}

export type EnumerationFilterState = CompositeType<EnumerationFilter, FilterState>;
