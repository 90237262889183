import { createFeatureSelector, createSelector } from '@ngrx/store';

import { of, Observable, BehaviorSubject, Subject } from 'rxjs';
import { switchMap, tap, finalize, map, delay } from 'rxjs/operators';

import { MemoizedSelector } from '@ngrx/store/src/selector';

import { ModelCollection } from '../_sdk/collection.model';
import { BaseModel } from '../_sdk/model';

import { ApiService } from './api.service';
import { TypeConverter } from '../_core/type-converter';
import { InvalidOperationException } from '../_core/exceptions/invalid-operation.exception';

export interface EntityService<T extends BaseModel> {

    readonly loading$: Observable<boolean>;

    getById(id: string | number): Observable<T>;
    search(queryObject?: any): Observable<ModelCollection<T>>;
    update(model: T): Observable<T>;
    updateRange(models: Array<T>): Observable<Array<T>>;
    insert(model: T): Observable<T>;
    insertRange(models: Array<T>): Observable<Array<T>>;
    delete(model: number | string | T): Observable<T>;
    deleteRange(models: Array<number | string | T>): Observable<Array<T>>;
}

export abstract class BaseEntityService<T extends BaseModel> implements EntityService<T> {

    public readonly id: string;

    // Added to subscribe explicitly to changes...
    private readonly _entityChangedSubject$ = new Subject<T | Array<T>>();
    public readonly entityChanged$ = this._entityChangedSubject$.asObservable();

    private readonly _loading$ = new BehaviorSubject(false);
    public readonly loading$ = this._loading$.asObservable().pipe(delay(0));

    constructor(protected readonly apiService: ApiService) {
        this.id = String.random();
    }

    protected getKey(model: number | string | T): string {
        let id: string;

        if (TypeConverter.isString(model)) { // string
            id = model;
        } else if (TypeConverter.isNumber(model)) { // number
            id = model.toString();
        } else if (model as BaseModel) { // BaseModel
            id = (<BaseModel>model).Id.toString();
        } else {
            throw new InvalidOperationException('Invalid argument type. [model] must be of type [string], [number], or [BaseModel]');
        }

        return id;
    }

    protected abstract get baseUri(): string;

    protected toEntity(source: any): T {
        return source as T;
    }

    public delete(model: number | string | T): Observable<T> {
        const id = this.getKey(model);

        const uri = `${this.baseUri}/${id}`;

        return this.getRequest(this.apiService.delete<T>(uri))
            .pipe(
                map(entity => this.toEntity(entity)),
                tap(entity => this._entityChangedSubject$.next(entity))
            );
    }

    // experimental
    public deleteRange(models: Array<number | string | T>): Observable<any> {
        const ids = models
            .map(model => this.getKey(model));

        const uri = `${this.baseUri}/delete-range`;

        return this.getRequest(this.apiService.put<any>(uri, ids))
            .pipe(
                tap(() => this._entityChangedSubject$.next(null))
            );
    }

    public insert(model: T): Observable<T> {
        const uri = `${this.baseUri}`;

        return this.getRequest(this.apiService.insert<T>(uri, model))
            .pipe(
                map(entity => this.toEntity(entity)),
                tap(entity => this._entityChangedSubject$.next(entity))
            );
    }

    // experimental
    public insertRange(models: Array<T>): Observable<Array<T>> {
        const uri = `${this.baseUri}/synchronize`;

        return this.getRequest(this.apiService.post<any>(uri, models))
            .pipe(
                map(collection => {
                    return collection.data
                        .map(item => this.toEntity(item));
                }),
                tap(data => this._entityChangedSubject$.next(data))
            );
    }

    public update(model: T): Observable<T> {
        const uri = `${this.baseUri}`;

        return this.getRequest(this.apiService.update<T>(uri, model))
            .pipe(
                map(entity => this.toEntity(entity)),
                map(entity => {
                    if (entity && entity.materialize) {
                        return entity.materialize();
                    }

                    return entity;
                }),
                tap(entity => this._entityChangedSubject$.next(entity))
            );
    }

    // experimental
    public updateRange(models: Array<T>): Observable<Array<T>> {
        const uri = `${this.baseUri}/range`;

        return this.getRequest(this.apiService.put(uri, models))
            .pipe(
                map(collection => {
                    return collection.data
                        .map(item => this.toEntity(item));
                }),
                tap(data => this._entityChangedSubject$.next(data))
            );
    }

    public search(queryObject?: any): Observable<ModelCollection<T>> {
        const uri = `${this.baseUri}/search`;

        return this.getRequest(this.apiService.query<T>(uri, queryObject))
            .pipe(
                map(collection => {
                    collection.data.forEach((item: T, index: number) => {
                        collection.data[index] = this.toEntity(item);
                    });
                    return collection;
                })
            );
    }

    public getById(id: string | number): Observable<T> {
        const uri = `${this.baseUri}/${id}`;

        return this.apiService.querySingle<T>(uri)
            .pipe(
                map(entity => this.toEntity(entity)),
                map(entity => {
                    if (entity && entity.materialize) {
                        return entity.materialize();
                    }

                    return entity;
                })
            );
    }

    protected getRequest<R>(query: Observable<R>): Observable<R> {
        return of(true)
            .pipe(
                tap(_ => this._loading$.next(true)),
                switchMap(_ => query),
                tap(_ => this._loading$.next(false)),
                finalize(() => this._loading$.next(false))
            )
    }

    protected changeEntity(entity: T): void {
        this._entityChangedSubject$.next(entity);
    }

    // The storeModules registered with "StoreModule.forFeature()" have to be selected with this method.
    protected getFeatureStoreSelector<TFeatureState, TSubState>(
        storeFeatureName: string,
        selectSubState: (s: TFeatureState) => TSubState)
        : MemoizedSelector<object, TSubState> {

        const featureSelector = createFeatureSelector<TFeatureState>(storeFeatureName);
        return this.getStateSelector(featureSelector, selectSubState);
    }

    protected getStateSelector<TSelector, TState>(
        storeSelector: MemoizedSelector<object, TSelector>,
        state: (s: TSelector) => TState
    ): MemoizedSelector<object, TState> {
        return createSelector(storeSelector, state);
    }
}
