import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class JsonInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const copiedRequest = request.clone({
            withCredentials: true,
            headers: (request.body instanceof(FormData))
                ? request.headers // If the current request contains for data (files), leave it as is...
                : request.headers.set('Content-Type', 'application/json')
        });

        return next.handle(copiedRequest);
    }
}
