export enum MeasurementUnit {
    kWh = 1,
    kW,
    kVAR,
    Pwr_Factor,
    Therms,
    Gallons,
    M_Gallons,
    CCF,
    CF,
    HCF,
    MCF,
    BTU,
    MBTU,
    Tons,
    Ton_Hours,
    Months,
    Weeks,
    Days,
    Hours,
    Minutes,
    Seconds,
    Pulses,
    Units,
    Watt_Hour = 24,
    Mega_Watt_Hour = 25,
    MMBTU = 26,
    SqFt = 27
}

export const MeasurementUnitNames = new Map<number, string>([
    [MeasurementUnit.kWh, 'kWh'],
    [MeasurementUnit.kW, 'kW'],
    [MeasurementUnit.kVAR, 'kVAR'],
    [MeasurementUnit.Pwr_Factor, 'Power Factor'],
    [MeasurementUnit.Therms, 'Therms'],
    [MeasurementUnit.Gallons, 'Gallons'],
    [MeasurementUnit.M_Gallons, 'Metric Gallons'],
    [MeasurementUnit.CCF, 'CCF'],
    [MeasurementUnit.CF, 'CF'],
    [MeasurementUnit.HCF, 'HCF'],
    [MeasurementUnit.MCF, 'MCF'],
    [MeasurementUnit.BTU, 'BTU'],
    [MeasurementUnit.MBTU, 'MBTU'],
    [MeasurementUnit.Tons, 'Tons'],
    [MeasurementUnit.Ton_Hours, 'Ton Hours'],
    [MeasurementUnit.Months, 'Months'],
    [MeasurementUnit.Weeks, 'Weeks'],
    [MeasurementUnit.Days, 'Days'],
    [MeasurementUnit.Hours, 'Hours'],
    [MeasurementUnit.Minutes, 'Minutes'],
    [MeasurementUnit.Seconds, 'Seconds'],
    [MeasurementUnit.Pulses, 'Pulses'],
    [MeasurementUnit.Units, 'Units'],
    [MeasurementUnit.Watt_Hour, 'Wh'],
    [MeasurementUnit.Mega_Watt_Hour, 'MWh'],
    [MeasurementUnit.MMBTU, 'MMBTU'],
    [MeasurementUnit.SqFt, 'Sq. ft.']
]);