import { BaseModel, EntityModel } from '../../../../../_sdk/model';
import { DataProperty, DataObject } from '../../../../../_core/type-converter';
import { SortDirectionType } from '../../../../../_sdk/sort-direction.enumeration';
import { ModelCollection } from '../../../../../_sdk/collection.model';
import { InvoicesPropertyManagerModel } from './invoices-property-manager.model';
import { CostTrendPMModel } from './cost-trend-pm.model';
import { RequestTrendPMModel } from './request-chart-trends.model';
import { RequestRuntimePMModel } from './request-runtime.model';


@DataObject()
export class HvacPropertyManagerViewModel extends BaseModel {
    public BuildingId : number = null;
    public BuildingName : string = null;
    public CustomerId : number = null;
    public CustomerName : string = null;

    @DataProperty({ converter: (s) => new ModelCollection(s) })
    public InvoicesHistory : ModelCollection<InvoicesPropertyManagerModel> = null;
    
    @DataProperty()
    public ActionRequriedInvoices : Array<object> = [];

    @DataProperty()
    public CostChartData : CostTrendPMModel = null;

    @DataProperty()
    public RequestChartData : RequestTrendPMModel = null;

    @DataProperty()
    public RequestsRuntime : RequestRuntimePMModel = null;
    
    public Total:number = 0;
}
export interface HvacPMVFilterState {
    BuildingId ?: number;
    SortField ?: string;
    SortDirection ?: SortDirectionType;
    pageSize ?: number;
    pageIndex ?: number;
}