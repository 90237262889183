import { BaseModel } from '../model';
import { CommandRule, CommandRole } from './command.enums';
import { DataProperty, DataObject } from '../../_core/type-converter';
import { ParameterValueModel } from '../parameter-configuration/parameter-value.model';
import { ProtocolTypeModel } from './protocol-type.model';

@DataObject()
export class CommandModel extends BaseModel {

    // From CommandType
    // NOTE: Id comes from CommandType...

    public Name: string = null;
    public Description: string = null;
    public SystemName: string = null;

    // From ConnectionCommand
    public Sequence: number = null;
    public Rule: CommandRule = CommandRule.ExecuteAlways;
    public Role: CommandRole = CommandRole.Read;
    public Active = false;

    @DataProperty()
    public ProtocolType: ProtocolTypeModel = null;

    @DataProperty({ instanceType: ParameterValueModel })
    public Parameters: Array<ParameterValueModel> = [];

}
