
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { TypeConverter } from '../_core/type-converter';

import { WorkContext } from '../_core/work-context';
import { QueryVisitor } from './query.visitor';

@Injectable()
export class ScopedQueryVisitor extends QueryVisitor {
    constructor(private readonly workContext: WorkContext) {
        super();
    }

    public visit(queryObject?: any): Observable<any> {
        let source$: Observable<any>;

        // Make sure we're working with observables.
        if (queryObject instanceof Observable) {
            source$ = queryObject;
        } else {
            source$ = of(queryObject || {});
        }

        return source$
            .pipe(
                switchMap(source => {
                    return this.workContext.scope$
                        .pipe(
                            map(scope => {
                                const proxy = Object.assign({}, source);

                                proxy.CustomerId = (scope.customer && scope.customer.Id) || source.CustomerId;
                                proxy.BuildingGroupId = (scope.buildingGroup && scope.buildingGroup.Id) || source.BuildingGroupId;
                                proxy.BuildingId = (scope.building && scope.building.Id) || source.BuildingId;
                                proxy.TenantId = (scope.tenant && scope.tenant.Id) || source.TenantId;
                                proxy.LeaseId = (scope.lease && scope.lease.Id) || source.LeaseId;
                                proxy.LeaseAreaId = (scope.leaseArea && scope.leaseArea.Id) || source.LeaseAreaId;

                                return proxy;
                            }),
                            distinctUntilChanged(TypeConverter.isEquivalent)
                        );
                })
            );
    }
}

