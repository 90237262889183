<ng-container [formGroup]="searchInput">
    <kendo-floatinglabel text="{{label}}" class="w-100">
        <kendo-textbox
        *ngIf="useContainer; else noContainer"
        [clearButton]="true"
        formControlName="input"
        (focus)="hasFocus = true" 
        (blur)="hasFocus = false"
        [ngStyle]="styles"
    ></kendo-textbox>
  </kendo-floatinglabel>

    <ng-template #noContainer>
        <span [ngStyle]="styles">
            <input formControlName="input" placeholder="{{ label }}" (focus)="hasFocus = true" (blur)="hasFocus = false">
            <span class="k-icon k-i-search search-input-icon"></span>
        </span>
    </ng-template>
</ng-container>
