import { EntityModel, BaseModel } from '../model';
import { DataProperty, DataObject, CompositeType, TypeConverter } from '../../_core/type-converter';
import { FilterState } from '../page-state';
import { CommandRule, CommandRole } from './command.enums';
import { ProtocolTypeModel } from './protocol-type.model';
import { ParameterValueModel } from '../parameter-configuration/parameter-value.model';

@DataObject()
export class CommandTypeModel extends EntityModel {
    public Name: string = null;
    public Description: string = null;
    public SystemName: string = null;
    public Active = false;
    public ProtocolTypeId: number = null;
}

export interface CommandTypeFilter {
    Name?: string;
    ProtocolTypeId?: number;
}

export type CommandTypeFilterState = CompositeType<CommandTypeFilter, FilterState>;
