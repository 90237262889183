import { Component } from '@angular/core';
import { BaseComponent } from '../_core/components/base.component';

@Component({
    selector: 'app-page-not-found',
    template: `
        <style type="text/css">
            .error {
                height: 600px;
            }
        </style>

        <div class="error d-flex align-items-center">
            <div class="container">
                <img data-no-lazy="1" class="img-fluid mb-4" src="https://www.getgenea.com/wp-content/themes/nopio_master_theme/assets/images/not_found_img.jpg" alt="Not found page image">

                <h2 class="title-lg mb-3">Oops! That page can’t be found.</h2>

                <p>It looks like nothing was found at this location.</p>

                <a [routerLink]="['/']" class="btn btn-primary mt-3">Back to Home</a>
            </div>
        </div>
    `,
    styles: []
}) 
export class PageNotFoundComponent extends BaseComponent {
}
