export enum InvoiceStatusType {
    UpcomingInvoice = 0,
    Audit,
    ChangesRequested,
    PendingApproval,
    Approved,
    Closed,
    Voided,
    Overdue,
    Due,
    NotReady 
}

export const InvoiceStatusTypeNames = new Map<number, string>([
    [InvoiceStatusType.UpcomingInvoice, 'Upcoming Invoice'],
    [InvoiceStatusType.Audit, 'In Audit'],    
    [InvoiceStatusType.ChangesRequested, 'Changes Requested'],
    [InvoiceStatusType.PendingApproval, 'Pending Approval'],
    [InvoiceStatusType.Approved, 'Approved'],
    [InvoiceStatusType.Closed, 'Closed'],
    [InvoiceStatusType.Voided, 'Voided'],
    [InvoiceStatusType.Overdue, 'Overdue'],    
    [InvoiceStatusType.Due, 'Due'],
    [InvoiceStatusType.NotReady, 'Not Ready']
]);