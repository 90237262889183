import { HttpEvent } from '@angular/common/http';
import { RESTResponse } from './rest.service';

export enum Status {
    Information = 1,
    Success,
    Redirection,
    Error,
    ClientError,
    ServerError
}
export interface HttpStatus {
    readonly code: number;
    readonly description: string;
    readonly messages: Array<string>;
}

export class HttpStatus {

    public static readonly statuses: Array<HttpStatus> = [
        new HttpStatus(100, 'Continue'),
        new HttpStatus(101, 'Switching Protocol'),
        new HttpStatus(102, 'Processing (WebDAV)'),
        new HttpStatus(103, 'Early Hints'),
        new HttpStatus(200, 'OK'),
        new HttpStatus(201, 'Created'),
        new HttpStatus(202, 'Accepted'),
        new HttpStatus(203, 'Non - Authoritative Information'),
        new HttpStatus(204, 'No Content'),
        new HttpStatus(205, 'Reset Content'),
        new HttpStatus(206, 'Partial Content'),
        new HttpStatus(207, 'Multi - Status(WebDAV)'),
        new HttpStatus(208, 'Already Reported(WebDAV)'),
        new HttpStatus(226, 'IM Used(HTTP Delta encoding)'),
        new HttpStatus(300, 'Multiple Choice'),
        new HttpStatus(301, 'Moved Permanently'),
        new HttpStatus(302, 'Found'),
        new HttpStatus(303, 'See Other'),
        new HttpStatus(304, 'Not Modified'),
        new HttpStatus(305, 'Use Proxy'),
        new HttpStatus(306, 'unused'),
        new HttpStatus(307, 'Temporary Redirect'),
        new HttpStatus(308, 'Permanent Redirect'),
        new HttpStatus(400, 'Bad Request'),
        new HttpStatus(401, 'Unauthorized'),
        new HttpStatus(402, 'Payment Required'),
        new HttpStatus(403, 'Forbidden'),
        new HttpStatus(404, 'Not Found'),
        new HttpStatus(405, 'Method Not Allowed'),
        new HttpStatus(406, 'Not Acceptable'),
        new HttpStatus(407, 'Proxy Authentication Required'),
        new HttpStatus(408, 'Request Timeout'),
        new HttpStatus(409, 'Conflict'),
        new HttpStatus(410, 'Gone'),
        new HttpStatus(411, 'Length Required'),
        new HttpStatus(412, 'Precondition Failed'),
        new HttpStatus(413, 'Payload Too Large'),
        new HttpStatus(414, 'URI Too Long'),
        new HttpStatus(415, 'Unsupported Media Type'),
        new HttpStatus(416, 'Requested Range Not Satisfiable'),
        new HttpStatus(417, 'Expectation Failed'),
        new HttpStatus(418, 'I\'m a teapot'),
        new HttpStatus(421, 'Misdirected Request'),
        new HttpStatus(422, 'Unprocessable Entity(WebDAV)'),
        new HttpStatus(423, 'Locked(WebDAV)'),
        new HttpStatus(424, 'Failed Dependency(WebDAV)'),
        new HttpStatus(425, 'Too Early'),
        new HttpStatus(426, 'Upgrade Required'),
        new HttpStatus(428, 'Precondition Required'),
        new HttpStatus(429, 'Too Many Requests'),
        new HttpStatus(431, 'Request Header Fields Too Large'),
        new HttpStatus(451, 'Unavailable For Legal Reasons'),
        new HttpStatus(500, 'Internal Server Error'),
        new HttpStatus(501, 'Not Implemented'),
        new HttpStatus(502, 'Bad Gateway'),
        new HttpStatus(503, 'Service Unavailable'),
        new HttpStatus(504, 'Gateway Timeout'),
        new HttpStatus(505, 'HTTP Version Not Supported'),
        new HttpStatus(506, 'Variant Also Negotiates'),
        new HttpStatus(507, 'Insufficient Storage(WebDAV)'),
        new HttpStatus(508, 'Loop Detected(WebDAV)'),
        new HttpStatus(510, 'Not Extended'),
        new HttpStatus(511, 'Network Authentication Required'),
        new HttpStatus(1000, 'Communication Error')
    ];

    public static readonly DEFAULT_SUCCESS_MESSAGE = 'Your request has been processed successfully.';
    public static readonly DEFAULT_ERROR_MESSAGE = 'There was an error processing your request';

    public static fromCode(code: number): HttpStatus {
        return HttpStatus.statuses.find(p => p.code === code) || HttpStatus.statuses.find(p => p.code === 1000);
    }

    public static fromHttpEvent(event: HttpEvent<any>): HttpStatus {

        const code = (event && (<any>event).status) || 1000;

        const status = HttpStatus.fromCode(code);

        const restResponse = RESTResponse.fromHttpEvent(event);

        const messages: Array<string> = [];
        const warnings: Array<string> = [];

        // Check to see if the response is in the expected RESTResponse format
        if (restResponse) {

            if (restResponse.Success) {
                // Is it successful and are there messages?
                if (restResponse.Messages && restResponse.Messages.any()) {
                    // Yay! Get the messages...
                    messages.push(...restResponse.Messages.filter(p => p.MessageType === 0).map(p => p.Message));
                    warnings.push(...restResponse.Messages.filter(p => p.MessageType === 1).map(p => p.Message));
                } else if (status.success) {
                    // OK, the response was successful, but there's no messages... Let's add a default message.
                    messages.push(HttpStatus.DEFAULT_SUCCESS_MESSAGE);
                }
            } else {
                // Are there any error messages?
                if (restResponse.Errors && restResponse.Errors.any()) {
                    // Yay! Get the messages...
                    messages.push(...restResponse.Errors.map(p => p.Message));
                } else if (status.success) {
                    // This means the API has errors, but the response status was 200...
                    // We need to let the user know we DID communicate successfully, but there was an error, and we don't know what it is...
                    messages.push(HttpStatus.DEFAULT_ERROR_MESSAGE);
                }
            }

        }

        // If we don't have ANY messages, but the request was successful, let's default the message.
        if (!messages.any() && !warnings.any() && status.success) {
            messages.push(HttpStatus.DEFAULT_SUCCESS_MESSAGE);
        }

        // If we DO have messages, create a new HttpStatus and use the messages as the description
        if (messages.any() || warnings.any()) {
            // TODO: I don't like using "<br />" here... Let's come up with a nicer way...
            return new HttpStatus(code, status.description, messages, warnings);
        }

        // Lastly, return the original status...
        return status;
    }

    constructor(public readonly code: number, public readonly description: string = null, public readonly messages: Array<string> = null, public readonly warnings: Array<string> = null) { }

    public get status(): Status {
        if (this.clientError) {
            return Status.ClientError;
        }

        if (this.error) {
            return Status.Error;
        }

        if (this.information) {
            return Status.Information;
        }

        if (this.redirection) {
            return Status.Redirection;
        }

        if (this.serverError) {
            return Status.ServerError;
        }

        return Status.Success;
    }

    public get information(): boolean {
        return this.code > 100 && this.code < 200;
    }

    public get success(): boolean {
        return this.information || (this.code >= 200 && this.code < 300);
    }

    public get redirection(): boolean {
        return this.code >= 300 && this.code < 400;
    }

    public get error(): boolean {
        return this.code >= 400;
    }

    public get clientError(): boolean {
        return this.error && this.code < 500;
    }

    public get serverError(): boolean {
        return this.code >= 500;
    }
}
