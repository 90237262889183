import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { StateProvinceModel } from '../../../_sdk/directory/state-province.model';
import * as StateProvinceActions from './state-province.actions';

export interface State {
    stateProvinces: ModelCollection<StateProvinceModel>;
}

const initialState: State = {
    stateProvinces: new ModelCollection<StateProvinceModel>()
};

export function reducer(
    state: State = initialState,
    action: StateProvinceActions.Actions
): State {

    let stateProvinces: ModelCollection<StateProvinceModel>;
    let index: number;

    switch (action.type) {
        case StateProvinceActions.DISPOSE:
            return {
                ...state,
                stateProvinces: initialState.stateProvinces
            };
        case StateProvinceActions.LOAD_COMPLETE:
            return {
                ...state,
                stateProvinces: action.payload
            };
        case StateProvinceActions.LOAD:
        case StateProvinceActions.INSERT:
        case StateProvinceActions.UPDATE:
        case StateProvinceActions.DELETE:
            return state;
        case StateProvinceActions.INSERT_COMPLETE:
            stateProvinces = new ModelCollection(state.stateProvinces.data
                .map(p => TypeConverter.convert(StateProvinceModel, p)));
           
            stateProvinces.data.push(action.payload);

            return {
                ...state,
                stateProvinces: stateProvinces
            };

        case StateProvinceActions.UPDATE_COMPLETE:
            stateProvinces = new ModelCollection(state.stateProvinces.data
                .map(p => TypeConverter.convert(StateProvinceModel, p)));

            index = stateProvinces.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                stateProvinces.data[index] = action.payload;
            }

            return {
                ...state,
                stateProvinces: stateProvinces
            };

        case StateProvinceActions.DELETE_COMPLETE:
            stateProvinces = new ModelCollection(state.stateProvinces.data
                .map(p => TypeConverter.convert(StateProvinceModel, p)));

            index = stateProvinces.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                stateProvinces.data.splice(index, 1);
            }

            return {
                ...state,
                stateProvinces: stateProvinces
            };

        default:
            return state;
    }
}
