export enum ReadIntervalType {
    None = 0,
    QuarterHourly = 1,
    Hourly = 2,
    QuarterDaily = 3,
    TwiceDaily = 4,
    Daily = 5
}

export const ReadIntervalTypeNames = new Map<number, string>([
    [ReadIntervalType.None, 'None'],
    [ReadIntervalType.QuarterHourly, '15 Minutes'],
    [ReadIntervalType.Hourly, 'Hourly'],
    [ReadIntervalType.QuarterDaily, '6 Hours'],
    [ReadIntervalType.TwiceDaily, '12 Hours'],
    [ReadIntervalType.Daily, 'Daily']
]);
