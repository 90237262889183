import { Directive, ViewContainerRef } from '@angular/core';
import { BaseDirective } from '../../../_core/components/base.directive';

// https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5
@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[controlErrorContainer], [formControlContainer]'
})
export class ControlErrorContainerDirective extends BaseDirective {
    constructor(public readonly vcr: ViewContainerRef) {
        super();
    }
}
