import { EntityModel } from '../../../../_sdk/model';

export class CostTrendModel extends EntityModel {
    public Year?: number;
    public Month?: number;
    public TotalCost?: number;
    public MonthOfYear?: string;
    public InvoicePeriodLabel?: string;

    constructor() {
        super();
    }
}
