import { BaseModel } from '../model';
import { DataObject, DataProperty } from '../../_core/type-converter';
import { TimeSpan } from '../../_core/date';

@DataObject()
export class TimezoneModel extends BaseModel {
    public get key(): string {
        return this.SystemName;
    }

    // Computed
    private _name: string;
    public get Name(): string {
        return this._name || (this._name = String.trimOrEmpty((this.DisplayName || '').replace(/^\(.*?\)/gi, '')));
    }
    public set Name(value: string) {
        // NOOP
    }

    public SystemName: string = null; // "Pacific Standard Time"
    public DisplayName: string = null; // "(UTC-08:00) Pacific Time (US & Canada)"
    public DaylightName: string = null; // "Pacific Daylight Time"
    public StandardName: string = null; // "Pacific Standard Time"
    public SupportsDaylightSavingTime: boolean = null; // true

    @DataProperty()
    public BaseUtcOffset: TimeSpan = null; // {-08:00:00}
}
