<div class="row pt-3">
    <div class="col-2">
        <span class="edit-action-button-container" *ngIf="showCancelButton">
            <button type="button" (click)="onClickCancel($event)" class="k-button edit-action-button">{{ cancelText ? cancelText : 'Cancel' | localizer:'button.cancel' | async }}</button>
        </span>
    </div>
    <div class="col-10 text-right">
        <ng-content></ng-content>

        <span class="edit-action-button-container mr-2" *ngIf="showDeleteButton">
            <button (click)="onClickDelete($event)" class="k-button k-danger edit-action-button">{{ 'Delete' | localizer:'button.delete' | async }}</button>
        </span>
        <span class="edit-action-button-container mr-2" *ngIf="showSaveButton">
            <button (click)="onClickSave($event)" class="k-button edit-action-button" [disabled]="saveButtonDisabled" [class.k-primary]="!showSaveAndCloseButton">{{ 'Save' | localizer:'button.save' | async }}</button>
        </span>
        <span class="edit-action-button-container" *ngIf="showSaveAndCloseButton">
            <button (click)="onClickSaveAndClose($event)" class="k-button k-primary edit-action-button" [disabled]="saveButtonDisabled">{{ 'Save & Close' | localizer:'button.save-and-close' | async }}</button>
        </span>
    </div>
</div>
