export enum AlarmType {
    ZeroReading = 1,
    DailyChangeOfValue,
    ToleranceRange
}

export const AlarmTypeNames = new Map<number, string>([
    [AlarmType.ZeroReading, 'Zero Reading'],
    [AlarmType.DailyChangeOfValue, 'Change of Value'],
    [AlarmType.ToleranceRange, 'Daily Tolerance']
]);
