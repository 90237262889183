<div class="container-fluid">
    <div class="row" *ngFor="let colGroup of (this.columnGroups$ | async)">
        <!-- Add the 'col-xl-5' if and ONLY if this grouping has at least 2 columns. -->
        <div class="col-12" [ngClass]="{ 'col-xl-6': colGroup.right || flexWidth !== true }">
            <ng-container *ngTemplateOutlet="colGroup.left"></ng-container>
        </div>
        
        <!-- right column -->
        <div class="col-12 col-xl-6" *ngIf="colGroup.right">
            <ng-container *ngTemplateOutlet="colGroup.right"></ng-container>
        </div>
    </div>
</div>