import { EntityModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class LanguageModel extends EntityModel {
    public Name: string = null;
    public LanguageCulture: string = null;
    public UniqueSeoCode: string = null;
    public Active = false;
    public FlagImageFileName: string = null;
    public DisplayOrder?= 0;

    /*
    @DataProperty({ instanceType: LocaleStringResourceModel })
    public LocaleStringResources: Array<LocaleStringResourceModel> = [];
     */
}

export interface LanguageFilter {
    Name?: string;
    UniqueSeoCode?: string;
    LoadResources?: boolean;
}

export type LanguageFilterState = CompositeType<LanguageFilter, FilterState>;
