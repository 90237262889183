import { Directive } from '@angular/core';
import { BaseDirective } from '../../../../../_core/components/base.directive';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[horizontalLayout]'
})
export class HorizontalLayoutDirective extends BaseDirective { }

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[verticalLayout]'
})
export class VerticalLayoutDirective extends BaseDirective { }

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[listLayout]'
})
export class ListLayoutDirective extends BaseDirective { }
