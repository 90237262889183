import { ElementRef, HostListener, Input, Directive } from '@angular/core';
import { BaseDirective } from '../../../../_core/components/base.directive';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[numeric]'
})
export class NumericDirective extends BaseDirective {

    regexStr = '^[0-9]*$';
    constructor(private el: ElementRef) { super(); }

    @Input() OnlyNumber: boolean;

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        const e = <KeyboardEvent>event;

        if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: Ctrl+V
            (e.keyCode === 86 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Numeric keypad
            (e.keyCode >= 96 && e.keyCode <= 105) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)) {
            // let it happen, don't do anything
            return;
        }
        const ch = String.fromCharCode(e.keyCode);
        const regEx = new RegExp(this.regexStr);

        if (regEx.test(ch)) {
            return;
        }

        e.preventDefault();
    }
}
