<form [formGroup]="passwordForm" (ngSubmit)="onChangeSubmit()">
    <div class="form-group" *ngIf="showOldPassword">
        <label for="oldPassword">Old Password</label>
        <input type="password" formControlName="oldPassword" placeholder="Old Password">
    </div>
    <div class="form-group">
        <label for="newPassword" for ="newPassword">New Password</label>
        <input type="password" id="newPassword" formControlName="newPassword" title="Your password must be between 6 and 16 characters" placeholder="New Password">
        
    </div>
    <div class="form-group">
        <label for="confirmNewPassword">Confirm New Password</label>
        <input type="password" id="confirmNewPassword" formControlName="confirmNewPassword" placeholder="Confirm New Password">
    </div>
    <div class="login-button mt-3">
        <button type="submit" class="btn login-btn" type="submit"  [disabled]="!passwordForm.valid">Submit</button>
    </div>
</form>
