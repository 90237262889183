import { Component, Input, Output,EventEmitter, forwardRef, Optional, Inject } from '@angular/core';
import { BaseComponent } from '../../../_core/components/base.component';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, ControlValueAccessor, FormBuilder, FormGroup } from '@angular/forms';
import { ValidatorArray, AsyncValidatorArray } from '../../../_core/components/forms/validate';
import { BaseValueAccessor } from '../../../_core/components/forms/base-value-accessor';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => YearPickerComponent),
    multi: true
  }]
})

export class YearPickerComponent extends BaseValueAccessor<number>{
 
  
  @Input()
  public showFutureYears: number = 0;
  
  @Input()
  public showPastYears:number = 0;

  @Output()
  public onSelect = new EventEmitter<number | string>();
 
  public readonly editForm: FormGroup;

  public selectedYear = new Date().getFullYear();

  public yearsArr: number[];



  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: ValidatorArray,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorArray,
      private readonly formBuilder: FormBuilder) {
        super();

      this.editForm = this.formBuilder.group({
       selectedYear: new Date().getFullYear(),
      });
      
      this.addSubscription( 
        this.editForm.valueChanges
          .pipe(
            tap(changes => {
              this.value = changes as number;
            })
          )
      )
    }
    
  protected OnInit(): void {
      this.yearList();
      this.emitSelectedValue(this.selectedYear);
  }

  protected bindValue(value: number | string): void {
    this.editForm.patchValue({'selectedYear': value}, { emitEvent: false });
  }

  yearList(){

    let currentYear = new Date().getFullYear();
   
    let yearsArr = [];

    for (let i=0; i < this.showPastYears; i++){    
      yearsArr.push(currentYear);
      currentYear -= 1;
    }
    
    currentYear = new Date().getFullYear();
    
    for(let i=0 ; i< this.showFutureYears; i++){
      currentYear += 1;
      yearsArr.push(currentYear);
    }

    yearsArr.sort((a, b) => b - a);
    // Add "All Years" option
    yearsArr.unshift("All Years");
    this.yearsArr = yearsArr;
  }

  emitSelectedValue(event:number | string) {
    if(event === 'Select Year')
    return;
    this.onSelect.emit(event);
  }
}
