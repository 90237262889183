import { DataObject, DataProperty } from '../../../../../_core/type-converter';
import { EntityModel } from '../../../../../_sdk/model';
import { UserModel } from '../../../../../_sdk/users/user.model';
import { InvoiceStateType } from './enumerations/invoice-state-type.enumeration';

@DataObject()
export class HvacInvoiceStateHistoryModel extends EntityModel {   

    PreviousState?: InvoiceStateType =null;
    NextState?: InvoiceStateType=null;
    ModifiedDate: Date=null;
    @DataProperty()
    ModifiedBy: UserModel=null;
    Description: string=null;
    TenantName: string=null;
    LeaseName: string=null;
    InvoiceLabel: string=null;
}