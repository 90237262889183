import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';
import { EnumerationModel } from '../../../_sdk/common/enumeration.model';
import * as EnumerationActions from './enumeration.actions';

export interface State {
    enumerations: ModelCollection<EnumerationModel>;
}

const initialState: State = {
    enumerations: new ModelCollection<EnumerationModel>()
};

export function reducer(
    state: State = initialState,
    action: EnumerationActions.Actions
): State {
    let enumerations: ModelCollection<EnumerationModel>;
    let index: number;

    switch (action.type) {
        case EnumerationActions.DISPOSE:
            return {
                ...state,
                enumerations: initialState.enumerations
            };
        case EnumerationActions.LOAD_COMPLETE:
            return {
                ...state,
                enumerations: action.payload
            };
        case EnumerationActions.LOAD:
        case EnumerationActions.INSERT:
        case EnumerationActions.UPDATE:
        case EnumerationActions.DELETE:
            return state;
        case EnumerationActions.INSERT_COMPLETE:
            enumerations = new ModelCollection(state.enumerations.data
                .map(p => TypeConverter.convert(EnumerationModel, p)));

            enumerations.data.push(action.payload);

            return {
                ...state,
                enumerations: enumerations
            };

        case EnumerationActions.UPDATE_COMPLETE:
            enumerations = new ModelCollection(state.enumerations.data
                .map(p => TypeConverter.convert(EnumerationModel, p)));

            index = enumerations.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                enumerations.data[index] = action.payload;
            }

            return {
                ...state,
                enumerations: enumerations
            };

        case EnumerationActions.DELETE_COMPLETE:
            enumerations = new ModelCollection(state.enumerations.data
                .map(p => TypeConverter.convert(EnumerationModel, p)));

            index = enumerations.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                enumerations.data.splice(index, 1);
            }

            return {
                ...state,
                enumerations: enumerations
            };

        default:
            return state;
    }
}
