import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { BaseComponent } from '../../_core/components/base.component';

import { Toastr, ToastrType, ToastrWrapper } from './toastr.model';
import { ToastrService } from './toastr.service';

@Component({
    selector: 'app-toastr',
    templateUrl: './toastr.component.html',
    styleUrls: ['./toastr.component.css'],
    // https://angular.io/guide/animations
    animations: [
        trigger('flyInOut', [
            // Once animation is over, it should have this state...
            state('in',
                style({
                    opacity: 1,
                    transform: 'translateX(0) translateY(0)'
                })
            ),
            // Transition in
            transition('void => *', [
                style({
                    opacity: 0,
                    transform: 'translateY(-50%)'
                }),
                animate('150ms ease-in')
            ]),
            transition('* => void', [
                animate('0.3s ease-in-out',
                    keyframes([
                        style({ opacity: 1, transform: 'translateX(0)', offset: 0 }),
                        style({ opacity: 1, transform: 'translateX(-15%)', offset: 0.7 }),
                        style({ opacity: 0, transform: 'translateX(40%)', offset: 1 })
                    ])
                )
            ])
        ])
    ]
})
export class ToastrComponent extends BaseComponent {

    public toastrs: ToastrWrapper[] = [];

    constructor(private toastrService: ToastrService) {
        super();
    }

    OnInit() {
        this.subscriptions.add(this.toastrService.getToastr()
            .subscribe(
                (toastr: Toastr) => {
                    if (!toastr) {
                        // clear toastrs when an empty toastr is received
                        this.toastrs = [];
                        return;
                    }

                    const toastrWrapper = new ToastrWrapper(toastr);
                    this.toastrs.push(toastrWrapper);
                    this.subscriptions.add(
                        toastrWrapper.expired.subscribe(_ => this.removeToastr(toastrWrapper))
                    );
                })
        );
    }

    removeToastr(toastr: ToastrWrapper) {
        toastr.stopTimer();
        this.toastrs = this.toastrs.filter(x => x.toastr !== toastr.toastr);
    }

    handlePin(toastr: ToastrWrapper): void {
        toastr.pinned = !toastr.pinned;
    }

    alertClass(toastr: ToastrWrapper) {
        if (!toastr || !toastr.toastr) {
            return;
        }

        switch (toastr.toastr.type) {
            case ToastrType.Success:
                return 'alert-success';
            case ToastrType.Error:
                return 'alert-danger';
            case ToastrType.Info:
                return 'alert-info';
            case ToastrType.Warning:
                return 'alert-warning';
            case ToastrType.Static:
                return 'alert-primary';
        }
    }

    iconClass(toastr: ToastrWrapper) {
        if (!toastr || !toastr.toastr) {
            return;
        }

        switch (toastr.toastr.type) {
            case ToastrType.Success:
                return 'k-i-check';
            case ToastrType.Error:
                return 'k-i-error';
            case ToastrType.Info:
                return 'k-i-info';
            case ToastrType.Warning:
                return 'k-i-warning';
            case ToastrType.Static:
                return 'k-i-notification';
        }
    }

    title(toastr: ToastrWrapper) {
        if (!toastr || !toastr.toastr) {
            return;
        }

        // return css class based on toastr type
        switch (toastr.toastr.type) {
            case ToastrType.Success:
                return 'Success!';
            case ToastrType.Error:
                return 'Error';
            case ToastrType.Info:
                return 'Info';
            case ToastrType.Warning:
                return 'Warning';
            case ToastrType.Static:
                return 'Alert';
        }
    }
}
