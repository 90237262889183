import { EntityModel, CoreEntity } from '../model';
import { AddressModel } from '../common/address.model';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { FilterContext } from '../page-state';
import { BuildingModel } from '../buildings/building.model';
import { UserFilter } from '../users/user.model';
import { EntityType } from '../search/entity-type.enum';

@DataObject()
export class CustomerModel extends EntityModel implements CoreEntity {
    public readonly EntityType: EntityType = EntityType.Customer;

    public Name: string = null;
    public Description: string = null;
    public Active = false;

    @DataProperty()
    public Address: AddressModel = null;

    public Buildings: Array<BuildingModel>;
}

export type CustomerForStorage = Pick<CustomerModel, 'Name' | 'Id'>;

export interface CustomerFilter {
    Name?: string;
    Description?: string;
}

export type CustomerFilterState = CompositeType<CustomerFilter, FilterContext>;
