import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalConfig } from '../../../_core/global.config';
import { notNull } from '../../../_core/rxjs.operators';
import { AuthService } from '../../../_services/auth/auth.service';

import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { BlobService } from '../../../_services/blob.service';

import { BaseComponent } from '../../../_core/components/base.component';



@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
  })
  export class ImageViewerComponent extends BaseComponent {

    @ViewChild('imageviewer') imageviewer: ElementRef;

    private readonly _imagePath$ = new BehaviorSubject<string>(null);
    public readonly imagePath$ = this._imagePath$.asObservable().pipe(notNull());

    private readonly _isLoading$ = new BehaviorSubject<boolean>(true);
    public readonly isLoading$ = this._isLoading$.asObservable().pipe(notNull());

    public _error$ = new BehaviorSubject<{ status: number, errorMessage: string }>(null);
    public error$ = this._error$.asObservable().pipe(notNull());


    @Input() 
    public get ImageUrl(): string {
      return this._imagePath$.getValue();
    }
  
    public set ImageUrl(value:string) {
      this._imagePath$.next(value);
    }

    constructor(
        private readonly authService: AuthService,
        private readonly blobservice: BlobService,
        private  _rndr: Renderer2,
        private readonly config: GlobalConfig) {
        super();
    
    }

    OnInit() {
        this.addSubscription(this._imagePath$.pipe(
            notNull(),
            distinctUntilChanged(),
            take(1),
            tap(imagePath =>{
                this.blobservice.retrieve(imagePath)
                .pipe(
                    notNull(),
                    take(1),
                    tap((data) => {
                        var myIframe = document.getElementById('imageviewer'); 
                        
                        //older Safari having issue with rendering blob 
                        //check if safari browser then  pass base url to load direct else add blob url to iframe src.   
    
                        if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){                   
                            myIframe.setAttribute("src", this.config.api('platform').baseUri+'/' + this._imagePath$.getValue() + '#toolbar=0&navpanes=0');                        
                            
                        } else {                      
                            let mimeType = data.headers.get('content-type');                                                
                            myIframe.setAttribute("src", URL.createObjectURL(new Blob([data.body], { type: mimeType })) + '#toolbar=0&navpanes=0');                   
                        }  
                                        
                        this._isLoading$.next(false);   
                    }, (err) => {
                        let error: { status: number, errorMessage: string };
                 
                        if (err.innerError.status == 404) {
                            error = {
                                errorMessage: "Image not found",
                                status: 404
                            }

                        } else {
                            error = {
                                status: 500,
                                errorMessage: "Couldn't load Image, Please try again"
                            }
                        }

                        this._error$.next(error);
                        this._isLoading$.next(false);
                    })
                ).subscribe()
            })
        ));
    }

    public onDownloadInvoice() {        
        var win = open(`${this.config.api('platform').baseUri}/${this._imagePath$.getValue()+'?isDownload=true' }`);
                
        setTimeout('win.document.execCommand("SaveAs")', 100);
        setTimeout('win.close()', 500);


    }
  }