import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot, Router } from '@angular/router';

import { AuthService } from '../_services/auth/auth.service';
import { createReturnUrl } from '../_core/url.utils';
import { UserModel } from '../_sdk/users/user.model';
import { tap, take } from 'rxjs/operators';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(
        private readonly router: Router,
        private readonly location: Location,
        private readonly authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkPermissions(route, state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkPermissions(route, state.url);
    }

    canLoad(route: Route) {
        return this.checkPermissions(route, this.location.path());
    }

    private checkPermissions(route: ActivatedRouteSnapshot | Route, returnUrl: string): boolean {
        let isAuthenticated: boolean;

        this.authService.isAuthenticated$
            .pipe(
                take(1),
                tap(p => {
                    isAuthenticated = p;
                })
            )
            .subscribe();
            
        if(!isAuthenticated) {
            this.router.navigate(['/login'], { queryParams: createReturnUrl(returnUrl) });
            return false;
        }

        const permissions = route.data['permissions'] as string[];
        const hasPermission = this.authService.hasAnyPermission(...permissions);

        if (!hasPermission) {
            this.router.navigate(['/no-access'], { state: { permissions } });
        }

        return hasPermission;
    }
}
