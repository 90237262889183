
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BaseComponent } from '../../../../_core/components/base.component';

@Component({
    selector: 'app-search-input', 
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchInputComponent),
            multi: true
        }
    ]
})
export class SearchInputComponent extends BaseComponent implements ControlValueAccessor {

    public searchInput: FormGroup;

    protected readonly debounceTime = 250;

    @Output()
    public readonly changes$: Observable<any>;

    private _onChange: (_: any) => void;
    private _onTouched: () => void;

    private _hasFocus: boolean;
    private _value: string;

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter();

    @Input()
    public label: string;

    @Input()
    public useContainer: boolean;

    @Input()
    public styles = { 'width': '100%' }; // Default style; Pass new styles to override

    @Input()
    public set value(value: string) {
        this._value = value;

        if (this._onChange) {
            this._onChange(value);
        }

        this.valueChange.emit(value);
        this.searchInput.patchValue({ input: value }, { emitEvent: false });
    }
    public get value(): string {
        return this._value;
    }

    public set hasFocus(value: boolean) {
        this._hasFocus = value;
    }
    public get hasFocus(): boolean {
        return this._hasFocus;
    }

    constructor(formBuilder: FormBuilder) {
        super();

        this.searchInput = formBuilder.group({
            input: ''
        });

        this.changes$ = this.searchInput.valueChanges
            .pipe(
                distinctUntilChanged(),
                debounceTime(this.debounceTime)
            );
    }

    OnInit() {
        super.OnInit();

        this.subscriptions.add(
            this.searchInput.valueChanges
                .subscribe(value => {
                    this.value = value.input;
                })
        );
    }

    public writeValue(value: any): void {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.searchInput.disable();
        } else {
            this.searchInput.enable();
        }
    }
}
