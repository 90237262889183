import { Directive, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { BaseDirective } from '../../../_core/components/base.directive';

// https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5
@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'form'
})
export class FormSubmitDirective extends BaseDirective {
    
    public readonly submit$ = fromEvent(this.element, 'submit')
        .pipe(
            tap(() => {
                if (this.element.classList.contains('submitted') === false) {
                    this.element.classList.add('submitted');
                }
                /* if (this.element.classList.contains('was-validated') === false) {
                    this.element.classList.add('was-validated');
                } */
            }),
            shareReplay(1)
        );

    constructor(private readonly host: ElementRef<HTMLFormElement>) {
        super();
    }

    get element() {
        return this.host.nativeElement;
    }
}
