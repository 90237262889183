import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUserRole from './user-role/user-role.reducers';
import * as fromUserRoleCategory from './user-role-category/user-role-category.reducers';

export const FEATURE_NAME = 'userManagementStore';

export interface FeatureState {
    userRoleState: fromUserRole.State;
    userRoleCategoryState: fromUserRoleCategory.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    userRoleState: fromUserRole.reducer,
    userRoleCategoryState: fromUserRoleCategory.reducer
};

export const featureSelector = createFeatureSelector<FeatureState>(FEATURE_NAME);

const getUserRoleStateSelector = (state: FeatureState) => state.userRoleState;
export const getUserRoleState = createSelector(featureSelector, getUserRoleStateSelector);
export const getUserRoles = createSelector(getUserRoleState, s => s.userRoles);

const getUserRoleCategoryStateSelector = (state: FeatureState) => state.userRoleCategoryState;
export const getUserRoleCategoryState = createSelector(featureSelector, getUserRoleCategoryStateSelector);
export const getUserRoleCategories = createSelector(getUserRoleCategoryState, s => s.userRoleCategories);