import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalizationContext } from '../../../_core/localization-context';

@Pipe({
    name: 'localizer'
})
export class LocaleResourcePipe implements PipeTransform {
    constructor(private readonly localizationContext: LocalizationContext) { }

    transform(defaultValue: string, key: string): Observable<string> {
        return this.localizationContext.resource(key, defaultValue);
    }
}
