export enum PanelType {
    Unknown = 1,
    CellularTower = 2,
    CommercialKitchen = 4,
    EVStation = 8,
    ElevatorsEscalatorsPeopleMovers = 16,
    Generator = 32,
    HVAC = 64,
    Irrigation =128,
    Lighting = 256,
    MainFeed = 512,
    ReceptaclesOutletsPlugLoad = 1024,
    RenewablePowerSource = 2048,
    ServerRoom = 4096,
    SpecializedEquipmentover25kVA = 8192,
    ToiletsRestrooms = 16384,
    BoilersHeatExchangers = 32768,
    MedicalIndustrial = 65536
}

export const PanelTypeNames = new Map<number,string>([ 
    [PanelType.Unknown, "Unknown"],
    [PanelType.CellularTower,"Cellular Tower"],
    [PanelType.CommercialKitchen, "Commercial Kitchen"],
    [PanelType.EVStation, "EV Station"],
    [PanelType.ElevatorsEscalatorsPeopleMovers, "Elevators / Escalators / People Movers"],
    [PanelType.Generator, "Generator"],
    [PanelType.HVAC, "HVAC"],
    [PanelType.Irrigation, "Irrigation"],
    [PanelType.Lighting,"Lighting"],
    [PanelType.MainFeed,"Main Feed"],
    [PanelType.ReceptaclesOutletsPlugLoad,"Receptacles / Outlets / Plug Load"],
    [PanelType.RenewablePowerSource, "Renewable Power Source"],
    [PanelType.ServerRoom,"Server Room"],
    [PanelType.SpecializedEquipmentover25kVA, "Specialized Equipment over 25kVA"],
    [PanelType.ToiletsRestrooms, "Toilets / Restrooms"],
    [PanelType.BoilersHeatExchangers, "Boilers / Heat Exchangers"],
    [PanelType.MedicalIndustrial, "Medical / Industrial"],
   ]);

export enum TenantGroup {
   Vacant = 1,
   CommonArea = 2,
   NonBilled = 3,
   Primary = 4 
}

export const TenantGroupNames = new Map<number,string>([
    [TenantGroup.Vacant,"Vacant"],
    [TenantGroup.CommonArea, "Common Area"],
    [TenantGroup.NonBilled,"Non-Billed"],
    [TenantGroup.Primary,"Primary"]
]);

export enum MeterSubType {
    Remote = 1,
    Physical = 2
}

export const MeterSubTypeNames = new Map<number,string>([
    [MeterSubType.Remote, "Remote"],
    [MeterSubType.Physical,"Physical"]
])

export enum UserType {
    Other = 1,
    HighUser = 2,
    MediumUser = 3,
    LowUser = 4
}

export const UserTypeNames = new Map<number,string>([
    [UserType.Other, "Other"],
    [UserType.HighUser, "High User"],
    [UserType.MediumUser, "Medium User"],
    [UserType.LowUser, "Low User"]
])