import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ActivateUserComponent } from './auth/activate-user/activate-user.component';
import { AuthCheckComponent } from './auth/auth-check/auth-check.component';
import { LoginComponent } from './auth/login/login.component';
import { NoAccessComponent } from './auth/no-access/no-access.component';
import { PasswordRecoveryComponent } from './auth/password-recovery/password-recovery.component';
import { PasswordComponent } from './auth/password/password.component';
import { RegisterComponent } from './auth/register/register.component';
import { RedirectComponent } from './redirect/redirect.component';

import './_core/array';
import './_core/date';
import './_core/number';
import './_core/string';

import { AppStoreModule } from './app-store.module';
import { AuthGuard } from './_guards/auth-guard.service';
import { PermissionGuard } from './_guards/permission-guard.service';
import { RoleGuard } from './_guards/role-guard.service';

import { AuthInterceptor } from './_interceptors/auth.interceptor';
import { ConnectivityInterceptor } from './_interceptors/connectivity.interceptor';
import { JsonInterceptor } from './_interceptors/json.interceptor';

import { GeneaCommonModule } from './_modules/common';
import { HttpResponseService } from './_services/http-response.service';
import { LocalStorageService } from './_services/IO/localStorageService';
import { StorageService } from './_services/IO/storage.service';
import { QueryVisitor } from './_services/query.visitor';
import { ScopedQueryVisitor } from './_services/scoped-query.visitor';
import { ToastrModule } from './_modules/toastr';
import { HttpRequestIndicatorModule } from './_modules/http-request-indicator';
import { NavigationIndicatorModule } from './_modules/navigation-indicator';
import { GeneaCommonUIModule } from './_modules/common-ui';
import { GoogleAnalyticsService } from './_modules/analytics/google-analytics.service';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ResolveRouteNotFoundComponent } from './catch-all/resolve-route-not-found.component';
import { PageNotFoundComponent } from './catch-all/page-not-found.component';
import { SnoozeNotificationComponent } from './auth/snooze-notification/snooze-notification.component';


@NgModule({
    declarations: [
        // Pages
        AppComponent,
        RedirectComponent,
        ResolveRouteNotFoundComponent,
        PageNotFoundComponent,
        
        // Auth pages
        ActivateUserComponent,
        AuthCheckComponent,
        LoginComponent,
        SnoozeNotificationComponent,
        PasswordRecoveryComponent,
        RegisterComponent,
        NoAccessComponent,
        
        // Components
        PasswordComponent
    ],
    imports: [
        // Ng
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        
        // NGRX
        AppStoreModule,

        // App routing
        AppRoutingModule,
        
        // Genea common modules
        GeneaCommonModule,
        GeneaCommonUIModule,
        ToastrModule,

        HttpRequestIndicatorModule.forRoot(),
        NavigationIndicatorModule,
        UploadModule
    ],
    providers: [
        // Google Analytics
        GoogleAnalyticsService,
        
        // Storage
        {
            provide: StorageService,
            useClass: LocalStorageService
        },

        // Used to override the current context in an API call
        {
            provide: QueryVisitor,
            useClass: ScopedQueryVisitor
        },

        // Guards
        AuthGuard,
        RoleGuard,
        PermissionGuard,

        // Used in interceptor
        HttpResponseService,

        // Interceptors
        { provide: HTTP_INTERCEPTORS, useClass: ConnectivityInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JsonInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    
}
