export enum UtilityType {
    Electric = 1,
    Gas = 2,
    WaterAndSewer = 3,
    Steam=4,
    Other=5,
    HotWater = 7,
    ChilledWater = 8
}

export const UtilityTypeNames = new Map<number, string>([
    [UtilityType.Electric, 'Electric'],
    [UtilityType.Gas, 'Gas'],
    [UtilityType.WaterAndSewer, 'Water + Sewer'],
    [UtilityType.Steam, 'Steam'],  
    [UtilityType.HotWater, 'Hot Water'],
    [UtilityType.ChilledWater, 'Chilled Water'],
    [UtilityType.Other, 'Other'],
]);

export const UtilityTypeLoadMapping = new Map<number,string>([
    [UtilityType.Electric, '1,2,4,8,16,32,64,256,512,1024,2048,4096,8192'],
    [UtilityType.Gas, '1,4,512'],
    [UtilityType.WaterAndSewer, '1,4,128,512,16384'],
    [UtilityType.Steam, '64,32768,65536'],  
    [UtilityType.HotWater, '4,64,4096,16384'],
    [UtilityType.ChilledWater, '64,4096'],
    [UtilityType.Other, '1'],
])