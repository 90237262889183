import { Directive,ElementRef } from '@angular/core';
import { BaseDirective } from '../../../_core/components/base.directive';

@Directive({
  selector: '[geneaPageHeadingStyle]'
})
export class PageHeadingDirective  extends BaseDirective {
  
  public get htmlElement(): HTMLElement {
    return this.hostElement.nativeElement;
}

constructor(private readonly hostElement: ElementRef) {
    super();
}

public OnInit(): void {
    super.OnInit();

     this.addClass('pt-3', 'pb-3', 'pl-3', 'border-bottom');
}

private addClass(...tokens: Array<string>): void {
    this.htmlElement.classList.add(...tokens);
}

}
