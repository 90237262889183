<div [formGroup]='editForm' class="w-auto">
    <kendo-datepicker
        formControlName="date"
        [readonly]="readonly"
        [min]="jsMin"
        [max]="jsMax"
        (valueChange)="onDateValueChange($event)">
    </kendo-datepicker>

    <kendo-timepicker
        formControlName="time"
        [readonly]="readonly"
        (valueChange)="onTimeValueChange($event)">
    </kendo-timepicker>
</div>
