import { TypeConverter } from './type-converter';

export { };

declare global {
    interface ArrayConstructor {
        range(length: number, start?: number): Array<number>;
    }
}
declare global {
    interface Array<T> {
        // Manipulate
        groupBy<TKey>(this: Array<T>, keySelector: (k: T) => TKey, valueTransform?: (source: Array<T>) => Array<T>): Map<TKey, Array<T>>;
        iterate(this: Array<T>, iterator: (value: T, index: number, array: T[]) => void): Array<T>;
        page(this: Array<T>, pageIndex: number, pageSize: number): Array<T>;
        intersect(this: Array<T>, other: Array<T>, comparer?: (l: T, r: T) => boolean): Array<T>;
        except(this: Array<T>, other: Array<T>, comparer?: (l: T, r: T) => boolean): Array<T>;
        equals(this: Array<T>, other: Array<T>, comparer?: (l: T, r: T) => boolean): boolean;
        defaultIfEmpty(this: Array<T>, defaultValue: Partial<T>): Array<T>;
        skip(this: Array<T>, count: number): Array<T>;
        take(this: Array<T>, count: number): Array<T>;
        // Transform
        joinWith<TRightSource, TKey, R>(this: Array<T>, rightSource: Array<TRightSource>, leftKeySelector: (x: T) => TKey, rightKeySelector: (x: TRightSource) => TKey, resultSelector: (o: T, i: TRightSource) => R): Array<R>;
        joinWhere<TRightSource, R>(this: Array<T>, rightSource: Array<TRightSource>, comparer?: (left: T, right: TRightSource) => boolean, resultSelector?: (o: T, i: TRightSource) => R): Array<R>;
        leftJoin<TRightSource, TKey, R>(this: Array<T>, rightSource: Array<TRightSource>, leftKeySelector: (x: T) => TKey, rightKeySelector: (x: TRightSource) => TKey, resultSelector: (o: T, i: TRightSource) => R): Array<R>;
        leftJoinWhere<TRightSource, R>(this: Array<T>, rightSource: Array<TRightSource>, comparer?: (left: T, right: TRightSource) => boolean, resultSelector?: (o: T, i: TRightSource) => R): Array<R>;

        many<R>(this: Array<T>, resultSelector?: (s: T) => Array<R>): Array<R>;
        flatten<R>(): Array<R>;

        // Test
        any(this: Array<T>, comparer?: (value: T) => any): boolean;
        all(this: Array<T>, comparer?: (value: T) => any): boolean;

        // Sort
        orderBy(this: Array<T>, propertyExpression: (element: T) => number | string): this;
        orderBy2(this: Array<T>, ...propertyExpressions: Array<(element: T) => number | string>): this;
        orderByDesc(this: Array<T>, propertyExpression: (element: T) => number | string): this;
        orderByDesc2(this: Array<T>, ...propertyExpressions: Array<(element: T) => number | string>): this;

        // Math
        sum(this: Array<T>, selector?: (k: T) => number): number;
        max(this: Array<T>, selector?: (k: T) => number): number;
        min(this: Array<T>, selector?: (k: T) => number): number;
        average(this: Array<T>, selector?: (k: T) => number): number;

        // To string
        toDelimitedString(this: Array<T>, delimiter?: string): string;
        toDelimitedFormattedString(this: Array<T>, formatter: (element: T) => string, delimiter?: string): string;

        // Extract
        first(this: Array<T>, expression?: (element: T) => boolean): T;
        firstOrDefault(this: Array<T>, expression?: (element: T) => boolean, defaultValue?: T): T;
        last(this: Array<T>, expression?: (element: T) => boolean): T;
        lastOrDefault(this: Array<T>, expression?: (element: T) => boolean, defaultValue?: T): T;
    }
}

declare global {
    interface Map<K, V> {
        iterate(this: Map<K, V>, iterator: (value: V, key?: K) => void): Map<K, V>;
        map<R>(this: Map<K, V>, callbackFn: (value: V, key?: K) => R): Array<R>;
        getValue(this: Map<K, V>, key: K, aquire: () => V): V;
        // toJSON(this: Map<K, V>): string;
    }
}

function normalizeCount(count: number): number {
    return Math.max(0, Math.abs(Math.round(count || 0)));
}

function arrayFirst<T>(this: Array<T>, expression?: (element?: T) => boolean): T {
    return this.find(expression || ((_) => true));
}

function arrayFirstOrDefault<T>(this: Array<T>, expression?: (element?: T) => boolean, defaultValue?: T): T {
    return this.first(expression) || defaultValue;
}

function arrayLast<T>(this: Array<T>, expression?: (element?: T) => boolean): T {
    let l = this.length;

    expression = expression || ((_) => true);
    while (l--) {
        const element = this[l];
        if (expression(element)) {
            return element;
        }
    }

    return null;
}

function arrayLastOrDefault<T>(this: Array<T>, expression?: (element?: T) => boolean, defaultValue?: T): T {
    return this.last(expression) || defaultValue;
}

function arraySkip<T>(this: Array<T>, count: number): Array<T> {
    const start = normalizeCount(count);

    return this.slice(start);
}

function arrayTake<T>(this: Array<T>, count: number): Array<T> {
    count = normalizeCount(count);

    return this.slice(0, count);
}

// https://stackoverflow.com/a/38327540
function arrayGroupBy<T, K>(this: Array<T>, keySelector: (k: T) => K, valueTransform?: (source: Array<T>) => Array<T>): Map<K, Array<T>> {
    const result = this.reduce((aggregate: Map<K, Array<T>>, element: T) => {
        const key = keySelector(element);
        const value = aggregate.get(key)
            || aggregate.set(key, []).get(key);

        value.push(element);

        return aggregate;
    }, new Map<K, Array<T>>());

    if (valueTransform) {
        result
            .forEach((v, k) => {
                result.set(k, valueTransform(v));
            });
    }

    return result;
}

/*
    MATH functions...

    We're going to wrap the selector function with a function that will ALWAYS return a number...

        Why? Because the array can be a primitive (numeric) array [1, 2, 3, 4], or it can be a complex (object) array [{x: 1}, {x: 2}, {x: 3}, {x: 4}].

        99% of the time, the array is a primitive array and the selector will be NULL.
            const value: Array<number> = [1, 2, 3, 4];

            No selector:
                const sum = value.sum(); // 10

            With a valid selector:
                const sum = value.sum(i => i); // 10

            With an invalid selector
                const sum = value.sum(i => i.fakeProperty); // 0, property 'fakeProperty' doesn't exist on type number, so there's no way to correctly resolve which peoperty we want to SUM

        For the other 1% of the time, the user needs to provide a selector function to get a numeric property from items in the array
            const value: Array<{x: number}> = [{x: 1}, {x: 2}, {x: 3}, {x: 4}];

            With a valid selector:
                const sum = value.sum(i => i.x); // 10

            No selector:
                const sum = value.sum(); // 0, no property selector, so no way to correctly resolve which peoperty we want to SUM

            With an invalid selector:
                const sum = value.sum(i => i); // 0
                const sum = value.sum(i => i.fakeProperty); // 0, property 'fakeProperty' doesn't exist on type {x: number}, so no way to correctly resolve which peoperty we want to SUM

        Either way, we need a "normalized" function to return something predictable.

        The final signature should be a function we can invoke on an object
            (x: T) => R;

    Insuring we will ALWAYS have a function
        When the selector is NOT NULL:
            Simply reference it as-is...
                const _function = selector;

        When the selector is NULL:
            Create a selector that simply returns the element in the array
                const _function = ((x: T) => x);

        The selector can be set in one line using the /falsy/ coalesce
            const _function = selector || ((j: T) => j);

    Wrapping the function
        Now that we are guaranteed to have a selector function, we need toguarantee the function returns a number. Let's wrap it!
            const _toNumber = (x: T) => Number(_function(x));

        Almost done! The function above will return a Number or NaN... We want a valid number! /falsy/ coalesce to the rescue
            const _toNumber = (x: T) => Number(_function(x)) || 0;

    Now, we have a function we can call on ANYTHING and ALWAYS get back a number...
        const primitive = _toNumber()(5); // 5
        const primitive2 = _toNumber(i => i)(5); // 5
        const complex = _toNumber<{ x: number }>(i => i.x)({x: 25}); // 25
        const invalid = _toNumber<any>(i => i.fakeProperty)({x: 25}); // 0
*/

function proxyToNumber<T>(selector?: (i: T) => number): (i: T) => number {
    const _function = selector || ((x: T) => x);
    const _toNumber = (x: T) => Number(_function(x)) || 0;

    return _toNumber;
}

function arraySum<T>(this: Array<T>, selector?: (k: T) => number): number {
    const _toNumber = proxyToNumber(selector);

    const sum = this.reduce((aggregate: number, element: T) => {
        const result = _toNumber(element);

        return aggregate += result;
    }, 0);

    return sum;
}

function arrayMax<T>(this: Array<T>, selector?: (k: T) => number): number {
    const _toNumber = proxyToNumber(selector);

    // Note: We seed the aggregate with the first element in the array
    // Because of this, we need to skip the first element before we reduce it
    const max = this
        .skip(1)
        .reduce((aggregate: number, element: T) => {
            return Math.max(aggregate, _toNumber(element));
        }, _toNumber(this.firstOrDefault())); // <-- Seed with the first element

    return max || 0;
}

function arrayMin<T>(this: Array<T>, selector?: (k: T) => number): number {
    const _toNumber = proxyToNumber(selector);

    // Note: We seed the aggregate with the first element in the array
    // Because of this, we need to skip the first element before we reduce it
    const min = this
        .skip(1)
        .reduce((aggregate: number, element: T) => {
            return Math.min(aggregate, _toNumber(element));
        }, _toNumber(this.firstOrDefault())); // <-- Seed with the first element

    return min || 0;
}

function arrayAverage<T>(this: Array<T>, selector?: (k: T | number) => number): number {
    const sum = this.sum(selector);

    // Note: Make not to divide by ZERO
    // Easy way is to Math.max(1, this.length)
    const average = sum / (Math.max(1, this.length));

    return average;
}

/*
Example usage

const left = [{ Id: 1, Name: 'Daniel', Age: 40 }, { Id: 2, Name: 'Jeff', Age: 30 }];
const right = [{ Id: 1, Occupation: 'Bounty Hunter' }, { Id: 2, Occupation: 'Couch Hunter' }];

const j = left.joinWith(right, (l) => l.Id, (r) => r.Id, (l, r) => Object.assign({}, l, r));

console.log(j);
console.log(j2);
*/
function arrayJoin<TLeft, TRight, K, R>(this: Array<TLeft>, rightSource: Array<TRight>, leftKeySelector: (x: TLeft) => K, rightKeySelector: (x: TRight) => K, resultSelector: (o: TLeft, i: TRight) => R): Array<R> {
    return this.reduce((aggregate: Array<R>, left: TLeft) => {
        const leftKey: K = leftKeySelector(left);
        const match: TRight = (rightSource || [])
            .find(right => TypeConverter.isEquivalent(leftKey, rightKeySelector(right)));

        if (!TypeConverter.isNull(match)) {
            aggregate.push(resultSelector(left, match));
        }

        return aggregate;
    }, []);
}

function arrayJoinWhere<TLeft, TRight, R>(this: Array<TLeft>, rightSource: Array<TRight>, comparer?: (left?: TLeft, right?: TRight) => boolean, resultSelector?: (o: TLeft, i: TRight) => R): Array<R> {
    comparer = comparer || ((_) => true);

    return this.reduce((aggregate: Array<R>, left: TLeft) => {
        const match: TRight = (rightSource || [])
            .find(right => comparer(left, right));

        if (!TypeConverter.isNull(match)) {
            aggregate.push(resultSelector(left, match));
        }

        return aggregate;
    }, []);
}

function arrayPivot<TLeft, TRight>(this: Array<TLeft>, rightSource: Array<TRight>, comparer?: (left?: TLeft, right?: TRight) => boolean): Array<TRight> {
    return this.joinWhere(rightSource, (l, r) => comparer(l, r), (l, r) => r);
}

function arrayLeftJoin<TLeft, TRight, K, R>(this: Array<TLeft>, rightSource: Array<TRight>, leftKeySelector: (x: TLeft) => K, rightKeySelector: (x: TRight) => K, resultSelector: (o: TLeft, i: TRight) => R): Array<R> {
    return this.reduce((aggregate: Array<R>, left: TLeft) => {
        const leftKey: K = leftKeySelector(left);
        const rightMatch: TRight = (rightSource || [])
            .find(right => TypeConverter.isEquivalent(leftKey, rightKeySelector(right)));

        aggregate.push(resultSelector(left, rightMatch));

        return aggregate;
    }, []);
}

function arrayLeftJoinWhere<TLeft, TRight, R>(this: Array<TLeft>, rightSource: Array<TRight>, comparer?: (left?: TLeft, right?: TRight) => boolean, resultSelector?: (o: TLeft, i: TRight) => R): Array<R> {

    comparer = comparer || ((_) => true);

    return this.reduce((aggregate: Array<R>, left: TLeft) => {
        const match: TRight = (rightSource || [])
            .find(right => comparer(left, right));

        aggregate.push(resultSelector(left, match));

        return aggregate;
    }, []);
}

function arrayMany<T, R>(this: Array<T>, resultSelector?: (s: T) => Array<R>): Array<R> {
    if (!resultSelector) {
        return this.flatten();
    }
    return this.reduce((aggregate: Array<R>, s: T) => {
        aggregate.push(...resultSelector(s));
        return aggregate;
    }, []);
}

function arrayFlatten<T>(this: Array<T>): Array<any> {
    let flatten: Function;
    flatten = function (arr: Array<any>, result: Array<any> = []) {
        arr.forEach(item => {
            if (Array.isArray(item)) {
                flatten(item, result);
            } else {
                result.push(item);
            }
        });

        return result;
    };

    return flatten(this);
}

function arrayIterate<T>(this: Array<T>, iterator: (value: T, index: number, array: T[]) => void): Array<T>;
function arrayIterate<T, R>(this: Array<T>, iterator: (value: T, index: number, array: T[]) => void, resultSelector?: (value) => T | R): Array<T | R> {
    // resultSelector = resultSelector || ((t) => t);

    this.forEach(iterator);

    if(resultSelector)
        return this.map(resultSelector);        

    return this;
}

function compareAscending(left: number | string | null, right: number | string | null) {

    if (TypeConverter.isNull(left)) {
        return -1;
    }

    if (TypeConverter.isNull(right)) {
        return 1;
    }

    if (TypeConverter.isString(left)) {
        if (!TypeConverter.isString(right)) {
            right = right;
        }
    } else if (TypeConverter.isNumber(left)) {
        if (!TypeConverter.isNumber(right)) {
            right = right;
        }
    }

    if (TypeConverter.isString(left) && TypeConverter.isString(right)) {
        if (left > right) {
            return 1;
        }

        if (left < right) {
            return -1;
        }

        return 0;
    }

    return Number(left) - Number(right);
}

function compareMultiple<T>(left: T, right: T, ascending: boolean = true, ...propertyExpressions: Array<(element: T) => number | string>) {

    let leftCriteria: any;
    let rightCriteria: any;

    if (propertyExpressions.length === 1) {
        leftCriteria = propertyExpressions[0](left);
        rightCriteria = propertyExpressions[0](right);
    } else {
        let index = -1;
        leftCriteria = '';
        rightCriteria = '';

        while (++index < propertyExpressions.length) {
            leftCriteria += (propertyExpressions[index](left) || '').toString();
            rightCriteria += (propertyExpressions[index](right) || '').toString();
        }
    }

    return compareAscending(leftCriteria, rightCriteria) * (ascending ? 1 : -1);
}

function arraySortAscDesc<T>(source: Array<T>, propertyExpression: (element: T) => number | string, ascending: boolean = true): Array<T> {
    return ([...source]).sort((a, b) => {
        return compareMultiple(a, b, ascending, propertyExpression);

        /*
            const aProp = propertyExpression(ascending ? a : b);
            let bProp = propertyExpression(ascending ? b : a);

            if (TypeConverter.isNull(aProp)) {
                return -1;
            }

            if (TypeConverter.isNull(bProp)) {
                return 1;
            }

            if (TypeConverter.isString(aProp)) {
                if (!TypeConverter.isString(bProp)) {
                    bProp = bProp.toString();
                }
            } else if (TypeConverter.isNumber(aProp)) {
                if (!TypeConverter.isNumber(bProp)) {
                    bProp = Number(bProp);
                }
            }

            if (TypeConverter.isString(aProp) && TypeConverter.isString(bProp)) {
                if (aProp > bProp) {
                    return 1;
                }

                if (aProp < bProp) {
                    return -1;
                }

                return 0;
            }

            return Number(aProp) - Number(bProp);
        */
    });
}

function arraySortAscDesc2<T>(source: Array<T>, ascending: boolean = true, ...propertyExpressions: Array<(element: T) => number | string>): Array<T> {
    return ([...source]).sort((a, b) => {
        return compareMultiple(a, b, ascending, ...propertyExpressions);
    });
}

function arraySortAsc<T>(this: Array<T>, propertyExpression: (element: T) => number | string | null): Array<T> {
    return arraySortAscDesc(this, propertyExpression, true);
}

function arraySortDesc<T>(this: Array<T>, propertyExpression: (element: T) => number | string | null): Array<T> {
    return arraySortAscDesc(this, propertyExpression, false);
}

function arraySortAsc2<T>(this: Array<T>, ...propertyExpressions: Array<(element: T) => number | string>): Array<T> {
    return arraySortAscDesc2(this, true, ...propertyExpressions);
}

function arraySortDesc2<T>(this: Array<T>, ...propertyExpressions: Array<(element: T) => number | string>): Array<T> {
    return arraySortAscDesc2(this, false, ...propertyExpressions);
}

function arrayPage<T>(this: Array<T>, pageIndex: number = 0, pageSize: number = 1): Array<T> {
    return this.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
}

function arrayIntersect<T>(this: Array<T>, other: Array<T>, comparer?: (l: T, r: T) => boolean): Array<T> {
    comparer = comparer || ((a, b) => a === b);

    return this.reduce((aggregate: Array<T>, element: T) => {
        if (other.some(e => comparer(element, e))) {
            aggregate.push(element);
        }

        return aggregate;
    }, []);
}

function arrayExcept<T>(this: Array<T>, other: Array<T>, comparer?: (l: T, r: T) => boolean): Array<T> {

    comparer = comparer || ((a, b) => a === b);

    return this.reduce((aggregate: Array<T>, element: T) => {
        if (!other.some(e => comparer(element, e))) {
            aggregate.push(element);
        }

        return aggregate;
    }, []);
}

function arrayEquals<T>(this: Array<T>, other: Array<T>, comparer?: (l: T, r: T) => boolean): boolean {
    comparer = comparer || ((a, b) => a === b);

    return this.reduce((outerAgg: boolean, outerEl: T) => {
        return outerAgg && other.any(innerEl => comparer(outerEl, innerEl));
    }, this.length === other.length);
}

function arrayAny<T>(this: Array<T>, comparer?: (value: T) => any): boolean {
    comparer = comparer || ((_) => true);

    return this.some(i => comparer(i));
}

function arrayAll<T>(this: Array<T>, comparer?: (value: T) => any): boolean {
    comparer = comparer || ((_) => true);

    return this.reduce((prev, cur) => {
        return prev && comparer(cur);
    }, this.length > 0);
}

function arrayDefaultIfEmpty<T>(this: Array<T>, defaultValue: T): Array<T> {
    if (!this.any() && !TypeConverter.isNull(defaultValue)) {
        this.push(defaultValue);
    }
    return this;
}

function arrayToDelimitedString<T extends string | number>(this: Array<T>, delimiter?: string): string {
    if (this.length === 0) {
        return '';
    }

    if (String.isNullOrEmpty(delimiter)) {
        delimiter = ',';
    }

    return this
        .filter(s => !TypeConverter.isNull(s))
        .map(s => s.toString())
        .filter(s => !String.isNullOrEmpty(s))
        .join(delimiter);
}

function arrayToDelimitedFormattedString<T>(this: Array<T>, formatter: (element: T) => string, delimiter?: string): string {
    return this
        .map(e => formatter(e))
        .toDelimitedString(delimiter);
}

function mapIterate<K, V>(this: Map<K, V>, iterator: (value: V, key?: K) => void): Map<K, V> {
    this.forEach(iterator);

    return this;
}

function mapMap<K, V, R>(this: Map<K, V>, callbackFn: (value: V, key?: K) => R): Array<R> {
    const result: Array<R> = [];

    this.forEach((v, k) => {
        result.push(callbackFn(v, k));
    });

    return result;
}

function mapGetValue<K, V>(this: Map<K, V>, key: K, aquire: () => V): V {
    let value: V = this.get(key);

    if(value === null || value === undefined) {
        value = aquire();

        if(value === null || value === undefined) {
            if(this.has(key)) {
                this.delete(key);
            }
        } else {
            this.set(key, value);
        }
    }

    return value;
}

function mapToJSON<K, V>(this: Map<K, V>): string {
    return TypeConverter.mapToJson(this);
}

Array.range = (length: number, start?: number): Array<number> => {
    // https://stackoverflow.com/a/10050831
    // Non-es6
    // Array.apply(null, Array(length)).map(function (_, i) { return (start || 0) + i; });
    return Array(length).fill(0).map((_, i) => (start || 0) + i);
};

Array.prototype.groupBy = arrayGroupBy;
Array.prototype.joinWith = arrayJoin;
Array.prototype.joinWhere = arrayJoinWhere;
Array.prototype.leftJoin = arrayLeftJoin;
Array.prototype.leftJoinWhere = arrayLeftJoinWhere;
Array.prototype.many = arrayMany;
Array.prototype.iterate = arrayIterate;
Array.prototype.page = arrayPage;
Array.prototype.intersect = arrayIntersect;
Array.prototype.except = arrayExcept;
Array.prototype.equals = arrayEquals;
Array.prototype.any = arrayAny;
Array.prototype.all = arrayAll;
Array.prototype.orderBy = arraySortAsc;
Array.prototype.orderByDesc = arraySortDesc;
Array.prototype.orderBy2 = arraySortAsc2;
Array.prototype.orderByDesc2 = arraySortDesc2;
Array.prototype.defaultIfEmpty = arrayDefaultIfEmpty;
Array.prototype.toDelimitedString = arrayToDelimitedString;
Array.prototype.toDelimitedFormattedString = arrayToDelimitedFormattedString;
Array.prototype.skip = arraySkip;
Array.prototype.take = arrayTake;
Array.prototype.first = arrayFirst;
Array.prototype.firstOrDefault = arrayFirstOrDefault;
Array.prototype.last = arrayLast;
Array.prototype.lastOrDefault = arrayLastOrDefault;
// Array.prototype.pivot = arrayPivot;
Array.prototype.flatten = arrayFlatten;
Array.prototype.sum = arraySum;
Array.prototype.max = arrayMax;
Array.prototype.min = arrayMin;
Array.prototype.average = arrayAverage;

Map.prototype.iterate = mapIterate;
Map.prototype.map = mapMap;
Map.prototype.getValue = mapGetValue;
// Map.prototype.toJSON = mapToJSON;
