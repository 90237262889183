import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap, shareReplay } from 'rxjs/operators';

import { TypeConverter } from '../../_core/type-converter';
import { BaseSettings } from '../configuration/base.settings';
import { SettingService } from '../configuration/setting.service';

@Injectable({
    providedIn: 'root'
})
export class CommonSettings extends BaseSettings {

    protected prefix: string = 'CommonSettings.'.toLowerCase();

    constructor(settingService: SettingService) {
        super(settingService);
    }

    public readonly MainSideMenuSize: Observable<string> = super.getByKey('MainSideMenuSize').pipe(
        map(value => {
            const defaultSize = '32px';

            if (!value) {
                return defaultSize;
            }

            switch (value.toLowerCase()) {
                case 's':
                case 'small':
                    return '18px';
                case 'l':
                case 'large':
                    return '48px';
                default:
                    return defaultSize;
            }
        })
    );

    // If this value is set to true, the no-access route will not display required roles or required permissions.
    public readonly SuppressDetailsInNoAccessPage: Observable<boolean> = super.getByKey('SuppressDetailsInNoAccessPage').pipe(
        map(value => TypeConverter.toBoolean(value)),
        shareReplay(1)
    );
}
