import { BillingDefaultScale, BuildingType, MeterType, ReaderType, BuildingClassification } from "../../admin/platform/buildings/enumerations/submeter-billing-setting.enum";
import { DataObject } from "../../_core/type-converter";
import { EntityModel } from "../model";

@DataObject()
export class SubmeterBillingSettingsModel extends EntityModel {
	public DifficultyScale: BillingDefaultScale = null;

	public BuildingMeterType: MeterType = null;

	public BuildingReaderType: Array<ReaderType> = [];

	public BuildingType: BuildingType = null;

	public BuildingClassification: BuildingClassification = null;

	public IsMRQExempt: Boolean = null;
}
