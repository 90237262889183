import { ProtocolTypeModel } from './protocol-type.model';
import { EntityModel } from '../model';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class ProtocolModel extends EntityModel {
    public Name: string = null;
    public Description: string = null;
    public SystemName: string = null;

    @DataProperty()
    public ProtocolType: ProtocolTypeModel = null;
}

export interface ProtocolFilter {
    Name?: string;
    ProtocolTypeId?: number;
}

export type ProtocolFilterState = CompositeType<ProtocolFilter, FilterState>;
