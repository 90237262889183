
import { take, tap, last } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthService } from '../_services/auth/auth.service';
import { TypeConverter } from '../_core/type-converter';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private readonly injector: Injector) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: string = null;
        let permissionHash: string = null;

        const authService = this.injector.get(AuthService);
        
        authService.token$
            .pipe(
                take(1),
                tap(t => {
                    token = t;
                })
            )
            .subscribe();

        authService.authenticatedUser$
            .pipe(
                take(1),
                tap(u => {
                    permissionHash = u && u.PermissionHash;
                })
            )
            .subscribe();

        if (String.isNullOrEmpty(token)) {
            return next.handle(request);
        }

        const copiedRequest = request.clone({
            headers: request.headers
                .set('Authorization', `Bearer ${token}`)
                .set('X-Genea-User-Hash', permissionHash || '')
        });

        return next.handle(copiedRequest)
            .pipe(
                last(),
                tap(event => {
                    if(event instanceof HttpResponse) {
                        this.inspectResponse(event, authService);
                    }
                })
            );
    }
    
    //  Inspect the response packet
    private inspectResponse<T>(response: HttpResponse<any>, authService: AuthService) {
        //  Check to see if we need to refresh the permissions
        const responseHash = response.headers.get("X-Genea-User-Hash");

        if (!String.isNullOrEmpty(responseHash)) {
            authService.refresh(responseHash);
        }
    }
}
