export interface RouteData {
    sticky: boolean;
    roles: Array<string>;
    permissions: Array<string>;
}

export const defaultRouteData: RouteData = {
    sticky: false,
    roles: [],
    permissions: []
}