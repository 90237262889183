import { UserRoleModel } from './user-role.model';
import { BaseModel } from '../model';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';
import { ContextReferenceModel } from './context-reference.model';
import { UserSettingModel } from './user-setting.model';
import { UserAclSummaryModel } from './user-acl-summary.model';
import { ModelCollection } from '../collection.model';
import { CustomerAclModel } from '../customers/customer-acl.model';
import { BuildingAclModel } from '../buildings/building-acl.model';
import { TenantAclModel } from '../tenants/tenant-acl.model';
import { LeaseAclModel } from '../leases/lease-acl.model';
import { LeaseAreaAclModel } from '../leases/lease-area-acl.model';

@DataObject()
export class UserModel extends BaseModel {

    public get key(): string {
        return this.UserGuid;
				}

    public UserGuid: string = null;
    public Username: string = null;
    public SystemName: string = null;
    public Email: string = null;
    public Active: boolean = null;
    public IsSystemAccount: boolean = null;
    public IsAdminAccount: boolean = null;
    public AdminComment: string = null;
    public FirstName: string = null;
    public LastName: string = null;
    public Company: string = null;
    public OfficePhoneNumber: string = null;
    public CellPhoneNumber: string = null
    public DisplayName: string = null;
    public ActivatedOn: Date = null;
    public LastLoginDate: Date = null;
    public LastActivityDate: Date = null;
    public PermissionHash: string = null;
    public DashboardURL :string = null;

    // TODO: We should further break down the roles for more granular user roles for proper client side role handling.
    // e.g. we can replace "UserRoles" with something like "EntityAclModel"
    // UserRoles for all
    @DataProperty({ instanceType: UserRoleModel })
    public UserRoles: UserRoleModel[] = null;

    @DataProperty()
    public Context: ContextReferenceModel = null;

    public PermissionRecords: string[] = null;

    public LanguageId: number = null;

    public IsPasswordResetRequested = null;

    @DataProperty({ instanceType: UserSettingModel })
    public UserSettings: Array<UserSettingModel> = [];

    public UserAclSummary: ModelCollection<UserAclSummaryModel> = null;

    @DataProperty({ instanceType: CustomerAclModel })
    public CustomerAclRecords: Array<CustomerAclModel> = null;

    @DataProperty({ instanceType: BuildingAclModel })
    public BuildingAclRecords: Array<BuildingAclModel> = null;

    @DataProperty({ instanceType: TenantAclModel })
    public TenantAclRecords: Array<TenantAclModel> = null;

    @DataProperty({ instanceType: LeaseAclModel })
    public LeaseAclRecords: Array<LeaseAclModel> = null;

    @DataProperty({ instanceType: LeaseAreaAclModel })
    public LeaseAreaAclRecords: Array<LeaseAreaAclModel> = null;
}

export interface UserFilter {
    Name?: string;
    Email?: string;
    UserRoles?: Array<number>;

    CustomerId?: number;
    BuildingId?: number;
    TenantId?: number;
    LeaseId?: number;
    LeaseAreaId?: number;
    UserStatus?: boolean; // True for active users only; False for inactive users only.
    token?: string;
}

export type UserFilterState = CompositeType<UserFilter, FilterState>;
