import { ActionReducerMap } from '@ngrx/store';

import * as fromPermissionRecord from './security/permission-record/permission-record.reducers';

export interface State {
    permissionRecordState: fromPermissionRecord.State;
}

export const reducers: ActionReducerMap<State> = {
    permissionRecordState: fromPermissionRecord.reducer
};
