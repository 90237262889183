
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSetting from './settings/setting.reducers';

export const FEATURE_NAME = 'configurationStore';

export interface FeatureState {
    settingState: fromSetting.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    settingState: fromSetting.reducer
};

export const featureSelector = createFeatureSelector<FeatureState>(FEATURE_NAME);

const getSettingStateSelector = (state: FeatureState) => state.settingState;
export const getSettingState = createSelector(featureSelector, getSettingStateSelector);
export const getSettings = createSelector(getSettingState, s => s.settings);
