import { WeekDay } from '@angular/common';
import { TimeSpan } from './date.timespan';
import { CompositeType } from '../type-converter';

export interface TimeRange {
    dayOfWeek?: WeekDay;
    startTime: TimeSpan;
    endTime: TimeSpan;
}

export class TimeRange {
    public static empty(dayOfWeek?: WeekDay): TimeRange {
        return {
            dayOfWeek: null,
            startTime: TimeSpan.empty(),
            endTime: TimeSpan.empty()
        };
    }
}

export type ServiceTimeRange = CompositeType<TimeRange, { onDemand: boolean }>;
