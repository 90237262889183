import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private readonly location: Location) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkRoles(route, state.url);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkRoles(route, state.url);
    }

    canLoad(route: Route) {
        return this.checkRoles(route, this.location.path());
    }

    private checkRoles(route: ActivatedRouteSnapshot | Route, returnUrl: string): boolean {
        return true;
        // if (this.authService.isTokenExpired) {
        //     this.router.navigate(['/login'], { queryParams: { returnUrl } });
        //     return false;
        // } else {
        //     const roles = route.data['roles'] as string[];
        //     const hasRole = this.authService.hasAnyRole(roles);

        //     if (!hasRole) {
        //         this.router.navigate(['/no-access'], { state: { roles } });
        //     }

        //     return hasRole;
        // }
    }
}
