import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { LanguageModel } from '../../../_sdk/localization/language.model';
import * as LanguageActions from './language.actions';

export interface State {
    languages: ModelCollection<LanguageModel>;
}

const initialState: State = {
    languages: new ModelCollection<LanguageModel>(),
};

export function reducer(
    state: State = initialState,
    action: LanguageActions.Actions
): State {

    let languages: ModelCollection<LanguageModel>;
    let index: number;

    switch (action.type) {
        case LanguageActions.DISPOSE:
            return {
                ...state,
                languages: initialState.languages
            };
        case LanguageActions.LOAD_COMPLETE:
            return {
                ...state,
                languages: action.payload
            };
        case LanguageActions.LOAD:
        case LanguageActions.INSERT:
        case LanguageActions.UPDATE:
        case LanguageActions.DELETE:
            return {
                ...state
            };
        case LanguageActions.INSERT_COMPLETE:
            languages = new ModelCollection(state.languages.data
                .map(p => TypeConverter.convert(LanguageModel, p)));

            languages.data.push(action.payload);

            return {
                ...state,
                languages: languages
            };

        case LanguageActions.UPDATE_COMPLETE:
            languages = new ModelCollection(state.languages.data
                .map(p => TypeConverter.convert(LanguageModel, p)));

            index = languages.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                languages.data[index] = action.payload;
            }

            return {
                ...state,
                languages: languages
            };

        case LanguageActions.DELETE_COMPLETE:
            languages = new ModelCollection(state.languages.data
                .map(p => TypeConverter.convert(LanguageModel, p)));

            index = languages.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                languages.data.splice(index, 1);
            }

            return {
                ...state,
                languages: languages
            };
        default:
            return state;
    }
}
