import { WeekDay } from '@angular/common';

import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';

import { InvalidOperationException } from '../exceptions/invalid-operation.exception';
import { UnspecificDateTime } from './date.unspecified';
import { getTimeZoneId } from './timezone-names';

const smalldateRegex = /^(\d{4})-(\d{2})-(\d{2})T?/;
const defaultStringFormat = 'MM/DD/YYYY';
const defaultJSONFormat = 'YYYY-MM-DD';

export class SmallDate {

    public readonly year: number;
    public readonly month: number;
    public readonly day: number;
    public readonly dayOfWeek: WeekDay;

    constructor(source?: { year: number, month: number, day: number } | Date) {
        if (!source) {
            return;
        }

        if (source instanceof Date) {
            this.year = source.getFullYear();
            this.month = source.getMonth();
            this.day = source.getDate();
        } else {
            this.year = source.year;
            this.month = source.month;
            this.day = source.day;
        }

        this.dayOfWeek = Date.dayOfWeek(this.toDate());
    }

    public static fromDate(date: Date): SmallDate {
        return new SmallDate(date);
    }

    private static fromMoment(source: moment.Moment) {
        return new SmallDate({ year: source.year(), month: source.month(), day: source.date() });
    }

    public static fromUnspecificDateTime(source: UnspecificDateTime): SmallDate {
        if (source.Year + source.Month + source.Day <= 0) {
            // It's not valid...
            return null;
        }

        const date = {
            year: source.Year,
            month: source.Month - 1, // 0 index...
            day: source.Day
        };

        return new SmallDate({ year: date.year, month: date.month, day: date.day });
    }

    public static now(): SmallDate {
        return SmallDate.fromDate(new Date());
    }

    public static addDays(date: SmallDate, offset: number): SmallDate {
        return date.addDays(offset);
    }

    public static addMonths(date: SmallDate, offset: number): SmallDate {
        return date.addMonths(offset);
    }

    public static addYears(date: SmallDate, offset: number): SmallDate {
        return date.addYears(offset);
    }

    public static isSmallDate(input: string): boolean {
        if (String.isNullOrEmpty(input)) {
            return false;
        }

        return smalldateRegex.test(input);
    }

    public static parse(input: string): SmallDate {

        const groups = smalldateRegex.exec(input);

        const year: number = +groups[1];
        let month: number = +groups[2];
        const day: number = +groups[3];

        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            throw new InvalidOperationException(`Invalid input: ${input}`);
        }

        // We need to reduce the month by 1 (zero index)
        month = month - 1;

        return new SmallDate({ year, month, day });
    }

    public static convert(value: any): SmallDate {
        if (!value) {
            return null;
        }

        if (value instanceof SmallDate) {
            return value;
        }

        if (value instanceof Date) {
            return SmallDate.fromDate(value);
        }

        // DateTime, moment
        if (typeof value.toDate === typeof Function) {
            return SmallDate.fromDate(value.toDate());
        }

        // UnspecificDateTime
        if (UnspecificDateTime.isUnspecificDateTime(value)) {
            return SmallDate.fromUnspecificDateTime(value);
        }

        if (typeof value === typeof '') { // String
            if (SmallDate.isSmallDate(<string>value)) {
                return SmallDate.parse(<string>value);
            }

            if (!isNaN(+value)) { // '40000'
                return SmallDate.fromDate(new Date(+value));
            }
        }

        if (typeof value === typeof 0) { // 40000
            return SmallDate.fromDate(new Date(Math.round(<number>value)));
        }

        return value as SmallDate;
    }

    public static format(date: SmallDate, formatter?: string): string {
        return date.format(formatter);
    }

    public static equals(a: SmallDate, b: SmallDate): boolean {
        if ((a === null || a === undefined) && (b === null || b === undefined)) {
            return true;
        }

        if (a === null || a === undefined) {
            return false;
        }

        return a.equals(b);
    }

    public get hasValue(): boolean {
        return this.year + this.month + this.day > 0;
    }

    private mutator(): moment.Moment {
        return moment([this.year, this.month, this.day]);
    }

    public addDays(offset: number): SmallDate {
        const mutate = this.mutator();
        return SmallDate.fromMoment(mutate.add(offset, 'd'));
    }

    public addMonths(offset: number): SmallDate {
        const mutate = this.mutator();
        return SmallDate.fromMoment(mutate.add(offset, 'M'));
    }

    public addYears(offset: number): SmallDate {
        const mutate = this.mutator();
        return SmallDate.fromMoment(mutate.add(offset, 'y'));
    }

    public toDate(): Date {
        const mutate = moment([this.year, this.month, this.day]);
        return mutate.toDate();
    }

    public format(formatter?: string): string {
        const ignoreTimeFormatRegex = /a|A|H|h|k|m|s|S|z|Z|x|X/g;
        formatter = (formatter || '').replace(ignoreTimeFormatRegex, '');
        const mutate = moment([this.year, this.month, this.day]);
        return mutate.format(formatter);
    }

    public toString(): string {
        return this.format(defaultStringFormat);
    }

    public toJSON(): string {
        return this.format(defaultJSONFormat);
    }

    public timezoneISOName(timezone: string): string {
        return getTimeZoneId(timezone);
    }
    
    public timezoneISOAbbr(timezone: string): string {
        return momentTZ().tz(this.timezoneISOName(timezone)).format('z');
    }
    
    public equals(other: SmallDate): boolean {
        if (other === null || other === undefined) {
            return false;
        }

        return this.year === other.year
            && this.month === other.month
            && this.day === other.day;
    }
    public static isLatest(currentDate,dateToCompare): boolean {
        return moment(dateToCompare).isAfter(currentDate);
    }
}
