import { Component, Optional, Inject, ViewChild, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel, FormBuilder, FormGroup } from '@angular/forms';

import { BaseInputElement } from '../../../../_core/components/forms/base-input-element';
import { DateTime, SmallDate } from '../../../../_core/date';
import { ValidatorArray, AsyncValidatorArray } from '../../../../_core/components/forms/validate';

let _identifier = 0;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'smalldate-datepicker',
    styleUrls: ['smalldate-datepicker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-container [formGroup]="editForm">
            <kendo-datepicker
                [id]="identifier"
                [format]="format"
                [bottomView] = "bottomView"
                [placeholder]="placeholder"
                [formatPlaceholder]="formatPlaceholder"
                [min]="min"
                [max]="max"
                [focusedDate]="focusedDate"
                [(value)]="selectedDate" 
                (valueChange)="onChange($event)"
                formControlName="jsValue"
                class="w-auto">
            </kendo-datepicker>
        </ng-container>
    `,

    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SmallDateDatePickerComponent),
        multi: true
    }]
})
export class SmallDateDatePickerComponent extends BaseInputElement<SmallDate> {

    public readonly identifier = `smalldate-datepicker-${_identifier++}`;

    public readonly editForm: FormGroup;

    private _min: Date;
    private _max: Date;
    private _focusedDate: Date;
    public selectedDate: Date;

    @ViewChild(NgModel)
    public model: NgModel;

    @Input()
    public format: string;

    @Input()
    public formatPlaceholder: string;

    @Input()
    public placeholder: string;

    @Input()
    public bottomView: string;

    public get focusedDate(): Date | SmallDate {
        return this._focusedDate;
    }

    @Input()
    public set focusedDate(value: Date | SmallDate) {
        if(!value) {
            return;
        }

        const normalized = this.normalizeValue(value).toDate();

        this._focusedDate = normalized;
    }

    public get min(): Date | SmallDate {
        return this._min;
    }
    @Input()
    public set min(value: Date | SmallDate) {
        if (!value) {
            return;
        }

        const normalized = this.normalizeValue(value).toDate();

        this._min = normalized;
    }

    public get max(): Date | SmallDate {
        return this._max;
    }
    @Input()
    public set max(value: Date | SmallDate) {
        if (!value) {
            return;
        }

        const normalized = this.normalizeValue(value).toDate();

        this._max = normalized;
    }

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: ValidatorArray,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorArray,
        private readonly formBuilder: FormBuilder
    ) {
        super(validators, asyncValidators);

        this.editForm = this.formBuilder.group({
            jsValue: null
        });

        this.format = 'MM/dd/yyyy';
        this.placeholder = this.format;
        this.formatPlaceholder = 'formatPattern';
    }

    public onChange(value: any): void {
        if (value) {
            this.value = SmallDate.fromDate(value);
            // Update the selected date to reflect the new value
            this.selectedDate = this.value.toDate();
        } else {
            this.value = value;
        }
    }

    public setDisabledState(isDisabled: boolean): void {
        super.setDisabledState(isDisabled);
    
        if(isDisabled) {
          this.editForm.disable();
        } else {
          this.editForm.enable();
        }
    }
    protected bindValue(value: SmallDate) {
        const normalized = this.normalizeValue(value);

        this.editForm.patchValue({ jsValue: normalized && normalized.toDate() });
    }

    private normalizeValue(value: Date | SmallDate | DateTime | string | number): SmallDate {
        if (!value) {
            return null;
        }

        return SmallDate.convert(value);
    }
}
