import { ModelCollection } from '../../../_sdk/collection.model';

import * as TemplateActions from './template.actions';
import { TemplateModel } from '../../../_sdk/parameter-configuration/template.model';
import { TypeConverter } from '../../../_core/type-converter';

export interface State {
    templates: ModelCollection<TemplateModel>;
}

const initialState: State = {
    templates: new ModelCollection<TemplateModel>(),
};

export function reducer(
    state: State = initialState,
    action: TemplateActions.Actions
): State {

    let templates: ModelCollection<TemplateModel>;
    let index: number;

    switch (action.type) {
        case TemplateActions.DISPOSE:
            return {
                ...state,
                templates: initialState.templates
            };
        case TemplateActions.LOAD_COMPLETE:
            return {
                ...state,
                templates: action.payload
            };

        case TemplateActions.LOAD:
        case TemplateActions.INSERT:
        case TemplateActions.UPDATE:
        case TemplateActions.DELETE:
            return state;
        case TemplateActions.INSERT_COMPLETE:
            templates = new ModelCollection(state.templates.data
                .map(p => TypeConverter.convert(TemplateModel, p)));

            templates.data.push(action.payload);

            return {
                ...state,
                templates: templates
            };

        case TemplateActions.UPDATE_COMPLETE:
            templates = new ModelCollection(state.templates.data
                .map(p => TypeConverter.convert(TemplateModel, p)));

            index = templates.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                templates.data[index] = action.payload;
            }

            return {
                ...state,
                templates: templates
            };

        case TemplateActions.DELETE_COMPLETE:
            templates = new ModelCollection(state.templates.data
                .map(p => TypeConverter.convert(TemplateModel, p)));

            index = templates.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                templates.data.splice(index, 1);
            }

            return {
                ...state,
                templates: templates
            };
        default:
            return state;
    }
}
