export { };

declare global {

    interface StringConstructor {
        equals: (
            left: string,
            right: string,
            caseSensitive?: boolean,
            trimBeforeCompare?: boolean
        ) => boolean;
        looseEquals: (left: string, right: string) => boolean;
        contains: (
            source: string,
            compare: string,
            caseSensitive?: boolean,
            trimBeforeCompare?: boolean
        ) => boolean;
        looseContains: (left: string, right: string) => boolean;
        isNullOrEmpty: (input: string) => boolean;
        stripHtml: (input: string) => string;
        escapeHtml: (input: string) => string;
        trimOrEmpty: (input: string) => string;

        padLeft: (input: string, char: string, length: number) => string;
        padRight: (input: string, char: string, length: number) => string;

        toHtml: (input: string) => string;
        toArray: (input: string, splitter: string) => Array<string>;
        random(length?: number): string;
        isString(input: any): boolean;

        numeric(input: string): string;
        alphaNumeric(input: string): string;
        alpha(input: string): string;
    }

    interface String {
        equals: (
            input: string,
            caseSensitive?: boolean,
            trimBeforeCompare?: boolean
        ) => boolean;
        looseEquals: (input: string) => boolean;
        contains: (
            input: string,
            caseSensitive?: boolean,
            trimBeforeCompare?: boolean
        ) => boolean;
        stripHtml: () => string;
        escapeHtml: () => string;
        trimOrEmpty: () => string;

        padLeft: (char: string, length: number) => string;
        padRight: (char: string, length: number) => string;

        toHtml: () => string;
        toArray: (splitter: string) => Array<string>;

        numeric(): string;
        alphaNumeric(): string;
        alpha(): string;
    }

}

if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (this.length > targetLength) {
            return String(this);
        }
        else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}

String.isString = (input: any): boolean => {
    switch (typeof input) {
        case 'string':
            return true;
    }

    return !!(input instanceof String || input === String);
};

String.equals = (
    left: string,
    right: string,
    caseSensitive: boolean = true,
    trimBeforeCompare: boolean = false
): boolean => {
    if (String.isNullOrEmpty(left) && String.isNullOrEmpty(right)) {
        return true;
    }

    left = left || '';
    right = right || '';

    if (!caseSensitive) {
        left = left.toLowerCase();
        right = right.toLowerCase();
    }

    if (trimBeforeCompare) {
        left = String.trimOrEmpty(left);
        right = String.trimOrEmpty(right);
    }

    return left === right;
};

String.looseEquals = (left: string, right: string): boolean => {
    return String.equals(left, right, false, true);
};

String.contains = (
    source: string,
    compare: string,
    caseSensitive: boolean = false,
    trimBeforeCompare: boolean = true
): boolean => {
    if (String.isNullOrEmpty(compare)) {
        return true;
    }

    if (String.isNullOrEmpty(source) && String.isNullOrEmpty(compare)) {
        return true;
    }

    source = source || '';

    if (!caseSensitive) {
        source = source.toLowerCase();
        compare = compare.toLowerCase();
    }

    if (trimBeforeCompare) {
        source = String.trimOrEmpty(source);
        compare = String.trimOrEmpty(compare);
    }

    return source.includes(compare);
};

String.looseContains = (left: string, right: string): boolean => {
    return String.contains(left, right, false, true);
};

String.isNullOrEmpty = (input: string): boolean => {
    input = String.trimOrEmpty(input);

    return input.length === 0;
};

String.stripHtml = (input: string): string => {
    let output = String.trimOrEmpty(input);

    if (String.isNullOrEmpty(output)) {
        return output;
    }

    output = output
        .replace(/<[\/]?([^> ]+)[^>]*>/g, '')
        .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, '')
        .replace(/<script[^>]*>[\s\S]*?<\/script>/gi, '')
        .replace(/<!--[\s\S]*?-->/g, '')
        .replace('&nbsp;', ' ')
        .replace('&amp;', '&');

    return output;
};

String.escapeHtml = (input: string): string => {
    let output = String.trimOrEmpty(input);

    if (String.isNullOrEmpty(output)) {
        return output;
    }

    output = output
        .replace(/"/g, '&quot;')
        .replace(/&(?!\w+;)/g, '&amp;')
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;');

    return output;
};

String.trimOrEmpty = (input: string): string => {
    if (!String.isString(input)) {
        return '';
    }

    return (input || '').trim();
};

String.padLeft = (input: string, char: string, length: number): string => {
    // const an = input.length;
    const charCount = input.length; //1 + Math.floor(Math.log(an) / Math.LN10);
    if (charCount >= length) {
        return input;
    }

    const padString = Math.pow(10, length - charCount).toString().substr(1).replace(/0/g, char);
    return padString + input;
};

String.padRight = (input: string, char: string, length: number): string => {
    // const an = input.length;
    const charCount = input.length; //1 + Math.floor(Math.log(an) / Math.LN10);

    if (charCount >= length) {
        return input;
    }

    const padString = Math.pow(10, length - charCount).toString().substr(1).replace(/0/g, char);
    return input + padString;
};

String.toHtml = (input: string): string => {
    let output = String.trimOrEmpty(input);

    if (String.isNullOrEmpty(output)) {
        return output;
    }

    output = input
        .replace(/\n?\r/gi, '<br />');

    return output;
};

String.toArray = (input: string, splitter: string = ''): Array<string> => {
    const sourceStr = <String>input;

    splitter = splitter || '';

    const split = (sourceStr)
        .split(splitter)
        .map(item => item.trim());

    return split;
};

String.random = (length?: number): string => {
    length = length || 5;
    return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
};

String.alpha = (input: string): string => {
    return input.replace(/[^a-zA-Z]/g, '');
};

String.numeric = (input: string): string => {
    return input.replace(/[^0-9]/g, '');
};

String.alphaNumeric = (input: string): string => {
    return input.replace(/\W*/g, '');
};
    

String.prototype.equals = function (
    input: string,
    caseSensitive?: boolean,
    trimBeforeCompare?: boolean
) {
    return String.equals(this.toString(), input, caseSensitive, trimBeforeCompare);
};

String.prototype.looseEquals = function (input: string) {
    return this.equals(input, false, true);
};

String.prototype.contains = function (
    input: string,
    caseSensitive?: boolean,
    trimBeforeCompare?: boolean
) {
    return String.contains(this.toString(), input, caseSensitive, trimBeforeCompare);
};

String.prototype.stripHtml = function () {
    return String.stripHtml(this);
};

String.prototype.escapeHtml = function () {
    return String.escapeHtml(this);
};

String.prototype.trimOrEmpty = function () {
    return String.trimOrEmpty(this);
};

String.prototype.padLeft = function (char: string, length: number): string {
    return String.padLeft(this, char, length);
};

String.prototype.padRight = function (char: string, length: number): string {
    return String.padRight(this, char, length);
};

String.prototype.toHtml = function (): string {
    return String.toHtml(this);
};

String.prototype.toArray = function (splitter: string = ''): Array<string> {
    return String.toArray(this, splitter);
};

String.prototype.alpha = function (): string {
    return String.alpha(this);
};

String.prototype.numeric = function (): string {
    return String.numeric(this);
};

String.prototype.alphaNumeric = function (): string {
    return String.alphaNumeric(this);
};
