import { Injectable } from '@angular/core';
import { FeeType } from '../../_models/enumerations/fee-type.enumeration';
import { ReadingType, ReadingTypeNames } from '../../_models/enumerations/reading-type.enumeration';
import { MeasurementUnit, MeasurementUnitNames } from '../../_models/enumerations/measurement-unit.enumeration';
import { ReadIntervalType, ReadIntervalTypeNames } from '../../../../../_sdk/ems-devices/read-interval-type.enum';
import { AlarmType } from '../../_models/enumerations/alarm-type.enumeration';
import { MeasurementType, MeasurementTypeNames } from '../../_models/enumerations/measurement-type.enumeration';
import { SiteType } from '../../_models/enumerations/site-type.enumeration';
import { DayType, DayTypeNames } from '../../../../../_sdk/calendar/enumerations/day-type.enumeration';
import { WeekOfMonth } from '../../../../../_sdk/calendar/enumerations/week-of-month.enumeration';
import { MonthOfYear } from '../../../../../_sdk/calendar/enumerations/month-of-year.enumeration';
import { SectorType, SectorTypeNames } from '../../_models/enumerations/sector-type.enumeration';
import { RateScheduleType, RateScheduleTypeNames } from '../../_models/enumerations/rate-schedule-type.enumeration';
import { WeekDay } from '@angular/common';
import { UtilityType, UtilityTypeLoadMapping, UtilityTypeNames } from '../../_models/enumerations/utility-type.enumeration';
/* New Imports for Water Load Types */
import { LoadType, LoadTypeNames } from '../../_models/enumerations/load-type.enumeration';
/* New Imports for Water Load Types */

import { LeaseSpaceType, LeaseSpaceTypeNames } from '../../../../../_sdk/leases/enumerations/lease-space-type-model';
import {PanelType,PanelTypeNames,MeterSubType,MeterSubTypeNames,TenantGroup,TenantGroupNames,UserType,UserTypeNames} from '../../_models/enumerations/meter-submeter-billing-settings.enum';
import { AutomationType, AutomationTypeNames } from '../../../invoicing/hvac-invoicing/_models/enumerations/automation-type.enumeration';
import { BusinessType, BusinessTypeNames, BussinessSubType, BussinessSubTypeName, LeaseType, LeaseTypeNames } from '../../../tenants/tenant-submeter-billing-settings.enum';
import { BillingDefaultScale, BillingDefaultScaleNames, BuildingClassification, BuildingClassificationNames, BuildingType, BuildingTypeNames, MeterType, MeterTypeNames, ReaderType, ReaderTypeNames } from '../../../buildings/enumerations/submeter-billing-setting.enum';
import { ServiceType, ServiceTypeNames } from '../../../../../_sdk/leases/service-type.enum';
import { BillingCycleType, BillingCycleTypeNames } from '../../../../../_sdk/billing/billing-cycle-type.enum';
import { InvoicingPeriodFormat, InvoicingPeriodFormatNames } from '../../../invoicing/meter-invoicing/_models/enumerations/invoicing-period-format.enumeration';

export interface IDropDownOptions {
    text: string,
    value: any
}
@Injectable({
    providedIn: 'root'
})
export class EnumHelperService {
    public FeeTypes: IDropDownOptions[] = [];
    public ReadingType: IDropDownOptions[] = [];
    public MeasurementUnit: IDropDownOptions[] = [];
    public MeasurementUnitFilter: IDropDownOptions[] = [];
    public ReadInterval: IDropDownOptions[] = [];
    public AlarmTypes: IDropDownOptions[] = [];
    public MeasurementTypes: IDropDownOptions[] = [];
    public LocationType: IDropDownOptions[] = [];
    public DayTypes: IDropDownOptions[] = [];
    public WeekDayTypes: IDropDownOptions[] = [];
    public WeekTypes: IDropDownOptions[] = [];
    public MonthTypes: IDropDownOptions[] = [];
    public SectorTypes: IDropDownOptions[] = [];
    public RateScheduleTypes: IDropDownOptions[] = [];
    public UtilityTypes: IDropDownOptions[] = [];
    public LoadTypes: IDropDownOptions[] = [];
    public LeaseSpaceTypes: IDropDownOptions[] = [];
    public PanelType: IDropDownOptions[] = [];
    public TenantGroup : IDropDownOptions[] = [];
    public MeterSubType : IDropDownOptions[] = [];
    public UserType : IDropDownOptions[] = [];
    public AutomationType: IDropDownOptions[] = [];
    public BusinessType: IDropDownOptions[] = [];
    public BussinessSubType: IDropDownOptions[] = [];
    public LeaseType: IDropDownOptions[] = [];
    public BuildingType: IDropDownOptions[] = [];
    public BuildingClassification: IDropDownOptions[] = [];
    public MeterType: IDropDownOptions[] = [];
    public DifficultyScale: IDropDownOptions[] = [];
    public ReaderType: IDropDownOptions[] = [];
    public BuildingAreaDefaultServiceType: IDropDownOptions[] = [];
    public BillingCycle: IDropDownOptions[] = [];
    public InvoicingPeriodFormat: IDropDownOptions[] = [];
    
    constructor() {
        //  Initializing enum values
        this.loadEnum(FeeType, this.FeeTypes, '-- Select a fee type --');
        this.loadEnum(MeasurementUnit, this.MeasurementUnit, '-- Select a unit of measurement --', MeasurementUnitNames);
        this.loadEnum(MeasurementUnit, this.MeasurementUnitFilter, 'Any Unit', MeasurementUnitNames);
        this.loadEnum(AutomationType, this.AutomationType, '', AutomationTypeNames);
        this.loadEnum(ReadIntervalType, this.ReadInterval, '-- Select a read interval --', ReadIntervalTypeNames);
        this.loadEnum(AlarmType, this.AlarmTypes, '-- Select an alarm type --');
        this.loadEnum(MeasurementType, this.MeasurementTypes, '-- Select a measurement type --');    
        this.loadEnum(InvoicingPeriodFormat, this.InvoicingPeriodFormat,'-- Select a period format --', InvoicingPeriodFormatNames);   
        this.loadEnum(ReadingType, this.ReadingType);   
        this.loadEnum(SiteType, this.LocationType);
        this.loadEnum(DayType, this.DayTypes);
        this.loadEnum(WeekDay, this.WeekDayTypes);
        this.loadEnum(WeekOfMonth, this.WeekTypes);
        this.loadEnum(MonthOfYear, this.MonthTypes);
        this.loadEnum(RateScheduleType, this.RateScheduleTypes, '', RateScheduleTypeNames);
        this.loadEnum(SectorType, this.SectorTypes);
        this.loadEnum(UtilityType, this.UtilityTypes,'',UtilityTypeNames);
        this.loadEnum(LoadType, this.LoadTypes,'',LoadTypeNames);
        this.loadEnum(LeaseSpaceType, this.LeaseSpaceTypes,'',LeaseSpaceTypeNames);
        this.loadEnum(PanelType,this.PanelType,'',PanelTypeNames);
        this.loadEnum(TenantGroup, this.TenantGroup,'', TenantGroupNames);
        this.loadEnum(MeterSubType, this.MeterSubType,'', MeterSubTypeNames);
        this.loadEnum(UserType,this.UserType,'',UserTypeNames);
        this.loadEnum(BusinessType,this.BusinessType,'',BusinessTypeNames);
        this.loadEnum(BussinessSubType,this.BussinessSubType,'',BussinessSubTypeName);
        this.loadEnum(LeaseType,this.LeaseType,'',LeaseTypeNames);
        this.loadEnum(BuildingType,this.BuildingType,'',BuildingTypeNames);
        this.loadEnum(BuildingClassification,this.BuildingClassification,'',BuildingClassificationNames);
        this.loadEnum(MeterType,this.MeterType,'',MeterTypeNames);
        this.loadEnum(BillingDefaultScale,this.DifficultyScale,'',BillingDefaultScaleNames);
        this.loadEnum(ReaderType,this.ReaderType,'',ReaderTypeNames);
        this.loadEnum(ServiceType, this.BuildingAreaDefaultServiceType,'',ServiceTypeNames);
        this.loadEnum(BillingCycleType, this.BillingCycle,'',BillingCycleTypeNames);
    }

    private loadEnum(enumType, localProperty: { text: string, value: any }[], initialValue: string = '', nameResolver: Map<number, string> = null) {
        if (initialValue != '')
            localProperty.push({ text: initialValue, value: -1 });

        for (let item in enumType) {
            if (isNaN(Number(item))) {
                if (nameResolver)
                    localProperty.push({ text: nameResolver.get(enumType[item]), value: enumType[item] });
                else
                    localProperty.push({ text: item, value: enumType[item] });
            }
        }
    }

    public getDayTypeName(type: DayType) {
        return DayTypeNames.get(type);
    }

    public getMeasurementUnitName(type: MeasurementUnit) {
        return MeasurementUnitNames.get(type);
    }

    public getMeasurementTypeName(type: MeasurementType) {
        return MeasurementTypeNames.get(type);
    }

    public getUtilityTypeName(type) {
        if(type !==0){
            return UtilityTypeNames.get(type);
        }
        else{
            return 'Unknown'
        }
	}

    public getLoadTypeName(type: LoadType) {
        return LoadTypeNames.get(type);
    }
    
    public getLeaseSpaceTypeName(type:LeaseSpaceType){
        return LeaseSpaceTypeNames.get(type);
    }

    public getReadingTypeName(type: ReadingType) {
        return ReadingTypeNames.get(type);
    }

    public getSectorTypeName(type: SectorType) {
        if (type != null)
            return SectorTypeNames.get(type);
        else
            return "Unspecified";
    }

    public getReadIntervalName(type: ReadIntervalType) {
        return ReadIntervalTypeNames.get(type);
    }

    public getPanelTypeName(type: PanelType) {
        return PanelTypeNames.get(type);
    }

    public getTenantGroupName(type: TenantGroup) {
        return TenantGroupNames.get(type);
    }

    public getMeterSubTypeName(type: MeterSubType) {
        return MeterSubTypeNames.get(type);
    }

    public getUserTypeName(type: UserType) {
        return UserTypeNames.get(type);
    }
    public getAutomationTypeName(type: AutomationType){
        return AutomationTypeNames.get(type)
    }
   
    public getBusinessTypeName(type: BusinessType){
        return BusinessTypeNames.get(type)
    }
   
    public getBussinessSubTypeName(type: BussinessSubType){
        return BussinessSubTypeName.get(type);
    }
   
    public getLeaseTypeName(type: LeaseType){
        return LeaseTypeNames.get(type)
    }

    public getBuildingTypeName(type: BuildingType){
        return BuildingTypeNames.get(type)
    }
    
    public getBuildingClassificationName(type: BuildingClassification){
        return BuildingClassificationNames.get(type)
    }
    
    public getMeterTypeName(type: MeterType){
        return MeterTypeNames.get(type)
    }
    
    public getBillingDefaultScaleNames(type: BillingDefaultScale){
        return BillingDefaultScaleNames.get(type)
    }
    
    public getReaderTypeNames(type: ReaderType){
        return ReaderTypeNames.get(type);
    }

    public getUtilityLoadMapping(type){
        return UtilityTypeLoadMapping.get(type).split(',')
    }
}