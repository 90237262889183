import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, of } from 'rxjs';

import { ModelCollection } from '../_sdk/collection.model';
import { RESTService } from './rest.service';
import { toQueryString } from '../_core/url.utils';
import { tap, switchMap, finalize } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private readonly _loading$ = new BehaviorSubject(false);
    public readonly loading$ = this._loading$.asObservable();

    constructor(public readonly restService: RESTService) {
    }

    public request(uri: string, method: string = 'get', body?: any): Observable<any> {
        return this.wrap(this.restService.request(uri, method, { body }));
    }

    public query<T>(uri: string, params?: any): Observable<ModelCollection<T>> {
        return this.wrap(this.restService.get<T>(uri, params, { responseType: 'collection' }));
    }

    public querySingle<T>(uri: string, params?: any): Observable<T> {
        return this.wrap(this.restService.get<T>(uri, params, { responseType: 'single' }));
    }

    public post<T>(uri: string, body?: any): Observable<ModelCollection<T>> {
        return this.wrap(this.restService.post<T>(uri, body, { responseType: 'collection' }));
    }
    
    public postSingle<T>(uri: string, body?: any): Observable<T> {
        return this.wrap(this.restService.post<T>(uri, body, { responseType: 'single' }));
    }
    
    public put<T>(uri: string, body?: any): Observable<ModelCollection<T>> {
        return this.wrap(this.restService.put<T>(uri, body, { responseType: 'collection' }));
    }
    
    public putSingle<T>(uri: string, body?: any): Observable<T> {
        return this.wrap(this.restService.put<T>(uri, body, { responseType: 'single' }));
    }
    
    public patch<T>(uri: string, body?: any): Observable<ModelCollection<T>> {
        return this.wrap(this.restService.patch<T>(uri, body, { responseType: 'collection' }));
    }
    
    public patchSingle<T>(uri: string, body?: any): Observable<T> {
        return this.wrap(this.restService.patch<T>(uri, body, { responseType: 'single' }));
    }
    
    public insert<T>(uri: string, model: T): Observable<T> {
        return this.wrap(this.postSingle<T>(uri, model));
    }

    public update<T>(uri: string, model: T): Observable<T> {
        return this.wrap(this.putSingle<T>(uri, model));
    }

    public delete<T>(uri: string, model?: T): Observable<T> {
        const queryString = toQueryString(model);

        if (!String.isNullOrEmpty(queryString)) {
            const appender = uri.contains('?')
                ? '&' 
                : '?';

            uri = `${uri}${appender}${queryString}`;
        }

        return this.wrap(this.restService.delete<T>(uri, { responseType: 'single' }));
    }
    

    private wrap<R>(query: Observable<R>): Observable<R> {
        return of(true)
            .pipe(
                tap(_ => this._loading$.next(true)),
                switchMap(_ => query),
                tap(_ => this._loading$.next(false)),
                finalize(() => this._loading$.next(false))
            )
    }
}
