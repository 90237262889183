import { EntityModel } from '../../../../../_sdk/model';
import { DataProperty, DataObject } from '../../../../../_core/type-converter';
import { HvacInvoiceStateHistoryModel } from './hvac-invoice-state-history.model';
import { DateTime } from '../../../../../_core/date';


@DataObject()
export class HvacInvoiceHeader  extends  EntityModel{
   public InvoiceId:number = null;
    public LeaseId: number = null;
    public InvoiceState: number = null;
    public InvoiceNumber: number = null;
    public InvoiceLabel: string = null;
    public SubTotal: number = null;
    public SeparateInvoice: boolean = null;
    public GrandTotal:Number = null;
    public Active: boolean = null;
    public Deleted: boolean = null;
    public Id: number = null;

    /*complex object*/
    @DataProperty()
    public History: HvacInvoiceStateHistoryModel = null;
    @DataProperty()
    public CreatedOn: DateTime = null;
    @DataProperty()
    public ModifiedOn: DateTime = null;
}