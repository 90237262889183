import { ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Renderer2, ViewContainerRef } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, tap } from 'rxjs/operators';

import { BaseDirective } from '../../../_core/components/base.directive';
import { LoadingIndicatorComponent } from './loading-indicator.component';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[geneaLoadingIndicator]'
})
export class LoadingIndicatorDirective extends BaseDirective {
    private _componentRef: ComponentRef<LoadingIndicatorComponent>;
    private _component: LoadingIndicatorComponent;

    private readonly _processes$ = new BehaviorSubject<Array<string>>([]);
    public readonly processing$ = this._processes$.asObservable()
        .pipe(
            map(p => p.length > 0),
            distinctUntilChanged()
        );
        
    private get element(): HTMLElement {
        return this.host.nativeElement;
    }

    constructor(
        private readonly renderer: Renderer2,
        private readonly host: ElementRef<HTMLElement>,
        private readonly container: ViewContainerRef,
        private readonly resolver: ComponentFactoryResolver) {
        super();
    }

    OnDestroy() {
        if (this._componentRef) {
            this._componentRef.destroy();
        }
    }

    private begin(k: string): void {
        const state = [...this._processes$.getValue()];

        state.push(k);

        this._processes$.next(state);
    }

    private complete(k: string): void {
        const state = [...this._processes$.getValue()];

        const index = state.findIndex(p => p === k);

        if(index < 0) {
            // If no stream with a matching key is found, short circuit...
            return;
        }
        
        state.splice(index, 1);

        this._processes$.next(state);
    }

    public process<T>(source: Observable<T>): Observable<T> {
        this.initLoader();

        /* this._component.self = self; */

        const key = String.random(5);

        this.begin(key);

        return source
            .pipe(
                tap(_ => this.complete(key)),
                catchError(e => {
                    this.complete(key);
                    throw e;
                }),
                finalize(() => this.complete(key))
            );
    }

    private initLoader(): void {
        if(this._component) {
            return;
        }

        const factory = this.resolver.resolveComponentFactory(LoadingIndicatorComponent);
        this._componentRef = this.container.createComponent(factory);
        this._component = this._componentRef.instance;

        // Creating the component appends the HTML element AFTER the parent. We don't want that... 
        // We want the loading indicator to be the last HTML element in the container... To do that, we need to move it...
        const loaderComponentElement = this._componentRef.location.nativeElement;
        this.element.append(loaderComponentElement);

        // Make sure to set the container's position to relative!
        this.renderer.addClass(this.element, 'position-relative');

        this.processing$
            .pipe(
                tap(p => {
                    this._component.loading = p;
                })
            ).subscribe();
    }
}
