import { InjectionToken, NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { ActivateUserComponent } from './auth/activate-user/activate-user.component';
import { AuthCheckComponent } from './auth/auth-check/auth-check.component';
import { LoginComponent } from './auth/login/login.component';
import { NoAccessComponent } from './auth/no-access/no-access.component';
import { PasswordRecoveryComponent } from './auth/password-recovery/password-recovery.component';
import { RegisterComponent } from './auth/register/register.component';
import { PageNotFoundComponent } from './catch-all/page-not-found.component';
import { ResolveRouteNotFoundComponent } from './catch-all/resolve-route-not-found.component';

import { RedirectComponent } from './redirect/redirect.component';

import { PermissionConstant } from './_core/permission-constant';
import { RoleConstant } from './_core/role-constant';
import { defaultRouteData } from './_modules/common/route-data/route-data';
import { AuthGuard } from './_guards/auth-guard.service';
import { PermissionGuard } from './_guards/permission-guard.service';
import { RoleGuard } from './_guards/role-guard.service';
import { BuildingInvoicePreviewPageComponent } from './_modules/common-ui/building-invoice-preview-page/building-invoice-preview-page.component';
import { SnoozeNotificationComponent } from './auth/snooze-notification/snooze-notification.component';
import { ViewUtilityBillComponent } from './admin/platform/invoicing/meter-invoicing/view-utility-bill/view-utility-bill.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

let isMobile = window.innerWidth < 960
let displayType: Route;
if(!isMobile){
    displayType = {
        path: 'admin',
        canActivate: [AuthGuard, RoleGuard, PermissionGuard],
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        data: {
            ...defaultRouteData,
            roles: [
                RoleConstant.Administrators,
                RoleConstant.BuildingEngineer,
                RoleConstant.PropertyManager,
                // The following roles have limitted access to scheduled request administration.
                RoleConstant.TenantManager,
                RoleConstant.TenantUser,
                RoleConstant.SecurityUser,
            ],
            permissions: [PermissionConstant.AccessAdminPanel]
        }
}
}
else{
    displayType = {
        path: 'admin',
        canActivate: [AuthGuard, RoleGuard, PermissionGuard],
        loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule),
        data: {
            ...defaultRouteData,
            roles: [
                RoleConstant.Administrators,
                RoleConstant.BuildingEngineer,
                RoleConstant.PropertyManager,
                // The following roles have limitted access to scheduled request administration.
                RoleConstant.TenantManager,
                RoleConstant.TenantUser,
                RoleConstant.SecurityUser,
            ],
            permissions: [PermissionConstant.AccessAdminPanel],
            isMobile: true // Set data for mobile module
        }
    }
}

const routes: Routes = [
    { 
        path: '', children: [
            { path: '', component: AuthCheckComponent, pathMatch: 'full' },
            { path: 'login', component: LoginComponent },
            { path: 'no-access', component: NoAccessComponent },
            { path: 'snooze-notification', component: SnoozeNotificationComponent },
            { path: 'register', component: RegisterComponent },
            // { path: 'profile/:userGuid', component: ProfileComponent },
            { path: 'password-recovery/:userId/:token', component: PasswordRecoveryComponent },
            { path: 'activate-user/:userId/:token', component: ActivateUserComponent },
            displayType,
            {
                path: 'view/:InvoiceType/invoice/:InvoiceId',
                component: BuildingInvoicePreviewPageComponent,
                canActivate: [AuthGuard, RoleGuard, PermissionGuard],
                data: {
                    ...defaultRouteData,
                    roles: [
                        RoleConstant.Administrators                      
                    ],
                    permissions: [PermissionConstant.AccessAdminPanel]
                }
            },
            {
                path: 'view/utility-bill/:utilityBillId/:accountingPeriodId',
                component: ViewUtilityBillComponent,
                data: {
                    ...defaultRouteData,
                    roles: [
                        RoleConstant.Administrators,
                        RoleConstant.PropertyManager,
                        RoleConstant.BuildingEngineer,
                    ],
                    permissions: [PermissionConstant.ReadMeterInvoices]
                }
            },
            {
                path: 'externalRedirect',
                /* resolve: {
                    url: externalUrlProvider
                }, */
                // We need a component here because we cannot define the route otherwise
                component: RedirectComponent,
            },
            {
				path: '826A0684-F203-4D95-9EDA-D6572772298A',
                loadChildren: './public/public.module#PublicModule'
            },
            { path: '404', component: PageNotFoundComponent },
            { path: '**', component: ResolveRouteNotFoundComponent }
        ],
    }
];

@NgModule({
    /* providers: [
        {
            provide: externalUrlProvider,
            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = route.paramMap.get('externalUrl');
                window.open(externalUrl, '_self');
            },
        },
    ], */
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
