import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { CommandTypeModel } from '../../../_sdk/ems-devices/command-type.model';
import * as CommandActions from './command.actions';

export interface State {
    commands: ModelCollection<CommandTypeModel>;
}

const initialState: State = {
    commands: new ModelCollection<CommandTypeModel>()
};

export function reducer(
    state: State = initialState,
    action: CommandActions.Actions
): State {

    let commands: ModelCollection<CommandTypeModel>;
    let index: number;

    switch (action.type) {
        case CommandActions.DISPOSE:
            return {
                ...state,
                commands: initialState.commands
            };

        case CommandActions.LOAD_COMPLETE:
            return {
                ...state,
                commands: action.payload
            };
        case CommandActions.LOAD:
        case CommandActions.INSERT:
        case CommandActions.UPDATE:
        case CommandActions.DELETE:
            return state;
        case CommandActions.INSERT_COMPLETE:
            commands = new ModelCollection(state.commands.data
                .map(p => TypeConverter.convert(CommandTypeModel, p)));

            commands.data.push(action.payload);

            return {
                ...state,
                commands: commands
            };

        case CommandActions.UPDATE_COMPLETE:
            commands = new ModelCollection(state.commands.data
                .map(p => TypeConverter.convert(CommandTypeModel, p)));

            index = commands.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                commands.data[index] = action.payload;
            }

            return {
                ...state,
                commands: commands
            };

        case CommandActions.DELETE_COMPLETE:
            commands = new ModelCollection(state.commands.data
                .map(p => TypeConverter.convert(CommandTypeModel, p)));

            index = commands.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                commands.data.splice(index, 1);
            }

            return {
                ...state,
                commands: commands
            };

        default:
            return state;
    }
}
