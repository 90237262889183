<span (click)="drawer.toggle()"
  kendoTooltip
  [tooltipTemplate]="expandCollapseTooltip"
  tooltipClass="tooltipExpandCollapse"
  position="right"
  showAfter="450"
  filter="span"
  [ngClass]="drawerExpanded ? 'toggle-btn k-icon k-i-close' : 'toggle-btn k-icon arrow-left'">
</span>

<kendo-drawer-container>
    <kendo-drawer
    #drawer       
    mode="push"
    [mini]="miniValue"
    [miniWidth]="70"
    [position]="'end'"
    [width]="500"
    [(expanded)]="drawerExpanded"
    [autoCollapse]="false"
    >   
      
    <ng-template kendoDrawerTemplate> 
        <div Class="notes-section" *ngIf="drawerExpanded && !isPM">
     
             <div class="loading-indicator k-i-loading loader" *ngIf="isContentLoading$ | async">  
             </div>
            
            
            <h5>Notes</h5>
            <p> Here you have ability to see the Internal and Change Request Notes </p>   
            
            <kendo-tabstrip #kendoTabStrip [keepTabContent]="true">
                <kendo-tabstrip-tab [title]="'Internal Notes'" [selected]="true">
                  <ng-template kendoTabContent>
                    <div class="internal-notes" geneaLoadingIndicator>
                        <form [formGroup]="buildingNoteForm">
                            ​<textarea        
                            autofocus                              
                            formControlName="Note"                                           
                            class=" w-100 mt-1 form-control k-textarea form-control-sm"                             
                            [placeholder]="'eg. Please remove Suite 550 from this billing statement'">
                        </textarea>
                        </form>
                        <div class="w-100 btn-block">
                            <label *ngIf="buildingNote?.ModifiedBy">  Last updated on {{buildingNote?.ModifiedOnUtc?.format("DD MMM, YYYY hh:mm A") ||  buildingNote?.CreatedOnUtc?.format("DD MMM, YYYY hh:mm A") }} by {{buildingNote?.ModifiedBy?.FirstName || buildingNote?.CreatedBy?.FirstName }} {{buildingNote.ModifiedBy?.LastName ||  buildingNote.CreatedBy?.LastName }}</label>         
                                <button class="save-link pointer mr-3 float-right" (click)="saveNote()" *ngIf="notesUpdated$ | async"> Save Notes </button>
                                <span class="saved-text mr-3 float-right" *ngIf="!(notesUpdated$ | async)"> <span class="k-icon k-i-check pr-1"></span> Saved </span>
                            </div>
                    </div>
                    </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab [title]="'Change Requests'">
                    <ng-template kendoTabContent>
                        <div class="change-requests-block">
                            <div *ngIf="(invoiceChangeRequest$ | async) else changeRequestNotavailable">
                                <p class="p-0"> {{(invoiceChangeRequest$ | async)?.Message}}  </p>
                                <div class="mt-4 cr-label">
                                    Last updated on {{(invoiceChangeRequest$ |async)?.ModifiedOnUtc?.format("DD MMM, YYYY hh:mm A")}} by {{(invoiceChangeRequest$ | async)?.ModifiedBy?.FirstName}} {{(invoiceChangeRequest$ | async)?.ModifiedBy?.LastName}}
                                </div>                               
                            </div>                        
                            <ng-template #changeRequestNotavailable> <label> Change requests are not available for the invoice. </label> </ng-template> 
                        </div>
                    </ng-template>
                  </kendo-tabstrip-tab>
            </kendo-tabstrip> 
            
            <div class="buttons-block d-inline">               
                <button class="k-button k-primary" *ngIf="(hvacInvoiceData$ | async)?.State == hvacInvoiceStatus.PendingApproval" (click)="changeInvoice(hvacInvoiceStatus.Approved)"> Approve </button>
                <button class="k-button k-primary"  *ngIf="(hvacInvoiceData$ | async)?.State == hvacInvoiceStatus.Audit" (click)="changeInvoice(hvacInvoiceStatus.PendingApproval)"> Send for Approval </button>
                <button class="k-button k-primary" *ngIf="(invoiceChangeRequest$ | async)?.Message && (hvacInvoiceData$ | async)?.State == hvacInvoiceStatus.ChangesRequested" (click)="changeInvoice(hvacInvoiceStatus.PendingApproval)"> Resend for Approval </button>
                <button class="k-button k-primary" *ngIf="(hvacInvoiceData$ | async)?.State == hvacInvoiceStatus.Approved" (click)="changeInvoice(hvacInvoiceStatus.Closed)"> Close Invoice </button>
                <button class="k-button ml-2"  [routerLink]="['/admin/platform/invoicing/hvac/period', (hvacInvoiceData$ | async)?.Id, 'edit']"> Edit Invoice </button>       
            </div>
        </div>

        <div *ngIf="!drawerExpanded">
            <div class="mini-container">
                <span class="notes-icon pointer" title="Notes" (click)="drawer.toggle()"></span>
            </div>

        </div>
        <div class="notes-section m-2" *ngIf="drawerExpanded && isPM">
     
            <div class="loading-indicator k-i-loading loader" *ngIf="isContentLoading$ | async">  
            </div>
           
           
           <h5>Change Requests</h5>
           <p> This section allows you to change requests for the selected invoice </p> 
           <div class="internal-notes" geneaLoadingIndicator>
            <form>
                ​<textarea        
                autofocus                                       
                class="p-2 w-100 mt-1 form-control k-textarea form-control-sm"                             
                [(ngModel)]="changeRequestMessage"
                name = "changerequestMessage"
                [disabled]="((hvacInvoiceData$ | async)?.State !== hvacInvoiceStatus.PendingApproval) || (disableChangeRequest$ | async)"                                  
                [placeholder]="'Please provide as many details as possible on all change requests, including tenant/area/dates etc…'">
            </textarea>
            </form>
            <div class="w-100 btn-block">
                <label *ngIf="(invoiceChangeRequestPM?.CreatedBy)">  
                    Last updated on {{invoiceChangeRequestPM?.ModifiedOnUtc?.format("DD MMM, YYYY hh:mm A") ||  invoiceChangeRequestPM?.CreatedOnUtc?.format("DD MMM, YYYY hh:mm A") }} by {{invoiceChangeRequestPM?.ModifiedBy?.FirstName || invoiceChangeRequestPM?.CreatedBy?.FirstName }} {{invoiceChangeRequestPM?.ModifiedBy?.LastName ||  invoiceChangeRequestPM?.CreatedBy?.LastName }}
                </label>         
                </div>
           </div>
           <div class="buttons-block d-inline" style="bottom: 15px;">               
            <button class="k-button ml-2" *ngIf="(hvacInvoiceData$ | async)?.State == hvacInvoiceStatus.PendingApproval" (click)="changeInvoice(hvacInvoiceStatus.Approved)"> Approve Invoice</button>
            <button class="k-button ml-2"  (click)="goback()"> Cancel </button>                                         
                <button class="k-button k-primary ml-2" *ngIf="true" (click)="requestInvoice(hvacInvoiceStatus.PendingApproval)" [disabled]="((hvacInvoiceData$ | async)?.State !== hvacInvoiceStatus.PendingApproval) || (disableChangeRequest$ | async)"> Request Changes </button>
        </div>
        </div>
    </ng-template>
  </kendo-drawer>

  <kendo-drawer-content>  
    <div class="nav-header">
        <h2 *ngIf="(hvacInvoiceData$|async) as invoiceData"> {{invoiceData?.BuildingName || ''  +  ' - ' }} Building {{ ' - ' + invoiceData?.Label || ''}} </h2>
    </div>
    
    <main class="main-content">
      <div class="pt-1 pb-2 pr-2 pageTitlePosition">
        <div class="pdf-container">
            <!-- <iframe  [src]="" width="950px"></iframe> -->
            <object id="pdfviewer" [data]="" type="application/pdf" style="width: 100%; height: 91vh;">
                <div>No online PDF viewer installed</div>
            </object>
            <div class="error-block">
              <span *ngIf="(error$ |async)?.status == 404" class="notFoundIcon ml-4"></span>
              <span *ngIf="(error$ |async)?.status == 500" class="internalErrorIcon ml-4"></span>
              <span class="error-message">{{ (error$ |async)?.errorMessage }}</span>
            </div>
          
            <div class="loading-indicator k-i-loading position-absolute" *ngIf="isLoading$ | async"> </div>
          </div>
     
      </div>
    </main>
    
    <div kendoWindowContainer></div>
    <div kendoDialogContainer></div>
  </kendo-drawer-content>
</kendo-drawer-container>

<ng-template #expandCollapseTooltip let-anchor>
    <span class="text-mute"> {{drawerExpanded ? 'Hide':'Expand'}} </span>
</ng-template>

<ng-template #requestChangeTemplate>
    <div class="container-fluid">
        <p>
            Once you send these changes, the invoice status will change and you will not be able to suggest more changes.
        </p>
    </div>
</ng-template>