import { Component, Host, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { DynamicFormComponent } from '../../containers/dynamic-form.component';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-button',
    styleUrls: ['form-button.component.scss'],
    template: `
    <ng-container [formGroup]="group">
        <button [class]="config.cssClass"
            [disabled]="config.disabled"
            (click)="clickHandler($event)">
            {{ config.value }}
        </button>
    </ng-container>
  `
})
export class FormButtonComponent implements Field {

    config: FieldConfig;
    group: FormGroup;

    constructor(private readonly form: DynamicFormComponent) {

    }

    public clickHandler(event: any): void {
        if (this.form) {
            this.form.handleSubmit(event);
        }
    }
}
