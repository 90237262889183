export enum LeaseSpaceType {
    Office = 1,
    Industrial = 2,
    Retail = 3,
    Healthcare = 4,
    Other = 5,
    NotApplicable=6
}
export const LeaseSpaceTypeNames = new Map<number, string>([
    [LeaseSpaceType.Office, 'Office'],
    [LeaseSpaceType.Industrial, 'Industrial'],
    [LeaseSpaceType.Retail, 'Retail'],
    [LeaseSpaceType.Healthcare, 'Healthcare'],  
    [LeaseSpaceType.Other, 'Other'],
    [LeaseSpaceType.NotApplicable, 'Not Applicable']
 ])

