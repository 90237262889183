import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, filter, switchMap } from 'rxjs/operators';
import { BaseComponent } from '../../_core/components/base.component';
import { CommonSettings } from '../../_services/common/common.settings';

@Component({
    selector: 'app-no-access',
    templateUrl: './no-access.component.html',
    styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent extends BaseComponent {

    private _roles$ = new BehaviorSubject<Array<string>>([]);
    private _permissions$ = new BehaviorSubject<Array<string>>([]);

    public roles$ = this._roles$.asObservable();
    public permissions$ = this._permissions$.asObservable();

    constructor(
        private readonly location: Location,
        private readonly route: ActivatedRoute,
        private readonly commonSettings: CommonSettings,
    ) {
        super();
    }

    OnInit(): void {
        super.OnInit();

        // Do not display the required roles and permissions if the commonSettings value is set.
        this.subscriptions.add(
            this.commonSettings.SuppressDetailsInNoAccessPage.pipe(
                filter(value => !value),
                switchMap(_ => this.route.paramMap),
                map(() => window.history.state),
                filter(p => !!p),
                tap(p => this._roles$.next(p.roles || [])),
                tap(p => this._permissions$.next(p.permissions || []))
            ).subscribe()
        );
    }

    public goBack(): void {
        this.location.back();
    }
}
