import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { BaseComponent } from '../../../_core/components/base.component';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'verifiable-results',
    templateUrl: './verifiable-results.component.html',
    styleUrls: ['./verifiable-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class VerifiableResultsComponent extends BaseComponent {

    private readonly _results$ = new BehaviorSubject<any>(null);

    @Input()
    public title: string;

    @Input()
    public customMessage: string;

    @Input()
    public get results(): any {
        return this._results$.getValue();
    }
    public set results(value: any) {
        this._results$.next(value);
    }

    public get resultsArray() {
        if(String.isNullOrEmpty(this.customMessage)) {
            return Object.keys(this.results || {})
                .map(p => this.results[p])
                .filter(p => !!p)
                .map(p => p.toString());
        }

        return [this.customMessage];
    }

    constructor() {
        super();
    }
}