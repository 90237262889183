import { Pipe, PipeTransform } from '@angular/core';
import { TimeSpan } from '../../../_core/date';
import { EntityType, EntityTypeNames } from '../../../_sdk/search/entity-type.enum';
import { TypeConverter } from '../../../_core/type-converter';

@Pipe({ name: 'entityType' })
export class EntityTypePipe implements PipeTransform {

    transform(value: EntityType | number | string): string {
        if(TypeConverter.isString(value)) {
            return value;
        }

        return EntityTypeNames.get(value);
    }
}
