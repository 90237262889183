export enum SectorType {
    Unspecified = 0,
    Residential = 1,
    Commercial,
    Industrial,
    Lighting
}

export const SectorTypeNames = new Map<number, string>([
    [SectorType.Unspecified, 'Unspecified'],
    [SectorType.Residential, 'Residential'],
    [SectorType.Commercial, 'Commercial'],
    [SectorType.Industrial, 'Industrial'],
    [SectorType.Lighting, 'Lighting']
]);