import { AfterViewInit, Injectable } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { DateTime } from '../../_core/date';
import { notNull } from '../../_core/rxjs.operators';
import { AuthService } from '../../_services/auth/auth.service';
declare const environmentResource:any; 

declare var hj: any;
declare var window: any;
type HotjarUser = { email: string, user_id: string, name: string };

@Injectable({
    providedIn: 'root'
})
export class HotjarService{
    private _isBooted: boolean = false;
    private _scriptAdded: boolean = false;

    private readonly _user$ = new BehaviorSubject<HotjarUser>(null);
    
    constructor(private readonly authService: AuthService) {
        this.authService.authenticatedUser$
            .pipe(
                tap(p => {
                    this._user$.next({
                        user_id: p && p.UserGuid,
                        email: p && p.Email,
                        name: p 
                            ? `${p.FirstName} ${p.LastName}`
                            : ''
                    });
                })
            )
            .subscribe();
    }
    /* commented because code is not working */
    /* ngAfterViewInit(){
        hj.ready(() => {

            window.hj('identify', this._user$.getValue().user_id, {
                FirstName:this._user$.getValue().name,
                Email:this._user$.getValue().email,
            });
        })
    } */
    public initialize(router: Router): this {
        this._user$
            .pipe(
                notNull(),
                distinctUntilKeyChanged('user_id'),
                tap(user => {

                    if(user.user_id && !this._isBooted) {
                        console.log('Hotjar: boot');

                        try {
                            this.hotjar(user)
                            
                        } catch(e) {
                            this._scriptAdded = false;
                
                            throw e;
                        }

                        this._isBooted = true;
                    }
                }),
                switchMap(user => {
                    return router.events
                        .pipe(
                            map(event => event as RouterEvent),
                            map(event => event && event.url),
                            filter(url => !String.isNullOrEmpty(url)),
                            distinctUntilChanged(),
                            tap(url => {
                                if(this._isBooted) {
                                    console.log('Hotjar: update');
                                    
                                }
                            })
                        )
                })
            )
            .subscribe();

        return this;
    }
    private hotjar(user: HotjarUser){
        window.hj = window.hj || (() =>{ (window.hj.q = window.hj.q || []).push(arguments)})
        window._hjSettings = {hjid:environmentResource.hjSettings.hjid,hjsv:environmentResource.hjSettings.hjsv};
        let element = document.getElementsByTagName('head')[0];
        let tag = document.createElement('script')
        tag.async = true;
        tag.src = 'https://static.hotjar.com/c/hotjar-' + window._hjSettings.hjid + '.js?sv=' + window._hjSettings.hjsv
        element.appendChild(tag)
        //Adding user related code here instead of after view
        window.hj('identify', user.user_id, {
            'User Name':user.name,
            'Email':user.email,
        });
    }
}