<div class="login-container">
    <div class="content">
        <div class="g-header text-center">
            <span alt="GENEA" class="geneaLogo"></span>
        </div>
        <div class="login-box row">
            <div class="col-4">
                <span class="no-access"></span>
            </div>
            <div class="col-8 go-back-button">

                <h2 class="Heading-text">You do not have access to the requested page.</h2>
                <div>
                    <div class="card p-3 text-center mb-3" *ngIf="(roles$ | async).length">
                        <label>
                            You need at least one of the following user roles
                        </label>
                        <div>
                            <div *ngFor="let role of roles$ | async">
                                <div>
                                    <strong>{{ role }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="(permissions$ | async).length">
                    <div class="card p-3 text-center mb-3">
                        <label>
                            You need at least one of the following permissions:
                        </label>
                        <div>
                            <div *ngFor="let permission of permissions$ | async">
                                <div>
                                    <strong>{{ permission }}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <button class="btn btn-primary" (click)="goBack()">Go Back!</button>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-3 text-center">
            <span class="btn-link pr-4"> <span class="phoneIcon"></span><a href="tel:+1-866-935-1557"
                    class="reset">866-935-1557 </a></span>
            <span class="btn-link"><span class="mailIcon"></span> <a href="mailto:support@getgenea.com"
                    class="reset">Support@GetGenea.com </a></span>
        </div>


    </div>