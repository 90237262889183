import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ControlErrorContainerDirective } from './control-error-container.directive';
import { ControlErrorComponent } from './control-error.component';
import { ControlErrorsDirective } from './control-errors.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';
import { GeneaInputsModule } from '../../common/inputs';

@NgModule({
    declarations: [
        ControlErrorContainerDirective,
        ControlErrorsDirective,
        FormSubmitDirective,

        ControlErrorComponent,
        ValidationMessagesComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        
        GeneaInputsModule
    ],
    exports: [
        ControlErrorContainerDirective,
        ControlErrorsDirective,
        FormSubmitDirective,
        ValidationMessagesComponent,
    ],
    entryComponents: [
        ControlErrorComponent
    ]
})
export class ControlErrorsModule {
    static exports(): Array<any> {
        return [
            ControlErrorContainerDirective,
            ControlErrorsDirective,
            FormSubmitDirective,
            ValidationMessagesComponent,
        ];
    }
 }
