import { EntityModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class CountryModel extends EntityModel {
    public Name: string = null;
    public TwoLetterIsoCode: string = null;
    public ThreeLetterIsoCode: string = null;
    public NumericIsoCode: number = null;
    public Active = false;
    public DisplayOrder: number = null;
}

export interface CountryFilter {
    LanguageId?: number;
    Name?: string;
    TwoLetterIsoCode?: string;
    ThreeLetterIsoCode?: string;
    NumericIsoCode?: number;
}

export type CountryFilterState = CompositeType<CountryFilter, FilterState>;
