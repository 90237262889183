import { Action } from '@ngrx/store';
import { LanguageModel } from '../../../_sdk/localization/language.model';
import { ModelCollection } from '../../../_sdk/collection.model';

export const LOAD = 'LANGUAGE_LOAD';
export const LOAD_COMPLETE = 'LANGUAGE_LOAD_COMPLETE';

export const INSERT = 'LANGUAGE_INSERT';
export const INSERT_COMPLETE = 'LANGUAGE_INSERT_COMPLETE';

export const UPDATE = 'LANGUAGE_UPDATE';
export const UPDATE_COMPLETE = 'LANGUAGE_UPDATE_COMPLETE';

export const DELETE = 'LANGUAGE_DELETE';
export const DELETE_COMPLETE = 'LANGUAGE_DELETE_COMPLETE';

export const DISPOSE = 'LANGUAGE_DISPOSE';

export class Load implements Action {
    public readonly type = LOAD;
}

export class LoadComplete implements Action {
    public readonly type = LOAD_COMPLETE;

    constructor(public payload: ModelCollection<LanguageModel>) { }
}

export class Insert implements Action {
    public readonly type = INSERT;

    constructor(public payload: LanguageModel) { }
}

export class InsertComplete implements Action {
    public readonly type = INSERT_COMPLETE;

    constructor(public payload: LanguageModel) { }
}

export class Update implements Action {
    public readonly type = UPDATE;

    constructor(public payload: LanguageModel) { }
}

export class UpdateComplete implements Action {
    public readonly type = UPDATE_COMPLETE;

    constructor(public payload: LanguageModel) { }
}

export class Delete implements Action {
    public readonly type = DELETE;

    constructor(public payload: LanguageModel) { }
}

export class DeleteComplete implements Action {
    public readonly type = DELETE_COMPLETE;

    constructor(public payload: LanguageModel) { }
}

export class Dispose implements Action {
    public readonly type = DISPOSE;
}

export type Actions =
    Load | LoadComplete
    | Insert | InsertComplete
    | Update | UpdateComplete
    | Delete | DeleteComplete
    | Dispose;
