import { EntityModel } from '../model';
import { DataObject, DataProperty } from '../../_core/type-converter';
import { TimeSpan } from '../../_core/date';

@DataObject()
export class LeaseAreaBillingConfigurationModel extends EntityModel {
    public BeforeLeaseWithinGracePeriodStartupFee: number = null;
    public AfterLeaseWithinGracePeriodStartupFee: number = null;
    public BetweenRequestsWithinGracePeriodStartupFee: number = null;

    @DataProperty()
    public BeforeLeaseGracePeriod: TimeSpan = null;
    
    @DataProperty()
    public AfterLeaseGracePeriod: TimeSpan = null;
    
    @DataProperty()
    public BetweenRequestsGracePeriod: TimeSpan = null;

    @DataProperty()
    public UseRequestStartTimeGracePeriod: TimeSpan = null;

    public CancellationFee: number = null;
    public StartupFee: number = null;

    @DataProperty()
    public MinimumBillTime: TimeSpan = null;

    public AdministrationMonthlyFee: number = null;
    public AdministrationMonthlyPercent: number = null;
}
