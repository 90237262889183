import './../_core/string';
import './../_core/number';
import { SortDirectionType } from './sort-direction.enumeration';

export interface PageState {
    pageIndex?: number;
    pageSize?: number;

    sortField?: string;
    sortDirection?: SortDirectionType;

    v?: string;
}

export class PageState {
    public static readonly defaultPageState: PageState = {
        pageIndex: 0,
        pageSize: 20,
        v: String.random()
    };

    public static readonly MAX_VALUE: PageState = {
        pageIndex: 0,
        pageSize: Number.MAX_INT32
    };
}

export interface FilterState extends PageState {
    showHidden?: boolean;
}

export class FilterState extends PageState {
    public static readonly defaultFilterState: FilterState = {
        ...PageState.MAX_VALUE,
        showHidden: true
    };
}

export interface FilterContext extends FilterState {
    CustomerId?: number;
    BuildingId?: number;
    TenantId?: number;
    LeaseId?: number;
    LeaseAreaId?: number;
}