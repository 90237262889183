// http://blog.rangle.io/angular-2-ngmodel-and-custom-form-components/

import { ControlValueAccessor } from '@angular/forms';

import { BaseComponent } from '../base.component';

import { Directive } from "@angular/core";

@Directive()
export class BaseValueAccessor<T> extends BaseComponent implements ControlValueAccessor {
    private innerValue: T;

    private changed = new Array<(value: T) => void>();
    private touched = new Array<() => void>();

    public get value(): T {
        return this.innerValue;
    }

    public set value(value: T) {
        if (!this.equals(this.innerValue, value)) {
            this.innerValue = value;
            this.changed.forEach(f => f(value));
        }
    }

    public touch() {
        this.touched.forEach(f => f());
    }

    public writeValue(value: T) {
        this.innerValue = value;
        this.bindValue(value);
    }

    public registerOnChange(fn: (value: T) => void) {
        this.changed.push(fn);
    }

    public registerOnTouched(fn: () => void) {
        this.touched.push(fn);
    }

    public setDisabledState(isDisabled: boolean): void {
        // Allow to be overriden
    }

    protected bindValue(value: T): void {
        // Allow to be overriden
    }

    protected equals(a: T, b: T): boolean {
        return a === b;
    }
}
