import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList, TemplateRef } from "@angular/core";

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseComponent } from '../../../_core/components/base.component';
import { notEmpty, notNull } from '../../../_core/rxjs.operators';
import { GeneaColumnLayoutColumnDirective } from './column-layout-column.directive';

@Component({
    selector: 'genea-two-column-layout',
    templateUrl: './two-column-layout.component.html',
    styleUrls: ['./two-column-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneaTwoColumnLayoutComponent extends BaseComponent {
    public readonly columnCount: number = 2; 

    private readonly _columns$ = new BehaviorSubject<QueryList<GeneaColumnLayoutColumnDirective>>(null);
    public readonly columnGroups$: Observable<Array<{ left: TemplateRef<any>, right: TemplateRef<any> }>>;
    
    @ContentChildren(GeneaColumnLayoutColumnDirective)
    protected set columns(value: QueryList<GeneaColumnLayoutColumnDirective>) {
        this._columns$.next(value);
    }

    @Input() 
    public flexWidth: boolean = true;
    
    constructor() {
        super();

        this.columnGroups$ = this._columns$
            .pipe(
                notNull(),
                map(columns => columns.toArray()),
                notEmpty(),
                map(columns => {
                    const groups: Array<{ left: TemplateRef<any>, right: TemplateRef<any> }> = [];

                    let index = 0;
                    let next = 0;

                    while(true) {
                        next = this.columnCount * index;

                        if(next >= columns.length) {
                            break;
                        }

                        const group = columns
                            .skip(next)
                            .take(this.columnCount)
                            .map(p => p.templateRef);

                        groups.push({
                            left: group.firstOrDefault(),
                            right: group.skip(1).firstOrDefault()
                        });

                        index++;
                    };

                    return groups;
                })
            );
    }
}