import { TypeConverter, DataObject } from '../_core/type-converter';
import { InvalidOperationException } from '../_core/exceptions/invalid-operation.exception';
import { EntityType } from './search/entity-type.enum';

export interface Entity {
    Id: any;

    readonly key: number | string | null;
    readonly isNew: boolean;
    readonly partial: boolean;
}

export interface CoreEntity extends Entity {
    readonly EntityType: EntityType;

    Id: number;
    Name: string;
    Description: string;
    Active: boolean;
}

export const entityEqualityComparer = <T extends Entity>(a: T, b: T) => {
    if(!a && !b) {
        return true;
    } else if((a && !b) || (!a && b)) {
        return false;
    }

    return a.key === b.key;
};

export abstract class BaseModel implements Entity {

    public Id: any = null;

    public get key(): number | string | null {
        return this.Id;
    }

    public get isNew(): boolean {
        if (TypeConverter.isNull(this.key)) {
            return true;
        }

        if (TypeConverter.isNumber(this.key)) {
            return this.key <= 0;
        }

        if (TypeConverter.isString(this.key)) {
            return String.isNullOrEmpty(this.key);
        }

        throw new InvalidOperationException(`Invalid [key] property. Only [string] or [number] is allowed.`);
    }

    // This is currently experimental and is not fully implemented.
    // The purpose is of this flag is to determine if we need to get a FULL model from the API.
    // When calling GetById, we should always get a fully materialized model from the API
    // When calling Search, or any other query-like API call, we may only get a PARTIAL model back.
    // There are many circumstances where we need a fully materialized model and not the partial model.
    // Calling GetById should set this flag to false to let components know whether we have a partial or full model
    // tslint:disable-next-line: member-ordering
    private _partial?: boolean;
    public get partial(): boolean {
        if(this._partial == null || this._partial == undefined) {
            this._partial = true;
        }

        return this._partial;
    }

    public materialize(): this {
        this._partial = false;
        return this;
    }

    public equals(other: BaseModel): boolean {
        return entityEqualityComparer(this, other);
    }
}

export abstract class EntityModel extends BaseModel implements Entity {
    public Id: number = null;

    public get key(): number {
        return this.Id;
    }
}

@DataObject()
export class CommonModel extends BaseModel {
    public Name: string = null;
    public Description: string = null;
    public SystemName: string = null;
    public Active: boolean = true;
}
