import { BaseModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class EnumerationItemModel extends BaseModel {
    public get key(): string {
        return this.SystemName;
    }

    public Name: string = null;
    public Description: string = null;
    public SystemName: string = null;
    public DisplayOrder: 0 = null;
}

export interface EnumerationItemFilter {
    Name?: string;
    SystemName?: string;
}

export type EnumerationItemFilterState = CompositeType<EnumerationItemFilter, FilterState>;
