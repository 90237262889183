
import { take, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthService } from '../_services/auth/auth.service';
import { createReturnUrl } from '../_core/url.utils';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private readonly router: Router,
        private readonly authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handleCanActivate(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handleCanActivate(route, state);
    }

    private handleCanActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let isAuthenticated = false;

        this.authService.isAuthenticated$
            .pipe(
                take(1),
                tap(p => {
                    isAuthenticated = p;
                })
            )
            .subscribe();

        if (!isAuthenticated) {
            this.router.navigate(['/login'], { queryParams: createReturnUrl(state.url) });
        }

        return isAuthenticated;
    }
}
