export enum DayType {
    Weekday = 1,
    Weekend = 2,
    Holiday = 3
}

export const DayTypeNames = new Map<number, string>([
    [DayType.Weekday, 'Weekday'],
    [DayType.Weekend, 'Weekend'],
    [DayType.Holiday, 'Holiday']
]);
