import { Injectable } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

import { tap } from 'rxjs/operators';

// import { environment } from '../../../environments/environment';
import { TypeConverter } from '../../_core/type-converter';

declare let gtag: Function;

declare const environmentResource:any; 

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    private _scriptAdded: boolean = false;

    constructor() {
        this.addTagManager();
    }

    private addTagManager(): void {
        try {
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${environmentResource.gaId}`;

            // IE:11 SUCKS
            // document.head.prepend(script);
            document.head.insertBefore(script, document.head.childNodes[0]);
            this._scriptAdded = true;
        } catch(e) {
            this._scriptAdded = false;

            throw e;
        }
    }

    public initialize(router: Router): this {
        if (this._scriptAdded) {
            router.events
                .pipe(
                    tap(event => this.trackNavigationEvent(event as RouterEvent))
                )
                .subscribe();
        }

        return this;
    }

    public trackNavigationEvent(event: RouterEvent): this {
        // TODO: Should we throw exceptions?
        if (this._scriptAdded) {
            if (event instanceof NavigationEnd) {
                gtag(
                    'config',
                    environmentResource.gaId,
                    {
                        // NOTE: This will include query strings...
                        // So, if someone spends 2 hours on a single grid applying various filters and sorts, each filter and sort = a new "event"
                        'page_path': event.urlAfterRedirects
                    }
                );
            }
        }

        return this;
    }

    public trackEvent(name: string, category: string, action: string, label: string = null, value: number = null): this {
        // TODO: Should we throw exceptions?
        if (this._scriptAdded) {
            const params = {
                'event_category': category,
                'event_label': label
            };

            if(action) {
                params['event_action'] = action;
            }

            if(!TypeConverter.isNull(value) && TypeConverter.isNumber(value) && value >= 0) {
                params['event_value'] = value;
            }

            gtag('event', name, params);
        }

        return this;
    }
}