import { DataObject, DataProperty } from '../../_core/type-converter';
import { ServiceType } from '../leases/service-type.enum';
import { BaseModel } from '../model';


@DataObject()
export class AreaDefaultServiceTypeModel extends BaseModel {

    public Id: number = null;
    public Name: string = null;
    public SystemName: string = null;
    
    @DataProperty()
    public Enum: ServiceType = null;

    public Deleted: boolean = null;

}


export const AreaDefaultServiceTypeNames = [
    {
        Id: ServiceType.HvacAndLighting,
        Enum: ServiceType.HvacAndLighting,
        Name: "HVAC & Lighting",
        SystemName: "HvacAndLighting",
        Deleted: false
    },
    {
        Id: ServiceType.LightingOnly,
        Enum: ServiceType.LightingOnly,
        Name: "Lighting Only",
        SystemName: "LightingOnly",
        Deleted: false
    },
    {
        Id: ServiceType.Metering,
        Enum: ServiceType.Metering,
        Name: "Metering",
        SystemName: "Metering",
        Deleted: false
    }
]