import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { ParameterDataTypeModel } from '../../../_sdk/parameter-configuration/parameter-data-type.model';
import * as ParameterDataTypeActions from './parameter-data-type.actions';

export interface State {
    parameterDataTypes: ModelCollection<ParameterDataTypeModel>;
}

const initialState: State = {
    parameterDataTypes: new ModelCollection<ParameterDataTypeModel>()
};

export function reducer(
    state: State = initialState,
    action: ParameterDataTypeActions.Actions
): State {

    let parameterDataTypes: ModelCollection<ParameterDataTypeModel>;
    let index: number;

    switch (action.type) {
        case ParameterDataTypeActions.DISPOSE:
            return {
                ...state,
                parameterDataTypes: initialState.parameterDataTypes
            };
        case ParameterDataTypeActions.LOAD_COMPLETE:
            return {
                ...state,
                parameterDataTypes: action.payload
            };
        case ParameterDataTypeActions.LOAD:
        case ParameterDataTypeActions.INSERT:
        case ParameterDataTypeActions.UPDATE:
        case ParameterDataTypeActions.DELETE:
            return state;
        case ParameterDataTypeActions.INSERT_COMPLETE:
            parameterDataTypes = new ModelCollection(state.parameterDataTypes.data
                .map(p => TypeConverter.convert(ParameterDataTypeModel, p)));

            parameterDataTypes.data.push(action.payload);

            return {
                ...state,
                parameterDataTypes: parameterDataTypes
            };

        case ParameterDataTypeActions.UPDATE_COMPLETE:
            parameterDataTypes = new ModelCollection(state.parameterDataTypes.data
                .map(p => TypeConverter.convert(ParameterDataTypeModel, p)));

            index = parameterDataTypes.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                parameterDataTypes.data[index] = action.payload;
            }

            return {
                ...state,
                parameterDataTypes: parameterDataTypes
            };

        case ParameterDataTypeActions.DELETE_COMPLETE:
            parameterDataTypes = new ModelCollection(state.parameterDataTypes.data
                .map(p => TypeConverter.convert(ParameterDataTypeModel, p)));

            index = parameterDataTypes.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                parameterDataTypes.data.splice(index, 1);
            }

            return {
                ...state,
                parameterDataTypes: parameterDataTypes
            };

        default:
            return state;
    }
}
