
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { GlobalConfig } from '../../_core/global.config';
import { TypeConverter } from '../../_core/type-converter';
import { ApiService } from '../api.service';
import { CacheableEntityService } from '../cacheable-entity.service';
import { ModelCollection } from '../../_sdk/collection.model';

import * as fromLocalization from '../../_store/localization/localization.reducers';
import * as LanguageActions from '../../_store/localization/language/language.actions';
import { LanguageModel, LanguageFilterState } from '../../_sdk/localization/language.model';

@Injectable({
    providedIn: 'root'
})
export class LanguageService extends CacheableEntityService<LanguageModel> {

    private readonly _source$: Observable<ModelCollection<LanguageModel>>;

    constructor(
        apiService: ApiService,
        config: GlobalConfig,
        private readonly store: Store<fromLocalization.FeatureState>
    ) {
        super(apiService, config);

        this._source$ = this.store.select(fromLocalization.getLanguages);
    }

    protected get baseUri(): string {
        return `${this.config.api('portal').baseUri}/language`;
    }

    protected toEntity(source: any): LanguageModel {
        return TypeConverter.convert(LanguageModel, source);
    }

    public getById(id: number): Observable<LanguageModel> {
        return this.search({ ...this.defaultFilterState }).pipe(
            map(p => p.data.find(f => f.Id === id)));
    }

    public delete(model: LanguageModel): Observable<LanguageModel> {
        this.store.dispatch(new LanguageActions.Delete(model));

        return super.delete(model).pipe(
            tap((result) => this.store.dispatch(new LanguageActions.DeleteComplete(result))));
    }

    public insert(model: LanguageModel): Observable<LanguageModel> {
        this.store.dispatch(new LanguageActions.Insert(model));

        return super.insert(model).pipe(
            tap((result) => this.store.dispatch(new LanguageActions.InsertComplete(result))));
    }

    public update(model: LanguageModel): Observable<LanguageModel> {
        this.store.dispatch(new LanguageActions.Update(model));

        return super.update(model).pipe(
            tap((result) => this.store.dispatch(new LanguageActions.UpdateComplete(result))));
    }

    public search(request?: LanguageFilterState): Observable<ModelCollection<LanguageModel>> {
        request = request || this.defaultFilterState;
        return super.filter(request, i => {
            return (
                i.Active
                && String.looseContains(i.Name, request.Name)
                && (String.isNullOrEmpty(request.UniqueSeoCode) || String.looseEquals(i.UniqueSeoCode, request.UniqueSeoCode))
            );
        });
    }

    public get source$(): Observable<ModelCollection<LanguageModel>> {
        return this._source$;
    }

    public clear(): void {
        this.store.dispatch(new LanguageActions.Dispose());
    }

    protected beginLoadingData(): void {
        this.store.dispatch(new LanguageActions.Load());
    }

    protected endLoadingData(data: ModelCollection<LanguageModel>): void {
        this.store.dispatch(new LanguageActions.LoadComplete(data));
    }
}
