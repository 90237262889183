export enum ReadingType {
    All= -1,
    Manual = 1,
    Automatic = 2
}

export const ReadingTypeNames = new Map<number, string>([
    [ReadingType.All,'All'],
    [ReadingType.Manual, 'Manual'],
    [ReadingType.Automatic, 'Automatic']
]);