import { EntityModel } from '../../../../../_sdk/model';
import { BillingCycleType } from './enumerations/billing-cycle-type.enumeration';
import { AutomationType } from './enumerations/automation-type.enumeration';
import { DataObject, DataProperty } from '../../../../../_core/type-converter';
import { UserModel } from '../../../../../_sdk/users/user.model';
import { DateTime } from '../../../../../_core/date';

@DataObject()
export class HvacBuildingInternalNotesModel extends EntityModel {  
   BuildingId: Number = null;
   Note: string  = null;
  
   @DataProperty()
   ModifiedBy?: UserModel = null; 
   
   @DataProperty()
   CreatedBy?: UserModel = null;

   @DataProperty()
   ModifiedOnUtc?: DateTime = null;
}