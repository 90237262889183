import { EntityModel } from '../../../../../_sdk/model';
import { BillingCycleType } from './enumerations/billing-cycle-type.enumeration';
import { AutomationType } from './enumerations/automation-type.enumeration';
import { DataObject, DataProperty } from '../../../../../_core/type-converter';
import { UserModel } from '../../../../../_sdk/users/user.model';
import { DateTime } from '../../../../../_core/date';
import { InvoiceStatusType } from './enumerations/invoice-status-type.enumeration';

@DataObject()
export class HvacInvoiceHistoryModel extends EntityModel {  
   
   public Title: string = null;   
      
   @DataProperty()
   public TimeStamp:DateTime = null;
      
   @DataProperty()
   public InvoiceState: InvoiceStatusType = null;
  
}