export { };

declare global {
    interface NumberConstructor {
        MAX_INT32: number;
        MIN_INT32: number;
    }
}

Number.MAX_INT32 = 2147483647;
Number.MIN_INT32 = -2147483647;
