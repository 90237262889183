import { AbstractControl, AsyncValidatorFn, Validator, ValidatorFn } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TypeConverter } from '../../type-converter';
import { AsyncValidatorArray, validate, ValidatorArray } from './validate';

export const aggregateControls = (control: AbstractControl): Array<AbstractControl> => {
    if (!control) {
        return [];
    }

    const current: Array<AbstractControl> = [control];

    // FormGroup and FormArray
    if (control['controls']) {
        if (TypeConverter.isArray(control['controls'])) {
            // FormArray
            control['controls'].iterate(c => current.push(...aggregateControls(c)));
        } else {
            // FormGroup
            Object.keys(control['controls']).iterate(c => current.push(...aggregateControls(control['controls'][c])));
        }
    }

    return current;
};

export const hasRequiredField = (control: AbstractControl, validators: ValidatorArray = [], asyncValidators: AsyncValidatorArray = []): Observable<boolean> => {
    if (!control) {
        return of(false);
    }

    const controls = aggregateControls(control);

    const syncronous = controls.filter(p => !!p.validator).map(p => <Validator | ValidatorFn>p.validator).concat(validators);
    const asyncronous = controls.filter(p => !!p.asyncValidator).map(p => <Validator | AsyncValidatorFn>p.asyncValidator).concat(asyncValidators);

    const result = validate(syncronous, asyncronous)({} as AbstractControl);

    return result
        .pipe(
            map(p => !!(p && p.required))
        );
};
