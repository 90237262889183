<fieldset>

    <!-- Only adds a legend if the geneaFieldsetLegend directive is used -->
    <legend *ngIf="geneaFieldsetLegendTemplate">
        <ng-container *ngTemplateOutlet="geneaFieldsetLegendTemplate"></ng-container>
    </legend>

    <!-- Only adds a description if the geneaFieldsetDescription directive is used -->
    <p *ngIf="geneaFieldsetDescriptionTemplate" class="description">
        <ng-container *ngTemplateOutlet="geneaFieldsetDescriptionTemplate"></ng-container>
    </p>

    <!-- The content of the fieldset -->
    <div class="content">
        <ng-container *ngTemplateOutlet="geneaFieldsetContentTemplate"></ng-container>

        <!-- 
            NOTE: You do not need to use "geneaFieldsetContentTemplate"...
            The content of the fieldset will be projected...
        -->
        <ng-content></ng-content>
    </div>

</fieldset>