import { Injectable } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { DateTime } from '../../_core/date';
import { notNull } from '../../_core/rxjs.operators';
import { AuthService } from '../../_services/auth/auth.service';

declare let Intercom: Function;
type IntercomUser = { email: string, user_id: string, name: string, v4UserRole:string };
let userRoles =[
    'Administrators',
    'PropertyManager',
    'BuildingEngineer',
    'TenantManager',
    'TenantUser',
    'AmrbUser'
]

@Injectable({
    providedIn: 'root'
})
export class IntercomService {
    private _isBooted: boolean = false;

    private readonly _user$ = new BehaviorSubject<IntercomUser>(null);
    
    constructor(private readonly authService: AuthService) {
        this.authService.authenticatedUser$
            .pipe(
                tap(p => {
                    let role = this.getRole(p.UserRoles)
                    this._user$.next({
                        user_id: p && p.UserGuid,
                        email: p && p.Email,
                        name: p 
                            ? `${p.FirstName} ${p.LastName}`
                            : '',
                        v4UserRole:role
                    });
                })
            )
            .subscribe();
    }
    public getRole(roleArray){
        let sysNameArray = []
        roleArray.forEach(element => {
            sysNameArray.push(element.SystemName)
        });
        for(let i of userRoles){
            if(sysNameArray.includes(i)){
                return i;
            }
        }
        return ''
    }

    public initialize(router: Router): this {
        this._user$
            .pipe(
                notNull(),
                distinctUntilKeyChanged('user_id'),
                tap(user => {

                    if(user.user_id && !this._isBooted) {
                        console.log('intercom: boot');

                        Intercom('boot', {
                            app_id: 'xngrqpmt',
                            created_at: DateTime.now().toDate().getTime(),
                            'v4WebPlatformUser': true,
                            ...user
                        });

                        this._isBooted = true;
                        this.checkIfMobile();
                    } else if(this._isBooted) {
                        console.log('intercom: shutdown');

                        Intercom('shutdown');

                        this._isBooted = false;
                    }
                }),
                switchMap(user => {
                    return router.events
                        .pipe(
                            map(event => event as RouterEvent),
                            map(event => event && event.url),
                            filter(url => !String.isNullOrEmpty(url)),
                            distinctUntilChanged(),
                            tap(url => {
                                if(this._isBooted) {
                                    console.log('intercom: update');
                                    Intercom('update', {
                                        url,
                                        ...user
                                    });
                                }
                            })
                        )
                })
            )
            .subscribe();

        return this;
    }
    public openIntercomChat(){
        Intercom("show");
    }
    private checkIfMobile(){
        if(window.innerWidth<960){
            Intercom('update', {
                "hide_default_launcher": true
            });
        }
    }
}