export function ArgumentException(message) {
    this.name = 'ArgumentException';
    this.message = message || 'Default Message';
    this.stack = (new Error()).stack;
}

ArgumentException.prototype = Object.create(Error.prototype);
ArgumentException.prototype.constructor = ArgumentException;

export function InvalidArgumentException(message) {
    this.name = 'InvalidArgumentException';
    this.message = message || 'Default Message';
    this.stack = (new Error()).stack;
}

InvalidArgumentException.prototype = Object.create(Error.prototype);
InvalidArgumentException.prototype.constructor = InvalidArgumentException;
