import { DataObject } from '../../_core/type-converter';
import { EntityModel } from '../model';

@DataObject()
export class ProductModel extends EntityModel {

    public Name: string = null;

    public Description: string = null;

    public SystemName: string = null;
}
