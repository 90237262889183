
// http://blog.rangle.io/angular-2-ngmodel-and-custom-form-components/
import { AbstractControl, AsyncValidatorFn, ValidationErrors, Validator, ValidatorFn, Validators, AsyncValidator } from '@angular/forms';

import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// tslint:disable-next-line:interface-over-type-literal
export type ValidationResult = { [validator: string]: string | boolean };
export type AsyncValidatorArray = Array<Validator | AsyncValidatorFn>;
export type ValidatorArray = Array<Validator | ValidatorFn>;

const normalizeValidator = (validator: Validator | ValidatorFn): ValidatorFn => {
    const validateFn = (validator as Validator).validate;
    const func = validateFn && validateFn.bind(validator);
    if (typeof func === 'function') {
        return (c: AbstractControl) => func(c);
    }

    return <ValidatorFn>validator;
};

const normalizeValidatorAsync = (validator: AsyncValidator | AsyncValidatorFn): AsyncValidatorFn => {
    const validateFn = (validator as AsyncValidator).validate;
    const func = validateFn && validateFn.bind(validator);
    if (typeof func === 'function') {
        return (c: AbstractControl) => func(c);
    }

    return <AsyncValidatorFn>validator;
};

const composeValidators = (validators: ValidatorArray): ValidatorFn => {
    if (validators == null || validators.length === 0) {
        return null;
    }

    return Validators.compose(validators.map(normalizeValidator));
};

const composeValidatorsAsync = (validators: AsyncValidatorArray): AsyncValidatorFn => {
    if (validators == null || validators.length === 0) {
        return null;
    }

    return Validators.composeAsync(validators.map(normalizeValidatorAsync));
};

export const validate = (validators: ValidatorArray, asyncValidators: AsyncValidatorArray) => {
    return (control: AbstractControl): Observable<ValidationErrors> => {
        const synchronousValid = () => {
            const validatorCallback = composeValidators(validators);
            if (validatorCallback) {
                return validatorCallback(control);
            }
            return null;
        };

        if (asyncValidators && asyncValidators.length) {
            // const asyncValidator = <AsyncValidatorFn>composeValidators(asyncValidators);
            const asyncValidator = composeValidatorsAsync(asyncValidators);

            const asyncResult = asyncValidator(control);
            let asyncResult$: Observable<any>;

            if (asyncResult instanceof Observable) {
                asyncResult$ = asyncResult;
            } else {
                asyncResult$ = from(asyncResult);
            }

            return asyncResult$
                .pipe(
                    map(v => {
                        const secondary = synchronousValid();
                        if (secondary || v) { // compose async and sync validator results
                            return Object.assign({}, secondary, v);
                        }

                        return null;
                    })
                );
        }

        if (validators && validators.length) {
            return of(synchronousValid());
        }

        return of(null);
    };
};

export const message = (validator: ValidationResult, key: string): string => {
    switch (key) {
        case 'required':
            return 'Please enter a value';
        case 'pattern':
            return 'Value does not match required pattern';
        case 'minlength':
            return 'Value must be N characters';
        case 'maxlength':
            return 'Value must be a maximum of N characters';
    }


    switch (typeof validator[key]) {
        case 'string':
            return <string>validator[key];
        default:
            return `Validation failed: ${key}`;
    }
};
