
import { Observable, of, Subject, throwError } from 'rxjs';

import { TypeConverter } from '../../_core/type-converter';
import { finalize, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

interface CacheContent {
    expiry: number;
    value: any;
}

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
@Injectable({
    providedIn: 'root'
})
export class CacheService {
    private readonly _id = String.random(10);

    private readonly _cache: Map<string, CacheContent>;
    private readonly _inFlightObservables: Map<string, Subject<any>>;
    private readonly _defaultMaxAge: number;

    constructor() {
        this._cache = new Map<string, CacheContent>();
        this._inFlightObservables = new Map<string, Subject<any>>();
        this._defaultMaxAge = 1000 * 60 * 60;
    }

    /**
     * Gets the value from cache if the key is provided.
     * If no value exists in cache, then check if the same call exists
     * in flight, if so return the subject. If not create a new
     * Subject inFlightObservable and return the source observable.
     */
    public get<T>(key: string, source?: Observable<T>, maxAgeMilliseconds?: number): Observable<T> | Subject<T> {

        if (this._hasValidCachedValue(key)) {
            this._log(`Getting ${key}`);
            return of(this._cache.get(key).value);
        }

        if (!maxAgeMilliseconds) {
            maxAgeMilliseconds = this._defaultMaxAge;
        }

        if (this._inFlightObservables.has(key)) {
            return this._inFlightObservables.get(key);
        } else if (source && source instanceof Observable) {
            // Return a complete stream.
            // This way, in-flight observables don't get created and
            // the data source doesn't get called until a subscription is made

            this._inFlightObservables.set(key, new Subject());
            this._log(`Calling api for ${key}`);

            return new Observable<T>(observer => {

                const subscription = source
                    .pipe(
                        tap(x => {
                            this.set(key, x, maxAgeMilliseconds);
                            this._log(`Setting ${key}`);
                            observer.next(x);
                        }),
                        catchError(err => {
                            this.remove(key);
                            this._log(`Error calling api for ${key}`);
                            observer.error(err);

                            // return of(null);

                            throw err;
                        }),
                        finalize(() => {
                            this._notifyInFlightObservers(key, null);
                            this._log(`Complete api call for ${key}`);
                            observer.complete();
                        })
                    )
                    .subscribe();

                return () => subscription.unsubscribe();
            });
        } else {
            return throwError('Requested key is not available in Cache');
        }
    }

    /**
     * Sets the value with key in the cache
     * Notifies all observers of the new value
     */
    public set(key: string, value: any, maxAge: number = this._defaultMaxAge): void {
        if (TypeConverter.isNull(value)) {
            this._cache.delete(key);
        } else {
            this._cache.set(key, { value: value, expiry: Date.now() + maxAge });
        }

        this._notifyInFlightObservers(key, value);
    }

    public remove(key: string): void {
        this._log(`Removing ${key}`);
        this._cache.delete(key);
    }

    public removeByPattern(pattern: string): void {
        this._cache.forEach((v, k) => {
            if (String.contains(k, pattern, false, false)) {
                this.remove(k);
            }
        });
    }

    /**
     * Checks if the a key exists in cache
     */
    public has(key: string): boolean {
        return this._cache.has(key);
    }

    public clear(): void {
        this._cache.forEach((v, k) => this.set(k, null));
    }

    /**
     * Publishes the value to all observers of the given
     * in progress observables if observers exist.
     */
    private _notifyInFlightObservers(key: string, value: any): void {
        if (this._inFlightObservables.has(key)) {
            const inFlight = this._inFlightObservables.get(key);
            if (inFlight.observers.length && value) {
                this._log(`Notifying ${inFlight.observers.length} flight subscribers for ${key}`);
                inFlight.next(value);
            }
            inFlight.complete();
            this._inFlightObservables.delete(key);
        }
    }

    /**
     * Checks if the key exists and has not expired.
     */
    private _hasValidCachedValue(key: string): boolean {
        if (this._cache.has(key)) {
            if (this._cache.get(key).expiry < Date.now()) {
                this._cache.delete(key);
                return false;
            }
            return true;
        }

        return false;
    }

    private _log(message?: any, ...optionalParams: any[]): void {
        console.log(`%c-- Cache: ${message}`, ...optionalParams, 'color: green');
    }
}
