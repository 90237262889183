import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { GeneaCommonModule } from '../common/common.module';
import { HttpRequestIndicatorInterceptor } from './http-request-indicator.interceptor';
import { HttpRequestIndicatorComponent } from './http-request-indicator.component';

@NgModule({
    declarations: [
        HttpRequestIndicatorComponent
    ],
    imports: [
        // Custom
        GeneaCommonModule,
    ],
    exports: [
        HttpRequestIndicatorComponent
    ]
})
export class HttpRequestIndicatorModule {
    static forRoot(): ModuleWithProviders<HttpRequestIndicatorModule> {
        return {
            ngModule: HttpRequestIndicatorModule,
            providers: [
               { provide: HTTP_INTERCEPTORS, useClass: HttpRequestIndicatorInterceptor, multi: true }
            ]
          };
    } 
}
