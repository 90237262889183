import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { switchMap, filter, tap } from 'rxjs/operators';

import { UserService } from '../../_services/users/user.service';
import { ToastrService } from '../../_modules/toastr';
import { BaseComponent } from '../../_core/components/base.component';
import { UserModel } from '../../_sdk/users/user.model';
import { LoadingIndicatorDirective } from '../../_modules/common-ui/loading-indicator/loading-indicator.directive';

@Component({
    selector: 'app-activate-user',
    templateUrl: './activate-user.component.html',
    styleUrls: ['./activate-user.component.scss'],
})
export class ActivateUserComponent extends BaseComponent {

    private _userId: number;
    private _token: string;

    public ready$ = new BehaviorSubject<boolean>(false);
    public user$ = new BehaviorSubject<UserModel>(null);

    @ViewChild(LoadingIndicatorDirective, {static: true})
    private loadingIndicator: LoadingIndicatorDirective;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly userService: UserService,
        private readonly toastrService: ToastrService
    ) {
        super();
    }

    OnInit() {
        super.OnInit();

        this.addSubscription(
            this.route.params.pipe(
                filter(p => p.userId),
                filter(p => p.token),
                tap(p => {
                   this._userId = p.userId;
                   this._token = p.token;
                }),
                switchMap(p => this.loadingIndicator.process(this.userService.validatePasswordToken(p.userId, p.token))),
                tap(r => {
                    if (r && r.expires) {
                        this.user$.next(r.userModel); 
                    }

                    this.ready$.next(true);
                })
            )
        );
    }

    onSubmitNewPassword({ newPassword, confirmNewPassword }) {
        this.toastrService.subscribeAndShowResult(
            this.loadingIndicator.process(
                this.userService.updatePassword(this._userId, newPassword, confirmNewPassword, this._token)
            ),
            _ => {
                this.router.navigate(['/login']);
            }, null, null,
            _ => 'Password was set successfully!'
        );
    }
}
