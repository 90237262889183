import { DateTime } from '../../_core/date';
import { DataObject, DataProperty } from '../../_core/type-converter';
import { ExpandedDateModel } from '../common/expanded-date.model';
import { EntityModel } from '../model';
import { FilterState } from '../page-state';
import { OperationStatus } from './operation-status.enum';
import { OperationType } from './operation-type.enum';
import { CommandStatus } from './command-status.enum';

@DataObject()
export class CommandResultModel extends EntityModel {
    public Status: CommandStatus = CommandStatus.NotExecuted;

    public OperationType: OperationType = OperationType.None;

    public OperationId: number = null;

    public EmsCommandResultId: number = null;

    public EmsCommandId: number = null;

    public CommandName: string = null;

    public CommandSequence: number = null;

    public RawCommandBody: string = null;

    public Value: string = null;

    @DataProperty()
    public CreatedOnExpanded: ExpandedDateModel = null;

    @DataProperty()
    public StartedDateExpanded: ExpandedDateModel = null;

    @DataProperty()
    public CompletedDateExpanded: ExpandedDateModel = null;
}

export interface CommandResultFilterState extends FilterState {
    OperationType?: OperationType;
    OperationStatus?: OperationStatus;
    StartDate?: DateTime;
    EndDate?: DateTime;
}
