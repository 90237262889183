
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalConfig } from '../../../_core/global.config';
import { notNull } from '../../../_core/rxjs.operators';
import { AuthService } from '../../../_services/auth/auth.service';

import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { BlobService } from '../../../_services/blob.service';

import { BaseComponent } from '../../../_core/components/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice-pdf-viewer',
  templateUrl: './invoice-pdf-viewer.component.html',
  styleUrls: ['./invoice-pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InvoicePdfViewerComponent extends BaseComponent {

  @ViewChild('pdfviewer') pdfviewer: ElementRef;


  private readonly _tenantInvoice$ = new BehaviorSubject<string>(null);
  public readonly tenantInvoice$ = this._tenantInvoice$.asObservable().pipe(notNull());

  private readonly _invoiceBlobUri$ = new BehaviorSubject<string>(null);
  public readonly invoiceBlobUri$ = this._invoiceBlobUri$.asObservable().pipe(notNull());

  private readonly _isLoading$ = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoading$.asObservable().pipe(notNull());

  public _error$ = new BehaviorSubject<{ status: number, errorMessage: string}>(null);
  public error$ = this._error$.asObservable().pipe(notNull());

  private _canChangeRequest: boolean = null;

  protected readonly accessCacheMap = new Map<string, boolean>();

  public expandChangeRequest: boolean = false;

  private readonly _changeRequestLoading$ = new BehaviorSubject<boolean>(false);
  public readonly changeRequestLoading$ = this._changeRequestLoading$.asObservable().pipe(notNull());

  public isLoading = true;


  public hasPermission(permission: string): boolean {
    return this.accessCacheMap.getValue(permission, () => this.authService.hasPermission(permission));
  }


 
  @Input()
  public get tenantInvoice(): string {
    return this._tenantInvoice$.getValue();
  }
  public set tenantInvoice(value: string) {
    this._tenantInvoice$.next(value);
  }

  @Output()
  public onApprove = new EventEmitter<any>();

  @Output()
  public onChangeRequest = new EventEmitter<any>();

  @Output()
  public onCancel = new EventEmitter<void>();

  
  constructor(
    private readonly authService: AuthService,
    private readonly blobservice: BlobService,
    private  _rndr: Renderer2,
    private readonly config: GlobalConfig,
    private router: Router) {
    super();

  }

  OnInit() {
    this.addSubscription(
      this._tenantInvoice$.pipe(
        notNull(),
        distinctUntilChanged(),
        take(1),
        tap(invoicePath => {
          this.blobservice.retrieve(invoicePath)
          .pipe(
            notNull(),
            take(1),
            tap(
              data => {
                // this._invoiceBlobUri$.next(URL.createObjectURL(new Blob([data.body], { type: 'application/pdf' })));
                this._invoiceBlobUri$.next(`${this.config.api('platform').baseUri}/`+invoicePath);  
                var myIframe = document.getElementById('pdfviewer');
                myIframe.setAttribute("src", this._invoiceBlobUri$.getValue() + '#toolbar=0&navpanes=0');
                this._isLoading$.next(false);
              },
              (err) => {
                let error: { status: number, errorMessage: string };
                if (err.innerError.status == 404) {
                  error = {
                    errorMessage: "Invoice not found",
                    status: 404
                  }
                } else if (err.innerError.status == 401) {
                  this.router.navigate(['/login']);
                  error = {
                    errorMessage: "Unauthorized",
                    status: 401
                  }
                } else {
                  error = {
                    status: 500,
                    errorMessage: "Couldn't load invoice, Please try again"
                  }
                }

                this._error$.next(error);
                this._isLoading$.next(false);
              }
            )
          ).subscribe()
        })
      )
    )    
  }
   
  public cancelHandler(): void {
    this.onCancel.emit();
  }

  public onDownloadInvoice() {
    window.open(`${this.config.api('platform').baseUri}/${this._tenantInvoice$.getValue().replace('view','download')}`, 'Download');
    window.focus();
  }
}