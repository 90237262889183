import { EntityModel } from '../../../../../_sdk/model';
import { BillingCycleType } from './enumerations/billing-cycle-type.enumeration';
import { AutomationType } from './enumerations/automation-type.enumeration';
import { DataObject, DataProperty } from '../../../../../_core/type-converter';
import { UserModel } from '../../../../../_sdk/users/user.model';
import { DateTime } from '../../../../../_core/date';
import { HvacInvoiceHistoryModel } from './hvac-invoice-history.model';

@DataObject()
export class HvacInvoiceChangeHistoryModel extends EntityModel {  
   public BuildingId: Number = null;
   public  BuildingName: string  = null;
   public  CustomerName: string  = null;
   public  InvoicePacketId: string  = null;
   public  BillingCycle: string  = null;

   @DataProperty({instanceType:HvacInvoiceHistoryModel})
   public  AuditLogs: Array<HvacInvoiceHistoryModel>  = [];
  
}