import { EntityModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class PointTypeModel extends EntityModel {
    public Name: string = null;
    public Description?: string = null;
    public SystemName: string = null;
}

export interface PointTypeFilter {
    Name?: string;
}

export type PointTypeFilterState = CompositeType<PointTypeFilter, FilterState>;
