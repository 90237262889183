import { BaseModel, EntityModel } from '../../../../../_sdk/model';
import { DataProperty, DataObject } from '../../../../../_core/type-converter';
import { InvoiceStateType } from './enumerations/invoice-state-type.enumeration';
import { BillingScheduleType } from '../../enumerations/billing-schedule-type.enumeration';
import { ProcessingStatusType } from './enumerations/processing-status-type.enumeration';
import { BuildingModel } from '../../../../../_sdk/buildings/building.model';
import { BillingPeriodModel } from '../../_models/billing-period.model';
import { DateTime } from '../../../../../_core/date';
import { HvacInvoiceModel } from './hvac-invoice.model';
import { HvacBuildingInternalNotesModel } from './hvac-building-internal-notes.model';
import { HvacChangeRequestModel } from './hvac-change-request.model';

@DataObject()
export class HvacInvoicePacketModel extends  BaseModel{
    @DataProperty()
    public  CurrentState: InvoiceStateType = null;
    @DataProperty()
    public  ScheduleType :BillingScheduleType = null;
    @DataProperty()
    public  ProcessingStatus: ProcessingStatusType = null;

    @DataProperty()
    public InternalNote:HvacBuildingInternalNotesModel= null;

    @DataProperty()
    public Conversation:HvacChangeRequestModel = null;

    public BuildingId? : number = null;

    public  BillingPeriodId?: number = null;

    public CustomerId? : number = null;

    public  CustomerName?: string = null;

    public  BuildingName?: string = null;

    @DataProperty()
    public  Building :BuildingModel = null;

    @DataProperty()
    public  BillingPeriod : BillingPeriodModel = null;

    @DataProperty()
    public  StartDate :DateTime = null;

    @DataProperty()
    public  EndDate:DateTime = null;

    public  Label:string = null;

    public  TotalCost?: number = null;
    
    public  FilePath:string = null;

    public  Uri:string = null;
    @DataProperty()
    public  ProcessingStart?: DateTime = null;
    @DataProperty()
    public  ProcessingEnd? :DateTime = null;
}
