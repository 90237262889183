export enum InvoiceStateType {
    Open = 1,
    ChangesRequested,
    PendingApproval,
    Approved,
    Closed,
    Voided
}
export const InvoiceStateTypeNames = new Map<number, string>([
    [InvoiceStateType.Open, 'Open'],   
    [InvoiceStateType.ChangesRequested, 'Changes Requested'],
    [InvoiceStateType.PendingApproval, 'Pending Approval'],
    [InvoiceStateType.Approved, 'Approved'],
    [InvoiceStateType.Closed, 'Closed'],
    [InvoiceStateType.Voided, 'Voided'],
]);