import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { tap } from 'rxjs/operators';

import { BaseDirective } from '../../../_core/components/base.directive';
import { LocalizationContext } from '../../../_core/localization-context';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[localizer]'
})
export class LocaleResourceDirective extends BaseDirective {
    @Input() resourceKey: string;
    @Input() defaultValue: string;

    // tslint:disable-next-line:no-input-rename
    @Input('prop') targetProperty = 'innerText';

    constructor(
        private readonly localizationContext: LocalizationContext,
        private readonly elementRef: ElementRef,
        private readonly renderer: Renderer2
    ) {
        super();
    }

    OnInit() {
        this.insureDefaultValue();

        this.addSubscription(
            this.localizationContext.resource(this.resourceKey, this.defaultValue)
                .pipe(
                    tap(resource => {
                        this.renderer.setProperty(
                            this.elementRef.nativeElement,
                            this.targetProperty,
                            resource
                        );
                    })
                )
        );
    }

    private insureDefaultValue(): void {
        if (String.isNullOrEmpty(this.defaultValue)) {
            try {
                this.defaultValue = this.elementRef.nativeElement[this.targetProperty];
            } catch (e) {
                console.warn('Default value could not be determined.');
            }
        }
    }
}