import { Pipe, PipeTransform } from '@angular/core';
import { WeekDay } from '@angular/common';

@Pipe({ name: 'weekday' })
export class WeekdayPipe implements PipeTransform {

    transform(value: WeekDay) {
        switch (value) {
            case WeekDay.Sunday: return 'Sunday';
            case WeekDay.Monday: return 'Monday';
            case WeekDay.Tuesday: return 'Tuesday';
            case WeekDay.Wednesday: return 'Wednesday';
            case WeekDay.Thursday: return 'Thursday';
            case WeekDay.Friday: return 'Friday';
            case WeekDay.Saturday: return 'Saturday';
        }

        return value;
    }
}
