import { AfterViewChecked, Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, first, take, tap } from 'rxjs/operators';

import { BaseComponent } from '../../_core/components/base.component';
import { AuthRequestModel } from '../../_sdk/auth/auth.model';
import { AuthService } from '../../_services/auth/auth.service';
import { UserService } from '../../_services/users/user.service';
import { LoadingIndicatorDirective } from '../../_modules/common-ui/loading-indicator/loading-indicator.directive';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements AfterViewChecked {

    private readonly _message$: BehaviorSubject<string>;
    public readonly message$: Observable<string>;

    private readonly _loginStatus$: BehaviorSubject<string>;
    public readonly loginStatus$: Observable<string>;

    private readonly _processing$: BehaviorSubject<boolean>;
    public readonly processing$: Observable<boolean>;

    public readonly loginFailed$: Subject<boolean>;

    public email: string;
    public password: string;
    public countRuns = 1;
    @ViewChild(LoadingIndicatorDirective)
    private loadingIndicator: LoadingIndicatorDirective;

    private process<T>(stream: Observable<T>): Observable<T> {
        if(this.loadingIndicator) {
            return this.loadingIndicator.process(stream);
        }

        return stream;

        // return this.loadingIndicatorService.process(stream, true);
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private readonly authService: AuthService,
        private readonly userService: UserService
    ) {
        super();

        this._message$ = new BehaviorSubject(null);
        this.message$ = this._message$.asObservable();

        this._loginStatus$ = new BehaviorSubject('Login');
        this.loginStatus$ = this._loginStatus$.asObservable();

        this._processing$ = new BehaviorSubject(false);
        this.processing$ = this._processing$.asObservable();

        this.loginFailed$ = new Subject();
    }

    OnInit(): void {
        
        // this.authService.logout(false, true);
    }
    ngAfterViewChecked() {
        if(this.countRuns>0){
            this.addSubscription(
                this.route.queryParamMap
                .pipe(
                    first(),
                    tap((params)=>{
                        if(params.keys.includes('saml')){
                            if(params.get('saml') === 'yes'){
                                let value = this.getCookie()
                                this._loginStatus$.next('One moment...');
                                this._processing$.next(true);
                                if(value === null){
                                    this._loginStatus$.next('Login');
                                    this._processing$.next(false);
                                    this.router.navigate(['/login']);
                                }
                                else{
                                    this.addSubscription(
                                        this.process(
                                            this.authService.loginSaml(this.getCookie())
                                            .pipe(
                                                take(1),
                                                finalize(() => {
                                                    this._loginStatus$.next('Login');
                                                    this._processing$.next(false);
                                                }),
                                            )
                                        )
                                    )
                                }
                            }
                        }
                    })
                )
            )
            this.countRuns = 0;
        }
    }

    getCookie(){
        let cookieArr = document.cookie.split(';');
        for(let i of cookieArr){
            if(i.match('access_token')){
                return i.substring(i.indexOf('=')+1, i.length)
            }
        }
        return null;
    }

    onLogin(form: NgForm) {
        this._loginStatus$.next('One moment...');
        this._processing$.next(true);

        const email = form.value.email;
        const password = form.value.password;

        this.addSubscription(
            this.process(this.authService.login(<AuthRequestModel>{ Email: email, Password: password }))
                .pipe(
                    finalize(() => {
                        this._loginStatus$.next('Login');
                        this._processing$.next(false);
                    })
                )
        );
    }

    public onForgotPassword(form: NgForm): void {
        const email = form.value.email;

        if (!email) {
            this._message$.next('Please enter a valid email address.');
            return;
        }

        if (confirm(`Do you want to send a recovery email to '${email}'?`)) {
            this.loginFailed$.next(false);
            this.addSubscription(
                this.process(this.userService.requestPasswordRecovery(email))
            );
        }
    }

    public onEmailChange(form: NgForm): void {
        if (form.controls['email'].valid) {
            this._message$.next(null);
        }
    }
}
