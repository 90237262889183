import { Injectable } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { map, distinctUntilChanged, shareReplay, tap } from 'rxjs/operators';

import { DataObject, DataProperty, TypeConverter } from './type-converter';

import { CustomerModel } from '../_sdk/customers/customer.model';
import { TenantModel } from '../_sdk/tenants/tenant.model';
import { BuildingModel } from '../_sdk/buildings/building.model';
import { StorageService } from '../_services/IO/storage.service';
import { LeaseModel } from '../_sdk/leases/lease.model';
import { LeaseAreaModel } from '../_sdk/leases/lease-area.model';
import { BuildingGroupModel } from '../_sdk/buildings/building-group.model';
import { entityEqualityComparer } from '../_sdk/model';

@DataObject()
export class ScopeContext {

    @DataProperty()
    public customer?: CustomerModel = null;

    @DataProperty()
    public buildingGroup?: BuildingGroupModel = null;

    @DataProperty()
    public building?: BuildingModel = null;

    @DataProperty()
    public tenant?: TenantModel = null;

    @DataProperty()
    public lease?: LeaseModel = null;

    @DataProperty()
    public leaseArea?: LeaseAreaModel = null;
}

interface ModelForStorage {
    Id: number;
    Name: string;
}

const SCOPE_KEY = 'scope';

@Injectable({
    providedIn: 'root'
})
export class WorkContext {

    private _cachedScope: ScopeContext;
    
    private readonly _subscriptions = new Subscription();

    public readonly scope$: Observable<ScopeContext>;

    public readonly customer$: Observable<CustomerModel>;
    public readonly buildingGroup$: Observable<BuildingGroupModel>;
    public readonly building$: Observable<BuildingModel>;
    public readonly tenant$: Observable<TenantModel>;
    public readonly lease$: Observable<LeaseModel>;
    public readonly leaseArea$: Observable<LeaseAreaModel>;
    public compareObjectsOfObjects = (prev, curr) => {
        // Get the keys of both objects
        const prevKeys = Object.keys(prev);
        const currKeys = Object.keys(curr);
        
        // Check if the keys are the same
        if (prevKeys.length !== currKeys.length || !prevKeys.every((key) => currKeys.includes(key))) {
            return false;
        }
        
        // Compare each inner object by ID
        for (const key of prevKeys) {
            if(prev[key] && curr[key]){
            if (prev[key].Id !== curr[key].Id) {
                return false;
            }
            }
            else if(prev[key] && !curr[key]){
            return false;
            }
            else if(!prev[key] && curr[key]){
            return false;
            }
        }
        
        // All inner objects are the same
        return true;
        };

    constructor(private readonly storageService: StorageService) {


        this.scope$ = this.storageService.get(SCOPE_KEY, ScopeContext)
            .pipe(
                distinctUntilChanged(TypeConverter.isEquivalent),
                map(scope => {
                    return scope || this.clear();
                })
            );

        this.customer$ = this.scope$
            .pipe(
                map(s => s && s.customer),
                distinctUntilChanged(entityEqualityComparer),
                map(p => TypeConverter.convert(CustomerModel, p)),
                shareReplay(1)
            );

        this.buildingGroup$ = this.scope$
            .pipe(
                map(s => s && s.buildingGroup),
                distinctUntilChanged(entityEqualityComparer),
                map(p => TypeConverter.convert(BuildingGroupModel, p)),
                shareReplay(1)
            );

        this.building$ = this.scope$
            .pipe(
                map(s => s && s.building),
                distinctUntilChanged(entityEqualityComparer),
                map(p => TypeConverter.convert(BuildingModel, p)),
                shareReplay(1)
            );

        this.tenant$ = this.scope$
            .pipe(
                map(s => s && s.tenant),
                distinctUntilChanged(entityEqualityComparer),
                map(p => TypeConverter.convert(TenantModel, p)),
                shareReplay(1)
            );

        this.lease$ = this.scope$
            .pipe(
                map(s => s && s.lease),
                distinctUntilChanged(entityEqualityComparer),
                map(p => TypeConverter.convert(LeaseModel, p)),
                shareReplay(1)
            );

        this.leaseArea$ = this.scope$
            .pipe(
                map(s => s && s.leaseArea),
                distinctUntilChanged(entityEqualityComparer),
                map(p => TypeConverter.convert(LeaseAreaModel, p)),
                shareReplay(1)
            );

        this._subscriptions.add(
            this.scope$
                .pipe(
                    tap(p => {
                        this._cachedScope = p;
                    })
                )
                .subscribe()
        );
    }

    public setScopeContext(scope: ScopeContext): void {
        const current = {
            ...this._cachedScope
        };

        if (scope) {
            Object.keys(scope)
                .forEach(key => {
                    current[key] = scope[key];
                });
        } else {
            // Same as a clear...
            this.clear(current);
        }

        this.putScopeContext(current);
    }

    private putScopeContext(scope: ScopeContext): ScopeContext {
        if (!this.hasValue(scope)) {
            this.storageService.put(SCOPE_KEY, null);
            return scope;
        }

        const proxy: {
            customer?: ModelForStorage,
            buildingGroup?: ModelForStorage,
            building?: ModelForStorage,
            tenant?: ModelForStorage,
            lease?: ModelForStorage,
            leaseArea?: ModelForStorage
        } = {};

        // Only persist the non-null properties...
        if (scope.customer) {
            proxy.customer = {
                Name: scope.customer.Name,
                Id: scope.customer.Id
            };
        }

        if (scope.buildingGroup) {
            proxy.buildingGroup = {
                Name: scope.buildingGroup.Name,
                Id: scope.buildingGroup.Id
            };
        }

        if (scope.building) {
            proxy.building = {
                Name: scope.building.Name,
                Id: scope.building.Id
            };
        }

        if (scope.tenant) {
            proxy.tenant = {
                Name: scope.tenant.Name,
                Id: scope.tenant.Id
            };
        }

        if (scope.lease) {
            proxy.lease = {
                Name: scope.lease.Name,
                Id: scope.lease.Id
            };
        }

        if (scope.leaseArea) {
            proxy.leaseArea = {
                Name: scope.leaseArea.Name,
                Id: scope.leaseArea.Id
            };
        }

        this.storageService.put(SCOPE_KEY, proxy);
    }

    private hasValue(scope: ScopeContext): boolean {
        return !!(scope && (scope.customer || scope.buildingGroup || scope.building || scope.tenant || scope.lease || scope.leaseArea));
    }

    private clear(scope?: ScopeContext): ScopeContext {
        scope = scope || new ScopeContext();

        scope.customer = null;
        scope.buildingGroup = null;
        scope.building = null;
        scope.tenant = null;
        scope.lease = null;
        scope.leaseArea = null;

        return scope;
    }
    
    public setCustomer(customer: CustomerModel): this {
        this.setScopeContext({ customer });

        return this;
    }

    public setBuildingGroup(buildingGroup: BuildingGroupModel): this {
        this.setScopeContext({ customer: buildingGroup.Customer, buildingGroup });

        return this;
    }

    public setBuilding(building: BuildingModel): this {
        this.setScopeContext({ customer: building.Customer, building });

        return this;
    }

    public setTenant(tenant: TenantModel): this {
        this.setScopeContext({ customer: tenant.Customer, tenant });

        return this;
    }

    public setLease(lease: LeaseModel): this {
        this.setScopeContext({ customer: lease.Customer, building: lease.Building, tenant: lease.Tenant, lease });

        return this;
    }

    public setLeaseArea(leaseArea: LeaseAreaModel): this {
        this.setScopeContext({ customer: leaseArea.Customer, building: leaseArea.Building, tenant: leaseArea.Tenant, lease: leaseArea.Lease, leaseArea });

        return this;
    }

    public removeCustomer(): this {
        this.setScopeContext({ 
            customer: null,
            buildingGroup: null,
            building: null,
            tenant: null,
            lease: null,
            leaseArea: null
        });

        return this;
    }

    public removeBuildingGroup(): this {
        this.setScopeContext({
            buildingGroup: null
        });

        return this;
    }
    
    public removeBuilding(): this {
        this.setScopeContext({ 
            building: null,
            lease: null,
            leaseArea: null
        });

        return this;
    }
    
    public removeTenant(): this {
        this.setScopeContext({ 
            tenant: null,
            lease: null,
            leaseArea: null
        });

        return this;
    }
    
    public removeLease(): this {
        this.setScopeContext({ 
            lease: null,
            leaseArea: null
        });

        return this;
    }
    
    public removeLeaseArea(): this {
        this.setScopeContext({ 
            leaseArea: null 
        });

        return this;
    }
}
