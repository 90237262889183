import { DataObject, DataProperty, CompositeType } from '../../_core/type-converter';

import { CommonModel, EntityModel } from '../model';
import { FilterState } from '../page-state';

import { ErrorHandling } from './error-handling.enum';
import { ExpandedDateModel } from '../common/expanded-date.model';
import { EmsDeviceControllerStatus } from './ems-device-status.enum';
import { HeartbeatStatus } from './heartbeat-status.enum';
import { AlertTypeModel } from './alert-configuration.model';

@DataObject()
export class EmsDeviceControllerModel extends EntityModel {

    public EmsDeviceControllerGuid: string = null;
    public Name: string = null;
    public Description?: string = null;
    public Version?: number = null;
    public Active: boolean = null;
    public AlarmEnabled: boolean = null;

    public SerialNumber?: string = null;
    public DevicePrimaryConnectionString?: string = null;
    public ModulePrimaryConnectionString?: string = null;

    public HeartbeatStatus?: HeartbeatStatus = HeartbeatStatus.Unknown;

    @DataProperty({ instanceType: CommonModel })
    public Buildings: Array<CommonModel> = [];

    public ErrorHandling: ErrorHandling = ErrorHandling.Decay;

    public Status: EmsDeviceControllerStatus = EmsDeviceControllerStatus.Live;
    
    public ErrorInterval = 10;

    public ErrorMaximumDecay = 10;

    public SchemaVersion: string = null;
    public ServiceVersion: string = null;
    public UpdateServiceVersion: string = null;

    @DataProperty()
    public LastActivityExpanded: ExpandedDateModel = null;
    @DataProperty()
    public LastHeartbeatExpanded: ExpandedDateModel = null;
    @DataProperty({ instanceType: AlertTypeModel })
    public AlertTypes: Array<AlertTypeModel> = [];
}

export interface EmsDeviceControllerFilter {
    CustomerId?: number;
    BuildingGroupId?: number;
    BuildingId?: number;
    EmsDeviceControllerGuid?: string;
    Name?: string;
    SerialNumber?: string;
}

export type EmsDeviceControllerFilterState = CompositeType<EmsDeviceControllerFilter, FilterState>;
