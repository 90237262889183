export enum InvoicingPeriodFormat {
    StartDate = 1,
    EndDate,
    Both,
    BillingDates
}

export const InvoicingPeriodFormatNames = new Map<number, string>([
[InvoicingPeriodFormat.StartDate, 'Start Month'],
[InvoicingPeriodFormat.EndDate, 'End Month'],
[InvoicingPeriodFormat.Both, 'Both Months'],
[InvoicingPeriodFormat.BillingDates, 'Exact Billing Dates']
])
