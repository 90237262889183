
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ToastrService } from '../toastr';
// import { environment } from '../../../environments/environment';

declare const environment: any;

export interface INavigatorState {
    show: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationIndicatorService implements OnDestroy {
    private routerEventSubscription: Subscription;

    private _navigator$ = new Subject<INavigatorState>();
    public navigator$ = this._navigator$.asObservable();

    private routeCache: string[] = [];

    constructor(private router: Router, private toastrService: ToastrService) {

        this.routerEventSubscription = this.router.events
            .pipe(
                tap(event => {
                    if (event instanceof NavigationStart) {
                        const url = this.normalizeUrl(event.url);
                        
                        if (this.routeCache.includes(url)) {
                            return;
                        }
                        return this.show();
                    }

                    if (event instanceof NavigationEnd) {
                        const url = this.normalizeUrl(event.url);

                        this.routeCache.push(url);
                        return this.hide();
                    }

                    if (event instanceof NavigationError) {
                        console.log('Navigation error', event);
                        this.toastrService.error(`Error accessing [${event.url}]`);
                        return this.hide();
                    }
                })
            ).subscribe();
    }

    ngOnDestroy() {
        this.routerEventSubscription.unsubscribe();
    }

    public getNavigator(): Observable<INavigatorState> {
        return this.navigator$;
    }

    public show(): void {
        this._navigator$.next(<INavigatorState>{ show: true });
    }

    public hide(): void {
        this._navigator$.next(<INavigatorState>{ show: false });
    }

    private normalizeUrl(url: string): string {
        url = String.trimOrEmpty(url).toLowerCase();

        if (String.isNullOrEmpty(url)) {
            return url;
        }

        if (url.contains(environment.versionCheckURL)) {
            // We don't want version checking to keep polluting the UI.
            return;
        }

        const qsIndex = url.indexOf('?');

        if (qsIndex >= 0) {
            url = url.substring(0, qsIndex);
        }

        return url;
    }
}
