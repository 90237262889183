
import { WeekDay } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgModel, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { distinctUntilChanged, tap } from 'rxjs/operators';
import { BaseInputElement } from '../../../../_core/components/forms/base-input-element';

import { AsyncValidatorArray, ValidatorArray } from '../../../../_core/components/forms/validate';
import { TimeRange, TimeSpan } from '../../../../_core/date';

let _identifier = 0;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'time-range-picker',
    styleUrls: ['time-range-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <div [formGroup]='editForm'>
        <div class="row" *ngIf="showHeader">
            <label class="col-sm-2 col-form-label" *ngIf="label">&nbsp;</label>
            <div class="col-sm-2">
                <h6>Start Time</h6>
            </div>
            <div class="col-sm-2">
                <h6>End Time</h6>
            </div>
        </div>

        <div class="row">
            <label class="col-sm-2 col-form-label" *ngIf="label">{{ label }}</label>
            <div class="col-sm-2">
                <timespan-timepicker
                    formControlName="startTime"
                    [format]="format"
                    [placeholder]="placeholder"
                    [formatPlaceholder]="formatPlaceholder"
                    [nowEnabled]="false">
                </timespan-timepicker>
            </div>
            <div class="col-sm-2">
                <timespan-timepicker
                    formControlName="endTime"
                    [format]="format"
                    [placeholder]="placeholder"
                    [formatPlaceholder]="formatPlaceholder"
                    [nowEnabled]="false">
                </timespan-timepicker>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
    `,

    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TimeRangePickerComponent),
        multi: true
    }]
})
export class TimeRangePickerComponent extends BaseInputElement<TimeRange> {

    public readonly editForm: FormGroup;

    @ViewChild(NgModel)
    public model: NgModel;

    @Input()
    public showHeader: boolean;

    @Input()
    public label: string;

    @Input()
    public format: string;

    @Input()
    public placeholder: string;

    @Input()
    public formatPlaceholder: string;

    public readonly identifier = `time-range-picker-${_identifier++}`;

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: ValidatorArray,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorArray,
        private readonly formBuilder: FormBuilder
    ) {
        super(validators, asyncValidators);

        this.editForm = this.formBuilder.group({
            dayOfWeek: [WeekDay.Sunday, [Validators.required]],
            startTime: [TimeSpan.empty(), [Validators.required]],
            endTime: [TimeSpan.empty(), [Validators.required]]
        });

        this.addSubscription(
            this.editForm.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    tap(changes => {
                        this.value = changes;
                    })
                )
        );

        this.showHeader = false;
        this.format = 'HH:mm:ss';
        this.placeholder = this.format;
        this.formatPlaceholder = 'formatPattern';
    }

    protected bindValue(value: TimeRange) {
        value = value || TimeRange.empty(WeekDay.Sunday);

        this.editForm.patchValue(value);
    }
    public setDisabledState(isDisabled: boolean): void {
        super.setDisabledState(isDisabled);
    
        if(isDisabled) {
          this.editForm.disable();
        } else {
          this.editForm.enable();
        }
    }
}
