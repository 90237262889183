import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { EnumFieldConfig } from '../../models/field-config.interface';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-multi-select',
    styleUrls: ['form-multi-select.component.scss'],
    template: `
    <ng-container [formGroup]="group">
        <kendo-multiselect
            [data]="config.options"
            [valuePrimitive]="true"
            [ngClass]="[config.cssClass || '']"
            [placeholder]="config.placeholder"
            [formControlName]="config.name"
            [popupSettings]="config.popupSettings || { width: 'auto'}"
            textField="Name"
            valueField="Id"
            style="min-width: 150px;">
        </kendo-multiselect>
    </ng-container>
  `
})
export class FormMultiSelectComponent implements Field {
    config: EnumFieldConfig;
    group: FormGroup;
}
