export const RoleConstant = {
    Administrators: 'Administrators',
    AMRBUser: 'AmrbUser',
    Registered: 'Registered',
    Guests: 'Guests',
    PropertyManager: 'PropertyManager',
    BuildingEngineer: 'BuildingEngineer',
    TenantManager: 'TenantManager',
    TenantUser: 'TenantUser',
    SecurityUser: 'SecurityUser',
    Developer: 'Developer',
    LeaseOnlyTenantUser: 'LeaseOnlyTenantUser',
    LightsOnlyTenantUser: 'LightsOnlyTenantUser',
    RestrictedTenantUser: 'RestrictedTenantUser',
    SecurityUserLightingOnly: 'SecurityUserLightingOnly'
};