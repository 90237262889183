// https://stackoverflow.com/a/49772435
// NOTE: This is not needed in Angular 6.1+ per comments in the above thread...

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keyvalue' })
export class KeyValuePipe implements PipeTransform {
    transform(map: Map<any, any>): Array<{ key: any, value: any }> {
        return (map || new Map())
            .map((v, k) => {
                return { key: k, value: v };
            });
    }
}

 // <li *ngFor="let recipient of map | keyvalue">
