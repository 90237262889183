import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, Type, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormButtonComponent } from '../form-button/form-button.component';
import { FormInputComponent } from '../form-input/form-input.component';
import { FormSelectComponent } from '../form-select/form-select.component';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { FormIpAddressComponent } from '../form-ip-address/form-ip-address.component';
import { FormCheckboxComponent } from '../form-checkbox/form-checkbox.component';
import { FormStaticTextComponent } from '../form-static-text/form-static-text.component';
import { LayoutMode, DisplayMode } from '../../models/modes';
import { FormPhoneComponent } from '../form-phone/form-phone.component';
import { FormNumberComponent } from '../form-number/form-number.component';
import { FormMacAddressComponent } from '../form-mac-address/form-mac-address.component';
import { FormCustomComponent } from '../form-custom/form-custom.component';
import { FormDateComponent } from '../form-date/form-date.component';
import { FormMultiSelectComponent } from '../form-multi-select/form-multi-select.component';
import { BaseDirective } from '../../../../../_core/components/base.directive';

const components: { [type: string]: Type<Field> } = {
    button: FormButtonComponent,
    input: FormInputComponent,
    select: FormSelectComponent,
    ipAddress: FormIpAddressComponent,
    macAddress: FormMacAddressComponent,
    phone: FormPhoneComponent,
    switch: FormCheckboxComponent,
    checkbox: FormCheckboxComponent,
    static: FormStaticTextComponent,
    number: FormNumberComponent,
    custom: FormCustomComponent,
    date: FormDateComponent,
    'multi-select': FormMultiSelectComponent
};

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[dynamicField]'
})
export class DynamicFieldDirective extends BaseDirective implements Field, OnChanges {
    @Input()
    public config: FieldConfig;

    @Input()
    public group: FormGroup;

    @Input()
    public layoutMode: LayoutMode = 'vertical';

    @Input()
    public displayMode: DisplayMode = 'edit';

    public component: ComponentRef<Field>;

    constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef
    ) {
        super();
    }

    ngOnChanges() {
        if (this.component) {
            this.component.instance.config = this.config;
            this.component.instance.group = this.group;
        }
    }

    OnInit() {
        if (!components[this.config.type]) {
            const supportedTypes = Object.keys(components).join(', ');
            throw new Error(
                `Trying to use an unsupported type (${this.config.type}). Supported types: ${supportedTypes}`
            );
        }

        const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
        this.component = this.container.createComponent(component);
        this.component.instance.config = this.config;
        this.component.instance.group = this.group;
    }
}
