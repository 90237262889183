import { Component, ComponentFactoryResolver, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { CustomFieldConfig } from '../../models/field-config.interface';
import { BaseComponent } from '../../../../../_core/components/base.component';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-custom',
    styleUrls: ['form-custom.component.scss'],
    template: `
    <ng-container [formGroup]="group">
        {{ config.name }}: TODO
    </ng-container>
  `
})
export class FormCustomComponent extends BaseComponent implements Field {
    config: CustomFieldConfig;
    group: FormGroup;

    constructor(private readonly resolver: ComponentFactoryResolver) {
        super();

        if (this.config.content instanceof TemplateRef) {

        } else {
            const test = this.resolver.resolveComponentFactory(this.config.content);
        }
    }

    public OnInit(): void {

    }
}
