
import { combineLatest, Observable, BehaviorSubject, zip } from 'rxjs';

import { filter, distinctUntilChanged, take, tap, first } from 'rxjs/operators';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { BaseComponent } from '../../../../../_core/components/base.component';
import { notNull } from '../../../../../_core/rxjs.operators';
import { IpAddress } from '../../../../../_core/ip-address';
import { TypeConverter } from '../../../../../_core/type-converter';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-ip-address',
    styleUrls: ['form-ip-address.component.scss'],
    template: `
    <ng-container [formGroup]="proxyGroup">
        <div class="input-group flex-nowrap">
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="3" placeholder="255" (keyup)="onKeyUp($event)"
                formControlName="segmentA" numeric #segmentA />
            <div class="input-group-append"><span class="input-group-text dot">.</span></div>
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="3" placeholder="255" (keyup)="onKeyUp($event)"
                formControlName="segmentB" numeric #segmentB />
            <div class="input-group-append"><span class="input-group-text dot">.</span></div>
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="3" placeholder="255" (keyup)="onKeyUp($event)"
                formControlName="segmentC" numeric #segmentC />
            <div class="input-group-append"><span class="input-group-text dot">.</span></div>
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="3" placeholder="255" (keyup)="onKeyUp($event)"
                formControlName="segmentD" numeric #segmentD />
        </div>
    </ng-container>
  `
})
export class FormIpAddressComponent extends BaseComponent implements Field {

    /***********************************************
     * Field interface...
    ***********************************************/

    // Use getter/setter so we can hook into the values being set.
    public get config(): FieldConfig {
        return this._config$.getValue();
    }
    public set config(value: FieldConfig) {
        this._config$.next(value);
    }

    public get group(): FormGroup {
        return this._group$.getValue();
    }
    public set group(value: FormGroup) {
        this._group$.next(value);
    }

    /***********************************************
     * Custom implementation...
    ***********************************************/

    // We're going to use a proxy form group that will broadcast
    // values to the Field form group...
    public proxyGroup: FormGroup;

    private _config$ = new BehaviorSubject<FieldConfig>(null);
    private _group$ = new BehaviorSubject<FormGroup>(null);

    private readonly validators: Array<ValidatorFn>;
    
    @ViewChild('segmentA') private segmentAElement: ElementRef;
    @ViewChild('segmentB') private segmentBElement: ElementRef;  
    @ViewChild('segmentC') private segmentCElement: ElementRef;
    @ViewChild('segmentD') private segmentDElement: ElementRef;

    private get segmentAControl(): AbstractControl {
        return this.proxyGroup.get('segmentA');
    }

    private get segmentBControl(): AbstractControl {
        return this.proxyGroup.get('segmentB');
    }

    private get segmentCControl(): AbstractControl {
        return this.proxyGroup.get('segmentC');
    }

    private get segmentDControl(): AbstractControl {
        return this.proxyGroup.get('segmentD');
    }

    constructor(private fb: FormBuilder) {
        super();

        this.validators = [Validators.min(0), Validators.max(255), this.requiredValidator()];

        // The form requires a FormGroup with its controls populated... Go ahead and create it...
        this.proxyGroup = this.createGroup();

        // We're going to listen for both the config and group properties
        // being set. Once they have a value, then we can bind our config properties
        // to our proxy form.
        this.addSubscription(
            zip(
                this._config$.pipe(notNull()),
                this._group$.pipe(notNull())
            ).pipe(
                take(1),
                tap(([config, group]) => {
                    // Both values have been set! bind our form...
                    this.bindForm(config, group);
                })
            )
        );
    }

    private bindForm(config: FieldConfig, group: FormGroup) {
        const ipAddress: IpAddress = IpAddress.parse(config.value);

        this.proxyGroup.setValue(ipAddress, { emitEvent: false });

        // When the proxy changes, notify the original.
        this.addSubscription(
            this.proxyGroup.valueChanges
                .pipe(
                    distinctUntilChanged(TypeConverter.isEquivalent),
                    tap(changes => {
                        const newValue = {};
                        newValue[config.name] = IpAddress.format(changes);
                        group.patchValue(newValue, { emitEvent: true });
                    })
                )
        );

        // Make sure we set the disabled state from the config
        if (config.disabled) {
            this.proxyGroup.disable();
        }

        // Used to set the enabled/disabled from the original control.
        // When a control is disabled in the original, we need to reflect that in the proxy...
        this.addSubscription(
            group.controls[config.name].statusChanges
                .pipe(
                    filter(status => status !== this.proxyGroup.status), // Note, YOU NEED THIS!
                    distinctUntilChanged(),
                    tap(status => {
                        const method = String.equals(status, 'disabled', false) ? 'disable' : 'enable';
                        // Remember, this is ONE control.
                        // Even though we're rendering 4 fields, it's still ONE control...
                        // THerefore, when the original form control is enabled/disabled,
                        // we need to enable/disable the GROUP, not just a control...
                        this.proxyGroup[method]();
                    })
                )
        );

        this.addSubscription(
            this.segmentAControl.valueChanges.pipe(
                filter((value: string) => value.length === 3),
                tap(_ => this.segmentBElement.nativeElement.focus())
            )
        );

        this.addSubscription(
            this.segmentBControl.valueChanges.pipe(
                filter((value: string) => value.length === 3),
                tap(_ => this.segmentCElement.nativeElement.focus())
            )
        );

        this.addSubscription(
            this.segmentCControl.valueChanges.pipe(
                filter((value: string) => value.length === 3),
                tap(_ => this.segmentDElement.nativeElement.focus())
            )
        );
    }

    public createGroup() {
        const group = this.fb.group({});

        Object.keys(IpAddress.empty())
            .forEach(key => {
                group.addControl(key, this.createControl({ disabled: false, validator: this.validators, value: null }));
            });

        return group;
    }

    public createControl({ disabled, validator, value }) {
        return this.fb.control({ disabled, value }, validator);
    }

    public onKeyUp(evt: any): void {
        console.log(evt);
    }

    private requiredValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if(!this.config || !this.config.required) {
                return null;
            }

            const value = control.value as string;

            if (String.isNullOrEmpty(value)) {
                return { 'invalid': 'invalid' };
            }

            return null;
        };
    }
}
