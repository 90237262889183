export const PermissionConstant = {

    AccessAdminPanel: 'AccessAdminPanel',
    AccessGlobalSearch: 'AccessGlobalSearch',

    // Users category
    AllowUserImpersonation: 'AllowUserImpersonation',
    CreateUsers: 'CreateUsers',
    ReadUsers: 'ReadUsers',
    UpdateUsers: 'UpdateUsers',
    DeleteUsers: 'DeleteUsers',
    ReportUsers: 'ReportUsers',
    ManageUsers: 'ManageUsers',

    ManageRoles: 'ManageRoles',
    CreateRoles: 'CreateRoles',
    ReadRoles: 'ReadRoles',
    UpdateRoles: 'UpdateRoles',
    DeleteRoles: 'DeleteRoles',
    ReportRoles: 'ReportRoles',
    ManagePermissions: 'ManagePermissions',
    CreatePermissions: 'CreatePermissions',
    ReadPermissions: 'ReadPermissions',
    UpdatePermissions: 'UpdatePermissions',
    DeletePermissions: 'DeletePermissions',
    ReportPermissions: 'ReportPermissions',

    // ACLs
    CreateCustomerAcls: 'CreateCustomerAcls',
    ReadCustomerAcls: 'ReadCustomerAcls',
    UpdateCustomerAcls: 'UpdateCustomerAcls',
    DeleteCustomerAcls: 'DeleteCustomerAcls',
    ReportCustomerAcls: 'ReportCustomerAcls',

    CreateBuildingAcls: 'CreateBuildingAcls',
    ReadBuildingAcls: 'ReadBuildingAcls',
    UpdateBuildingAcls: 'UpdateBuildingAcls',
    DeleteBuildingAcls: 'DeleteBuildingAcls',
    ReportBuildingAcls: 'ReportBuildingAcls',

    CreateTenantAcls: 'CreateTenantAcls',
    ReadTenantAcls: 'ReadTenantAcls',
    UpdateTenantAcls: 'UpdateTenantAcls',
    DeleteTenantAcls: 'DeleteTenantAcls',
    ReportTenantAcls: 'ReportTenantAcls',

    CreateLeaseAcls: 'CreateLeaseAcls',
    ReadLeaseAcls: 'ReadLeaseAcls',
    UpdateLeaseAcls: 'UpdateLeaseAcls',
    DeleteLeaseAcls: 'DeleteLeaseAcls',
    ReportLeaseAcls: 'ReportLeaseAcls',

    CreateLeaseAreaAcls: 'CreateLeaseAreaAcls',
    ReadLeaseAreaAcls: 'ReadLeaseAreaAcls',
    UpdateLeaseAreaAcls: 'UpdateLeaseAreaAcls',
    DeleteLeaseAreaAcls: 'DeleteLeaseAreaAcls',
    ReportLeaseAreaAcls: 'ReportLeaseAreaAcls',

    // Buildings category
    ManageBuildings: 'ManageBuildings',
    CreateBuildings: 'CreateBuildings',
    ReadBuildings: 'ReadBuildings',
    UpdateBuildings: 'UpdateBuildings',
    DeleteBuildings: 'DeleteBuildings',
    ReportBuildings: 'ReportBuildings',
    CreateBuildingHolidays: 'CreateBuildingHolidays',
    ReadBuildingHolidays: 'ReadBuildingHolidays',
    UpdateBuildingHolidays: 'UpdateBuildingHolidays',
    DeleteBuildingHolidays: 'DeleteBuildingHolidays',
    ReportBuildingHolidays: 'ReportBuildingHolidays',
    CreateBuildingGroups: 'CreateBuildingGroups',
    ReadBuildingGroups: 'ReadBuildingGroups',
    UpdateBuildingGroups: 'UpdateBuildingGroups',
    DeleteBuildingGroups: 'DeleteBuildingGroups',
    ReportBuildingGroups: 'ReportBuildingGroups',
    ReadBuildingUsers: 'ReadBuildingUsers',

    // Customers category
    ManageCustomers: 'ManageCustomers',
    CreateCustomers: 'CreateCustomers',
    ReadCustomers: 'ReadCustomers',
    UpdateCustomers: 'UpdateCustomers',
    DeleteCustomers: 'DeleteCustomers',
    ReportCustomers: 'ReportCustomers',
    CreateCustomerHolidays: 'CreateCustomerHolidays',
    ReadCustomerHolidays: 'ReadCustomerHolidays',
    UpdateCustomerHolidays: 'UpdateCustomerHolidays',
    DeleteCustomerHolidays: 'DeleteCustomerHolidays',
    ReportCustomerHolidays: 'ReportCustomerHolidays',
    CreateRemittanceAddresses: 'CreateRemittanceAddresses',
    ReadRemittanceAddresses: 'ReadRemittanceAddresses',
    UpdateRemittanceAddresses: 'UpdateRemittanceAddresses',
    DeleteRemittanceAddresses: 'DeleteRemittanceAddresses',
    ReportRemittanceAddresses: 'ReportRemittanceAddresses',
    ReadCustomerUsers: 'ReadCustomerUsers',


    // Tenants category
    ManageTenants: 'ManageTenants',
    CreateTenants: 'CreateTenants',
    ReadTenants: 'ReadTenants',
    UpdateTenants: 'UpdateTenants',
    DeleteTenants: 'DeleteTenants',
    ReportTenants: 'ReportTenants',
    ReadTenantUsers: 'ReadTenantUsers',

    // Tenant Invoices
    ReadTenantInvoices: 'ReadTenantInvoices',
    UpdateTenantInvoices: 'UpdateTenantInvoices',
    VoidTenantInvoices: 'VoidTenantInvoices',
    AdjustTenantInvoices: 'AdjustTenantInvoices',
    ApproveTenantInvoices: 'ApproveTenantInvoices',

    // Leases category
    ManageLeases: 'ManageLeases',
    CreateLeases: 'CreateLeases',
    ReadLeases: 'ReadLeases',
    UpdateLeases: 'UpdateLeases',
    DeleteLeases: 'DeleteLeases',
    ReportLeases: 'ReportLeases',
    ReadLeaseUsers: 'ReadLeaseUsers',

    // Lease Areas category
    ManageLeaseAreas: 'ManageLeaseAreas',
    CreateLeaseAreas: 'CreateLeaseAreas',
    ReadLeaseAreas: 'ReadLeaseAreas',
    UpdateLeaseAreas: 'UpdateLeaseAreas',
    DeleteLeaseAreas: 'DeleteLeaseAreas',
    ReportLeaseAreas: 'ReportLeaseAreas',
    ReadLeaseAreaUsers: 'ReadLeaseAreaUsers',

    // Zones category
    ManageZones: 'ManageZones',
    CreateZones: 'CreateZones',
    ReadZones: 'ReadZones',
    UpdateZones: 'UpdateZones',
    DeleteZones: 'DeleteZones',
    ReportZones: 'ReportZones',

    // Adhoc Requests
    CreateAdHocRequests: 'CreateAdHocRequests',
    ReadAdHocRequests: 'ReadAdHocRequests',
    UpdateAdHocRequests: 'UpdateAdHocRequests',
    DeleteAdHocRequests: 'DeleteAdHocRequests',
    ReportAdHocRequests: 'ReportAdHocRequests',

    // Points category
    ManagePoints: 'ManagePoints',
    CreatePoints: 'CreatePoints',
    ReadPoints: 'ReadPoints',
    UpdatePoints: 'UpdatePoints',
    DeletePoints: 'DeletePoints',
    ReportPoints: 'ReportPoints',
    ReadActivePoints: 'ReadActivePoints',
    ReadAutoOffPoints: 'ReadAutoOffPoints',
    CancelAutoOffPoints: 'CancelAutoOffPoints',
    VerifyPoints: 'VerifyPoints',

    // Connections category
    ManageConnections: 'ManageConnections',
    CreateConnections: 'CreateConnections',
    ReadConnections: 'ReadConnections',
    UpdateConnections: 'UpdateConnections',
    DeleteConnections: 'DeleteConnections',
    ReportConnections: 'ReportConnections',

    // EMS category
    CreateProtocolTypes: 'CreateProtocolTypes',
    ReadProtocolTypes: 'ReadProtocolTypes',
    UpdateProtocolTypes: 'UpdateProtocolTypes',
    DeleteProtocolTypes: 'DeleteProtocolTypes',
    CreatePointTypes: 'CreatePointTypes',
    ReadPointTypes: 'ReadPointTypes',
    UpdatePointTypes: 'UpdatePointTypes',
    DeletePointTypes: 'DeletePointTypes',
    CreateProtocols: 'CreateProtocols',
    ReadProtocols: 'ReadProtocols',
    UpdateProtocols: 'UpdateProtocols',
    DeleteProtocols: 'DeleteProtocols',
    CreateCommands: 'CreateCommands',
    ReadCommands: 'ReadCommands',
    UpdateCommands: 'UpdateCommands',
    DeleteCommands: 'DeleteCommands',
    CreatePointTemplates: 'CreatePointTemplates',
    ReadPointTemplates: 'ReadPointTemplates',
    UpdatePointTemplates: 'UpdatePointTemplates',
    DeletePointTemplates: 'DeletePointTemplates',
    CreateConnectionTemplates: 'CreateConnectionTemplates',
    ReadConnectionTemplates: 'ReadConnectionTemplates',
    UpdateConnectionTemplates: 'UpdateConnectionTemplates',
    DeleteConnectionTemplates: 'DeleteConnectionTemplates',
    CreateCommandTemplates: 'CreateCommandTemplates',
    ReadCommandTemplates: 'ReadCommandTemplates',
    UpdateCommandTemplates: 'UpdateCommandTemplates',
    DeleteCommandTemplates: 'DeleteCommandTemplates',
    ManageDeviceControllers: 'ManageDeviceControllers',
    CreateDeviceControllers: 'CreateDeviceControllers',
    ReadDeviceControllers: 'ReadDeviceControllers',
    UpdateDeviceControllers: 'UpdateDeviceControllers',
    DeleteDeviceControllers: 'DeleteDeviceControllers',

    // Disable Service category
    CreateBuildingDisableServices: 'CreateBuildingDisableServices',
    ReadBuildingDisableServices: 'ReadBuildingDisableServices',
    UpdateBuildingDisableServices: 'UpdateBuildingDisableServices',
    DeleteBuildingDisableServices: 'DeleteBuildingDisableServices',
    ReportBuildingDisableServices: 'ReportBuildingDisableServices',
    CreateZoneDisableServices: 'CreateZoneDisableServices',
    ReadZoneDisableServices: 'ReadZoneDisableServices',
    UpdateZoneDisableServices: 'UpdateZoneDisableServices',
    DeleteZoneDisableServices: 'DeleteZoneDisableServices',
    ReportZoneDisableServices: 'ReportZoneDisableServices',

    // Configuration category
    ManageDirectory: 'ManageDirectory',
    CreateCountries: 'CreateCountries',
    ReadCountries: 'ReadCountries',
    UpdateCountries: 'UpdateCountries',
    DeleteCountries: 'DeleteCountries',
    ReportCountries: 'ReportCountries',
    CreateStateProvinces: 'CreateStateProvinces',
    ReadStateProvinces: 'ReadStateProvinces',
    UpdateStateProvinces: 'UpdateStateProvinces',
    DeleteStateProvinces: 'DeleteStateProvinces',
    ReportStateProvinces: 'ReportStateProvinces',
    CreateRegions: 'CreateRegions',
    ReadRegions: 'ReadRegions',
    UpdateRegions: 'UpdateRegions',
    DeleteRegions: 'DeleteRegions',
    ReportRegions: 'ReportRegions',
    CreateLanguages: 'CreateLanguages',
    ReadLanguages: 'ReadLanguages',
    UpdateLanguages: 'UpdateLanguages',
    DeleteLanguages: 'DeleteLanguages',
    ReportLanguages: 'ReportLanguages',
    CreateLocaleStringResources: 'CreateLocaleStringResources',
    ReadLocaleStringResources: 'ReadLocaleStringResources',
    UpdateLocaleStringResources: 'UpdateLocaleStringResources',
    DeleteLocaleStringResources: 'DeleteLocaleStringResources',
    ReportLocaleStringResources: 'ReportLocaleStringResources',
    CreateEnumerations: 'CreateEnumerations',
    ReadEnumerations: 'ReadEnumerations',
    UpdateEnumerations: 'UpdateEnumerations',
    DeleteEnumerations: 'DeleteEnumerations',
    ReportEnumerations: 'ReportEnumerations',
    CreateParameters: 'CreateParameters',
    ReadParameters: 'ReadParameters',
    UpdateParameters: 'UpdateParameters',
    DeleteParameters: 'DeleteParameters',
    ReportParameters: 'ReportParameters',
    CreateDynamicSettings: 'CreateDynamicSettings',
    ReadDynamicSettings: 'ReadDynamicSettings',
    UpdateDynamicSettings: 'UpdateDynamicSettings',
    DeleteDynamicSettings: 'DeleteDynamicSettings',
    ReportDynamicSettings: 'ReportDynamicSettings',
    ReadSystemInfo: 'ReadSystemInfo',
    UpdateSystemInfo: 'UpdateSystemInfo',
    ReadActivityLogs: 'ReadActivityLogs',

    // Notification category
    ManageNotifications: 'ManageNotifications',
    CreateNotificationTemplates: 'CreateNotificationTemplates',
    ReadNotificationTemplates: 'ReadNotificationTemplates',
    UpdateNotificationTemplates: 'UpdateNotificationTemplates',
    DeleteNotificationTemplates: 'DeleteNotificationTemplates',
    CreateNotificationSchedules: 'CreateNotificationSchedules',
    ReadNotificationSchedules: 'ReadNotificationSchedules',
    UpdateNotificationSchedules: 'UpdateNotificationSchedules',
    DeleteNotificationSchedules: 'DeleteNotificationSchedules',
    ReadNotificationJobs: 'ReadNotificationJobs',
    ResendNotificationJobs: 'ResendNotificationJobs',
    SendBuildingWelcomeMessages: 'SendBuildingWelcomeMessages',

    // Metering category
    CreateMeasurements: 'CreateMeasurements',
    ReadMeasurements: 'ReadMeasurements',
    UpdateMeasurements: 'UpdateMeasurements',
    DeleteMeasurements: 'DeleteMeasurements',
    EditRateSchedule: 'EditRateSchedule',
    DeleteRateSchedule: 'DeleteRateSchedule',
    EditMeter: 'EditMeter',
    DeleteMeter: 'DeleteMeter',
    DecommissionMeter:'DecommissionMeter',
    EditMeasurement: 'EditMeasurement',
    ReportMeasurements: 'ReportMeasurements',
    CreateMeterInvoices: 'CreateMeterInvoices',
    ReadMeterInvoices: 'ReadMeterInvoices',
    UpdateMeterInvoices: 'UpdateMeterInvoices',
    DeleteMeterInvoices: 'DeleteMeterInvoices',
    ReportMeterInvoices: 'ReportMeterInvoices',
    CreateMeterRates: 'CreateMeterRates',
    ReadMeterRates: 'ReadMeterRates',
    UpdateMeterRates: 'UpdateMeterRates',
    ManageMeterRates: 'ManageMeterRates',
    DeleteMeterRates: 'DeleteMeterRates',
    ReportMeterRates: 'ReportMeterRates',
    CreateMeterReads: 'CreateMeterReads',
    ViewRateAudit : "ViewRateAudit",
    ReadMeterReads: 'ReadMeterReads',
    UpdateMeterReads: 'UpdateMeterReads',
    DeleteMeterReads: 'DeleteMeterReads',
    ReportMeterReads: 'ReportMeterReads',
    CreatePhysicalMeters: 'CreatePhysicalMeters',
    ReadPhysicalMeters: 'ReadPhysicalMeters',
    UpdatePhysicalMeters: 'UpdatePhysicalMeters',
    DeletePhysicalMeters: 'DeletePhysicalMeters',
    ReportPhysicalMeters: 'ReportPhysicalMeters',
    CreateVirtualMeters: 'CreateVirtualMeters',
    ReadVirtualMeters: 'ReadVirtualMeters',
    UpdateVirtualMeters: 'UpdateVirtualMeters',
    DeleteVirtualMeters: 'DeleteVirtualMeters',
    ReportVirtualMeters: 'ReportVirtualMeters',
    CreateMeterBillings: 'CreateMeterBillings',
    ReadMeterBillings: 'ReadMeterBillings',
    UpdateMeterBillings: 'UpdateMeterBillings',
    DeleteMeterBillings: 'DeleteMeterBillings',
    ReportMeterBillings: 'ReportMeterBillings',
    CreateMeterAllowances: 'CreateMeterAllowances',
    ReadMeterAllowances: 'ReadMeterAllowances',
    UpdateMeterAllowances: 'UpdateMeterAllowances',
    DeleteMeterAllowances: 'DeleteMeterAllowances',
    ReportMeterAllowances: 'ReportMeterAllowances',
    ReadAllowanceReport: 'ReadAllowanceReport',
    CreateMeterLedgers: 'CreateMeterLedgers',
    ReadMeterLedgers: 'ReadMeterLedgers',
    UpdateMeterLedgers: 'UpdateMeterLedgers',
    DeleteMeterLedgers: 'DeleteMeterLedgers',
    ReportMeterLedgers: 'ReportMeterLedgers',
    CreateMeterInvoiceTemplates: 'CreateMeterInvoiceTemplates',
    ReadMeterInvoiceTemplates: 'ReadMeterInvoiceTemplates',
    UpdateMeterInvoiceTemplates: 'UpdateMeterInvoiceTemplates',
    DeleteMeterInvoiceTemplates: 'DeleteMeterInvoiceTemplates',
    CreateMeterBillingConfigurations: 'CreateMeterBillingConfigurations',
    ReadMeterBillingConfigurations: 'ReadMeterBillingConfigurations',
    UpdateMeterBillingConfigurations: 'UpdateMeterBillingConfigurations',
	DeleteMeterBillingConfigurations: 'DeleteMeterBillingConfigurations',
    CreateUtilityConfiguration:'CreateUtilityConfiguration',
    ReadTenantInvoiceReport:'ReadTenantInvoiceReport',
    canViewBillingLogs:'canViewBillingLogs',
    MeterReadQuarantine:'MeterReadQuarantine',
    BulkMeterUpdate: 'BulkMeterUpdate',

  
    /*************** HVAC *****************/
    CreateHVACInvoices: 'CreateHVACInvoices',
    ReadHVACHistories: 'ReadHVACHistories',
    ReadHVACBillingConfigurations: 'ReadHVACBillingConfigurations',
    UpdateHVACBillingConfigurations: 'UpdateHVACBillingConfigurations',
    ReadHVACRates: 'ReadHVACRates',
    CancelHVACInvoices: 'CancelHVACInvoices',
    ReleaseHVACInvoices: 'ReleaseHVACInvoices',
    PostHVACInvoices: 'PostHVACInvoices',
    CloseHVACInvoices: 'CloseHVACInvoices',
    ReconcileHVACInvoices: 'ReconcileHVACInvoices',
    ReopenHVACInvoices: 'ReopenHVACInvoices',
    PreviewHVACInvoices: 'PreviewHVACInvoices',
    DownloadHVACInvoices: 'DownloadHVACInvoices',
    UpdateHVACInvoices: 'UpdateHVACInvoices',
    DeleteHVACInvoices:'DeleteHVACInvoices',
    ApproveHVACTenantInvoices: 'ApproveHVACTenantInvoices',
    VoidHVACTenantInvoices: 'VoidHVACTenantInvoices',
    ReadHVACTenantInvoices:'ReadHVACTenantInvoices',
    ReadHVACTenantInvoiceReport:'ReadHVACTenantInvoiceReport',

    // Permission Records for Meter Invoice operation
    CancelMeterInvoices: 'CancelMeterInvoices',
    ReleaseMeterInvoices: 'ReleaseMeterInvoices',
    PostMeterInvoices: 'PostMeterInvoices',
    ApproveMeterInvoices:'ApproveMeterInvoices',
    CloseMeterInvoices: 'CloseMeterInvoices',
    ReconcileMeterInvoices: 'ReconcileMeterInvoices',
    ReopenMeterInvoices: 'ReopenMeterInvoices',
    PreviewMeterInvoices: 'PreviewMeterInvoices',
    DownloadMeterInvoices: 'DownloadMeterInvoices',
    ChangeRequestMeterInvoices:'ChangeRequestMeterInvoices',
    UpdateCurrentInvoiceState:'UpdateCurrentInvoiceState',

    ReadMeterHistories: 'ReadMeterHistories',
    ReportMeterHistories: 'ReportMeterHistories',

    // Service Requests category
    ManageServiceRequests: 'ManageServiceRequests',
    CreateServiceRequests: 'CreateServiceRequests',
    ReadServiceRequests: 'ReadServiceRequests',
    UpdateServiceRequests: 'UpdateServiceRequests',
    DeleteServiceRequests: 'DeleteServiceRequests',
    ReportServiceRequests: 'ReportServiceRequests',
    AcknowledgeRequestAlarms: 'AcknowledgeRequestAlarms',
    CreateHVACServiceRequests: 'CreateHVACServiceRequests',
    CreateLightingOnlyServiceRequests: 'CreateLightingOnlyServiceRequests',
    RetryServiceRequests: 'RetryServiceRequests',
    ViewCommandHistory: 'ViewCommandHistory',
    ReadHvacInvoices: 'ReadHvacInvoices',
    ReadHVACInvoicesPMDashboard:'ReadHVACInvoicesPMDashboard',

    // EMS API
    CreateEmsResults: 'CreateEmsResults',
    UpdateEmsResults: 'UpdateEmsResults',
    ReadEmsConfigurations: 'ReadEmsConfigurations',

    // Report Center
    AccessReportCenter: 'AccessReportCenter',

    CreateNonBillableServiceRequests: 'CreateNonBillableServiceRequests',
    SuppressServiceRequestNotifications: 'SuppressServiceRequestNotifications',

    //energy-analytics
     ViewEnergyAnalytics:'ViewEnergyAnalytics'
};
