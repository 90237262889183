import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';
import { SettingModel } from '../../../_sdk/configuration/setting.model';
import * as SettingActions from './setting.actions';

export interface State {
    settings: ModelCollection<SettingModel>;
}

const initialState: State = {
    settings: new ModelCollection<SettingModel>()
};

export function reducer(state: State = initialState, action: SettingActions.Actions): State {

    let settings: ModelCollection<SettingModel>;
    let index: number;

    switch (action.type) {
        case SettingActions.DISPOSE:
            return {
                ...state,
                settings: initialState.settings
            };
        case SettingActions.LOAD_COMPLETE:
            return {
                ...state,
                settings: action.payload
            };
        case SettingActions.LOAD:
        case SettingActions.INSERT:
        case SettingActions.UPDATE:
        case SettingActions.DELETE:
            return {
                ...state
            };
        case SettingActions.INSERT_COMPLETE:
            settings = new ModelCollection(state.settings.data
                .map(p => TypeConverter.convert(SettingModel, p)));

            settings.data.push(action.payload);

            return {
                ...state,
                settings: settings
            };

        case SettingActions.UPDATE_COMPLETE:
            settings = new ModelCollection(state.settings.data
                .map(p => TypeConverter.convert(SettingModel, p)));

            index = settings.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                settings.data[index] = action.payload;
            }

            return {
                ...state,
                settings: settings
            };

        case SettingActions.DELETE_COMPLETE:
            settings = new ModelCollection(state.settings.data
                .map(p => TypeConverter.convert(SettingModel, p)));

            index = settings.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                settings.data.splice(index, 1);
            }

            return {
                ...state,
                settings: settings
            };

        default:
            return state;
    }
}
