import { CompositeType, DataObject, DataProperty, TypeConverter } from '../../_core/type-converter';
import { BuildingModel } from '../buildings/building.model';
import { CustomerModel } from '../customers/customer.model';
import { EntityModel, CoreEntity } from '../model';
import { FilterState } from '../page-state';
import { TenantModel } from '../tenants/tenant.model';
import { LeaseAreaBillingConfigurationModel } from './lease-area-billing-configuration.model';
import { LeaseAreaRequestConfigurationModel } from './lease-area-request-configuration.model';
import { LeaseAreaServiceHourModel } from './lease-area-service-hour.model';
import { LeaseModel } from './lease.model';
import { ServiceHourModel } from './service-hour.model';
import { ServiceHoursModel } from './service-hours.model';
import { ServiceRequestDurationModel } from './service-request-duration.model';
import { ServiceRequestDurationsModel } from './service-request-durations.model';
import { ServiceTypeModel } from './service-type.model';
import { EntityType } from '../search/entity-type.enum';
import { AllowanceModel } from '../../admin/platform/allowance/_models/allowance.model';
import { ZoneModel } from '../zones/zone.model';
import { LeaseRateModel } from './lease-rate.model';
import { HolidayModel } from '../holidays/holiday.model';
import { LeaseAreaRateModel } from './lease-area-rate.model';

@DataObject()
export class LeaseAreaModel extends EntityModel implements CoreEntity {
    public readonly EntityType: EntityType = EntityType.LeaseArea;

    public Name: string = null;
    public Description: string = null;
    public Version: number = null;
    public VersionedOnUtc: Date = null;
    public Active = false;

    @DataProperty()
    public LeaseAreaBillingConfiguration: LeaseAreaBillingConfigurationModel = null;

    @DataProperty()
    public LeaseAreaRequestConfiguration: LeaseAreaRequestConfigurationModel = null;

    @DataProperty()
    public Lease: LeaseModel = null;

    // Added by Daniel
    @DataProperty()
    public Tenant: TenantModel = null;

    @DataProperty()
    public Building: BuildingModel = null;

    @DataProperty()
    public Customer: CustomerModel = null;

    @DataProperty()
    public Allowance: AllowanceModel = null;

    @DataProperty({ instanceType: ZoneModel })
    public Zones:Array<ZoneModel> = null;

    @DataProperty({ instanceType: ServiceHourModel })
    public ServiceRequestHours: Array<ServiceHourModel> = [];

    @DataProperty({ instanceType: ServiceRequestDurationModel })
    public ServiceRequestDurations: Array<ServiceRequestDurationModel> = [];

    @DataProperty({ instanceType: ServiceTypeModel })
    public ServiceTypes: Array<ServiceTypeModel> = [];

    @DataProperty({ instanceType: ServiceTypeModel })
    public ServiceRequestTypes: Array<ServiceTypeModel> = [];

    @DataProperty()
    public LeaseAreaRates: Array<LeaseAreaRateModel> = []; 

    @DataProperty({instanceType:HolidayModel})
    public Holidays: Array<HolidayModel> =[]; 
    
    public get ServiceRequestDurationsComputed(): Array<ServiceRequestDurationsModel> {
        if (!!this.ServiceRequestDurations) {
            return this.ServiceRequestDurations
                .filter(p => p.ServiceType)
                .orderBy(p => p.ServiceType.Name)
                .groupBy(p => p.ServiceType.Id)
                .map((leaseAreaRequestDuration, serviceTypeId) => {
                    return <ServiceRequestDurationsModel>{
                        serviceType: leaseAreaRequestDuration
                            .map(p => p.ServiceType)
                            .find(p => p.Id === serviceTypeId),
                        requestDurations: leaseAreaRequestDuration
                            .orderBy(p => p.UserRole.Name)
                    };
                });
        }

        return [];
    }

    public set ServiceRequestDurationsComputed(value: Array<ServiceRequestDurationsModel>) {
        this.ServiceRequestDurations = (this.ServiceRequestDurations || []).filter(p => p.ServiceType);

        // Default the value
        value = value || [];

        if (!value.any()) {
            // No value... Clear out the property
            this.ServiceRequestDurations.length = 0; // resets the array...
            return;
        }

        value.forEach(duration => {
            duration.requestDurations
                .forEach(requestDuration => {
                    let byService = this.ServiceRequestDurations.find(p =>
                        p.ServiceType.Id === duration.serviceType.Id
                        && p.UserRole.Id === requestDuration.UserRole.Id);

                    if (!byService) {
                        this.ServiceRequestDurations.push(
                            // Little shortcut, here...
                            // Create the instance, set the 'byService' variable, and push it to the array...
                            byService = TypeConverter.convert(ServiceRequestDurationModel, {
                                UserRole: requestDuration.UserRole,
                                ServiceType: duration.serviceType
                            })
                        );
                    }

                    byService.DurationHours = requestDuration.DurationHours;
                });
        });
    }

    // Computed... No need for a container... The LeaseArea IS the container...
    // Projects an array based on the current lease area's hours.
    public get ServiceRequestHoursComputed(): Array<ServiceHoursModel> {
        if (!!this.ServiceRequestHours) {
            return this.ServiceRequestHours
                .filter(p => p.ServiceType)
                .orderBy(p => p.ServiceType.Name)
                .groupBy(p => p.ServiceType.Id)
                .map((leaseAreaHour, serviceTypeId) => {
                    return <ServiceHoursModel>{
                        serviceType: leaseAreaHour
                            .map(p => p.ServiceType)
                            .find(p => p.Id === serviceTypeId),
                        timeRanges: leaseAreaHour
                            .orderBy(p => p.DayOfWeek)
                            .map(p => p.timeRangeComputed)
                    };
                });
        }

        return [];
    }

    // Takes the incoming value and sets the lease area's hours value
    public set ServiceRequestHoursComputed(value: Array<ServiceHoursModel>) {
        // Default the property
        this.ServiceRequestHours = (this.ServiceRequestHours || []).filter(p => p.ServiceType);

        // Default the value
        value = value || [];

        if (!value.any()) {
            // No value... Clear out the property
            this.ServiceRequestHours.length = 0; // resets the array...
            return;
        }

        value.forEach(serviceHour => {
            serviceHour.timeRanges
                .forEach(timeRange => {
                    let byService = this.ServiceRequestHours.find(p =>
                        p.ServiceType.Id === serviceHour.serviceType.Id
                        && p.DayOfWeek === timeRange.dayOfWeek);

                    if (!byService) {
                        this.ServiceRequestHours.push(
                            // Little shortcut, here...
                            // Create the instance, set the 'byService' variable, and push it to the array...
                            byService = TypeConverter.convert(LeaseAreaServiceHourModel, {
                                LeaseAreaId: this.Id,
                                ServiceType: serviceHour.serviceType
                            })
                        );
                    }

                    // Pushes the timeRange value to the underlying lease area hour instance.
                    byService.timeRangeComputed = timeRange;
                });
        });
    }
}

export interface LeaseAreaFilter {
    Id?: number;
    Name?: string;
    Description?: string;
    CustomerName?: string;
    BuildingName?: string;
    TenantName?: string;
    LeaseName?: string;
    CustomerId?: number;
    BuildingId?: number;
    TenantId?: number;
    LeaseId?: number;
    FilterByService?: boolean;
}

export type LeaseAreaForStorage = Pick<LeaseAreaModel, 'Name' | 'Id'>;

export type LeaseAreaFilterState = CompositeType<LeaseAreaFilter, FilterState>;
