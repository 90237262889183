export enum OperationType {
    None = 0,
    Read = 1,
    Activate = 2,
    Deactivate = 3,
    Error = 4,
    Adhoc = 5
}

export const OperationTypeNames = new Map<number, string>([
    [OperationType.None, 'None'],
    [OperationType.Read, 'Read'],
    [OperationType.Activate, 'Activate'],
    [OperationType.Deactivate, 'Deactivate'],
    [OperationType.Error, 'Error'],
    [OperationType.Adhoc, 'Adhoc']
]);
