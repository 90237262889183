import { EntityModel } from '../../../../../_sdk/model';
import { CostTrendModel } from '../../_models/cost-trend.model';
import { MonthCostTrendModel } from '../../_models/month-cost-trend.model';
import { DataObject, DataProperty } from '../../../../../_core/type-converter';

@DataObject()
export class CostTrendPMModel extends EntityModel {
    public LastMonthTotal?: number = null;
    public LastYearTotal?: number = null;
    public MonthTotal?: number = null;
    public MonthVsLastMonth?: number = null;
    public YearTotal?: number = null;
    public YearVsLastYear?: number = null;

    @DataProperty()
    public YearCostTrends?: Array<CostTrendModel> = null;

    @DataProperty()
    public MonthCostTrends?: Array<MonthCostTrendModel> = null;


    constructor() {
        super();
    }
}
