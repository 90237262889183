import { EntityModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class UserRoleCategoryModel extends EntityModel {
    public Name: string = null;
    public SystemName: string = null;
}

export interface UserRoleCategoryFilter {
    Id?: number;
    Name?: string;
    SystemName?: string;
}

export type UserRoleCategoryFilterState = CompositeType<UserRoleCategoryFilter, FilterState>;
