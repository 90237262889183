import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { ProtocolTypeModel } from '../../../_sdk/ems-devices/protocol-type.model';
import * as ProtocolTypeActions from './protocol-type.actions';

export interface State {
    protocolTypes: ModelCollection<ProtocolTypeModel>;
}

const initialState: State = {
    protocolTypes: new ModelCollection<ProtocolTypeModel>()
};

export function reducer(
    state: State = initialState,
    action: ProtocolTypeActions.Actions
): State {

    let protocolTypes: ModelCollection<ProtocolTypeModel>;
    let index: number;

    switch (action.type) {
        case ProtocolTypeActions.DISPOSE:
            return {
                ...state,
                protocolTypes: initialState.protocolTypes
            };

        case ProtocolTypeActions.LOAD_COMPLETE:
            return {
                ...state,
                protocolTypes: action.payload
            };
        case ProtocolTypeActions.LOAD:
        case ProtocolTypeActions.INSERT:
        case ProtocolTypeActions.UPDATE:
        case ProtocolTypeActions.DELETE:
            return state;
        case ProtocolTypeActions.INSERT_COMPLETE:
            protocolTypes = new ModelCollection(state.protocolTypes.data
                .map(p => TypeConverter.convert(ProtocolTypeModel, p)));

            protocolTypes.data.push(action.payload);

            return {
                ...state,
                protocolTypes: protocolTypes
            };

        case ProtocolTypeActions.UPDATE_COMPLETE:
            protocolTypes = new ModelCollection(state.protocolTypes.data
                .map(p => TypeConverter.convert(ProtocolTypeModel, p)));

            index = protocolTypes.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                protocolTypes.data[index] = action.payload;
            }

            return {
                ...state,
                protocolTypes: protocolTypes
            };

        case ProtocolTypeActions.DELETE_COMPLETE:
            protocolTypes = new ModelCollection(state.protocolTypes.data
                .map(p => TypeConverter.convert(ProtocolTypeModel, p)));

            index = protocolTypes.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                protocolTypes.data.splice(index, 1);
            }

            return {
                ...state,
                protocolTypes: protocolTypes
            };

        default:
            return state;
    }
}
