import { Observable, Subscription, of } from 'rxjs';
import { take, map, tap, switchMap } from 'rxjs/operators';

import { BaseModel } from '../_sdk/model';
import { Cacheable } from './caching/cachable';
import { BaseEntityService } from './base.service';
import { ModelCollection } from '../_sdk/collection.model';
import { ApiService } from './api.service';
import { GlobalConfig } from '../_core/global.config';
import { FilterState } from '../_sdk/page-state';
import { notNull } from '../_core/rxjs.operators';

export abstract class CacheableEntityService<T extends BaseModel> extends BaseEntityService<T> implements Cacheable<ModelCollection<T>> {

    protected readonly defaultFilterState: FilterState = FilterState.defaultFilterState;

    private readonly _subscription: Subscription;

    private _cache$: Observable<ModelCollection<T>>;
    private _isInitialized: boolean;

    constructor(
        apiService: ApiService,
        protected readonly config: GlobalConfig,
    ) {
        super(apiService);

        console.log(this, this.id);
        
        this._subscription = new Subscription();
        this._isInitialized = false;
    }

    public abstract get source$(): Observable<ModelCollection<T>>;

    public abstract clear(): void;

    public refresh(): void {
        this.clear();
        this.fetch();
    }

    public dispose(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }

        this.clear();

        if (this._cache$) {
            this._cache$ = undefined;
        }
    }

    protected abstract beginLoadingData(): void;
    protected abstract endLoadingData(data: ModelCollection<T>): void;

    protected get cache$(): Observable<ModelCollection<T>> {
        if (!this._isInitialized) {
            this.initializeCache();
        }

        return this._cache$;
    }

    protected filter(request: FilterState, filterPredicate: (model: T) => boolean): Observable<ModelCollection<T>> {

        return this.cache$
            .pipe(
                notNull(),
                map(collection => {
                    let models = collection.data;
                    let total = collection.total;

                    if (request) {
                        models = models.filter(filterPredicate);

                        const start = request.pageIndex * request.pageSize;
                        const end = start + request.pageSize;
                        total = models.length;
                        models = models.slice(start, end);
                    }

                    return new ModelCollection(models, total);
                })
            );
    }

    protected initializeCache(): void {
        this._isInitialized = true;

        if (!this._cache$) {
            this.fetch();
        }

        this._cache$ = this.source$;
    }

    protected fetch(additionalData?: any): void {
        const request = Object.assign({
            pageIndex: 0,
            pageSize: Number.MAX_INT32 // this.config.intMaxValue
        }, additionalData);

        this._subscription.add(
            of(true)
                .pipe(
                    tap(() => this.beginLoadingData()),
                    switchMap(() => super.search(request).pipe(take(1))),
                    tap(data => this.endLoadingData(data))
                )
                .subscribe()
        );
    }
}
