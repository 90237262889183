import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FloatingLabelModule } from '@progress/kendo-angular-label';

import { DateTimePickerComponent } from './datetime-picker/datetime-picker.component';
import { InputTouchDirective } from './directives/input-touch.directive';
import { KendoTouchDirective } from './directives/kendo-touch.directive';
import { NumericDirective } from './directives/numeric.directive';
import { GeneaInputDirective } from './genea-input.directive';
import { GeneaLabelDirective } from './genea-label.directive';
import { SearchInputComponent } from './search-input/search-input.component';
import { SmallDateDatePickerComponent } from './smalldate-datepicker/smalldate-datepicker.component';
import { TimeRangePickerComponent } from './time-range-picker/time-range-picker.component';
import { TimeZoneDropDownComponent } from './time-zone-dropdown/time-zone-dropdown.component';
import { TimeSpanTimePickerComponent } from './timespan-timepicker/timespan-timepicker.component';
import { TriStateSwitchComponent } from './tri-state-switch/tri-state-switch.component';

@NgModule({
    declarations: [
        GeneaInputDirective,
        GeneaLabelDirective,

        InputTouchDirective,
        KendoTouchDirective,
        NumericDirective, 
        
        DateTimePickerComponent,
        SmallDateDatePickerComponent,
        TimeZoneDropDownComponent,
        TimeSpanTimePickerComponent,
        TimeRangePickerComponent,
        SearchInputComponent,
        TriStateSwitchComponent
    ],
    imports: [
        // Ng
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        
        // Kendo
        InputsModule,
        DateInputsModule,
        DropDownsModule,
        FloatingLabelModule
    ],
    exports: [
        // Kendo
        InputsModule,
        DateInputsModule,
        DropDownsModule,
        FloatingLabelModule,

        GeneaInputDirective,
        GeneaLabelDirective,
        
        InputTouchDirective,
        KendoTouchDirective,
        NumericDirective, 
        
        DateTimePickerComponent,
        SmallDateDatePickerComponent,
        TimeZoneDropDownComponent,
        TimeSpanTimePickerComponent,
        TimeRangePickerComponent,
        SearchInputComponent,
        TriStateSwitchComponent
    ],
})
export class GeneaInputsModule { 
    static exports(): Array<any> {
        return [
            GeneaInputDirective,
            GeneaLabelDirective,

            InputTouchDirective,
            KendoTouchDirective,
            NumericDirective, 
            
            DateTimePickerComponent,
            SmallDateDatePickerComponent,
            TimeZoneDropDownComponent,
            TimeSpanTimePickerComponent,
            TimeRangePickerComponent,
            SearchInputComponent,
            TriStateSwitchComponent
        ]
    }
}
