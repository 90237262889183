import { Component, forwardRef, Inject, Input, Optional, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, NgModel, NG_ASYNC_VALIDATORS, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePickerComponent, TimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { BehaviorSubject } from 'rxjs';
import { delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseInputElement } from '../../../../_core/components/forms/base-input-element';
import { AsyncValidatorArray, ValidatorArray } from '../../../../_core/components/forms/validate';
import { DateTime, SmallDate, TimeSpan } from '../../../../_core/date';
import { notNull } from '../../../../_core/rxjs.operators';

const minValue: Date = new Date(1900, 1, 1, 0, 0, 0);
const maxValue: Date = new Date(2099, 12, 31, 23, 59);

let _identifier = 0;

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['./datetime-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true
        }
    ]
})
export class DateTimePickerComponent extends BaseInputElement<DateTime> {

    public readonly identifier = `datetime-picker-${_identifier++}`;

    public readonly editForm: FormGroup;

    private readonly _datePicker$ = new BehaviorSubject<DatePickerComponent>(null);
    private readonly _timePicker$ = new BehaviorSubject<TimePickerComponent>(null);

    private _min: DateTime;
    private _max: DateTime;

    @ViewChild(NgModel)
    public model: NgModel;

    @ViewChild(DatePickerComponent)
    private get _datePicker(): DatePickerComponent {
        return this._datePicker$.getValue();
    }
    private set _datePicker(value: DatePickerComponent) {
        this._datePicker$.next(value);
    }

    @ViewChild(TimePickerComponent)
    private get _timePicker(): TimePickerComponent {
        return this._timePicker$.getValue();
    }
    private set _timePicker(value: TimePickerComponent) {
        this._timePicker$.next(value);
    }

    @Input()
    public readonly: boolean;

    @Input()
    public timezone: string;

    @Input()
    public get min(): Date | DateTime {
        return this._min || minValue;
    }
    public set min(value: Date | DateTime) {
        if (!value) {
            return;
        }

        const normalized = this.normalizeValue(value);

        this._min = normalized;
    }

    @Input()
    public get max(): Date | DateTime {
        return this._max || maxValue;
    }
    public set max(value: Date | DateTime) {
        if (!value) {
            return;
        }

        const normalized = this.normalizeValue(value);

        this._max = normalized;
    }

    public get jsMin(): Date {
        return this._min && this._min.date.toDate();
    }

    public get jsMax(): Date {
        return this._max && this._max.date.toDate();
    }

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: ValidatorArray,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorArray,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder) {
        super(validators, asyncValidators);

        this.editForm = this.formBuilder.group({
            date: null,
            time: null
        });
    }

    protected bindValue(value: DateTime): void {
        const normalized = this.normalizeValue(value);

        this.editForm.patchValue({ date: (normalized && normalized.toDate()), time: (normalized && normalized.toDate()) }, { emitEvent: false });

        try {
            this.changeDetector.detectChanges();
        } catch (_) { }
    }

    public onDateValueChange(value: Date): void {
        this.value = new DateTime(value).setTime((this.value || DateTime.now()).timeOfDay);
    }

    public onTimeValueChange(value: Date): void {
        const time = this.normalizeValue(value).timeOfDay;
        const computed = (this.value || DateTime.now()).setTime(time);

        const equals = (a: TimeSpan, b?: TimeSpan) => {
            if(!b) {
                return false;
            }

            return String.equals(a.format('HHmm'), b.format('HHmm'));
        };

        // Now, we can truly check to see if the value === now.
        this.value = equals(time, TimeSpan.now())
            ? computed.timezone(this.timezone)
            : computed;
           
        this.editForm.get('time').patchValue(this.value.toDate(), { emitEvent: false });
    }

    public setDisabledState(isDisabled: boolean): void {
        this._datePicker.setDisabledState(isDisabled);
        this._timePicker.setDisabledState(isDisabled);
        super.setDisabledState(isDisabled);
    }

    private normalizeValue(value: Date | SmallDate | DateTime | string | number): DateTime {
        if (!value) {
            return null;
        }

        const result = DateTime.convert(value);

        // Remove all seconds/milliseconds
        return new DateTime({year: result.date.year, month: result.date.month, day: result.date.day, hour: result.timeOfDay.hours, minute: result.timeOfDay.minutes, second: 0, millisecond: 0});
    }
}
