
// http://cloudmark.github.io/Data-Mapping/
// http://cloudmark.github.io/Json-Mapping/

import 'reflect-metadata';

import { TimeSpan, SmallDate, DateTime } from './date';
import { Type } from '@angular/core';

const dataObjectMetadataKey = 'genea:design:properties';
const dataPropertyMetadataKey = 'genea:design:types';

function getInstanceType(target: any, propertyKey: string): any {
    return Reflect.getMetadata('design:type', target, propertyKey);
}

function getDataType<T>(target: any, propertyKey: string): DataTypesMetaData<T> {
    return Reflect.getMetadata(dataPropertyMetadataKey, target, propertyKey);
}

export interface DataTypesMetaData<T> {
    name?: string;
    instanceType?: Type<T>;
    converter?: (data: any) => T;
}

const propertyMap: Map<string, Array<string>> = new Map();

function reflectProperties<T extends { new() }>(target: T): Array<string> {
    const instance = new target();
        
    const instancePrototype = Object.getPrototypeOf(instance);
    const instancePrototypeProperties = Object.getOwnPropertyNames(instancePrototype);

    /*
        Little trick, here...
        Some models with properties may have getters/setters instead of regular properties
        We want to access those values, too. The below code allows us to access those properties...

        Example:
            export class SomeModel {
                private _myProperty: string;

                constructor() {
                    this._myProperty = 'Hello World';
                }

                public get myProperty(): string {
                    return this._myProperty;
                }

                public set myProperty(value: string) {
                    if(value !== this._myPoperty) {
                        this._myProperty = value;
                        this.doSomething();
                    }
                }

                private doSomething(): void {
                    // Do some computation...
                }
            }

        We can create an instance using the type converter like this...
            const instance = TypeConverter.convert(SomeModel, { myProperty: 'Good-bye World!' });

        In this case, SomeModel has a getter/setter property of 'myProperty'
        But, the source object has a plain-old property of 'myProperty'

        This is completely valid and correct. The caveat is SomeModel.myProperty is NOT a valid 'key' and source.myProperty is!

        If we want to pass the value of 'myProperty' from the source to the destination instance, we'll need to 'reflect'
        on SomeModel's prototype and find all properties that are getter/setters AND are configurable (can be set) AND are enumerable (public).

        Once we have these, we can use Object.keys(instanceOfSomeModel) and concat the matching getter/setter properties to assign values.
    */
    const gettersAndSetters = instancePrototypeProperties
        .filter(name => {
            const result = Object.getOwnPropertyDescriptor(instancePrototype, name);
            return !!result.set && !!result.get && result.configurable; // && result.enumerable;
        });

    const properties = Object.keys(instance).concat(gettersAndSetters);

    return properties;
}

export function DataObject(): Function {
    return <T extends { new() }>(target: T) => {
        const properties = reflectProperties(target);

        Reflect.defineMetadata(dataObjectMetadataKey, properties, target)

        return target;
    };
}

/*
USAGE:

Example 1: No parameters
    @DataProperty()
    public RegisteredDate: Date = null;

Example 2: Specify converter (function)
    @DataProperty({ converter: dateTimeConverter })
    public RegisteredDate: Date = null;

    @DataProperty({ converter: Date.convert })
    public RegisteredDate: Date = null;

Example 3: Specify converter and provide options to the converter
    @DataProperty({ converter: (data) => dateConverter(data, { localize: false }) })
    public RegisteredDate: Date = null;

    @DataProperty({ converter: (data) => Date.convert(data, { localize: false }) })
    public RegisteredDate: Date = null;

Example 4: Specify type (most common)
    @DataProperty({ instanceType: AddressModel })
    public Address: AddressModel = null;

    @DataProperty({ instanceType: CustomerModel })
    public Customer: CustomerModel = null;

Note about Date and TimeSpan...
    JSON dates and timespans are strings...

    If you want a JSON date to be converted to a javascript Date object, at minimum
        you must use the DataProperty() decorator... Parameters are optional...

    If you want a TimeSpan object, at minimum, you must use the DataProperty() decorator.
        Again, parameters are optional...

    If you do not use the DataProperty() decorator, the converted value will remain a string
        even if the property type is Date/TimeSpan...

    // See the date.ts file for Date and TimeSpan conversion logic
*/
export function DataProperty<T>(metadata?: DataTypesMetaData<T> | Function | string): any {

    if (metadata instanceof String || typeof metadata === 'string') {
        return Reflect.metadata(dataPropertyMetadataKey, {
            name: metadata,
            instanceType: undefined,
            converter: undefined
        });
    }

    if (metadata instanceof Function || typeof metadata === 'function') {
        return Reflect.metadata(dataPropertyMetadataKey, {
            name: undefined,
            instanceType: metadata,
            converter: undefined
        });
    }

    return Reflect.metadata(dataPropertyMetadataKey, {
        name: metadata && metadata.name,
        instanceType: metadata && metadata.instanceType,
        converter: metadata && metadata.converter
    });
}

/*

USAGE

const userObject = JSON.parse('...json...');
const user = DataConverter.convert(UserModel, userObject);

const customerObject = JSON.parse('...json...');
const customer = DataConverter.convert(CustomerModel, customerObject);

const awesomeObject = JSON.parse('...json...');
const awesome = DataConverter.convert(AwesomeClass, awesomeObject);

*/

// TODO: Move away from a static class and refactor to pure functions.
// TypeConverter works for what we're doing, but it's considered "old-school"
export class TypeConverter {
    public static hasValue(obj: any): boolean {
        return !TypeConverter.isNull(obj);
    }

    public static isNull(obj: any): boolean {
        return obj === null || obj === undefined;
    }
 
    public static isString(obj: any): obj is string {
        return String.isString(obj);
    }

    public static isNumber(obj: any): obj is number {
        switch (typeof obj) {
            case 'number':
                return true;
        }

        return !!(obj instanceof Number || obj === Number);
    }

    public static isBoolean(obj: any): obj is boolean {
        switch (typeof obj) {
            case 'boolean':
                return true;
        }

        return !!(obj instanceof Boolean || obj === Boolean);
    }

    public static isPrimitive(obj: any): boolean {
        return TypeConverter.isString(obj) || TypeConverter.isNumber(obj) || TypeConverter.isBoolean(obj);
    }

    public static isArray(obj: any): obj is any[] {
        if (obj === Array) {
            return true;
        } else if (typeof Array.isArray === 'function') {
            return Array.isArray(obj);
        }

        return !!(obj instanceof Array);
    }

    public static isDate(obj: any): obj is Date {
        if (obj === Date) {
            return true;
        }

        return !!(obj instanceof Date);
    }

    public static isSmallDate(obj: any): obj is SmallDate {
        if (obj === SmallDate) {
            return true;
        }

        return !!(obj instanceof SmallDate);
    }

    public static isDateTime(obj: any): obj is DateTime {
        if (obj === DateTime) {
            return true;
        }

        return !!(obj instanceof DateTime);
    }

    public static isTimeSpan(obj: any): obj is TimeSpan {
        if (obj === TimeSpan) {
            return true;
        }

        return !!(obj instanceof TimeSpan);
    }

    public static convert<T>(destinationType: Type<T>, source: Partial<T>): T {
        if ((destinationType === undefined) || (source === undefined)) {
            return undefined;
        }

        let properties: Array<string> = Reflect.getMetadata(dataObjectMetadataKey, destinationType);

        if(!properties) {
            console.warn(`Type [${destinationType.name}] does not have [${dataObjectMetadataKey}] metadata applied. Make sure the destinationType uses the "DataObject() attribute."`);
            properties = reflectProperties(destinationType);
        }

        const instance = new destinationType();

        // Note: The ONLY way we can get the properties from a 'Partial<T>' is to force it to be of type 'any'
        const sourceRef: any = <any>source;

        properties.forEach(property => {
            const propertyMetadataFn: (_) => any = (propMetaData: DataTypesMetaData<any>) => {

                const propertyName = propMetaData.name || property;
                const innerObject = sourceRef && sourceRef[propertyName];
                const instanceType = getInstanceType(instance, property);

                if (propMetaData && propMetaData.converter) {
                    return propMetaData.converter(innerObject);
                }

                if (TypeConverter.isArray(instanceType)) {
                    const metadata = getDataType(instance, property);
                    if (metadata.instanceType || TypeConverter.isPrimitive(instanceType)) {
                        if (innerObject && TypeConverter.isArray(innerObject)) {
                            return innerObject
                                .map(item => TypeConverter.convert(metadata.instanceType, item));
                        }

                        return [];
                    }

                    return innerObject;
                }

                if (TypeConverter.isDate(instanceType)) {
                    return Date.convert(innerObject);
                }

                if (TypeConverter.isSmallDate(instanceType)) {
                    return SmallDate.convert(innerObject);
                }

                if (TypeConverter.isDateTime(instanceType)) {
                    return DateTime.convert(innerObject);
                }

                if (TypeConverter.isTimeSpan(instanceType)) {
                    return TimeSpan.convert(innerObject);
                }

                if (innerObject && !TypeConverter.isPrimitive(instanceType)) {
                    return TypeConverter.convert(instanceType, innerObject);
                }

                return innerObject;
            };

            const propertyMetadata = getDataType(instance, property);

            if (propertyMetadata) {
                instance[property] = propertyMetadataFn(propertyMetadata);
            } else {
                if (sourceRef && sourceRef[property] !== undefined) {
                    instance[property] = sourceRef[property];
                }
            }
        });

        return instance;
    }

    public static toNumber(value: string | number | null): number | null {
        if(TypeConverter.isNull(value)) {
            return null;
        }

        const asNum = +value;

        if(isNaN(asNum)) {
            return null;
        }

        return asNum;
    }

    public static toBoolean(value: boolean | number | string | null): boolean {
        switch (value) {
            case true:
            case 1:
                return true;
            case false:
            case 0:
            case null:
            case undefined:
            case NaN:
                return false;
        }

        if (TypeConverter.isString(value)) {
            switch (String.trimOrEmpty(value).toLowerCase()) {
                case '1':
                case 'true':
                case 'yes':
                    return true;
                case '':
                case '0':
                case 'false':
                case 'no':
                case 'null':
                case 'undefined':
                    return false;
            }
        }

        return false;
    }

    public static mapToObject<K, V>(value: Map<K, V>): any {
        const output = {};

        value.forEach((v, k) => {
            if (!!v && TypeConverter.isPrimitive(k)) {
                // Make sure to go deep.
                output[k.toString()] = (v instanceof Map)
                    ? TypeConverter.mapToObject(v)
                    : v;
            }
        });

        return output;
    }

    public static objectToMap(value: any): Map<string, any> {
        const output = new Map<string, any>();

        if (!!value) {
            Object.entries(value)
                .iterate(e => {
                    output.set(e[0], e[1]);
                });
        }

        return output;
    }

    public static mapToJson<K, T>(map: Map<K, T>) {
        if (!!map) {
            const obj: any = {};

            map.forEach((v, k) => {
                if (!!v) {
                    obj[k] = v;
                }
            });

            return JSON.stringify(obj);
        }

        return '';
    }

    public static jsonToMap(json: string): Map<string, any> {
        return TypeConverter.objectToMap(JSON.parse(json));
    }

    public static cloneDeep(target: any, ...sources): any {
        return cloneDeep(target, sources);
    }

    // NOTE: Very simple equality comparer with limitations...
    // http://adripofjavascript.com/blog/drips/object-equality-in-javascript.html
    public static isEquivalent(a: any, b: any): boolean {
        if (TypeConverter.isNull(a) && TypeConverter.isNull(b)) {
            return true;
        }

        if (TypeConverter.isNull(a) || TypeConverter.isNull(b)) {
            return false;
        }

        if (TypeConverter.isPrimitive(a) && TypeConverter.isPrimitive(b)) {
            return a === b;
        }

        // Create arrays of property names
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            const propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }

    public static mapEnum(_: object): Map<number, string> {
        const result = new Map();

        Object.keys(_)
            .filter(k => TypeConverter.isNumber(_[k as any]))
            .forEach(k => {
                const key = +_[k as any];
                const value = k;
                result.set(key, value);
            });

        return result;
    }
}

export type CompositeType<T1, T2> = T1 & T2;

// https://www.typescriptlang.org/docs/handbook/advanced-types.html
// Look for "type NonFunctionPropertyNames"
export type NonFunctionPropertyNames<T> = { [K in keyof T]: T[K] extends Function ? never : K }[keyof T];

export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

// Makes a deep clone of an object or objects in sources and attaches them in target.
// Copies functions as well as properties.
export function cloneDeep(target: any, ...sources): any {
    if (!(sources && sources.any())) {
        return target;
    }

    sources.forEach(source => {
        const result = Object.keys(source).reduce((descriptors, key) => {
            descriptors[key] = Object.getOwnPropertyDescriptor(source, key);
            return descriptors;
        }, {});

        Object.defineProperties(target, result);
    });

    return target;
}
