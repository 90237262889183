import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../../../_core/components/base.component';
import { Router } from '@angular/router';
import { BuildingInvoiceService } from '../_services/building-invoice.service';
import { distinctUntilChanged, take, tap } from 'rxjs/operators';
import { LoadingIndicatorDirective } from '../../../../../_modules/common-ui/loading-indicator/loading-indicator.directive';
import { BehaviorSubject, Observable } from 'rxjs';
import { notNull } from '../../../../../_core/rxjs.operators';
import { ViewUtilityBillUploadList } from '../_models/utility-bill-upload-list.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../../_services/auth/auth.service';
import { EnumHelperService } from '../../../metering/_services/helpers/enum-helper.service';
import { BlobService } from '../../../../../_services/blob.service';
import { GlobalConfig } from '../../../../../_core/global.config';
import { DateTime } from '../../../../../_core/date';
import { ToastrService } from '../../../../../_modules/toastr';

@Component({
  selector: 'app-view-utility-bill',
  templateUrl: './view-utility-bill.component.html',
  styleUrls: ['./view-utility-bill.component.scss']
})
export class ViewUtilityBillComponent extends BaseComponent implements OnInit {

  @ViewChild(LoadingIndicatorDirective, { static: true })
  private loadingIndicator: LoadingIndicatorDirective;
  public ShowCloseButton: boolean = false;
  public hideIncompleteButton: boolean = false;

  private process<T>(stream: Observable<T>): Observable<T> {
    if (this.loadingIndicator) {
      return this.loadingIndicator.process(stream);
    }

    return stream;
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  public utilityBillingFileForm: FormGroup;

  public drawerExpanded = true;
  public miniValue = true;
  public selectedFile = '';

  public objectKeys = Object.keys;
  public _error$ = new BehaviorSubject<{ status: number, errorMessage: string }>(null);
  public error$ = this._error$.asObservable().pipe(notNull());

  private readonly _isLoading$ = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoading$.asObservable().pipe(notNull());

  private readonly _utilityBillData$ = new BehaviorSubject<ViewUtilityBillUploadList>(null);
  public readonly utilityBillData$ = this._utilityBillData$.asObservable().pipe(notNull());

  private readonly _isContentLoading$ = new BehaviorSubject<boolean>(true);
  public readonly isContentLoading$ = this._isContentLoading$.asObservable().pipe(notNull());

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public enumServiceHelper: EnumHelperService,
    private readonly authService: AuthService,
    private readonly blobservice: BlobService,
    private readonly config: GlobalConfig,
    protected readonly toastrService: ToastrService,
    public invoiceService: BuildingInvoiceService,
    private readonly blobService: BlobService
  ) {
    super();
    if (this.isAdmin) {
      this.utilityBillingFileForm = this.formBuilder.group({
        'UtilityBillId': [null],
        'BuildingName': [null],
        'Type': [null],
        'Provider': [null],
        'AccountNumber': [null],
        'ProviderMeterNumber': [null, [Validators.required]],
        'TotalUsage': [null, [Validators.required]],
        'TotalCharges': [null, [Validators.required]],
        'StartDate': [null],
        'EndDate': [null],
        'Comment': [null],
        'Notes': [null],
        'Bills': [null, [Validators.required]],
        'Status': [null]
      });
    }
    else {
      this.utilityBillingFileForm = this.formBuilder.group({
        'UtilityBillId': [null],
        'BuildingName': [null],
        'Type': [null],
        'Provider': [null],
        'AccountNumber': [null],
        'StartDate': [null],
        'EndDate': [null],
        'Comment': [null],
        'Bills': [null, [Validators.required]],
        'Status': [null]
      });
    }
  }

  ngOnInit(): void {
    if (this.router.url.includes('utility-bill')) {
      this.addSubscription(
        this.process(
          this.invoiceService.getUtilityBillDetailsData(
            this.router.parseUrl(this.router.url).root.children.primary.segments[2].path,
            this.router.parseUrl(this.router.url).root.children.primary.segments[3].path)
            .pipe(
              tap(
                (response) => {
                  if(response.Status === 4){
                    this.ShowCloseButton = true;
                  }
                  else if(response.Status === 3){
                    this.hideIncompleteButton = true;
                  }
                  this._utilityBillData$.next(response);
                  this._isContentLoading$.next(false);
                  this.loadUtilityBillFile(this.objectKeys(response.Files)[0], Object.values(response.Files)[0]);
                }
              )
            )
        )
      )
      this.addSubscription(
        this.utilityBillData$
          .pipe(
            tap((data) => {
              this.utilityBillingFileForm.patchValue({
                UtilityBillId: data.Id,
                BuildingName: data.BuildingName,
                Type: this.enumServiceHelper.getUtilityTypeName(data.Type),
                Provider: data.Provider,
                AccountNumber: data.AccountNumber,
                ProviderMeterNumber: data.ProviderMeterNumber,
                StartDate: new Date(data.StartDate?.toString()),
                EndDate: new Date(data.EndDate?.toString()),
                Bills: data.Files,
                Comment: data.Comment,
                Notes: data.Notes,
                TotalUsage: this.transformFromAPI(data.TotalUsage,false),
                TotalCharges: this.transformFromAPI(data.TotalCharges,true)
              });
            })
          )
      )
    }
  }

  public get isAdmin(): boolean {
    return this.authService.hasRole(this.RoleConstant.Administrators);
  }

  public uploadUtilityBillFile(): void {
    this.fileInput.nativeElement.click();
  }
  public onFileChange(event: any): void {
    const files = event.target.files;
    this.utilityBillingFileForm.patchValue({ Bills: files });
  }
  public loadUtilityBillFile(id, name): void {
    let uri = 'invoicing/metering/utility-bill/file/' + id;
    this.selectedFile = id;
    this._isLoading$.next(true);
    if (name.includes('.xlsx') || name.includes('.csv')) {
      let error = {
        status: 500,
        errorMessage: "Preview not available"
      }
      var myIframe = document.getElementById('pdfviewer');
      myIframe.setAttribute("hidden", 'true');
      var utilityBillImage = document.getElementById('utilityBillImageContainer');
      utilityBillImage?.remove();
      this._error$.next(error);
      this._isLoading$.next(false);
      return;
    }
    else {
      let error = {
        status: 0,
        errorMessage: ""
      }
      this._error$.next(error);
      var myIframe = document.getElementById('pdfviewer');
      myIframe.removeAttribute("hidden");
      var utilityBillImage = document.getElementById('utilityBillImage');
      utilityBillImage?.remove();
    }
    if (name.includes('.jpeg') || name.includes('.jpg') || name.includes('.png')) {
      //TODO: Add element as image and hide pdf viewer and approve and incomplete button code
      let error = {
        status: 0,
        errorMessage: ""
      }
      this._error$.next(error);
      var utilityBillImage = document.getElementById('utilityBillImageContainer');
      utilityBillImage?.remove();
      this.addSubscription(
        this.blobservice.retrieve(uri).pipe(
          notNull(),
          take(1),
          tap(
            data => {
              var container = document.getElementById('pdf-container').appendChild(document.createElement('div'));
              container.setAttribute("id", "utilityBillImageContainer");
              container.setAttribute("style", "max-width: 100%;height: 90vh;,overflow:auto;");
              var img = container.appendChild(document.createElement('img'));
              img.setAttribute("id", "utilityBillImage");
              img.setAttribute("src", URL.createObjectURL(new Blob([data.body])));
              img.setAttribute("style", "width: 100%; height: 100%; object-fit: contain;");
              this._isLoading$.next(false);
            },
            (err) => {
              let error: { status: number, errorMessage: string };
              debugger;
              if (err.innerError.status == 404) {
                error = {
                  errorMessage: "Utility Bill not found",
                  status: 404
                }

              } else {
                error = {
                  status: 500,
                  errorMessage: "Couldn't load Utility Bill, Please try again"
                }
              }

              this._error$.next(error);
              this._isLoading$.next(false);
            }
          )
        ))
      var myIframe = document.getElementById('pdfviewer');
      myIframe.setAttribute("hidden", 'true');
    }
    else {
      var myIframe = document.getElementById('pdfviewer');
      myIframe.removeAttribute("hidden");
      var utilityBillImage = document.getElementById('utilityBillImageContainer');
      utilityBillImage?.remove();
    }
    this.addSubscription(
      this.blobservice.retrieve(uri).pipe(
        notNull(),
        take(1),
        tap(
          data => {
            var myIframe = document.getElementById('pdfviewer');

            //older Safari having issue with rendering blob 
            //check if safari browser then  pass base url to load direct else add blob url to iframe src.   
            if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
              myIframe.setAttribute("data", this.config.api('platform').baseUri + uri + '#zoom=168');

            } else {
              let zoom;
              if (window.innerWidth < 1600) {
                zoom = 120;
              }
              else if (window.innerWidth > 1900) {
                zoom = 165
              }
              myIframe.setAttribute("data", URL.createObjectURL(new Blob([data.body], { type: 'application/pdf' })) + '#zoom=' + zoom);
            }

            this._isLoading$.next(false);
          },
          (err) => {
            let error: { status: number, errorMessage: string };
            debugger;
            if (err.innerError.status == 404) {
              error = {
                errorMessage: "Utility Bill not found",
                status: 404
              }

            } else {
              error = {
                status: 500,
                errorMessage: "Couldn't load Utility Bill, Please try again"
              }
            }

            this._error$.next(error);
            this._isLoading$.next(false);
          }
        )
      )
    )
  }
  public changeUtilityBillStatus(status): void {
    if (this.utilityBillingFileForm.valid) {
      this._isContentLoading$.next(true)
      let formData = new FormData();
      formData.set('UtilityBillId', this.utilityBillingFileForm.value.UtilityBillId);
      formData.set('StartDate', this.utilityBillingFileForm.value.StartDate.toLocaleDateString("en-US"));
      formData.set('EndDate', this.utilityBillingFileForm.value.EndDate.toLocaleDateString("en-US"));
      formData.set('Status', status);
      formData.set('Comment', this.utilityBillingFileForm.value.Comment);
      formData.set('Notes', this.utilityBillingFileForm.value.Notes);
      formData.set('TotalUsage', this.utilityBillingFileForm.value.TotalUsage);
      formData.set('TotalCharges', this.formatAmountForApi(this.utilityBillingFileForm.value.TotalCharges.toString()).toString());
      formData.set('ProviderMeterNumber', this.formatAmountForApi(this.utilityBillingFileForm.value.ProviderMeterNumber.toString()).toString());
      this.toastrService.subscribeAndShowResult(
        this.invoiceService.postUtilityBillUploadList(formData)
      ).add(() => {
        this._isContentLoading$.next(false)
        if(status === 3){
          this.hideIncompleteButton = true;
        }else{
          this.ShowCloseButton = true;
        }
      });
    }
    else{
      this.utilityBillingFileForm.markAllAsTouched();
    }
  }
  public closeTab(){
    close();
  }
  public downloadFile(Id,name){
    const downloadUrl = `invoicing/metering/utility-bill/file/download/${Id}`;
    this._isContentLoading$.next(true);
    this.addSubscription(this.blobService.retrieve(downloadUrl).pipe(
      distinctUntilChanged(),
      tap(
        (response) => {
          const dataResponse = response.headers;
          const blob = new Blob([response.body], { type: dataResponse.get('content-type') });
          const fileName = dataResponse.get('content-disposition').split(';')[1].split('filename')[1]?.split('=')[1]?.replace(/\"/g, '');
          
          if (!fileName) {
            this.toastrService.error("File not found.");
            return;
          }
          
          const blobUrl = URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = blobUrl;
          anchor.download = name;
          anchor.click();
          
          URL.revokeObjectURL(blobUrl);
          this.toastrService.success(`File ${name} downloaded successfully.`);
          this._isContentLoading$.next(false);
        },
        (error) => {
          if(error?.innerError?.status === 401) {
            this.router.navigate(['/login']);
          }
        }
      )
    ));
  }
  transformAmount(event: KeyboardEvent,isCurreny: boolean): void {
    let inputValue = (event.target as HTMLInputElement).value;
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(event.key)) {
      return;
    }
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    let [integerPart, decimalPart] = inputValue.split('.');
    integerPart = this.addCommasToInteger(integerPart);
    if (decimalPart) {
      if(isCurreny){
        decimalPart = decimalPart.substring(0, 2);
      }
      else{
        decimalPart = decimalPart.substring(0, 6);
      }
    }
    if(inputValue.contains('.')){
      integerPart = integerPart + '.';
    }
    let formattedValue = '';
    if(isCurreny){
      formattedValue = '$' + integerPart + (decimalPart ? decimalPart : '');
    }
    else{
      formattedValue = integerPart + (decimalPart ? decimalPart : '');
    }
    (event.target as HTMLInputElement).value = formattedValue;
  }
  addCommasToInteger(integer: string): string {
    let result = '';
    let counter = 0;
    for (let i = integer.length - 1; i >= 0; i--) {
      if (counter && counter % 3 === 0) {
        result = ',' + result; //
      }
      result = integer[i] + result;
      counter++;
    }

    return result;
  }
  formatAmountForApi(amount): number {
    return parseFloat(amount.replace(/[^0-9.-]+/g, ''));
  }
  transformFromAPI(amount,isCurreny: boolean): string {
    if(!amount){
      return
    }
    let amountString = amount.toString();
    let [integerPart, decimalPart] = amountString.split('.');

    // Format the integer part by adding commas
    integerPart = this.addCommasToInteger(integerPart);

    if(isCurreny){
      return '$' + integerPart + (decimalPart ? '.' + decimalPart : '');
    }
    else{
      return integerPart + (decimalPart ? '.' + decimalPart : '');
    }
  }
}
