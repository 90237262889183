import { Directive, Input } from "@angular/core";
import { BaseDirective } from '../../../_core/components/base.directive';

@Directive({
    selector: 'kendo-tabstrip-tab'
})
export class GeneaTabStripTabDirective extends BaseDirective {

    @Input()
    public tabName: string;

    /* @Input()
    public defaultTab: boolean = false; */

    public OnInit(): void {

       /*  if (this.defaultTab) {
            this.tabStripTab.selected = true;

            this.addSubscription(
                this.route.queryParams
                    .pipe(
                        map(params => params.tab),
                        distinctUntilChanged(),
                        tap(tab => {

                            const isNull = tab === null || tab === undefined;

                            const selected = (isNull && this.defaultTab);

                            if(selected === true) {
                                this.tabStripTab.selected = true;
                            }
                        })
                    )
            );

        } */

        super.OnInit();
    }
}