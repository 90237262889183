import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GeneaColumnLayoutColumnDirective } from './column-layout-column.directive';
import { GeneaTwoColumnLayoutComponent } from './two-column-layout.component';

@NgModule({
    declarations: [
        
        GeneaTwoColumnLayoutComponent,
        GeneaColumnLayoutColumnDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        GeneaTwoColumnLayoutComponent,
        GeneaColumnLayoutColumnDirective
    ]
})
export class GeneaTwoColumnLayoutModule {
    static exports(): Array<any> {
        return [
            GeneaTwoColumnLayoutComponent,
            GeneaColumnLayoutColumnDirective
        ];
    }
 }
