import { Input, EventEmitter, Output, ViewChild, Injector, Directive } from '@angular/core';
import { NgModel, FormControl, NgControl, NG_VALIDATORS } from '@angular/forms';

import { Observable, BehaviorSubject, interval } from 'rxjs';
import { take, tap, switchMap, first } from 'rxjs/operators';

import { BaseInputElement } from './forms/base-input-element';
import { ValidatorArray, AsyncValidatorArray } from './forms/validate';
import { hasRequiredField } from './forms/form-helper';
import { TypeConverter } from '../type-converter';
import { notEmpty } from '../rxjs.operators';
import { DropDownItem } from '../../_modules/common/inputs';

let identifier = 0;
export const defaultItem: DropDownItem = { Id: null, Name: '-- Select --' };

@Directive()
export abstract class BaseDropDown extends BaseInputElement<DropDownItem> {
    // The control will be injected so we can get details about its properties
    private controlRef: FormControl;

    private _data$: Observable<Array<DropDownItem>>;

    private readonly _defaultItem$: BehaviorSubject<DropDownItem>;
    public readonly defaultItem$: Observable<DropDownItem>;

    @ViewChild(NgModel)
    public model: NgModel;

    @Input()
    public valueField = 'Id';

    @Input()
    public textField = 'Name';

    @Input()
    public get defaultText(): string {
        return this._defaultItem$.getValue().Name;
    }
    public set defaultText(value: string) {
        this._defaultItem$.next({ Id: null, Name: value });
    }

    @Input()
    public valuePrimitive = false;

    @Output()
    public valueChange: EventEmitter<DropDownItem>;

    @Input()
    public loading: boolean;

    public readonly identifier = `drop-down-${identifier++}`;

    constructor(
        validators: ValidatorArray,
        asyncValidators: AsyncValidatorArray) {
        super(validators, asyncValidators);

        this.valueChange = new EventEmitter();

        this._defaultItem$ = new BehaviorSubject(defaultItem);
        this.defaultItem$ = this._defaultItem$.asObservable();

        this.loading = false;
    }

    OnInit() {
        this._data$ = this.dataProvider();

        super.OnInit();
    }

    // https://stackoverflow.com/a/51126965
    // The form control is only set after initialization
    AfterViewInit(): void {

        if (this.model && this.model.control) {
            this.controlRef = this.model.control;

            // If the value is required, then let's select the first element
            let hasRequired = false;

            hasRequiredField(this.controlRef)
                .pipe(
                    take(1),
                    tap(required => {
                        hasRequired = required;
                    })
                );

            if (hasRequired) {
                this.addSubscription(
                    interval(1)
                        .pipe(
                            take(1),
                            tap(() => {
                                this._defaultItem$.next(null);
                            }),
                            switchMap(() => this.data$),
                            notEmpty(),
                            take(1),
                            tap(data => {
                                const firstItem = data.firstOrDefault();

                                if (!firstItem) {
                                    return;
                                }

                                if (TypeConverter.isNull(this.value)) {
                                    if (this.valuePrimitive) {
                                        this.value = firstItem && firstItem[this.valueField];
                                    } else if (TypeConverter.isNull(firstItem[this.valueField])) {
                                        this.value = firstItem;
                                    }
                                }
                            })
                        )
                );
            }
        }

        super.AfterViewInit();
    }

    protected abstract dataProvider(): Observable<Array<DropDownItem>>;

    public get data$(): Observable<Array<DropDownItem>> {
        return this._data$;
    }

    public valueChangeHandler(value: any): void {
        this.valueChange.emit(value);
    }
}
