import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from '../../_core/components/base.component';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends BaseComponent {

    constructor(private readonly router: Router) {
        super();
    }

    onRegister(form: NgForm) {
        const email = form.value.email;
        const password = form.value.password;
        const confirmpassword = form.value.confirmpassword;
        // this.store.dispatch(new AuthActions.TryLogin(<AuthRequestModel>{ Email: email, Password: password }));
    }
}
