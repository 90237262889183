export enum EmsDeviceControllerStatus {
    Live = 1,
    Simulated = 2,
    ReadOnly = 3,
    HotBackup = 4
}

export const EmsDeviceControllerStatusNames = new Map<number, string>([
    [EmsDeviceControllerStatus.Live, 'Live'],
    [EmsDeviceControllerStatus.Simulated, 'Simulated'],
    [EmsDeviceControllerStatus.ReadOnly, 'ReadOnly'],
    [EmsDeviceControllerStatus.HotBackup, 'HotBackup']
]);
