<div class="login-container">
    <div class="content" geneaLoadingIndicator>
        <div class="g-header text-center">
            <span alt="GENEA" class="geneaLogo"></span>
        </div>
        <div class="align-self-center login-box">
            <div *ngIf="ready$ | async">
                <div *ngIf="user$ | async else expired">
                    <h1 class="login-text">Welcome!</h1>
                    <label class="login-label">Please create a password to activate your Genea user account.</label>
                    <div><label class="login-label">Email: &nbsp; {{ (user$ | async)?.Email }}</label></div>
                    <div>
                        <app-password (changePassword)="onSubmitNewPassword($event)" [showOldPassword]="false">
                        </app-password>
                    </div>
                </div>
                <ng-template #expired>
                    <div>
                        <div class="text-center pb-2">
                            <h2 class="login-text">Activation Expired</h2>
                        </div>
                        <div class="text-center">
                            The link that you are attempting to use to activate your account has either already been used or it has expired.
                        </div>
                        <br>
                        <div class="text-center">
                            To receive a new activation link, please use the password reset link on the login screen or contact <a href="mailto: support@getgenea.com">support@getgenea.com</a>.
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<!-- <div geneaLoadingIndicator></div> -->