<div class="switch-toggle switch-3 switch-candy">
    <input [id]="onId" [name]="groupName + '_' + id" type="radio" [value]="onValue" [(ngModel)]="value">
    <label [for]="onId">{{ onText }}</label>

    <input [id]="naId" [name]="groupName + '_' + id" type="radio" [value]="naValue" [(ngModel)]="value" checked="checked">
    <label [for]="naId">{{ naText }}</label>

    <input [id]="offId" [name]="groupName + '_' + id" type="radio" [value]="offValue" [(ngModel)]="value">
    <label [for]="offId">{{ offText }}</label>
    <a></a>
</div>
