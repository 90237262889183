import { Component, OnInit } from '@angular/core';
import { ToastrService } from '../../_modules/toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../_core/components/base.component';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { SnoozeRequestModel, SnoozeResultModel } from '../../_sdk/notification/snooze-notification.model';
import { AuthService } from '../../_services/auth/auth.service';
import { BehaviorSubject, of } from 'rxjs';
import { SmallDate } from '../../_core/date';


@Component({
  selector: 'app-snooze-notification',
  templateUrl: './snooze-notification.component.html',
  styleUrls: ['./snooze-notification.component.scss']
})
export class SnoozeNotificationComponent extends BaseComponent {
  private readonly _snoozeResponse$ = new BehaviorSubject<SnoozeResultModel>(null);
  public readonly snoozeResponse$ = this._snoozeResponse$.asObservable();
  public snoozeRequest : SnoozeRequestModel  = {} as SnoozeRequestModel;
  private readonly _loading$ = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading$.asObservable();
  public error: boolean = false;
  
  constructor(
    private readonly toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly authService: AuthService) {
      super();
     }

  ngOnInit(): void {
    this.addSubscription(
      this.route.queryParamMap.pipe(
        distinctUntilChanged(),
        tap(params => {
          params.keys.forEach(key => {
            switch (key.toLowerCase()) {
              case 'q':
                this._loading$.next(true);
                this.snoozeRequest.Content = this.router.parseUrl(this.router.url).queryParams['q'].replaceAll(" ","+");
                this.sendData();
                break;
              default:
                //Handle error for incorrect value of parameter name
                this.error = true;
                break;
            }
          }
          )
        }
        )
      )
    )
  }
  private sendData(){
    this.addSubscription(
      this.authService.snoozeNotification(this.snoozeRequest)
      .pipe(
        tap((response:SnoozeResultModel) =>{
          this._loading$.next(false)
          this._snoozeResponse$.next(response)
          }
        ),
        catchError(()=>{
          this._loading$.next(false)
          this.error = true
          return of(null)
        })
      )
    )
  }
  public closeWindow(){
    //Can't close windows not opened by script hence the warning
    if(!this.isOpenedByScript()){
      this.toastrService.warn("This window has not been open via the provided link. Please close the tab manually")
      return;
    }
    window.close()
  }
  //Check if window has been opened by script otherwise show warning 
  public isOpenedByScript() {
    return window.opener !== null;
  }
  public SnoozeStart(value){
    return new Date(value).format('M/DD/YY')
  }
  public SnoozeEnd(value){
    return new Date(value).format('M/DD/YY')
  }
}
