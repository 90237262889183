import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-static-text',
    styleUrls: ['form-static-text.component.scss'],
    template: `
    <ng-container [formGroup]="group">
        <p class="form-control-plaintext" [ngClass]="[config.cssClass || '']">{{ stringValue }}</p>

        <!-- <input type="text" readonly
            [ngClass]="[config.cssClass || '']"
            [attr.placeholder]="config.placeholder"
            [formControlName]="config.name"> -->
    </ng-container>
  `
})
export class FormStaticTextComponent implements Field {
    config: FieldConfig;
    group: FormGroup;

    public get control(): AbstractControl {
        if(!(this.group || this.config)) {
            return null;
        }

        return this.group.get(this.config.name);
    }

    public get stringValue(): string {
        if(!Boolean(this.control) || this.control.value === null || this.control.value === undefined) {
            return '';
        }

        return this.control.value.toString();
    }
}
