import { Component, TemplateRef, ContentChild, Input } from '@angular/core';
import { HorizontalLayoutDirective, VerticalLayoutDirective, ListLayoutDirective } from '../components/dynamic-field/dynamic-layout.directive';
import { LayoutMode, DisplayMode } from '../models/modes';
import { FieldConfig } from '../models/field-config.interface';
import { BaseComponent } from '../../../../_core/components/base.component';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'dynamic-layout',
    styleUrls: ['dynamic-layout.component.scss'],
    template: `
    <ng-container [ngSwitch]="layoutMode">
        <ng-container *ngSwitchCase="'horizontal'">
            <div class="form-row">
                <ng-container *ngFor="let field of config">
                    <div class="col-auto mr-3">
                        <ng-container *ngTemplateOutlet="horizontalLayoutTemplate; context: { $implicit: field }"></ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vertical'">
            <ng-container *ngFor="let field of config">
                <div class="form-group row">
                    <ng-container *ngTemplateOutlet="verticalLayoutTemplate; context: { $implicit: field }"></ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'list'">
            <dl class="row">
                <ng-container *ngFor="let field of config">
                    <ng-container *ngTemplateOutlet="listLayoutTemplate; context: { $implicit: field }"></ng-container>
                </ng-container>
            </dl>
        </ng-container>
        <ng-container *ngSwitchCase="'asIs'">
            <dl class="row">
                <ng-container *ngFor="let field of config">
                    <div class="col">
                        <ng-container *ngTemplateOutlet="horizontalLayoutTemplate; context: { $implicit: field }"></ng-container>
                    </div>
                </ng-container>
            </dl>
        </ng-container>
    </ng-container>
  `
})
export class DynamicLayoutComponent extends BaseComponent {

    @ContentChild(HorizontalLayoutDirective, { read: TemplateRef }) horizontalLayoutTemplate;
    @ContentChild(VerticalLayoutDirective, { read: TemplateRef }) verticalLayoutTemplate;
    @ContentChild(ListLayoutDirective, { read: TemplateRef }) listLayoutTemplate;

    @Input()
    public layoutMode: LayoutMode = 'vertical';

    @Input()
    public displayMode: DisplayMode = 'edit';

    @Input()
    public config: FieldConfig[] = [];
}
