<kendo-dropdownlist
    #dropDownList
    [id]="identifier"
    [data]="data$ | async"
    [textField]="textField"
    [valueField]="valueField"
    [defaultItem]="defaultItem$ | async"
    [valuePrimitive]="valuePrimitive"
    [(ngModel)]="value"
    (valueChange)="valueChangeHandler($event)"
    [loading]="loading">
</kendo-dropdownlist>
