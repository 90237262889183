// https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5

import { InjectionToken } from '@angular/core';
import { TimeSpan, SmallDate, isDateType } from '../../../_core/date';

const minValueErrorFn = ({ minValue, value }) => {
    if (isDateType(minValue)) {
        if (minValue instanceof SmallDate) {
            return `Minimum date '${minValue}'`;
        }

        return `Minimum date '${minValue.format('MM/DD/YYYY h:mm A')}'`;
    }

    return `Minimum length is '${minValue}'`;
};

const timeSpanErrorFn = ({ minValue, maxValue, value }) => {
    return `Minimum value '${minValue.format('HH:mm')}'`;
};

// NOTE: We may want to hijack all of the built in validators...
// https://angular.io/api/forms/Validators
/*
class Validators {
  static min(min: number): ValidatorFn
  static max(max: number): ValidatorFn
  static required(control: AbstractControl): ValidationErrors | null <---------- Handled
  static requiredTrue(control: AbstractControl): ValidationErrors | null
  static email(control: AbstractControl): ValidationErrors | null
  static minLength(minLength: number): ValidatorFn
  static maxLength(maxLength: number): ValidatorFn
  static pattern(pattern: string | RegExp): ValidatorFn
  static nullValidator(control: AbstractControl): ValidationErrors | null
  static compose(validators: ValidatorFn[]): ValidatorFn | null
  static composeAsync(validators: AsyncValidatorFn[]): AsyncValidatorFn | null
}
*/

export const CommonErrors = {
    required: (params: any) => {
        if (params && params['field']) {
            return `'${params['field']}' is required`;
        }

        return `This field is required`;
    },
    minValue: minValueErrorFn,
    minTimeSpan: timeSpanErrorFn,

    // Kendo uses 'minError' for Date inputs
    // minError: minValueErrorFn,
    // Kendo uses 'timeRangeError' for time inputs
    // We currently handle duration using a kendo time picker.
    // If we let kendo handle its own validation, we'll get 2 errors. minDuration and timeRangeError.
    // TODO: See if we can avoid this...
    // timeRangeError: timeSpanErrorFn,

    minlength: ({ requiredLength, actualLength }) => `Minimum length is '${requiredLength}'`,
    minTime: ({ minValue, value }) => `Minimum time '${(<TimeSpan>minValue).format('hh:mm A')}'`,
    minDuration: ({ minValue, value }) => `Minimum duration '${(<TimeSpan>minValue).format('HH:mm')}'`
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => CommonErrors
});
