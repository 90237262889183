import { BaseModel } from '../model';
import { DataObject } from '../../_core/type-converter';

@DataObject()
export class ParameterValueModel extends BaseModel {
    public get key(): number {
        return this.ParameterOptionId;
    }

    public Property: string = null;
    public Value: string = null;
    public ParameterOptionId: number = null;
}
