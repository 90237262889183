import { DataObject, DataProperty } from '../../_core/type-converter';
import { EntityModel } from '../model';
import { SmallDate } from '../../_core/date';

@DataObject()
export class BuildingProductModel extends EntityModel {

    public BuildingId: number = null;

    public ProductId: number = null;

    public ProductName: string = null;

    @DataProperty()
    public GoLiveDate: SmallDate = null;
}
