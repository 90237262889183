import { UserModel } from "../../../../../_sdk/users/user.model";
import { DateTime } from "../../../../../_core/date";
import { DataObject, DataProperty } from "../../../../../_core/type-converter";
import { EntityModel } from "../../../../../_sdk/model";

@DataObject()
export class HvacChangeRequestModel extends EntityModel{
    Message: string = null;

    @DataProperty()
    CreatedOnUtc: DateTime = null;

    UserFisrtName: string = null;
    UserLastName: string = null;
    UserEmail: string = null;
    UserId: Number = null;
    @DataProperty()
    ModifiedOnUtc:DateTime = null;
    
    @DataProperty()
    CreatedBy: UserModel = null;

    @DataProperty()
    ModifiedBy: UserModel = null;
}

@DataObject()
export class HvacChangeRequestCollectionModel extends EntityModel {  
  
    @DataProperty({instanceType: HvacChangeRequestModel})
    Conversation: Array<HvacChangeRequestModel> = [];
}