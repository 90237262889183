<div class="login-container">
  
    <div class="content" geneaLoadingIndicator>
        
            
            <div class="g-header text-center">
                <span alt="GENEA" class="geneaLogo" ></span>
            </div>

            <div class="align-self-center login-box">
                <h1 class="login-text" >Log In</h1>
                <form (ngSubmit)="onLogin(f)" #f="ngForm">
                    <div class="col-12 emailClass form-group p-0">                        
                        <input type="email" id="email" name="email" class="form-control emailIcon" (change)="onEmailChange(f)" [ngModel]="email" email required>
                        <label class="login-label" for="email">Email</label>
                        <span class="text-danger">{{ message$ | async }}</span>
                    </div>
                    <div class="col-12 form-group p-0">                        
                        <input type="password" id="password" name="password" class="form-control lockIcon" [ngModel]="password" required>
                        <label class="login-label" for="password">Password</label>
                    </div>
                    <div class="login-button">
                        <button type="submit" class="btn login-btn" [disabled]="!f.valid || (processing$ | async)">{{ loginStatus$ | async }}</button>
                    </div>
                    <div class="text-center">
                        Forgot your password? Reset it <a (click)="onForgotPassword(f)"><strong class="reset">HERE</strong>.</a>
                    </div>
                </form>
            </div>
            <div class="login-message-container">
                <div class="col-md-12">
                    <span class="text-left btn-link"> <span class="phoneIcon"></span><a href="tel:+1-866-935-1557" class="reset">866-935-1557 </a></span>
                    <span class="float-right btn-link"><span class="mailIcon"></span> <a href="mailto:support@getgenea.com" class="reset">Support@GetGenea.com </a></span>
                </div>
                <div *ngIf="loginFailed$ | async" class="login-fail-message text-center">
                    Your login attempt was not successful. Please try again.
                </div>
            </div>
        
    </div>
  
</div>
<!-- <div geneaLoadingIndicator></div> -->
