import { DataObject, CompositeType, DataProperty } from '../../_core/type-converter';
import { CoreEntity, EntityModel } from '../model';
import { FilterState } from '../page-state';
import { CustomerModel } from '../customers/customer.model';
import { EntityType } from '../search/entity-type.enum';

@DataObject()
export class BuildingGroupModel extends EntityModel implements CoreEntity {
    public readonly EntityType: EntityType = EntityType.BuildingGroup;
    
    public Name: string = null;
    public Description: string = null;
    public Active: boolean = true;
    
    @DataProperty()
    public Customer: CustomerModel = null;
}

export interface BuildingGroupFilter extends FilterState {
    CustomerId?: number;
    Name?: string;
}

export type BuildingGroupFilterState = CompositeType<BuildingGroupFilter, FilterState>;
