import { EntityModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class RegionModel extends EntityModel {
    public Name: string = null;
    public Description: string = null;
    public Active = false;
}

export interface RegionFilter {
    Name?: string;
    Description?: string;
}

export type RegionFilterState = CompositeType<RegionFilter, FilterState>;
