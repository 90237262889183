export enum BillingCycleType {

    Monthly=4,
    BiMonthly,
    Quarterly,
    BiAnnually,
    Annually
}

export const BillingCycleTypeNames = new Map<number, string>([

    [BillingCycleType.Monthly, 'Monthly'],
    [BillingCycleType.BiMonthly, 'Bi Monthly'],
    [BillingCycleType.Quarterly, 'Quarterly'],
    [BillingCycleType.BiAnnually , "Bi Annually"],
    [BillingCycleType.Annually , "Annually"],    
]);
