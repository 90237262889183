export enum LoadType {
    Lighting = 1,
    HVAC = 2,
    PlugLoad = 3,
    EVCharging = 4,
    Other = 5,
}

export const LoadTypeNames = new Map<number, string>([
    [LoadType.Lighting, 'Lighting'],
    [LoadType.HVAC, 'HVAC'],
    [LoadType.PlugLoad, 'Receptacle/Outlets/Plug Load'],
    [LoadType.EVCharging, 'EV station'],  
    [LoadType.Other, 'Unknown'],
]);
