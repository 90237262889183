import { AfterContentInit, AfterViewInit, Directive, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PermissionConstant } from '../permission-constant';
import { RoleConstant } from '../role-constant';

@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {

    protected readonly subscriptions = new Subscription();
    private readonly ngUnsubscribe = new Subject();

    public RoleConstant = RoleConstant;
    public PermissionConstant = PermissionConstant;

    public ngOnInit(): void {
        this.OnInit();
    }

    public ngAfterViewInit(): void {
        this.AfterViewInit();
    }

    public ngAfterContentInit(): void {
        this.AfterContentInit();
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        this.subscriptions.unsubscribe();

        this.OnDestroy();
    }

    protected OnInit(): void {
    }

    protected OnDestroy(): void {
    }

    protected AfterViewInit(): void {
    }

    protected AfterContentInit(): void {
    }

    protected addSubscription<T>(obvervable: Observable<T>,
        next?: (value: T) => void,
        error?: (error: any) => void,
        complete?: () => void): Subscription {
        return obvervable
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(next, error, () => {
                if (complete) {
                    complete();
                }
            });
    }
}
