import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';
import { EnumerationItemModel } from '../../../_sdk/common/enumeration-item.model';
import * as EnumerationItemActions from './enumeration-item.actions';

export interface State {
    enumerationItems: ModelCollection<EnumerationItemModel>;
}

const initialState: State = {
    enumerationItems: new ModelCollection<EnumerationItemModel>()
};

export function reducer(state: State = initialState, action: EnumerationItemActions.Actions): State {
    let enumerationItems: ModelCollection<EnumerationItemModel>;
    let index: number;

    switch (action.type) {
        case EnumerationItemActions.DISPOSE:
            return {
                ...state,
                enumerationItems: initialState.enumerationItems
            };
        case EnumerationItemActions.LOAD_COMPLETE:
            return {
                ...state,
                enumerationItems: action.payload
            };
        case EnumerationItemActions.LOAD:
        case EnumerationItemActions.INSERT:
        case EnumerationItemActions.UPDATE:
        case EnumerationItemActions.DELETE:
            return {
                ...state
            };
        case EnumerationItemActions.INSERT_COMPLETE:
            enumerationItems = new ModelCollection(state.enumerationItems.data
                .map(p => TypeConverter.convert(EnumerationItemModel, p)));

            enumerationItems.data.push(action.payload);

            return {
                ...state,
                enumerationItems: enumerationItems
            };

        case EnumerationItemActions.UPDATE_COMPLETE:
            enumerationItems = new ModelCollection(state.enumerationItems.data
                .map(p => TypeConverter.convert(EnumerationItemModel, p)));

            index = enumerationItems.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                enumerationItems.data[index] = action.payload;
            }

            return {
                ...state,
                enumerationItems: enumerationItems
            };

        case EnumerationItemActions.DELETE_COMPLETE:
            enumerationItems = new ModelCollection(state.enumerationItems.data
                .map(p => TypeConverter.convert(EnumerationItemModel, p)));

            index = enumerationItems.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                enumerationItems.data.splice(index, 1);
            }

            return {
                ...state,
                enumerationItems: enumerationItems
            };

        default:
            return state;
    }
}
