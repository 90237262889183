import { Directive, EventEmitter, HostListener } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, pairwise, takeUntil, tap } from 'rxjs/operators';

import { BaseDirective } from '../../../../_core/components/base.directive';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'input'
})
export class InputTouchDirective extends BaseDirective {

    private readonly _hasFocus$ = new BehaviorSubject<boolean>(false);
    private readonly _touched$ = new BehaviorSubject<boolean>(false);

    public readonly hasFocus$ =  this._hasFocus$.asObservable().pipe(distinctUntilChanged());
    public readonly touched$ = this._touched$.asObservable().pipe(distinctUntilChanged());

    public readonly touchChanges = new EventEmitter<any>();

    @HostListener('focus')
    protected onFocus(): void {
        this._hasFocus$.next(true);
    }

    @HostListener('blur')
    protected onBlur(): void {
        this._hasFocus$.next(false);
    }

    constructor() {
        super();

        this.addSubscription(
            this._hasFocus$
                .pipe(
                    pairwise(),
                    takeUntil(this.touchChanges),
                    tap(([previous, current]) => {
                        if (previous && !current) {
                            this._touched$.next(true);
                            this.touchChanges.emit('TOUCHED');
                        }
                    })
                )
        );
    }
}
