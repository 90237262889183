import { EntityModel, CommonModel } from '../model';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { ProtocolTypeModel } from './protocol-type.model';
import { ConnectionModel } from './connection.model';
import { FilterState } from '../page-state';
import { PointTypeModel } from './point-type.model';
import { PointRole, PointRestriction } from './point.enums';
import { ParameterValueModel } from '../parameter-configuration/parameter-value.model';
import { CommandModel } from './command-model.model';
import { CommandResultModel } from '../operations/command-result.model';
import { ExpandedDateModel } from '../common/expanded-date.model';
import { TimezoneModel } from '../common/timezone-info.model';
import { EmsDeviceControllerModel } from './ems-device-controller.model';
import { ReadIntervalType } from './read-interval-type.enum';

@DataObject()
export class PointModel extends EntityModel {

    public Id : number = null;
    public Name: string = null;
    public Description?: string = null;
    public Version = 1;
    public Active = true;

    public Role: PointRole = PointRole.None;
    public Restriction: PointRestriction = PointRestriction.None;

    @DataProperty()
    public Connection: ConnectionModel = null;

    @DataProperty()
    public PointType: PointTypeModel = null;

    @DataProperty()
    public ProtocolType: ProtocolTypeModel = null;

    @DataProperty({ instanceType: ParameterValueModel })
    public Parameters: Array<ParameterValueModel> = [];

    @DataProperty({ instanceType: CommandModel })
    public Commands: Array<CommandModel> = [];

    // Display properties

    public ActivePointCount = 0;

    @DataProperty()
    public EmsDeviceController: EmsDeviceControllerModel = null;

    @DataProperty()
    public TimeZoneInfo: TimezoneModel = null;

    @DataProperty()
    public LastReadCommand?: CommandResultModel = null;

    @DataProperty()
    public VersionedOnExpanded: ExpandedDateModel = null;

    @DataProperty()
    public LastActivatedOnExpanded: ExpandedDateModel = null;

    @DataProperty()
    public LastDeactivatedOnExpanded: ExpandedDateModel = null;

    @DataProperty({ instanceType: CommonModel })
    public Tenants: Array<CommonModel> = null;

    @DataProperty({ instanceType: CommonModel })
    public Leases: Array<CommonModel> = null;

    @DataProperty({ instanceType: CommonModel })
    public LeaseAreas: Array<CommonModel> = null;

    @DataProperty({ instanceType: CommonModel })
    public Measurements: Array<CommonModel> = null;

    @DataProperty({ instanceType: CommonModel })
    public Zones: Array<CommonModel> = null;

    @DataProperty({ instanceType: CommonModel })
    public Buildings: Array<CommonModel> = null;

    @DataProperty({ name: 'Generation' })
    public AutoOffGeneration: number = null;

    @DataProperty({ name: 'FirstScheduledOnExpanded' })
    public AutoOffFirstScheduledOnExpanded: ExpandedDateModel = null;

    @DataProperty({ name: 'NextScheduledOnExpanded' })
    public AutoOffNextScheduledOnExpanded: ExpandedDateModel = null;

    public ReadIntervalType: ReadIntervalType = ReadIntervalType.None;
}

export type PointField = keyof PointModel;

export interface PointFilter {
    Id?: number;
    Name?: string;
    Description?: string;
    ProtocolTypeId?: number;
    ConnectionId?: number;
    ConnectionName?: string;
    CustomerId?: number;
    BuildingGroupId?: number;
    BuildingId?: number;
    EmsDeviceControllerId?: number;
    Role?: PointRole;
    Restriction?: PointRestriction;
    Active?: boolean;

    IncludeFields?: Array<PointField>;
    FilterByBuilding?: boolean;
    ShowHidden?: boolean;
}

export type PointFilterState = CompositeType<PointFilter, FilterState>;

