import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TypeConverter } from './type-converter';
import { ModelCollection } from '../_sdk/collection.model';

export const notNull = <T>(predicate?: (value: T) => value is T) => (source: Observable<T>) =>
    new Observable<T>(observer => source
        .pipe(
            filter(p => (predicate && predicate(p)) || !TypeConverter.isNull(p)),
        ).subscribe({
            next(x) { observer.next(x); },
            error(err) { observer.error(err); },
            complete() { observer.complete(); }
        })
    );

export const notEmpty = <T>() => (source: Observable<T>) =>
    new Observable<T>(observer => source
        .pipe(
            notNull(),
            filter(x => {
                return (TypeConverter.isArray(x) && Array.prototype.any.call(x) === true)
                    || (TypeConverter.isString(x) && !String.isNullOrEmpty(x))
                    || (x instanceof ModelCollection && x.any());
            })
        ).subscribe({
            next(x) { observer.next(x); },
            error(err) { observer.error(err); },
            complete() { observer.complete(); }
        })
    );
