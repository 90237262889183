import { NgModule } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FloatingLabelModule } from '@progress/kendo-angular-label';
import { LayoutModule, PanelBarModule } from '@progress/kendo-angular-layout';
import { MenusModule } from '@progress/kendo-angular-menu';
import { PopupModule } from '@progress/kendo-angular-popup';
import { RippleModule } from '@progress/kendo-angular-ripple';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

// import 'hammerjs';

@NgModule({
    imports: [
        InputsModule,
        DateInputsModule,
        DropDownsModule,
        ButtonsModule,
        DialogModule,
        LayoutModule,
        PanelBarModule,
        PopupModule,
        WindowModule,
        SortableModule,
        MenusModule,
        RippleModule,
        TooltipModule,
        FloatingLabelModule,
        
        // GridModule,
        // PDFModule,
        // PDFExportModule,
        // ExcelExportModule,
        // ExcelModule,
        // ChartsModule,
        // TreeViewModule,
    ],
    exports: [
        InputsModule,
        DateInputsModule,
        DropDownsModule,
        ButtonsModule,
        DialogModule,
        LayoutModule,
        PanelBarModule,
        PopupModule,
        WindowModule,
        SortableModule,
        MenusModule,
        RippleModule,
        TooltipModule,
        FloatingLabelModule,
        // GridModule,
        // PDFModule,
        // PDFExportModule,
        // ExcelExportModule,
        // ExcelModule,
        // ChartsModule,
        // TreeViewModule,
    ]
})
export class KendoModule { }
