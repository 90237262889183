import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';
import { UserRoleModel } from '../../../_sdk/users/user-role.model';
import * as UserRoleActions from './user-role.actions';

export interface State {
    userRoles: ModelCollection<UserRoleModel>;
}

const initialState: State = {
    userRoles: new ModelCollection<UserRoleModel>()
};

export function reducer(
    state: State = initialState,
    action: UserRoleActions.Actions
): State {

    let userRoles: ModelCollection<UserRoleModel>;
    let index: number;

    switch (action.type) {
        case UserRoleActions.DISPOSE:
            return {
                ...state,
                userRoles: initialState.userRoles
            };
        case UserRoleActions.LOAD_COMPLETE:
            return {
                ...state,
                userRoles: action.payload
            };
        case UserRoleActions.LOAD:
        case UserRoleActions.INSERT:
        case UserRoleActions.UPDATE:
        case UserRoleActions.DELETE:
            return state;
        case UserRoleActions.INSERT_COMPLETE:
            userRoles = new ModelCollection(state.userRoles.data
                .map(p => TypeConverter.convert(UserRoleModel, p)));

            userRoles.data.push(action.payload);

            return {
                ...state,
                userRoles: userRoles
            };

        case UserRoleActions.UPDATE_COMPLETE:
            userRoles = new ModelCollection(state.userRoles.data
                .map(p => TypeConverter.convert(UserRoleModel, p)));

            index = userRoles.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                userRoles.data[index] = action.payload;
            }

            return {
                ...state,
                userRoles: userRoles
            };

        case UserRoleActions.DELETE_COMPLETE:
            userRoles = new ModelCollection(state.userRoles.data
                .map(p => TypeConverter.convert(UserRoleModel, p)));

            index = userRoles.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                userRoles.data.splice(index, 1);
            }

            return {
                ...state,
                userRoles: userRoles
            };

        default:
            return state;
    }
}
