import { EntityModel } from '../model';
import { DataObject, DataProperty, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';
import { TemplateOptionModel } from './template-option.model';

// TemplateConfigurationModel.cs
@DataObject()
export class TemplateModel extends EntityModel {

    public Type: TemplateType = null;
    public Name: string = null;
    public Description: string = null;
    public SystemName: string = null;
    public Active = false;
    public Version: number = null;
    public VersionedOn: Date = null;

    @DataProperty({ instanceType: TemplateOptionModel })
    public Options: Array<TemplateOptionModel> = [];
}

export interface TemplateFilter {
    Name?: string;
    Type?: string;
}

export type TemplateFilterState = CompositeType<TemplateFilter, FilterState>;

export type TemplateType = 'PointTemplate' | 'ConnectionTemplate' | 'CommandTemplate';
