import { EntityModel } from '../../_sdk/model';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';
import { BuildingModel } from '../buildings/building.model';
import { SmallDate } from '../../_core/date';

@DataObject()
export class HolidayModel extends EntityModel {

    public Name: string = null;

    public Description: string = null;

    @DataProperty()
    public Date: SmallDate = null;

    public OnDemand: boolean = false;
    
    public Active: boolean = true;

    public CustomerId: number = null;

    @DataProperty()
    public Buildings: Array<BuildingModel> = null;
    
    public FutureHoliday: boolean=false;
}

export interface HolidayFilter extends FilterState {
    CustomerId?: number;
    BuildingId?: number;
    Name?: string;
    CurrentYearOnly?: boolean;
}

export type HolidayFilterState = CompositeType<HolidayFilter, FilterState>;
