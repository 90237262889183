import { Action } from '@ngrx/store';
import { UserRoleCategoryModel } from '../../../_sdk/users/user-role-category.model';
import { ModelCollection } from '../../../_sdk/collection.model';

export const LOAD = 'USER_ROLE_CATEGORY_LOAD';
export const LOAD_COMPLETE = 'USER_ROLE_CATEGORY_LOAD_COMPLETE';

export const INSERT = 'USER_ROLE_CATEGORY_INSERT';
export const INSERT_COMPLETE = 'USER_ROLE_CATEGORY_INSERT_COMPLETE';

export const UPDATE = 'USER_ROLE_CATEGORY_UPDATE';
export const UPDATE_COMPLETE = 'USER_ROLE_CATEGORY_UPDATE_COMPLETE';

export const DELETE = 'USER_ROLE_CATEGORY_DELETE';
export const DELETE_COMPLETE = 'USER_ROLE_CATEGORY_DELETE_COMPLETE';

export const DISPOSE = 'USER_ROLE_CATEGORY_DISPOSE';

export class Load implements Action {
    public readonly type = LOAD;
}

export class LoadComplete implements Action {
    public readonly type = LOAD_COMPLETE;

    constructor(public payload: ModelCollection<UserRoleCategoryModel>) { }
}

export class Insert implements Action {
    public readonly type = INSERT;

    constructor(public payload: UserRoleCategoryModel) { }
}

export class InsertComplete implements Action {
    public readonly type = INSERT_COMPLETE;

    constructor(public payload: UserRoleCategoryModel) { }
}

export class Update implements Action {
    public readonly type = UPDATE;

    constructor(public payload: UserRoleCategoryModel) { }
}

export class UpdateComplete implements Action {
    public readonly type = UPDATE_COMPLETE;

    constructor(public payload: UserRoleCategoryModel) { }
}

export class Delete implements Action {
    public readonly type = DELETE;

    constructor(public payload: UserRoleCategoryModel) { }
}

export class DeleteComplete implements Action {
    public readonly type = DELETE_COMPLETE;

    constructor(public payload: UserRoleCategoryModel) { }
}

export class Dispose implements Action {
    public readonly type = DISPOSE;
}

export type Actions =
    Load | LoadComplete
    | Insert | InsertComplete
    | Update | UpdateComplete
    | Delete | DeleteComplete
    | Dispose;
