import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'minutesToHours' })
export class MinutesToHoursPipe implements PipeTransform {

    transform(value: number): string {
        const hours = Math.trunc(value / 60);
        const minutes = value % 60;

        let result = '';

        if (hours > 0) {
            result = `${hours} hour${hours > 1 ? 's' : ''} `;
        }

        if (minutes > 0) {
            result += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }

        return result.trim();
    }
}
