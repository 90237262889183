import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { BaseComponent } from '../../../_core/components/base.component';

@Component({
    selector: 'app-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingIndicatorComponent extends BaseComponent {

    private readonly _loading$ = new BehaviorSubject(false);
    public readonly loading$ = this._loading$.asObservable();

    @Input()
    public get loading(): boolean {
        return this._loading$.getValue();
    }
    public set loading(value: boolean) {
        this._loading$.next(value);
    }
}
