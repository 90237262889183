import { DataObject } from '../../_core/type-converter';
import { ServiceHourModel } from './service-hour.model';

@DataObject()
export class LeaseAreaServiceHourModel extends ServiceHourModel {

    public get key(): string {
        const values: Array<string> = [];

        values.push(((this.LeaseAreaId && this.LeaseAreaId.toString()) || ''));
        values.push((this.DayOfWeek || '').toString());
        values.push(((this.ServiceType && this.ServiceType.Id) || '').toString());

        const key = values.toDelimitedString('-');

        return key;
    }

    public LeaseAreaId = 0;
}

