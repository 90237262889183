import { EntityModel } from '../../../../_sdk/model';

export class BillingPeriodModel extends EntityModel {
    public Year?: number;
    public Month?: number;
    public Week?: number;

    constructor() {
        super();
    }
}