import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { switchMap, filter, tap } from 'rxjs/operators';

import { UserService } from '../../_services/users/user.service';
import { ToastrService } from '../../_modules/toastr';
import { BaseComponent } from '../../_core/components/base.component';
import { UserModel } from '../../_sdk/users/user.model';

@Component({
    selector: 'app-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordRecoveryComponent extends BaseComponent {

    private _userId: number;
    private _token: string;

    public user$ = new BehaviorSubject<UserModel>(null);
    public expirationMessage$ = new BehaviorSubject<string>(null);
    public errorMessage$ = new BehaviorSubject<string>(null);

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly userService: UserService,
        private readonly toastrService: ToastrService
    ) {
        super();
        this.user$ = new BehaviorSubject(null);
        this.errorMessage$ = new BehaviorSubject('');
    }

    OnInit() {
        super.OnInit();

        this.subscriptions.add(
            this.route.params.pipe(
                filter(p => p.userId),
                filter(p => p.token),
                tap(p => {
                   this._userId = p.userId;
                   this._token = p.token;
                }),
                switchMap(p => this.userService.validatePasswordToken(p.userId, p.token)),
            ).subscribe(p => {
                if (p && p.expires) {
                    const date = new Date(p.expires + 'Z');

                    // If 'usersettings.passwordtokenexpirationminutes' is set to 0, then it means no expiration.
                    // Server returns max date in that case.
                    if (date.getFullYear() === 9999) {
                        this.expirationMessage$.next('This page does not expire.');
                    } else {
                        this.expirationMessage$.next(`This page expires on ${date}`);
                    }

                    this.user$.next(p.userModel);
                } else {
                    this.expirationMessage$.next('Page expired!');
                }
            })
        );
    }

    onSubmitNewPassword({ newPassword, confirmNewPassword }) {
        this.toastrService.subscribeAndShowResult(
            this.userService.updatePassword(this._userId, newPassword, confirmNewPassword, this._token),
            _ => {
                this.router.navigate(['/login']);
            },
            _ => console.log(_)
        );
    }

}
