import { DataObject } from "../../_core/type-converter";
import { EntityModel } from "../model";
import { LeaseType, BussinessSubType, BusinessType } from "../../admin/platform/tenants/tenant-submeter-billing-settings.enum";

@DataObject()
export class TenantSubmeterBillingSettingsModel extends EntityModel {
	public BusinessType: BusinessType = null;

	public BussinessSubType: Array<BussinessSubType> = [];

	public LeaseType: LeaseType = null;
}
