import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';

import { BaseComponent } from '../../_core/components/base.component';
import { INavigatorState, NavigationIndicatorService } from './navigation-indicator.service';

@Component({
    selector: 'navigation-indicator',
    templateUrl: './navigation-indicator.component.html',
    styleUrls: ['./navigation-indicator.component.scss'],
    animations: [
        trigger('fadeInOut', [
            // transition in
            transition(':enter', [   // :enter is alias to 'void => *'
                style({ opacity: 1 })
            ]),
            transition(':leave', [   // :leave is alias to '* => void'
                animate(500, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class NavigationIndicatorComponent extends BaseComponent {

    show = false;

    constructor(private navigatorService: NavigationIndicatorService) {
        super();
    }

    OnInit() {
        this.subscriptions.add(
            this.navigatorService.getNavigator()
                .subscribe((navState: INavigatorState) => {
                    this.show = navState.show;
                })
        );
    }
}
