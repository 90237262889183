import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConfig } from '../../../../../_core/global.config';
import { ApiService } from '../../../../../_services/api.service';
import { BaseEntityService } from '../../../../../_services/base.service';
import { ModelCollection } from '../../../../../_sdk/collection.model';
import { HvacInvoiceStatusFilterState, HvacInvoiceStatusModel } from '../_models/hvac-invoice-status-model';
import { HvacBuildingInvoiceModel } from '../_models/hvac-building-invoice.model';
import { TypeConverter } from '../../../../../_core/type-converter';
import { map, tap } from 'rxjs/operators';
import { DateTime, SmallDate } from '../../../../../_core/date';
import { HvacInvoicePeriodModel } from '../_models/hvac-invoice-period.model';

@Injectable({
    providedIn: 'root'
})
export class HvacBuildingInvoiceService extends BaseEntityService<HvacBuildingInvoiceModel> {
    constructor(readonly apiService: ApiService, private readonly config: GlobalConfig) {
        super(apiService);
    }

    protected get baseUri(): string {
        return `${this.config.api('platform').baseUri}/invoicing/hvac/status`;
    } 
    
    protected get basePeriodUri(): string {
        return `${this.config.api('platform').baseUri}/invoicing/hvac/period`;
    } 

    public search(request?: HvacInvoiceStatusFilterState): Observable<ModelCollection<HvacBuildingInvoiceModel>> {
        return super.search(request);
    }

    public loadInvoiceStatusAggregate(request: HvacInvoiceStatusFilterState): Observable<HvacInvoiceStatusModel> {
        const uri = `${this.baseUri}/search`;

        return this.apiService.querySingle<HvacInvoiceStatusModel>(uri, request)
            .pipe(
                map(p => {
                    const model = TypeConverter.convert(HvacInvoiceStatusModel, p);

                    model.Statuses = new ModelCollection((model.Statuses && model.Statuses.data || []), p.Total);

                    return model;
                })
            );
    }


    public loadInvoiceDetails(model:{BuildingId: Number, InvoicePacketId: Number, StartDate:SmallDate}): Observable<HvacBuildingInvoiceModel>{
        const uri = `${this.basePeriodUri}/search`;
        return this.apiService.querySingle(uri,model);
    }
    public patchChangeRequest(data:HvacInvoicePeriodModel, buildingId): Observable<HvacInvoicePeriodModel>{
        return this.apiService.patchSingle(this.basePeriodUri + '/building/' + buildingId + '/requestInvoiceChange',data)
    }
}
