export enum SiteType {
    None,
    Building,
    Site
}

export const SiteTypeNames = new Map<number, string>([
    [SiteType.None, 'None'],
    [SiteType.Building, 'Building'],
    [SiteType.Site, 'Site']
]);
