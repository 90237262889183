import { Component, Input } from '@angular/core';

import { BaseComponent } from '../../../../_core/components/base.component';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'validation-messages',
    templateUrl: './validation-messages.component.html',
    styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent extends BaseComponent {

    @Input() messages: Array<string> = [];
}
