import { EntityModel } from '../../../../../_sdk/model';

export class RequestRuntimePMModel extends EntityModel {
    public BillableLastMonth?: number = null;
    public BillableLastYear?: number = null;
    public BillableMonth?: number = null;
    public BillableYear?: number = null;
    public TotalHoursLastMonth?: number = null;
    public TotalHoursLastYear?: number = null;
    public TotalHoursMonth?: number = null;
    public TotalHoursYear?: number = null;
    public TotalMonthVsLastMonth?: number = null;
    public TotalYearVsLastYear?: number = null;


    constructor() {
        super();
    }
}