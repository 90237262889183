import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { environment } from '../../../environments/environment';
declare const environment: any;

@Injectable({
    providedIn: 'root'
})
export class HttpRequestIndicatorService {

    private _urls: Array<string>;
    private readonly _requests$: BehaviorSubject<Array<string>>;
    public readonly requests$: Observable<Array<string>>;

    constructor() {
        this._urls = [];
        this._requests$ = new BehaviorSubject<Array<string>>(this._urls);
        this.requests$ = this._requests$.asObservable();
    }

    public addRequest(url: string) {
        url = this.normalizeUrl(url);

        if (String.isNullOrEmpty(url)) {
            return;
        }

        if (this.contains(url)) {
            return;
        }

        this._urls.push(url);

        this.publish();
    }

    public removeRequest(url: string): void {
        url = this.normalizeUrl(url);

        if (String.isNullOrEmpty(url)) {
            return;
        }

        this._urls = this._urls.filter(r => r !== url);

        this.publish();
    }

    public clear() {
        this._urls.length = 0;

        this.publish();
    }

    private contains(url: string): boolean {
        return this._urls.filter(r => r === url).length > 0;
    }

    private publish(): void {
        this._requests$.next(this._urls);
    }

    private normalizeUrl(url: string): string {
        url = String.trimOrEmpty(url).toLowerCase();

        if (String.isNullOrEmpty(url)) {
            return url;
        }

        if (url.contains(environment.versionCheckURL)) {
            // We don't want version checking to keep polluting the UI.
            return;
        }

        const qsIndex = url.indexOf('?');

        if (qsIndex >= 0) {
            url = url.substring(0, qsIndex);
        }

        return url;
    }
}
