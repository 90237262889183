
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LanguageModel } from '../_sdk/localization/language.model';
import { LocaleStringResourceModel } from '../_sdk/localization/locale-string-resource.model';

import { AuthService } from '../_services/auth/auth.service';
import { LanguageService } from '../_services/localization/language.service';
import { LocaleStringResourceService } from '../_services/localization/locale-string-resource.service';

import { GlobalConfig } from './global.config';
import { notEmpty, notNull } from './rxjs.operators';
import { TypeConverter } from './type-converter';

@Injectable({
    providedIn: 'root'
})
export class LocalizationContext {

    // Our resource container...
    private readonly _resources = new Map<string, BehaviorSubject<string>>();
    private readonly _missingResources = new Map<string, string>();

    // We only care about the language Id changing...
    private readonly _languageId$ = new BehaviorSubject<number>(null);
    public readonly languageId$ = this._languageId$.asObservable()
        .pipe(
            notNull(), 
            filter(p => p > 0)
        );

    public readonly language$: Observable<LanguageModel>;

    constructor(
        private readonly config: GlobalConfig,
        private readonly authService: AuthService,
        private readonly localeStringResourceService: LocaleStringResourceService,
        private readonly languageService: LanguageService
    ) {
        this._languageId$.next(this.config.localizationConfig.languageId);

        this.language$ = this.languageId$
            .pipe(
                distinctUntilChanged(),
                switchMap(languageId => this.languageService.getById(languageId))
            );

        // Set up our subscriptions...
        this.authService.authenticatedUser$
            .pipe(
                map(user => user && user.LanguageId),
                filter(p => p > 0),
                distinctUntilChanged(),
                tap(p => {
                    this._languageId$.next(p);
                })
            )
            .subscribe();

        this.languageId$
            .pipe(
                filter(p => p > 0),
                distinctUntilChanged(),
                tap(p => {
                    this.loadResources(p)
                })
            )
            .subscribe();

        this.localeStringResourceService.entityChanged$
            .pipe(
                tap(_ => {
                    this.loadResources(this._languageId$.getValue());
                })
            )
            .subscribe();
    }

    private loadResources(languageId: number): void {
        this.localeStringResourceService.search({ LanguageId: languageId })
            .pipe(
                notEmpty(),
                take(1),
                tap(collection => {
                    this.updateResources(collection.data);
                })
            )
            .subscribe();
    }

    private updateResources(resources: Array<LocaleStringResourceModel>): void {
        resources
            .forEach(resource => {
                this.updateResource(resource.Name, resource.Value);
            });

        this.updateMissingResources(resources);
    }

    private updateResource(key: string, value: string): Observable<string> {
        key = this.normalizeKey(key);
        value = String.trimOrEmpty(value);

        const output = this._resources.getValue(key, () => {
            // Defaulting to an empty string limits broadcasting values from NULL to ''
            // Just default it to '' and we're good...
            return new BehaviorSubject('');
        });

        if (!String.equals(output.getValue(), value)) {
            // Only update resources with a different value than the current resource...
            output.next(value);
        }

        return output;
    }

    private normalizeKey(key: string): string {
        return String.trimOrEmpty(key).toLowerCase();
    }

    private updateMissingResources(data: Array<LocaleStringResourceModel>): Array<LocaleStringResourceModel> {
        return data
            .iterate(resource => {
                this._missingResources.delete(this.normalizeKey(resource.Name));
            });
    }

    public resource(key: string, defaultValue?: string): Observable<string> {
        key = this.normalizeKey(key);

        const resource$ = this._resources.get(key);

        if (!resource$) {
            this._missingResources.set(key, defaultValue || '[UNKNOWN]');

            if (this.config.localizationConfig.logMissingResources) {
                console.log(`Invalid resource: key: [${key}], defaultValue: [${defaultValue}]`);
            }

            // We MUST set the resource in the map.
            // If no default value is given, default to the key so it's easier to find in the UI.
            return this.updateResource(key, defaultValue || key);
        }

        return resource$;
    }

    public getResource(key:string): string{
        return this._resources.get(key).getValue();
    }

    public logMissingResources(): void {
        console.log(this._missingResources);
    }

    public synchronize(): Observable<any> {
        const resources = this._missingResources
            .map((v, k) => {
                return TypeConverter.convert(LocaleStringResourceModel, {
                    LanguageId: this._languageId$.getValue(),
                    Name: k,
                    Value: v
                });
            })
            .filter(p => !String.isNullOrEmpty(p.Name));

        return this.localeStringResourceService.insertRange(resources);
    }
}
