import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { PointTypeModel } from '../../../_sdk/ems-devices/point-type.model';
import * as PointTypeActions from './point-type.actions';

export interface State {
    pointTypes: ModelCollection<PointTypeModel>;
}

const initialState: State = {
    pointTypes: new ModelCollection<PointTypeModel>()
};

export function reducer(
    state: State = initialState,
    action: PointTypeActions.Actions
): State {

    let pointTypes: ModelCollection<PointTypeModel>;
    let index: number;

    switch (action.type) {
        case PointTypeActions.DISPOSE:
            return {
                ...state,
                pointTypes: initialState.pointTypes
            };

        case PointTypeActions.LOAD_COMPLETE:
            return {
                ...state,
                pointTypes: action.payload
            };
        case PointTypeActions.LOAD:
        case PointTypeActions.INSERT:
        case PointTypeActions.UPDATE:
        case PointTypeActions.DELETE:
            return state;
        case PointTypeActions.INSERT_COMPLETE:
            pointTypes = new ModelCollection(state.pointTypes.data
                .map(p => TypeConverter.convert(PointTypeModel, p)));

            pointTypes.data.push(action.payload);

            return {
                ...state,
                pointTypes: pointTypes
            };

        case PointTypeActions.UPDATE_COMPLETE:
            pointTypes = new ModelCollection(state.pointTypes.data
                .map(p => TypeConverter.convert(PointTypeModel, p)));

            index = pointTypes.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                pointTypes.data[index] = action.payload;
            }

            return {
                ...state,
                pointTypes: pointTypes
            };

        case PointTypeActions.DELETE_COMPLETE:
            pointTypes = new ModelCollection(state.pointTypes.data
                .map(p => TypeConverter.convert(PointTypeModel, p)));

            index = pointTypes.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                pointTypes.data.splice(index, 1);
            }

            return {
                ...state,
                pointTypes: pointTypes
            };

        default:
            return state;
    }
}
