import { EntityModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class ProtocolTypeModel extends EntityModel {
    public Name: string = null;
    public Description?: string = null;
    public SystemName: string = null;
    public SupportsBulkUpload: boolean = false;
}

export interface ProtocolTypeFilter {
    Name?: string;
}

export type ProtocolTypeFilterState = CompositeType<ProtocolTypeFilter, FilterState>;
