import { Subscription, Subject, Observable } from 'rxjs';
import { OnInit, OnDestroy, AfterContentInit, AfterViewInit, Directive } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Directive()
export class BaseDirective  implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {

    // Note: This is optional and simply allows another way to manage subscriptions
    private readonly ngUnsubscribe = new Subject<any>();

    public ngOnInit(): void {
        this.OnInit();
    }

    public ngAfterViewInit(): void {
        this.AfterViewInit();
    }

    public ngAfterContentInit(): void {
        this.AfterContentInit();
    }

    public ngOnDestroy(): void {
        // Note: This is optional and simply allows another way to manage subscriptions
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();

        this.OnDestroy();
    }

    protected OnInit(): void {
    }

    protected OnDestroy(): void {
    }

    protected AfterViewInit(): void {
    }

    protected AfterContentInit(): void {
    }

    // Note: This is optional and simply allows another way to manage subscriptions
    // https://stackoverflow.com/questions/38008334/angular-rxjs-when-should-i-unsubscribe-from-subscription/41177163#41177163
    protected addSubscription<T>(obvervable: Observable<T>,
        next?: (value: T) => void,
        error?: (error: any) => void,
        complete?: () => void): Subscription {
        return obvervable
            .pipe(
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(next, error, () => {
                if (complete) {
                    complete();
                }
            });
    }
}