import { WeekDay } from '@angular/common';

import * as moment from 'moment';

import { DateTime } from './date.datetime';
import { Milliseconds } from './date.internal';
import { SmallDate } from './date.smalldate';
import { TimeSpan } from './date.timespan';
import { UnspecificDateTime } from './date.unspecified';

declare global {

    interface DateConstructor {
        addMilliseconds(date: Date, offset: number): Date;
        addSeconds(date: Date, offset: number): Date;
        addMinutes(date: Date, offset: number): Date;
        addHours(date: Date, offset: number): Date;
        addDays(date: Date, offset: number): Date;
        addYears(date: Date, offset: number): Date;

        timeOfDay(date: Date): TimeSpan;

        format(date: Date, formatter: string): string;
        isDate(input: string): boolean;

        convert(value: any): Date;
        dayOfWeek(value: Date): WeekDay;
        daysInMonth(year: number, month: number): number;
    }

    interface Date {
        format(formatter?: string): string;
    }
}

Date.addMilliseconds = (date: Date, offset: number): Date => {
    return new Date(date.getTime() + offset);
};

Date.addSeconds = (date: Date, offset: number): Date => {
    return new Date(date.getTime() + offset * Milliseconds.perSecond);
};

Date.addMinutes = (date: Date, offset: number): Date => {
    return new Date(date.getTime() + offset * Milliseconds.perMinute);
};

Date.addHours = (date: Date, offset: number): Date => {
    return new Date(date.getTime() + offset * Milliseconds.perHour);
};

Date.addDays = (date: Date, offset: number): Date => {
    return new Date(date.getTime() + offset * Milliseconds.perDay);
};

Date.addYears = (date: Date, offset: number): Date => {
    return new Date(date.getTime() + offset * Milliseconds.perYear);
};

Date.format = (date: Date, formatter: string): string => {
    return moment(date).format(formatter);
};

Date.timeOfDay = (date: Date): TimeSpan => {
    return TimeSpan.fromDate(date);
};

Date.dayOfWeek = function (date: Date): WeekDay {
    return <WeekDay>date.getDay();
};

Date.isDate = (input: string): boolean => {
    if (String.isNullOrEmpty(input)) {
        return false;
    }

    return moment(input).isValid();
};

Date.convert = (value: any): Date => {
    if (!value) {
        return null;
    }

    if (value instanceof Date) {
        return value;
    }

    if (typeof value === typeof 0) { // 40000
        return new Date(Math.round(<number>value));
    }

    if (typeof value === typeof '') { // String
        if (Date.isDate(<string>value)) { // '1976-09-29T01:07:58Z'
            const valAsMoment = moment(<string>value);

            return valAsMoment.toDate();
        }

        if (!isNaN(+value)) { // '40000'
            return new Date(Math.round(+value));
        }
    }

    // DateTime, SmallDate
    if (!!value.toDate) {
        return value.toDate();
    }

    return value as Date;
};

Date.daysInMonth = (year: number, month: number) => {
    return new Date(year, month, 0).getDate();
};

Date.prototype.format = function (formatter: string) {
    return Date.format(this, formatter);
};

/* 
// "new Date(2018, 8, 4, 14, 38)" creates a date object in California as follows:
//      Tue Sep 04 2018 14:38:00 GMT-0700 (Pacific Daylight Time)
// When it's posted to the server, the date object takes the following form:
//      2018-09-04T21:38:00.000Z
// It is the ISO form with the UTC offset applied, which is correct.
// But the problem is that the server-side deserialization is set up to take a DateTime object as an "Unspecified" kind.
// Eventually, the above date is interpreted as "2018/9/4 9:38 PM" instead of "2018/9/4 2:38 PM".
// This function makes up for the timezone offset and produced the following:
//      2018-09-04T14:38:00.000Z
// Technically, it's not really "Unspecified" date that this function returns because it still has the "Z" at the end.
// But the server will drop it and make it "Unspecified".
Date.prototype.toUnspecifiedDate = function () {
    return this.addMinutes(-this.getTimezoneOffset());
};
 */

export { DateTime } from './date.datetime';
export { SmallDate } from './date.smalldate';
export { TimeRange } from './date.timerange';
export { TimeSpan } from './date.timespan';
export { UnspecificDateTime } from './date.unspecified';

export function isDateType(value: any): boolean {
    if (value instanceof Date
        || value instanceof DateTime
        || value instanceof SmallDate
        || UnspecificDateTime.isUnspecificDateTime(value)
        || moment.isMoment(value)) {
        return true;
    }

    return false;
}
