import { DataObject, DataProperty } from '../../_core/type-converter';
import { BaseModel } from '../model';
import { ServiceTypeModel } from './service-type.model';
import { UserRoleModel } from '../users/user-role.model';

@DataObject()
export class ServiceRequestDurationModel extends BaseModel {
    public get key(): string {
        const values: Array<string> = [];

        // values.push((this.ServiceRequest && this.ServiceRequest.Id || '').toString());
        values.push((this.ServiceType && this.ServiceType.Id || '').toString());
        values.push(((this.UserRole && this.UserRole.Id) || '').toString());

        const key = values.toDelimitedString('-');

        return key;
    }

    public DurationHours: number = null;

    @DataProperty()
    public ServiceType: ServiceTypeModel = null;

    @DataProperty()
    public UserRole: UserRoleModel = null;
}
