import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';
import { UserRoleCategoryModel } from '../../../_sdk/users/user-role-category.model';
import * as UserRoleCategoryActions from './user-role-category.actions';

export interface State {
    userRoleCategories: ModelCollection<UserRoleCategoryModel>;
}

const initialState: State = {
    userRoleCategories: new ModelCollection<UserRoleCategoryModel>()
};

export function reducer(
    state: State = initialState,
    action: UserRoleCategoryActions.Actions
): State {

    let userRoleCategories: ModelCollection<UserRoleCategoryModel>;
    let index: number;

    switch (action.type) {
        case UserRoleCategoryActions.DISPOSE:
            return {
                ...state,
                userRoleCategories: initialState.userRoleCategories
            };
        case UserRoleCategoryActions.LOAD_COMPLETE:
            return {
                ...state,
                userRoleCategories: action.payload
            };
        case UserRoleCategoryActions.LOAD:
        case UserRoleCategoryActions.INSERT:
        case UserRoleCategoryActions.UPDATE:
        case UserRoleCategoryActions.DELETE:
            return state;
        case UserRoleCategoryActions.INSERT_COMPLETE:
            userRoleCategories = new ModelCollection(state.userRoleCategories.data
                .map(p => TypeConverter.convert(UserRoleCategoryModel, p)));

            userRoleCategories.data.push(action.payload);

            return {
                ...state,
                userRoleCategories: userRoleCategories
            };

        case UserRoleCategoryActions.UPDATE_COMPLETE:
            userRoleCategories = new ModelCollection(state.userRoleCategories.data
                .map(p => TypeConverter.convert(UserRoleCategoryModel, p)));

            index = userRoleCategories.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                userRoleCategories.data[index] = action.payload;
            }

            return {
                ...state,
                userRoleCategories: userRoleCategories
            };

        case UserRoleCategoryActions.DELETE_COMPLETE:
            userRoleCategories = new ModelCollection(state.userRoleCategories.data
                .map(p => TypeConverter.convert(UserRoleCategoryModel, p)));

            index = userRoleCategories.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                userRoleCategories.data.splice(index, 1);
            }

            return {
                ...state,
                userRoleCategories: userRoleCategories
            };

        default:
            return state;
    }
}
