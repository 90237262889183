import { EntityModel } from '../../../../../_sdk/model';
import { SortDirectionType } from '../../../../../_sdk/sort-direction.enumeration';
import { CompositeType, DataObject, DataProperty } from '../../../../../_core/type-converter';
import { FilterState } from '../../../../../_sdk/page-state';
import { ModelCollection } from '../../../../../_sdk/collection.model';
import { InvoiceStatusType } from './enumerations/invoice-status-type.enumeration';
import { HvacBuildingInvoiceModel } from './hvac-building-invoice.model';
import { HvacInvoiceStatusViewModel } from './hvac invoice-status-view.model';
import { SmallDate } from '../../../../../_core/date';

@DataObject()
export class HvacInvoiceStatusModel extends EntityModel {
    @DataProperty({ converter: (s) => new ModelCollection(s) })
    public InvoiceStatusViewModel: ModelCollection<HvacInvoiceStatusViewModel> = null;
    @DataProperty({ converter: (s) => new ModelCollection(s) })
    public Statuses: ModelCollection<HvacBuildingInvoiceModel> = null;

    public Total:number = 0;
}

export interface HvacInvoiceStatusFilter {
    CustomerId?: number;
    CustomerName?: string;
    BuildingId?: number;
    BuildingName?: string;
    ModifiedBy?: string;
    LastInvoice?: Date;
    NextInvoice?: Date;
    StartDate?: SmallDate;
    EndDate?: SmallDate;
    Statuses?: Array<InvoiceStatusType>;
    BillingDay?: number;
    SortField?: string;
    SortDirection?: SortDirectionType;
}

export type HvacInvoiceStatusFilterState = CompositeType<HvacInvoiceStatusFilter, FilterState>;