import { DataObject, CompositeType, DataProperty } from '../../_core/type-converter';
import { FilterState } from '../page-state';
import { EntityModel } from '../model';
import { AddressModel } from '../common/address.model';

@DataObject()
export class RemittanceAddressModel extends EntityModel {
    public Name: string = null;
    public Description: string = null;
    public Active = false;
    public CustomerId: number = null;

    @DataProperty()
    public Address: AddressModel = null;
}

export interface RemittanceAddressFilter {
    Name?: string;
    CustomerName?: string;
    CustomerId?: number;
}

export type RemittanceAddressFilterState = CompositeType<RemittanceAddressFilter, FilterState>;
