
import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, last, tap } from 'rxjs/operators';

import { HttpResponseService } from '../_services/http-response.service';

// https://github.com/angular/angular/issues/18224
@Injectable()
export class ConnectivityInterceptor implements HttpInterceptor {

    private readonly interestingMethods = ['post', 'put', 'delete'];
    private readonly interestingHttpEventTypes = [HttpEventType.Response];

    constructor(private readonly httpResponseService: HttpResponseService) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

        return next.handle(request)
            .pipe(
                // We only want the last emitted value. It should be the "complete"
                last(),
                // Handle the response.
                tap(event => {
                    // Filter out only things we're interested in (POST, PUT, DELETE, etc...)
                    if (this.isInteresting(request, event)) {
                        this.httpResponseService.handleResponse(event);
                    }
                }),
                // If there's an error, let's catch it, handle it, then throw it up the chain.
                catchError((err: any) => {
                    this.httpResponseService.handleResponse(err);

                    // return of({ innerError: err, handled: true });
                    throw { innerError: err, handled: true };
                })
            );
    }

    private isInteresting(request: HttpRequest<any>, response: HttpEvent<any>): boolean {

        const isInterstingMethod = this.interestingMethods.any(p => String.looseEquals(p, request.method));
        const isInterestingType = this.interestingHttpEventTypes.any(p => p === response.type);

        return isInterstingMethod && isInterestingType;
    }
}
