import { EntityModel } from '../model';
import { CountryModel } from './country.model';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class StateProvinceModel extends EntityModel {
    public Name: string = null;
    public Abbreviation: string = null;
    public Active = false;
    public DisplayOrder: number = null;

    @DataProperty()
    public Country: CountryModel = null;
}

export interface StateProvinceFilter {
    CountryId?: number;
    LanguageId?: number;
    Name?: string;
    Abbreviation?: string;
}

export type StateProvinceFilterState = CompositeType<StateProvinceFilter, FilterState>;
