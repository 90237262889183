
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromPermissionRecord from './permission-record/permission-record.reducers';

export const FEATURE_NAME = 'securityStore';

export interface FeatureState {
    permissionRecordState: fromPermissionRecord.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    permissionRecordState: fromPermissionRecord.reducer
};

export const featureSelector = createFeatureSelector<FeatureState>(FEATURE_NAME);

const getPermissionRecordStateSelector = (state: FeatureState) => state.permissionRecordState;
export const getPermissionRecordState = createSelector(featureSelector, getPermissionRecordStateSelector);
export const getPermissionRecords = createSelector(getPermissionRecordState, s => s.permissionRecords);
