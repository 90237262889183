<div class="container">
  <div class="row">
    <div class="col-4 offset-4">
      <form (ngSubmit)="onRegister(f)" #f="ngForm">
        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" name="email" class="form-control"
            ngModel
            email
            required>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" name="password" class="form-control"
            ngModel
            required>
        </div>
        <div class="form-group">
          <label for="password">Confirm Password</label>
          <input type="password" id="confirmpassword" name="confirmpassword" class="form-control"
            ngModel
            required>
        </div>
        <button class="btn btn-primary" type="submit"
          [disabled]="!f.valid">Register</button>
      </form>
    </div>
  </div>
</div>
