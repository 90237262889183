import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { GeneaInputsModule } from '../../common/inputs';
import { ControlErrorsModule } from '../control-error/control-errors.module';
import { GeneaFieldsetModule } from '../fieldset/fieldset.module';

import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { HorizontalLayoutDirective, ListLayoutDirective, VerticalLayoutDirective } from './components/dynamic-field/dynamic-layout.directive';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormCustomComponent } from './components/form-custom/form-custom.component';
import { FormDateComponent } from './components/form-date/form-date.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormIpAddressComponent } from './components/form-ip-address/form-ip-address.component';
import { FormMacAddressComponent } from './components/form-mac-address/form-mac-address.component';
import { FormMultiSelectComponent } from './components/form-multi-select/form-multi-select.component';
import { FormNumberComponent } from './components/form-number/form-number.component';
import { FormPhoneComponent } from './components/form-phone/form-phone.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormStaticTextComponent } from './components/form-static-text/form-static-text.component';
import { DynamicFormComponent } from './containers/dynamic-form.component';
import { DynamicLayoutComponent } from './containers/dynamic-layout.component';
import { FilterComponent } from './filter/filter.component';

@NgModule({
    declarations: [
        DynamicFieldDirective,

        VerticalLayoutDirective,
        HorizontalLayoutDirective,
        ListLayoutDirective,

        DynamicFormComponent,
        DynamicLayoutComponent,

        FormButtonComponent,
        FormInputComponent,
        FormNumberComponent,
        FormStaticTextComponent,
        FormSelectComponent,
        FormMultiSelectComponent,
        FormPhoneComponent,
        FormIpAddressComponent,
        FormMacAddressComponent,
        FormCheckboxComponent,
        FormCustomComponent,
        FormDateComponent,
        FilterComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        // Shared
        GeneaInputsModule,

        // Kendo
        InputsModule,
        DateInputsModule,
        DropDownsModule,

        ControlErrorsModule,
        GeneaFieldsetModule
    ],
    exports: [
        DynamicFormComponent,
        FilterComponent
    ],
    entryComponents: [
        FormButtonComponent,
        FormInputComponent,
        FormNumberComponent,
        FormStaticTextComponent,
        FormSelectComponent,
        FormPhoneComponent,
        FormIpAddressComponent,
        FormCheckboxComponent,
        FormMacAddressComponent,
        FormCustomComponent,
        FormDateComponent,
        FormMultiSelectComponent
    ]
})
export class DynamicFormModule {
    static exports(): Array<any> {
        return [
            DynamicFormComponent,
            FilterComponent
        ]
    }
 }
