
import {of,  Observable } from 'rxjs';
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { BaseComponent } from '../../_core/components/base.component';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordComponent extends BaseComponent {

    passwordForm: FormGroup;

    @Input()
    showOldPassword: boolean;

    @Output()
    changePassword: EventEmitter<{ newPassword: string, confirmNewPassword: string, oldPassword: string }>;

    constructor(
        formBuilder: FormBuilder,
    ) {
        super();

        this.passwordForm = formBuilder.group({
            oldPassword: '',
            newPassword: ['', Validators.required],
            confirmNewPassword: ['', Validators.required]
        });

        this.changePassword = new EventEmitter();
        this.passwordForm.setAsyncValidators(this.checkPassword());
    }

    checkPassword(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{[key: string]: any}> => {
            if (this.newPassword !== this.confirmNewPassword) {
                return of({'match': 'Passwords do not match.'});
            }

            if (this.newPassword.length < 6) {
                return of({'length': 'Password must be greater than 6 characters and less than 16 characters.'});
            }

            return of(null);
        };
    }

    onChangeSubmit() {
        this.changePassword.emit({
            newPassword: this.newPassword,
            confirmNewPassword: this.confirmNewPassword,
            oldPassword: this.oldPassword
        });
    }

    get newPassword(): string {
        return this.passwordForm.get('newPassword').value;
    }

    get confirmNewPassword(): string {
        return this.passwordForm.get('confirmNewPassword').value;
    }

    get oldPassword(): string {
        return this.passwordForm.get('oldPassword').value;
    }
}
