import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromEnumeration from './enumeration/enumeration.reducers';
import * as fromEnumerationItem from './enumeration-item/enumeration-item.reducers';

export const FEATURE_NAME = 'commonStore';

export interface FeatureState {
    enumerationState: fromEnumeration.State;
    enumerationItemState: fromEnumerationItem.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    enumerationState: fromEnumeration.reducer,
    enumerationItemState: fromEnumerationItem.reducer
};

export const featureSelector = createFeatureSelector<FeatureState>(FEATURE_NAME);

const getEnumerationStateSelector = (state: FeatureState) => state.enumerationState;
export const getEnumerationState = createSelector(featureSelector, getEnumerationStateSelector);
export const getEnumerations = createSelector(getEnumerationState, s => s.enumerations);

const getEnumerationItemStateSelector = (state: FeatureState) => state.enumerationItemState;
export const getEnumerationItemState = createSelector(featureSelector, getEnumerationItemStateSelector);
export const getEnumerationItems = createSelector(getEnumerationItemState, s => s.enumerationItems);


