import { Injectable } from '@angular/core';
import { GlobalConfig } from '../../../../../_core/global.config';
import { ApiService } from '../../../../../_services/api.service';
import { BaseEntityService } from '../../../../../_services/base.service';
import { InvoiceAuditModel } from '../_models/invoice-audit.model';
import { HvacInvoiceStatusFilterState } from '../_models/hvac-invoice-status-model';
import { HvacInvoicePeriodModel } from '../_models/hvac-invoice-period.model';
import { HvacBuildingInternalNotesModel } from '../_models/hvac-building-internal-notes.model';
import { HvacInvoiceChangeHistoryModel } from '../_models/hvac-invoice-change-history.model';
import { map } from 'rxjs/operators';
import { TypeConverter } from '../../../../../_core/type-converter';
import { InvoicesPropertyManagerModel } from '../_models/invoices-property-manager.model';

@Injectable({
    providedIn: 'root'
})
export class HvacInvoicePeriodService extends BaseEntityService<HvacInvoicePeriodModel> {
    constructor(readonly apiService: ApiService, private readonly config: GlobalConfig) {
        super(apiService);
    }

    protected get baseUri(): string {
        return `${this.config.api('platform').baseUri}/invoicing/hvac/period`;
    }    

   // public getInvoices(buildingId: number) {
    //     return this.apiService.query<HvacInvoicePeriodModel>(this.baseUri + '/building/' + buildingId);
    // }

    //  Create a batch of invoices for a building
    public batchInvoices(buildingId: number, model: HvacInvoicePeriodModel) {     
        return this.apiService.insert<HvacInvoicePeriodModel>(this.baseUri + '/building/' + buildingId, model);
    }

    //  Create a batch of invoices for a building
    public batchCancel(buildingId: number, model: HvacInvoicePeriodModel) {
        return this.apiService.update<HvacInvoicePeriodModel>(this.baseUri + '/building/' + buildingId + '/cancel', model);
    }

    //  Create a batch of invoices for a building
    public auditInvoice(invoicePeriodId: number) {
        return this.apiService.query<InvoiceAuditModel>(this.baseUri + '/' + invoicePeriodId);
    }

    public search(request?: HvacInvoiceStatusFilterState) {
        return super.search(request) as any;
    }

    public updateInvoiceStatus(model:Array<HvacInvoicePeriodModel>){
        return this.apiService.update<Array<HvacInvoicePeriodModel>>(this.baseUri ,model);
    }
    public updatePMInvoiceStatus(model:Array<InvoicesPropertyManagerModel>){
        return this.apiService.update<Array<InvoicesPropertyManagerModel>>(this.baseUri ,model);
    }
    public updateChangeRequest(buildingId,model){
        return this.apiService.patchSingle<HvacInvoicePeriodModel>(this.baseUri+'/building/'+buildingId+'/requestInvoiceChange' ,model);
    }

    public updateBuildingNote(model:HvacBuildingInternalNotesModel){
        return this.apiService.patchSingle<HvacBuildingInternalNotesModel>(this.baseUri+'/internalnotes' ,model);
    }

    public getInvoiceChangeHistory(invoiceId:number){
        const uri = `${this.baseUri}/invoicechangehistory/`+invoiceId;
        return this.getRequest(
          this.apiService.querySingle<HvacInvoiceChangeHistoryModel>(uri)
          .pipe(
            map(entity => 
              TypeConverter.convert(HvacInvoiceChangeHistoryModel, entity)
              ))
          );
    }
    public regeneratePacket(model: HvacInvoicePeriodModel) {
        return this.apiService.insert<HvacInvoicePeriodModel>(this.baseUri + '/regenerate/' + model.Id, null);
    }
}
