import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConfig } from '../../../../../_core/global.config';
import { ApiService } from '../../../../../_services/api.service';
import { BaseEntityService } from '../../../../../_services/base.service';
import { ModelCollection } from '../../../../../_sdk/collection.model';
import { InvoiceStatusModel, InvoiceStatusFilterState } from '../_models/invoice-status-model';
import { BuildingInvoiceModel } from '../_models/building-invoice.model';
import { BuildingInvoiceConfigurationListModel } from '../_models/building-invoice-configuration-list.model';
import { map } from 'rxjs/operators';
import { TypeConverter } from '../../../../../_core/type-converter';
import { BuildingInvoiceDetails } from '../_models/building-invoice-details.model';
import { UtilityBillConfigurationModel, UtilityBillList, UtilityBillListFilterState } from '../_models/utility-bill-list.model';
import { UtilityBillListData, UtilityBillUploadList, UtilityBillUploadListFilter, ViewUtilityBillUploadList } from '../_models/utility-bill-upload-list.model';
import { UtilityBillHistory, UtilityBillHistoryFilterState } from '../_models/utility-bill-history.model';

@Injectable({
    providedIn: 'root'
})
export class BuildingInvoiceService extends BaseEntityService<any> {
    constructor(readonly apiService: ApiService, private readonly config: GlobalConfig) {
        super(apiService);
    }

    protected get baseUri(): string {
        return `${this.config.api('platform').baseUri}/invoicing/metering/status`;
    }    
    protected get UBbaseUri(): string {
        return `${this.config.api('platform').baseUri}/invoicing/metering/utility-bill`;
    }
    protected get utilitybaseUri(): string {
        return `${this.config.api('platform').baseUri}/invoicing/metering//utility/provider`;

    }

    public search(request?: InvoiceStatusFilterState) {
        return super.search(request) as any;
    }

    public loadInvoiceStatusAggregate(request?: InvoiceStatusFilterState): Observable<InvoiceStatusModel> {
        const uri = `${this.baseUri}/search`;

        return this.apiService.querySingle<InvoiceStatusModel>(uri, request)
            .pipe(
                map(p => {
                    const model = TypeConverter.convert(InvoiceStatusModel, p);

                    model.Statuses = new ModelCollection((model.Statuses && model.Statuses.data || []), p.Total);
                    
                    return model;
                })
            );
    }

    public loadUtilityBillList(request?: UtilityBillListFilterState): Observable<ModelCollection<UtilityBillList>>{
        return this.getRequest(
            this.apiService.query<UtilityBillList>(this.UBbaseUri, request)
            .pipe(
                map(collection => {
                    collection.data.forEach((item: UtilityBillList, index: number) => {
                        collection.data[index] = this.toEntity(item);
                    });
    
                    return collection;
                })
            )
        );
    }
    public loadUtilityBillUploadList(request?: UtilityBillUploadListFilter): Observable<ModelCollection<UtilityBillUploadList>>{
        return this.getRequest(
            this.apiService.query<UtilityBillUploadList>(this.UBbaseUri + '/status/pending', request)
            .pipe(
                map(collection => {
                    collection.data.forEach((item: UtilityBillUploadList, index: number) => {
                        collection.data[index] = this.toEntity(item);
                    });
    
                    return collection;
                })
            )
        );
    }
    public loadUtilityBillHistory(request?: UtilityBillHistoryFilterState): Observable<ModelCollection<UtilityBillHistory>>{
        return this.getRequest(
            this.apiService.query<UtilityBillHistory>(this.UBbaseUri + '/history', request)
            .pipe(
                map(collection => {
                    collection.data.forEach((item: UtilityBillHistory, index: number) => {
                        collection.data[index] = this.toEntity(item);
                    });
    
                    return collection;
                })
            )
        );
    }
    public postUtilityBillSilentNotification(request){
        return this.apiService.putSingle(this.UBbaseUri+'/history/silent-notification', request)
    }
    public postUtilityBillUploadList(request){
        return this.apiService.putSingle(this.UBbaseUri+'/file', request)
    }
    public billingConfigurationSearch(request?: InvoiceStatusFilterState) {
        return this.apiService.query<BuildingInvoiceConfigurationListModel>(this.baseUri + '/billingConfigurationSearch/', request);
    }
    public deleteUtilityBill(id){
        return this.apiService.delete(this.UBbaseUri+'/history/'+id)
    }
    public assignPeriodUtilityBill(request){
        return this.apiService.putSingle(this.UBbaseUri+'/history/assign-period', request)
    }
    public getUtilityProviderData(request){
        return this.apiService.query(this.utilitybaseUri+ '?name=' +request)
    }
    public postUtilityConfiguration(request){
        return this.apiService.postSingle(this.UBbaseUri+'/configuration', request)
    }
    public putUtilityConfiguration(request){
        return this.apiService.putSingle(this.UBbaseUri+'/configuration', request)
    }
    public getUtilityBillConfiguration(id){
        return this.apiService.querySingle<UtilityBillConfigurationModel>(this.UBbaseUri+'/configuration/'+id)
    }
    public getRemarks(id){
        return this.apiService.querySingle(this.UBbaseUri+'/history/remarks/'+id)
    }
    public postRemarks(request){
        return this.apiService.putSingle(this.UBbaseUri+'/history/remarks', request)
    }
    public getUtilityBillDetailsData(id,accountingPeriodId){
        return this.apiService.querySingle<ViewUtilityBillUploadList>(this.UBbaseUri+'/'+id+'/'+accountingPeriodId)
    }
    //add types
    public loadInvoiceDetails(request){
        const uri = `${this.baseUri}/searchDetails`;
        return this.apiService.querySingle<BuildingInvoiceDetails>(uri, request)
    }
    public sendNotificationReminder(buildingId,accountingPeriodId,){
        return this.apiService.putSingle(this.UBbaseUri+'/history/send-reminder/'+buildingId+'/'+accountingPeriodId,null)
    }
}
