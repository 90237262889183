import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalConfig } from '../_core/global.config';

@Injectable({
    providedIn: 'root'
})
export class BlobService {
    private readonly _baseUri: string;

    constructor(
        private readonly config: GlobalConfig,
        private readonly httpClient: HttpClient,
    ) {
        this._baseUri = `${this.config.api('portal').baseUri}`;
    }

    public retrieve(path: string): Observable<HttpResponse<Blob>> {
        const url = `${this._baseUri}/${path}`;

        return this.httpClient.get(url, {
            'responseType': 'blob',
            'observe': 'response'
        });
    }

    public retrieveAccountingFile(path: string): Observable<HttpResponse<Blob>> {
        //const url = `${this._baseUri}/${path}`;

        return this.httpClient.get(path, {
            'responseType': 'blob',
            'observe': 'response'
        });
    }
}
