import { EntityModel } from '../../../../../_sdk/model';
import { DataObject, DataProperty } from '../../../../../_core/type-converter';
import { RequestTrendModel } from '../../_models/request-trend.model';
import { MonthRequestTrendModel } from '../../_models/month-request-trend.model';

@DataObject()
export class RequestTrendPMModel extends EntityModel {
    public RequestLastMonthTotal?: number = null;
    public RequestLastYearTotal?: number = null;
    public RequestMonthTotal?: number = null;
    public RequestMonthVsLastMonth?: number = null;
    public RequestYearTotal?: number = null;
    public RequestYearVsLastYear?: number = null;

    @DataProperty()
    public YearRequestTrends?: Array<RequestTrendModel> = null;

    @DataProperty()
    public MonthRequestTrends?: Array<MonthRequestTrendModel> = null;


    constructor() {
        super();
    }
}