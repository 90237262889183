import { ChangeDetectionStrategy, Component, forwardRef, ViewChild, Optional, Inject, ChangeDetectorRef,Input} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

import { BaseInputElement } from '../../../../_core/components/forms/base-input-element';
import { TimezoneModel } from '../../../../_sdk/common/timezone-info.model';
import { ValidatorArray, AsyncValidatorArray } from '../../../../_core/components/forms/validate';
import { TimezoneService } from '../../../../_services/common/timezone.service';
import { notEmpty } from '../../../../_core/rxjs.operators';

let identifier = 0;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'time-zone-dropdown',
    template: `
    <kendo-dropdownlist
        #dropDownList
        [id]="identifier"
        [data]="data$ | async"
        textField="DisplayName"
        valueField="SystemName"
        [valuePrimitive]="false"
        [(ngModel)]="value"
        [loading]="loading"
        (close)="_onClose($event)"
        [disabled]="!editable">
    </kendo-dropdownlist>
    `,
    styleUrls: ['./time-zone.dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,

    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TimeZoneDropDownComponent),
        multi: true
    }]
})
export class TimeZoneDropDownComponent extends BaseInputElement<TimezoneModel> {

    private readonly _selectedValue$: BehaviorSubject<TimezoneModel>;

    private _dropDownList$: BehaviorSubject<DropDownListComponent>;
    protected dropDownList$: Observable<DropDownListComponent>;
    protected get dropDownList(): DropDownListComponent {
        return this._dropDownList$.getValue();
    }
    
    @Input()
    public editable:boolean;
    
    @ViewChild('dropDownList', { read: DropDownListComponent })
    protected set dropDownList(value: DropDownListComponent) {
        this._dropDownList$.next(value);
    }

    public readonly identifier = `time-zone-dropdown-${identifier++}`;

    public loading: boolean;

    @ViewChild(NgModel)
    public model: NgModel;

    private readonly _data$: BehaviorSubject<Array<TimezoneModel>>;
    public readonly data$: Observable<Array<TimezoneModel>>;

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: ValidatorArray,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorArray,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly timezoneService: TimezoneService
    ) {
        super(validators, asyncValidators);

        this._selectedValue$ = new BehaviorSubject(null);

        this._dropDownList$ = new BehaviorSubject(null);
        this.dropDownList$ = this._dropDownList$.asObservable();

        this._data$ = new BehaviorSubject([]);
        this.data$ = this._data$.asObservable();
    }

    OnInit() {
        this.addSubscription(
            this.timezoneService.loading$
                .pipe(delay(1), tap(loading => this.loading = loading))
        );

        this.addSubscription(
            this.timezoneService.search()
                .pipe(
                    notEmpty(),
                    tap(data => this._data$.next(data))
                )
        );

        super.OnInit();
    }

    protected bindValue(value: TimezoneModel) {
        this._selectedValue$.next(value);
        this.changeDetector.detectChanges();
    }

    public _onClose(event: any): void {
        this.touch();
    }
}
