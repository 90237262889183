import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { ProtocolModel } from '../../../_sdk/ems-devices/protocol.model';
import * as ProtocolActions from './protocol.actions';

export interface State {
    protocolsTypes: ModelCollection<ProtocolModel>;
}

const initialState: State = {
    protocolsTypes: new ModelCollection<ProtocolModel>()
};

export function reducer(
    state: State = initialState,
    action: ProtocolActions.Actions
): State {

    let protocols: ModelCollection<ProtocolModel>;
    let index: number;

    switch (action.type) {
        case ProtocolActions.DISPOSE:
            return {
                ...state,
                protocolsTypes: initialState.protocolsTypes
            };
        case ProtocolActions.LOAD_COMPLETE:
            return {
                ...state,
                protocolsTypes: action.payload
            };
        case ProtocolActions.LOAD:
        case ProtocolActions.INSERT:
        case ProtocolActions.UPDATE:
        case ProtocolActions.DELETE:
            return state;
        case ProtocolActions.INSERT_COMPLETE:
            protocols = new ModelCollection(state.protocolsTypes.data
                .map(p => TypeConverter.convert(ProtocolModel, p)));

            return {
                ...state,
                protocolsTypes: protocols
            };

        case ProtocolActions.UPDATE_COMPLETE:
            protocols = new ModelCollection(state.protocolsTypes.data
                .map(p => TypeConverter.convert(ProtocolModel, p)));

            index = protocols.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                protocols.data[index] = action.payload;
            }

            return {
                ...state,
                protocolsTypes: protocols
            };

        case ProtocolActions.DELETE_COMPLETE:
            protocols = new ModelCollection(state.protocolsTypes.data
                .map(p => TypeConverter.convert(ProtocolModel, p)));

            index = protocols.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                protocols.data.splice(index, 1);
            }

            return {
                ...state,
                protocolsTypes: protocols
            };

        default:
            return state;
    }
}
