import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalConfig } from '../../../../../_core/global.config';
import { ApiService } from '../../../../../_services/api.service';
import { BaseEntityService } from '../../../../../_services/base.service';
import { ModelCollection } from '../../../../../_sdk/collection.model';
import { HvacInvoiceStatusFilterState, HvacInvoiceStatusModel } from '../_models/hvac-invoice-status-model';

import { TypeConverter } from '../../../../../_core/type-converter';
import { map, tap } from 'rxjs/operators';
import { DateTime, SmallDate } from '../../../../../_core/date';
import { HvacPropertyManagerViewModel, HvacPMVFilterState } from '../_models/hvac-property-manager-view.model';
import { StorageService } from '../../../../../_services/IO/storage.service';
import { HvacPropertyManagerBuildingModel } from '../_models/hvac-property-manager-building.model';

@Injectable({
    providedIn: 'root'
})
export class HvacPropertyManagerViewService extends BaseEntityService<HvacPropertyManagerViewModel> {
    public buildingId;
    constructor(readonly apiService: ApiService, private readonly config: GlobalConfig, private StorageService: StorageService) {
        super(apiService);
    }

    protected get baseUri(): string {
        return `${this.config.api('platform').baseUri}/invoicing/hvac/propertymanager`;
    }
    public searchPM(request?: HvacPMVFilterState): Observable<HvacPropertyManagerViewModel> {
        return this.apiService.querySingle<HvacPropertyManagerViewModel>(this.baseUri +'/list',request).pipe(
            map(p => {
                const model = TypeConverter.convert(HvacPropertyManagerViewModel, p);

                model.InvoicesHistory = new ModelCollection((model.InvoicesHistory && model.InvoicesHistory.data || []), p.Total);

                return model;
            })
        );
    }
    public setBuildingId(param): void{
        this.StorageService.put('pmviewBuildingId', param)
        this.buildingId = param;
    }
    public setChangeRequestView(param): void{
        this.StorageService.put('changeRequestView', param)
    }
    public getBuildingId(): Observable<number>{
        return this.StorageService.get('pmviewBuildingId')
    }
    public getChangeRequestView() :Observable<boolean>{
        return this.StorageService.get('changeRequestView')
    }
    public getBuildingList(params): Observable<Array<HvacPropertyManagerBuildingModel>>{
        return this.apiService.querySingle(this.baseUri + '/buildinglist' + params)
    }
    public propertyManagerData(param){
        return this.apiService.querySingle(this.baseUri+'/'+ param)
    }
}