<div *ngIf="show"
    [@fadeInOut]>
    <div #ref class="http-request-indicator-container"><ng-content></ng-content></div>

    <!-- If no content is provided, use the below progress bar -->
    <ng-container *ngIf="!ref.innerHTML.trim()">
      <div class="http-request-indicator-progress">
        <div class="progress">
            <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="100" aria-valuemax="100" style="width: 100%"></div>
          </div>
      </div>
    </ng-container>
</div>
