import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

// import { environment } from '../environments/environment';

import * as fromApp from './_store/app.reducers';
import * as fromUserManagement from './_store/users/user-management.reducers';
import * as fromConfiguration from './_store/configuration/configuration.reducers';
import * as fromLocalization from './_store/localization/localization.reducers';
import * as fromSecurity from './_store/security/security.reducers';

import * as fromCommon from './_store/common/common.reducers';
import * as fromDirectory from './_store/directory/directory.reducers';
import * as fromEmsDevice from './_store/ems-devices/ems-device.reducers';
import * as fromParameterConfiguration from './_store/parameter-configuration/parameter-configuration.reducers';

const environment: any = {};

@NgModule({
    imports: [
        StoreModule.forRoot(fromApp.reducers),

        StoreModule.forFeature(fromUserManagement.FEATURE_NAME, fromUserManagement.reducers),
        StoreModule.forFeature(fromConfiguration.FEATURE_NAME, fromConfiguration.reducers),
        StoreModule.forFeature(fromLocalization.FEATURE_NAME, fromLocalization.reducers),
        StoreModule.forFeature(fromSecurity.FEATURE_NAME, fromSecurity.reducers),

        StoreModule.forFeature(fromDirectory.FEATURE_NAME, fromDirectory.reducers),
        StoreModule.forFeature(fromCommon.FEATURE_NAME, fromCommon.reducers),
        StoreModule.forFeature(fromEmsDevice.FEATURE_NAME, fromEmsDevice.reducers),
        StoreModule.forFeature(fromParameterConfiguration.FEATURE_NAME, fromParameterConfiguration.reducers),

        StoreRouterConnectingModule.forRoot(),
        
        !environment.production
            ? StoreDevtoolsModule.instrument()
            : []
    ]
})
export class AppStoreModule { }
