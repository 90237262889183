import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { GeneaInputsModule } from './inputs/genea-inputs.module';
import { KendoModule } from './kendo.module';
import { LocalizerModule } from './localization/localizer.module';

import { ConcatDateTimePipe } from './pipes/concat-date-time.pipe';
import { DecodeHtmlStringPipe } from './pipes/decode-html-string.pipe';
import { KeyValuePipe } from './pipes/key-value.pipe';
import { MinutesToHoursPipe } from './pipes/minutes-to-hours.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { WeekdayPipe } from './pipes/weekday.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { TextToHtmlPipe } from './pipes/text-to-html.pipe';
import { EntityTypePipe } from './pipes/entity-type.pipe';
import { NamedComponentContainerDirective } from './named-component/named-component.directive';
import { RouteDataDirective } from './route-data/route-data.directive';

@NgModule({
    declarations: [
        // Pipes
        DecodeHtmlStringPipe,
        KeyValuePipe,
        SafeHtmlPipe,
        WeekdayPipe,
        YesNoPipe,
        MinutesToHoursPipe,
        ConcatDateTimePipe,
        TextToHtmlPipe,
        EntityTypePipe,

        NamedComponentContainerDirective,
        RouteDataDirective
    ],
    imports: [
        // Ng
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        // cdk
        DragDropModule,
        
        // Kendo
        KendoModule,

        // Shared
        GeneaInputsModule,
        LocalizerModule
    ],
    exports: [
        // Ng
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        
        // cdk
        DragDropModule,

        // Kendo
        KendoModule,

        // Shared
        ...GeneaInputsModule.exports(),
        ...LocalizerModule.exports(),

        // Declared
        DecodeHtmlStringPipe,
        KeyValuePipe,
        SafeHtmlPipe,
        WeekdayPipe,
        YesNoPipe,
        MinutesToHoursPipe,
        ConcatDateTimePipe,
        TextToHtmlPipe,
        EntityTypePipe,
        
        NamedComponentContainerDirective,
        RouteDataDirective
    ],
})
export class GeneaCommonModule {

}
