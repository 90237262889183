<span (click)="drawer.toggle()" kendoTooltip [tooltipTemplate]="expandCollapseTooltip"
    tooltipClass="tooltipExpandCollapse" position="right" showAfter="450" filter="span"
    [ngClass]="drawerExpanded ? 'toggle-btn k-icon k-i-close' : 'toggle-btn k-icon arrow-left'">
</span>
<kendo-drawer-container>
    <kendo-drawer #drawer mode="push" [mini]="miniValue" [miniWidth]="70" [position]="'end'" [width]="500"
        [(expanded)]="drawerExpanded" [autoCollapse]="false">
        <ng-template kendoDrawerTemplate>
            <div Class="notes-section" *ngIf="drawerExpanded">

                <div class="loading-indicator k-i-loading loader" *ngIf="isContentLoading$ | async">  
                </div>


                <h5>Review Utility Bill</h5>
                <p> Review the utility bill details here</p>

                <form [formGroup]="utilityBillingFileForm" class="pb-3 ensureOverflow" style="height: 95%;">
                    <div class="inputfield-container">
                        <div class="col-6 no-margin">
                            <div class="form-group">
                                <label for="BuildingName" class="control-label" formControlName="BuildingName">Building
                                    Name</label>
                                <input type="text" class="form-control form-control-sm k-textbox"
                                    formControlName="BuildingName" disabled />
                            </div>
                            <div class="form-group">
                                <label for="Provider" class="control-label" formControlName="Provider">Utility Bill
                                    Provider</label>
                                <input type="text" class="form-control form-control-sm k-textbox"
                                    formControlName="Provider" disabled />
                            </div>
                            <div class="form-group" *ngIf="isAdmin">
                                <label for="TotalCharges" class="control-label" formControlName="TotalCharges">Total
                                    Current Charges</label>
                                <input type="text" class="form-control form-control-sm k-textbox"
                                    formControlName="TotalCharges" placeholder="Total Current Charges" (input)="transformAmount($event,true)"/>
                            </div>
                            <div class="form-group" *ngIf="isAdmin">
                                <label for="EndDate" class="control-label" formControlName="EndDate">Utility Bill End
                                    Date</label>
                                <kendo-datepicker class="form-control form-control-sm k-textbox"
                                    formControlName="EndDate"></kendo-datepicker>
                            </div>
                            <div class="form-group" *ngIf="!isAdmin">
                                <label for="StartDate" class="control-label" formControlName="StartDate">Utility Bill
                                    Start Date</label>
                                <kendo-datepicker class="form-control form-control-sm k-textbox"
                                    formControlName="StartDate"></kendo-datepicker>
                            </div>
                        </div>
                        <div class="col-6 no-margin">
                            <div class="form-group">
                                <label for="Type" class="control-label" formControlName="Type">Utility Type</label>
                                <input type="text" class="form-control form-control-sm k-textbox" formControlName="Type"
                                    disabled />
                            </div>
                            <div class="form-group">
                                <label for="AccountNumber" class="control-label" formControlName="AccountNumber">Acct.
                                    #</label>
                                <input type="text" class="form-control form-control-sm k-textbox"
                                    formControlName="AccountNumber" disabled />
                            </div>
                            <div class="form-group" *ngIf="isAdmin">
                                <label for="TotalUsage" class="control-label" formControlName="TotalUsage">Total Usage Units</label>
                                <div class="form-control form-control-sm k-textbox  d-flex flex-column align-items-start" [class.red-border]="utilityBillingFileForm.get('TotalUsage').touched && utilityBillingFileForm.get('TotalUsage').errors !==null">
                                    <input type="text"  formControlName="TotalUsage" placeholder="Total Usage Units" style="box-shadow: none; border-radius: 5px 0px 0px 5px; border: none; min-height: 33px;" (input)="transformAmount($event,false)"/>
                                    <span style="color: #CCC6C6; margin-right: 10px; position: absolute; right: 5px; top:7px;">{{enumServiceHelper.getMeasurementUnitName((utilityBillData$ | async)?.UoM)}}</span>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="isAdmin">
                                <label for="StartDate" class="control-label" formControlName="StartDate">Utility Bill
                                    Start Date</label>
                                <kendo-datepicker class="form-control form-control-sm k-textbox"
                                    formControlName="StartDate"></kendo-datepicker>
                            </div>
                            <div class="form-group" *ngIf="!isAdmin">
                                <label for="EndDate" class="control-label" formControlName="EndDate">Utility Bill End
                                    Date</label>
                                <kendo-datepicker class="form-control form-control-sm k-textbox"
                                    formControlName="EndDate"></kendo-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="form-group d-flex flex-column ml-2 mr-2">
                        <label for="Comment" class="control-label" formControlName="Comment">External Comments</label>
                        <textarea class="k-textarea request-text-area" formControlName="Comment" placeholder="No comments" disabled></textarea>
                    </div>
                    <div class="form-group d-flex flex-column ml-2 mr-2" *ngIf="isAdmin">
                        <label for="Notes" class="control-label" formControlName="Notes">Internal Notes</label>
                        <textarea class="k-textarea request-text-area" formControlName="Notes"
                            placeholder="Add Notes"></textarea>
                    </div>
                    <div *ngIf="!isAdmin" class="warning-text-container">
                        Please notify us of any adjustments to account number or meter number on your utility bills for
                        updated information
                    </div>

                    <div class="upload-bill-list-container ml-2 mr-2 mb-5"
                        [class.red-border]="utilityBillingFileForm.get('Bills').touched && utilityBillingFileForm.get('Bills').errors !==null">
                        <div class="header mb-3">
                            Utility Bills
                        </div>
                        <div class="d-flex justify-content-between align-items-center flex-column"
                            *ngIf="utilityBillingFileForm.get('Bills')?.value === null || objectKeys(utilityBillingFileForm.get('Bills')?.value).length === 0">
                            <span class="no-file-upload"></span>
                            <span class="upload-text">No utility bill uploaded yet</span>
                        </div>
                        <div class="d-flex flex-wrap flex-column"
                            *ngIf="utilityBillingFileForm.get('Bills')?.value !== null">
                            <div *ngFor="let key of objectKeys(utilityBillingFileForm.get('Bills')?.value)" class="d-flex align-items-center flex-row mb-3 ml-3">
                                <div class="file-name-container" [class.selected-file-container]="selectedFile === key">
                                    <span class="pointer" (click)="loadUtilityBillFile(key,utilityBillingFileForm.get('Bills')?.value[key])">{{utilityBillingFileForm.get('Bills')?.value[key]}}</span>
                                </div>
                                <div class="download-icon-container ml-3 pointer" *ngIf="selectedFile === key" (click)="downloadFile(key,utilityBillingFileForm.get('Bills')?.value[key])"><span class="download-icon"></span></div>
                            </div>
                        </div>
                        <!-- Comenting this because this might be needed in the future -->
                        <!-- <div class="footer">
                            <input type="file" (change)="onFileChange($event)" class="form-control-file"
                                accept=".pdf, .png, .xls, .xlsx, .jpeg, .jpg" [hidden]="true" multiple #fileInput />
                            <button type="button" class="btn btn-primary btn-sm"
                                (click)="uploadUtilityBillFile()">Add More Bill</button>
                        </div> -->
                    </div>
                </form>

                <div class="submit-button-container d-inline" *ngIf="!ShowCloseButton && isAdmin">
                    <button class="k-button approve-btn" (click)="changeUtilityBillStatus(4)"> Approve </button>
                    <button class="k-button ml-2 incomplete-btn" (click)="changeUtilityBillStatus(3)" *ngIf="!hideIncompleteButton"> Incomplete </button>
                    <button class="k-button ml-2 incomplete-btn" (click)="closeTab()" *ngIf="hideIncompleteButton"> Close </button>
                </div>
                <div class="submit-button-container d-inline" *ngIf="ShowCloseButton && isAdmin">
                    <button class="k-button approve-btn" (click)="closeTab()"> Close </button>
                </div>
                <div class="submit-button-container d-inline" *ngIf="!isAdmin">
                    <button class="k-button approve-btn" (click)="closeTab()"> Close </button>
                </div>
            </div>
            <div *ngIf="!drawerExpanded">
                <div class="mini-container">
                    <span class="notes-icon pointer" title="Utility Bill Details" (click)="drawer.toggle()"></span>
                </div>
            </div>
        </ng-template>
    </kendo-drawer>
    <kendo-drawer-content>
        <div class="nav-header">
            <h2 *ngIf="(utilityBillData$|async) as invoiceData"> {{invoiceData?.CustomerName || '' }} >>
                {{invoiceData?.BuildingName || '' }} >> {{ invoiceData?.ProviderMeterNumber || ''}} ({{
                invoiceData?.Type || ''}}) - {{ invoiceData?.InvoicePeriod || ''}}</h2>
        </div>

        <main class="main-content">
            <div class="pt-1 pb-2 pr-2 pageTitlePosition">
                <div class="pdf-container" id="pdf-container">
                    <object id="pdfviewer" [data]="" type="application/pdf" style="width: 100%; height: 91vh;">
                        <div>No online PDF viewer installed</div>
                    </object>
                    <div class="error-block">
                        <span *ngIf="(error$ |async)?.status == 404" class="notFoundIcon ml-4"></span>
                        <span *ngIf="(error$ |async)?.status == 500" class="internalErrorIcon ml-4"></span>
                        <span class="error-message">{{ (error$ |async)?.errorMessage }}</span>
                    </div>

                    <div class="loading-indicator k-i-loading position-absolute" *ngIf="isLoading$ | async"> </div>
                </div>

            </div>
        </main>

        <div kendoWindowContainer></div>
        <div kendoDialogContainer></div>
    </kendo-drawer-content>
</kendo-drawer-container>
<ng-template #expandCollapseTooltip let-anchor>
    <span class="text-mute"> {{drawerExpanded ? 'Hide':'Expand'}} </span>
</ng-template>