export enum CommandRole {
    Read = 1,
    Activate = 2,
    Deactivate = 3
}

export const CommandRoleNames = new Map<number, string>([
    [CommandRole.Read, 'Read'],
    [CommandRole.Activate, 'Activate'],
    [CommandRole.Deactivate, 'Deactivate']
]);

export enum CommandRule {
    ExecuteAlways = 1,
    ExecuteIfPreviousSucceeded = 2,
    ExecuteIfPreviousFailed = 3
}

export const CommandRuleNames = new Map<number, string>([
    [CommandRule.ExecuteAlways, 'Execute Always'],
    [CommandRule.ExecuteIfPreviousSucceeded, 'Execute If Previous Succeeded'],
    [CommandRule.ExecuteIfPreviousFailed, 'Execute If Previous Failed']
]);
