import { map } from 'rxjs/operators';
// http://blog.rangle.io/angular-2-ngmodel-and-custom-form-components/

import { NgModel } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseValueAccessor } from './base-value-accessor';

import {
    AsyncValidatorArray,
    ValidatorArray,
    ValidationResult,
    message,
    validate,
} from './validate';

import { Directive } from "@angular/core";

@Directive()
export abstract class BaseInputElement<T> extends BaseValueAccessor<T> {
    protected abstract model: NgModel;

    // we will ultimately get these arguments from @Inject on the derived class
    constructor(
        protected readonly validators: ValidatorArray,
        protected readonly asyncValidators: AsyncValidatorArray
    ) {
        super();
    }

    protected innerValidate(): Observable<ValidationResult> {
        return validate
            (this.validators, this.asyncValidators)
            (this.model.control);
    }

    public get invalid(): Observable<boolean> {
        return this.model.control.touched && this.innerValidate().pipe(map(v => Object.keys(v || {}).length > 0));
    }

    public get failures(): Observable<Array<string>> {
        return this.innerValidate().pipe(map(v => Object.keys(v).map(k => message(v, k))));
    }
    
    public setDisabledState(isDisabled: boolean): void {

        if (this.model && this.model.control) {
            if (isDisabled) {
                this.model.control.disable();
            } else {
                this.model.control.enable();
            }
        }
    }
}
