import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-phone',
    styleUrls: ['form-phone.component.scss'],
    template: `
    <ng-container [formGroup]="group">
        <label *ngIf="config.label">{{ config.label }}</label>
        <input type="text" [placeholder]="'(___) ___-____'" 
            [ngClass]="[config.cssClass || '']"
            [formControlName]="config.name">
    </ng-container>
  `
})
export class FormPhoneComponent implements Field {

    config: FieldConfig;
    group: FormGroup;
}
