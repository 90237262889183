import { ComponentFactoryResolver, ComponentRef, ContentChildren, Directive, HostListener, Inject, Optional, QueryList, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';

import { combineLatest, EMPTY, merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { ControlErrorContainerDirective } from './control-error-container.directive';
import { ControlErrorComponent } from './control-error.component';
import { FORM_ERRORS } from './form-errors';
import { FormSubmitDirective } from './form-submit.directive';
import { BaseDirective } from '../../../_core/components/base.directive';
import { InputTouchDirective } from '../../common/inputs/directives/input-touch.directive';

// https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5
@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[formControl]:not(label), [formControlName]:not(label)'
})
export class ControlErrorsDirective extends BaseDirective {

    private readonly _thisTouched$: Subject<boolean>;
    private readonly _inputs$: Subject<Array<InputTouchDirective>>;
    private readonly _touched$: Observable<boolean>;

    private readonly _container: ViewContainerRef;
    private readonly _submit$: Observable<Event>;
    private _component: ControlErrorComponent;
    private _ref: ComponentRef<ControlErrorComponent>;
    
    @HostListener('blur')
    protected onBlur() {
        this._thisTouched$.next(true);
    }

    @ContentChildren(InputTouchDirective)
    protected set inputs(value: QueryList<InputTouchDirective>) {
        this._inputs$.next(value.toArray());
    }

    constructor(
        vcr: ViewContainerRef,
        @Optional() /*@Host()*/ private readonly form: FormSubmitDirective,
        @Optional() controlErrorContainer: ControlErrorContainerDirective,
        @Inject(FORM_ERRORS) private readonly errors: any,
        private readonly control: NgControl,
        private readonly resolver: ComponentFactoryResolver
    ) {
        super();

        this._thisTouched$ = new Subject();
        this._inputs$ = new Subject();
        this._touched$ = this._inputs$
            .pipe(
                switchMap(inputs => combineLatest(inputs.map(input => input.touched$))
                    .pipe(
                        map(touches => touches.any(p => p)),
                        filter(p => p)
                    )
                ),
                switchMap(inputTouched => this._thisTouched$
                    .pipe(
                        map(touched => inputTouched || touched)
                    )
                )
            );

        this._container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
        this._submit$ = this.form ? this.form.submit$ : EMPTY;
    }

    public OnInit() {
        let submitted: boolean = false;  /* update value when form is submitted  */
        this.addSubscription(
            merge(
                this._submit$.pipe(map(() => 'submit'), tap( _ => submitted = true)),
                this._touched$.pipe(map(() => 'touched')),
                this.control.valueChanges.pipe(map(() => 'valueChanges')),
                this.control.statusChanges.pipe(map(() => 'statusChanges'))
            ).pipe(
                tap(x => {
                    let message: string;

                    // TODO: Revisit - UPDATED 04/20/21 by Siddhant     
                    // trgigger error on submitted when form control is still pristine              
                    if(submitted || !this.control.pristine) {
                        const controlErrors = this.control.errors;

                        // console.log(controlErrors, this.control.value);

                        if (controlErrors) {

                            const firstKey = Object.keys(controlErrors).firstOrDefault();
                            const errorHandler = this.errors[firstKey];

                            if (!errorHandler) {
                                // We don't have a particular handler for this error... Short circuit
                                return;
                            }

                            message = errorHandler(controlErrors[firstKey]);
                        }
                    }

                    this.setError(message);
                })
            )
        );
    }

    public OnDestroy(): void {
        if (this._ref) {
            this._ref.destroy();
            this._ref = null;
        }
    }

    private setError(text: string) {
        if (!this._ref) {
            const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
            this._ref = this._container.createComponent(factory);

            this._component = this._ref.instance as ControlErrorComponent;
        }

        this._component.text = text;

        this._component.toggle(!String.isNullOrEmpty(text));
    }
}