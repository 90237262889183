import { ChangeDetectorRef, Directive, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { tap, take, delay, switchMap, takeUntil, takeWhile } from 'rxjs/operators';

import { BaseDirective } from '../../../_core/components/base.directive';
import { hasRequiredField } from '../../../_core/components/forms/form-helper';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'label[formControlName]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => GeneaLabelDirective),
        multi: true
    }]
})
export class GeneaLabelDirective extends BaseDirective implements ControlValueAccessor {
    @Input()
    public formControlName: string;

    @Input()
    public readOnly: boolean = false;

    @Input()
    public cssClass = 'required';

    @HostBinding('class.required')
    get required(): boolean {
        return this._required;
    }

    private _required = false;

    constructor(private readonly container: ControlContainer, private readonly changeDetector: ChangeDetectorRef) {
        super();
    }

    AfterViewInit(): void {
        if (this.readOnly || !this.formControlName) {
            return;
        }

        const control = this.container.control.get(this.formControlName);

        this.addSubscription(
            hasRequiredField(control).pipe(
                delay(0),
                take(1),
                tap(required => {
                    this._required = required;
                    this.changeDetector.markForCheck();
                })
            )
        );

        this.addSubscription(
            control.parent.valueChanges.pipe(
                switchMap(_ => hasRequiredField(control)),
                tap(required => {
                    this._required = required;
                    this.changeDetector.markForCheck();
                })
            )
        );
    }

    public registerOnTouched(fn: any): void { /* NOOP */ }
    public registerOnChange(fn: any): void { /* NOOP */ }
    public writeValue(obj: any): void { /* NOOP */ }
}
