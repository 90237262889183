import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../../_core/components/base.component';

// https://netbasal.com/make-your-angular-forms-error-messages-magically-appear-1e32350b7fa5
@Component({
    template: `
    <span class="text-danger" *ngIf="_show">
        {{ _text }}
    </span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
        span{
            font-size:12px;
        }
    `]
})
export class ControlErrorComponent extends BaseComponent {
    public _text: string;
    public _show = false;

    @Input()
    public set text(value: string) {
        this._text = value;
    }

    public toggle(value: boolean) {
        this._show = value;
        this.cdr.detectChanges();
    }
    
    constructor(private cdr: ChangeDetectorRef) {
        super();
    }
}
