////////////////////////////////////////////////////////////////////////////////
/// Common custom validators used for dynamic validation
////////////////////////////////////////////////////////////////////////////////

import { ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export function required(key?: string, condition?: (control: AbstractControl) => boolean): ValidatorFn {
    return ((ctrl: AbstractControl): ValidationErrors => {
        if ((condition && condition(ctrl)) || !ctrl.value) {
            return { 'required': `'${key || 'Field'}' is required.` };
        }
        return null;
    });
}

export function requiredIfValidator(predicate: () => boolean) {
    return (formControl: AbstractControl) => {
      if (!formControl.parent) {
        return null;
      }

      if (predicate()) {
        return Validators.required(formControl); 
      }
      
      return null;
    }
  }