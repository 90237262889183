import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { GeneaCommonModule } from '../common/common.module';
import { NavigationIndicatorComponent } from './navigation-indicator.component';

@NgModule({
    declarations: [
        NavigationIndicatorComponent
    ],
    imports: [
        // Custom
        GeneaCommonModule,
    ],
    exports: [
        NavigationIndicatorComponent
    ]
})
export class NavigationIndicatorModule {
    
}
