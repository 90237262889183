
export const Milliseconds = (() => {
    const HOURS_IN_DAY = 24;
    const DAYS_IN_YEAR = 365.2422;

    const perTick = .0001;
    const perSecond = 1000;
    const perMinute = perSecond * 60;
    const perHour = perMinute * 60;
    const perDay = perHour * HOURS_IN_DAY;
    const perYear = perDay * DAYS_IN_YEAR;

    return {
        perTick: perTick,
        perSecond: perSecond,
        perMinute: perMinute,
        perHour: perHour,
        perDay: perDay,
        perYear: perYear
    };

})();

export const epoch = new Date(1970, 1, 1, 0, 0, 0, 0);

