import * as LocaleStringResourceActions from './locale-string-resource.actions';
import { LocaleStringResourceModel } from '../../../_sdk/localization/locale-string-resource.model';
import { ModelCollection } from '../../../_sdk/collection.model';
import { TypeConverter } from '../../../_core/type-converter';

export interface State {
    resources: ModelCollection<LocaleStringResourceModel>;
}

const initialState: State = {
    resources: new ModelCollection<LocaleStringResourceModel>()
};

export function reducer(
    state: State = initialState,
    action: LocaleStringResourceActions.Actions
): State {

    let resources: ModelCollection<LocaleStringResourceModel>;
    let index: number;

    switch (action.type) {
        case LocaleStringResourceActions.DISPOSE:
            return {
                ...state,
                resources: initialState.resources
            };
        case LocaleStringResourceActions.LOAD_COMPLETE:
            return {
                ...state,
                resources: action.payload
            };
        case LocaleStringResourceActions.LOAD:
        case LocaleStringResourceActions.INSERT:
        case LocaleStringResourceActions.UPDATE:
        case LocaleStringResourceActions.DELETE:
            return state;
        case LocaleStringResourceActions.INSERT_COMPLETE:
            return {
                ...state,
                resources: resources
            };

        case LocaleStringResourceActions.UPDATE_COMPLETE:
            resources = new ModelCollection(state.resources.data
                .map(p => TypeConverter.convert(LocaleStringResourceModel, p)));

            index = resources.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                resources.data[index] = action.payload;
            }

            return {
                ...state,
                resources: resources
            };

        case LocaleStringResourceActions.DELETE_COMPLETE:
            resources = new ModelCollection(state.resources.data
                .map(p => TypeConverter.convert(LocaleStringResourceModel, p)));

            index = resources.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                resources.data.splice(index, 1);
            }

            return {
                ...state,
                resources: resources
            };
        default:
            return state;
    }
}
