import { DataObject, DataProperty } from '../../_core/type-converter';
import { BaseModel } from '../model';
import { CustomerModel } from '../customers/customer.model';
import { BuildingModel } from '../buildings/building.model';
import { TenantModel } from '../tenants/tenant.model';
import { LeaseModel } from '../leases/lease.model';
import { LeaseAreaModel } from '../leases/lease-area.model';
import { ProductModel } from '../products/product.model';

@DataObject()
export class ContextReferenceModel extends BaseModel {

    @DataProperty({ instanceType: CustomerModel })
    public Customers: Array<CustomerModel> = [];

    @DataProperty({ instanceType: BuildingModel })
    public Buildings: Array<BuildingModel> = [];

    @DataProperty({ instanceType: TenantModel })
    public Tenants: Array<TenantModel> = [];

    @DataProperty({ instanceType: LeaseModel })
    public Leases: Array<LeaseModel> = [];

    @DataProperty({ instanceType: LeaseAreaModel })
    public LeaseAreas: Array<LeaseAreaModel> = [];

    @DataProperty({ instanceType: ProductModel })
    public Products: Array<ProductModel> = [];
}