import { BaseModel, EntityModel, CoreEntity } from '../model';
import { DataObject, CompositeType, DataProperty } from '../../_core/type-converter';
import { FilterState, FilterContext } from '../page-state';
import { AddressModel } from '../common/address.model';
import { CustomerModel } from '../customers/customer.model';
import { UserFilter } from '../users/user.model';
import { EntityType } from '../search/entity-type.enum';
import { AllowanceModel } from '../../admin/platform/allowance/_models/allowance.model';
import { BuildingModel } from '../buildings/building.model';
import {TenantSubmeterBillingSettingsModel} from './tenant-submeter-billing-settings.model';
import { BusinessType, BussinessSubType, LeaseType } from '../../admin/platform/tenants/tenant-submeter-billing-settings.enum';
@DataObject()
export class TenantModel extends EntityModel implements CoreEntity {
    public readonly EntityType: EntityType = EntityType.Tenant;

    public Name: string = null;
    public Description: string = null;
    public Active = false;

    @DataProperty()
    public Address: AddressModel = null;

    @DataProperty()
    public Buildings: Array<any> = [];

    @DataProperty()
    public Customer: CustomerModel = null;
    
    @DataProperty()
    public Allowance: AllowanceModel = null;

    @DataProperty()
    public TenantClassifyData: TenantSubmeterBillingSettingsModel = null;

    public BusinessType :  BusinessType = null;

    public BussinessSubType : BussinessSubType = null;

    public BussinessSubTypes : Array<BussinessSubType> =  [];

    public LeaseType : LeaseType = null;
}

export type TenantForStorage = Pick<TenantModel, 'Name' | 'Id'>;

export interface TenantFilter {
    TenantId?: number;
    Name?: string;
    CustomerName?: string;
    BuildingName?:string;
    CustomerId?: number;
    BuildingId?: number;
}

export type TenantFilterState = CompositeType<TenantFilter, FilterContext>;
