import { HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ToastrService } from '../_modules/toastr';

import { HttpStatus } from './http-response-status';

@Injectable()
export class HttpResponseService {

    constructor(private readonly toastrService: ToastrService) { }

    public handleResponse(httpEvent: HttpEvent<any>, request: HttpRequest<any> = null): HttpEvent<any> {
        const status = HttpStatus.fromHttpEvent(httpEvent);
        
        if(httpEvent['error']?.size){
          var reader = new FileReader();
          var errorJson;
          reader.onload = () => {
            var string = String(reader.result)
            errorJson = JSON.parse(string);
            this.toastrService.error(errorJson.Errors.first().Message);
          } 
          reader.readAsText(httpEvent['error']);
        }
        else
        if (status.error) {
            this.toastrService.error(...(status.messages || [status.description]));
        }

        if (status.success) {
            if(status.warnings.any()) {
                this.toastrService.warn(...status.warnings);
            } else {
                this.toastrService.success(...(status.messages || [status.description]));
            }
        }

        return httpEvent;
    }    
}
