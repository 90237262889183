import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromParameterDataType from './parameter-data-type/parameter-data-type.reducers';
import * as fromParameterOption from './parameter-option/parameter-option.reducers';
import * as fromParameterOptionGroup from './parameter-option-group/parameter-option-group.reducers';
import * as fromTemplate from './templates/template.reducers';

export const FEATURE_NAME = 'paramConfigStore';

export interface FeatureState {
    parameterDataTypeState: fromParameterDataType.State;
    parameterOptionState: fromParameterOption.State;
    parameterOptionGroupState: fromParameterOptionGroup.State;
    templatesState: fromTemplate.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    parameterDataTypeState: fromParameterDataType.reducer,
    parameterOptionState: fromParameterOption.reducer,
    parameterOptionGroupState: fromParameterOptionGroup.reducer,
    templatesState: fromTemplate.reducer
};

export const featureSelector = createFeatureSelector<FeatureState>(FEATURE_NAME);

const getParameterDataTypeStateSelector = (state: FeatureState) => state.parameterDataTypeState;
export const getParameterDataTypeState = createSelector(featureSelector, getParameterDataTypeStateSelector);
export const getParameterDataTypes = createSelector(getParameterDataTypeState, s => s.parameterDataTypes);

const getParameterOptionStateSelector = (state: FeatureState) => state.parameterOptionState;
export const getParameterOptionState = createSelector(featureSelector, getParameterOptionStateSelector);
export const getParameterOptions = createSelector(getParameterOptionState, s => s.parameterOptions);

const getParameterOptionGroupStateSelector = (state: FeatureState) => state.parameterOptionGroupState;
export const getParameterOptionGroupState = createSelector(featureSelector, getParameterOptionGroupStateSelector);
export const getParameterOptionGroups = createSelector(getParameterOptionGroupState, s => s.parameterOptionGroups);

const getTemplateStateSelector = (state: FeatureState) => state.templatesState;
export const getTemplateState = createSelector(featureSelector, getTemplateStateSelector);
export const getTemplates = createSelector(getTemplateState, s => s.templates);
