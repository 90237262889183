import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-input',
    styleUrls: ['form-input.component.scss'],
    template: `
    <ng-container [formGroup]="group">
        <input
            type="text"
            [ngClass]="[config.cssClass || '']"
            [attr.placeholder]="config.placeholder"
            [formControlName]="config.name" />
    </ng-container>
  `
})
export class FormInputComponent implements Field {

    config: FieldConfig;
    group: FormGroup;
}
