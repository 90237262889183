
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { GlobalConfig } from '../../_core/global.config';
import { TypeConverter } from '../../_core/type-converter';

import { CacheableEntityService } from '../cacheable-entity.service';
import { ApiService } from '../api.service';

import * as fromConfiguration from '../../_store/configuration/configuration.reducers';
import * as SettingActions from '../../_store/configuration/settings/setting.actions';
import { SettingModel, SettingFilterState } from '../../_sdk/configuration/setting.model';
import { ModelCollection } from '../../_sdk/collection.model';

@Injectable({
    providedIn: 'root'
})
export class SettingService extends CacheableEntityService<SettingModel> {

    private readonly _source$: Observable<ModelCollection<SettingModel>>;

    constructor(
        apiService: ApiService,
        config: GlobalConfig,
        private readonly store: Store<fromConfiguration.FeatureState>
    ) {
        super(apiService, config);

        this._source$ = this.store.select(fromConfiguration.getSettings);
    }

    protected get baseUri(): string {
        return `${this.config.api('portal').baseUri}/setting`;
    }

    protected toEntity(source: any): SettingModel {
        return TypeConverter.convert(SettingModel, source);
    }

    public getById(id: number): Observable<SettingModel> {
        return this.search({ ...this.defaultFilterState })
            .pipe(
                map(p => p.data.find(f => f.Id === id))
            );
    }

    public delete(model: SettingModel): Observable<SettingModel> {
        this.store.dispatch(new SettingActions.Delete(model));

        return super.delete(model)
            .pipe(
                tap((result) => this.store.dispatch(new SettingActions.DeleteComplete(result)))
            );
    }

    public insert(model: SettingModel): Observable<SettingModel> {
        this.store.dispatch(new SettingActions.Insert(model));

        return super.insert(model)
            .pipe(
                tap((result) => this.store.dispatch(new SettingActions.InsertComplete(result)))
            );
    }

    public update(model: SettingModel): Observable<SettingModel> {
        this.store.dispatch(new SettingActions.Update(model));

        return super.update(model)
            .pipe(
                tap((result) => this.store.dispatch(new SettingActions.UpdateComplete(result)))
            );
    }

    public search(request?: SettingFilterState): Observable<ModelCollection<SettingModel>> {
        return super.filter(request, i => {
            return (
                String.looseContains(i.Name, request.Name)
                && String.looseContains(i.Value, request.Value)
            );
        });
    }

    public get source$(): Observable<ModelCollection<SettingModel>> {
        return this._source$;
    }

    public clear(): void {
        this.store.dispatch(new SettingActions.Dispose());
    }

    protected beginLoadingData(): void {
        this.store.dispatch(new SettingActions.Load());
    }

    protected endLoadingData(data: ModelCollection<SettingModel>): void {
        this.store.dispatch(new SettingActions.LoadComplete(data));
    }
}
