import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpRequestIndicatorService } from './http-request-indicator.service';
import { BaseComponent } from '../../_core/components/base.component';

@Component({
    selector: 'http-request-indicator',
    templateUrl: './http-request-indicator.component.html',
    styleUrls: ['./http-request-indicator.component.scss'],
    animations: [
        trigger('fadeInOut', [
            // transition in
            transition(':enter', [   // :enter is alias to 'void => *'
                style({ opacity: 1 })
            ]),
            transition(':leave', [   // :leave is alias to '* => void'
                animate(500, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class HttpRequestIndicatorComponent extends BaseComponent {

    public show = false;

    constructor(private readonly ajaxRequestService: HttpRequestIndicatorService) {
        super();
    }

    OnInit() {
        this.addSubscription(
            this.ajaxRequestService.requests$
                .pipe(
                    tap(requests => {
                        this.show = requests.length > 0;
                    })
                )
        );
    }
}
