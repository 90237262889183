import { Component, Output, ViewChild } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap, take } from 'rxjs/operators';
import { DynamicFormComponent } from '../containers/dynamic-form.component';
import { FieldConfig } from '../models/field-config.interface';
import { notNull } from '../../../../_core/rxjs.operators';
import { TypeConverter } from '../../../../_core/type-converter';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'filter-form',
    template: `
    <div class="card bg-light">
        <div class="card-body filters">
            <dynamic-form [config]="fields" [layoutMode]="'horizontal'" [displayMode]="'edit'" #form="dynamicForm" (submit)="submit($event)">
            </dynamic-form>
        </div>
    </div>`
})
export class FilterComponent {

    private readonly _form$: BehaviorSubject<DynamicFormComponent>;

    private readonly clearButton: FieldConfig;

    private fieldConfig: any = {};

    protected readonly debounceTime = 250;

    @ViewChild('form', { read: DynamicFormComponent })
    protected get form(): DynamicFormComponent {
        return this._form$.getValue();
    }
    protected set form(value: DynamicFormComponent) {
        this._form$.next(value);
    }

    @Output()
    public get controls() { return this.form.controls; }

    @Output()
    public readonly changes$: Observable<any>;

    @Output()
    public get valid() { return this.form.valid; }

    @Output()
    public get value() { return this.form.value; }

    public static configure<T>(key: keyof T, config: FieldConfig): FieldConfig {
        config.name = key.toString();

        return config;
    }

    constructor() {
        this._form$ = new BehaviorSubject(null);

        this.clearButton = {
            value: '✕',
            name: 'submit',
            type: 'button',
            cssClass: 'k-button k-primary'
        };

        this.changes$ = this._form$
            .pipe(
                notNull(),
                take(1),
                switchMap(form => form.changes$),
                distinctUntilChanged(TypeConverter.isEquivalent),
                debounceTime(this.debounceTime),
                startWith(null)
            );
    }

    public setValue(name: string, value: any) {
        this.form.setValue(name, value);
    }

    public addField(config: FieldConfig): this {
        this.fieldConfig[config.name] = config;
        
        return this;
    }

    public addFields(configs: FieldConfig[]): this {
        configs.forEach(config => this.addField(config));
        return this;
    }

    public get fields(): FieldConfig[] {
        return Object.keys(this.fieldConfig)
            .map(p => this.fieldConfig[p])
            .concat([this.clearButton]);

        // return [...this.fieldConfig, this.clearButton];
    }

    public submit(value: any) {
        this.reset();
    }

    public reset(): void {
        this.form.resetForm();
    }
}
