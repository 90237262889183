import { DataObject, DataProperty } from '../../_core/type-converter';
import { EntityModel } from '../model';
import { UserRoleModel } from '../users/user-role.model';

@DataObject()
export class LeaseAreaRequestConfigurationModel extends EntityModel {

    public EnableRequests = true;

    public EnableRecurringRequests = true;

    public EnableManagerRecurringRequests = true;

    public EnableUserRecurringRequests = true;

    public NotifyRequester = true;

    public NotifyPropertyManager = false;

    public NotifyBuildingEngineer = false;

    public NotifyTenantManager = false;

    public ControlHVACAndLightTogether = false;

    public EnableHVACRecurringRequests = true;

    public EnableLightingRecurringRequests = true;

    public RequirePlatformUserRequestReference = false;

    public RequireTenantUserRequestReference = false;
}
