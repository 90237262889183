import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { GlobalConfig } from '../../_core/global.config';
import { TypeConverter } from '../../_core/type-converter';
import { ApiService } from '../api.service';

import { TimezoneModel } from '../../_sdk/common/timezone-info.model';
import { CacheService } from '../caching/cache.service';

@Injectable({
    providedIn: 'root'
})
export class TimezoneService {
    private static readonly _cacheKey = 'global-timezones';

    private readonly _loading$ = new BehaviorSubject<boolean>(false);
    public readonly loading$ = this._loading$.asObservable();

    constructor(
        private readonly apiService: ApiService,
        private readonly config: GlobalConfig,
        private readonly cacheService: CacheService
    ) {
    }

    protected get baseUri(): string {
        return `${this.config.api('portal').baseUri}/common`;
    }

    protected toEntity(source: any): TimezoneModel {
        return TypeConverter.convert(TimezoneModel, source);
    }

    public getTimezoneById(id: string): Observable<TimezoneModel> {
        const uri = `${this.baseUri}/timezone/${id}`;

        return this.apiService.querySingle<TimezoneModel>(uri);
    }

    public search(): Observable<Array<TimezoneModel>> {
        const uri = `${this.baseUri}/timezone/search`;

        const timeZones = this.cacheService.get(
            TimezoneService._cacheKey,
            this.apiService.query<TimezoneModel>(uri)
                .pipe(
                    map(collection => {
                        return collection.data.map(p => this.toEntity(p));
                    })
                )
        );

        return timeZones;
    }

    public clear(): void {
        this.cacheService.set(TimezoneService._cacheKey, null);
    }
}
