import { TypeConverter } from "./type-converter";

export interface IpAddress {
    segmentA: string;
    segmentB: string;
    segmentC: string;
    segmentD: string;
}

export class IpAddress {
    public static empty(): IpAddress {
        return {
            segmentA: '',
            segmentB: '',
            segmentC: '',
            segmentD: ''
        };
    }

    public static valid(value: string | IpAddress): boolean {
        if(!value) {
            return false;
        }
        
        let ip: IpAddress;

        if(TypeConverter.isString(value)) {
            ip = IpAddress.parse(value);
        } else {
            ip = value;
        }

        return Object.keys(ip)
            .reduce((valid, segment) => {
                const num = TypeConverter.toNumber(ip[segment]);

                return valid && num >= 0 && num <= 255;
            }, true);
    }

    public static parse(value?: string): IpAddress {
        const output: IpAddress = IpAddress.empty();

        if (String.isNullOrEmpty(value)) {
            return output;
        }

        const split = value.split('.').map(e => e.trim().numeric());

        if (split.length > 0) {
            output.segmentA = split[0].substring(0, 3);
        }

        if (split.length > 1) {
            output.segmentB = split[1].substring(0, 3);
        }

        if (split.length > 2) {
            output.segmentC = split[2].substring(0, 3);
        }

        if (split.length > 3) {
            output.segmentD = split[3].substring(0, 3);
        }

        return output;
    }

    public static format(value: IpAddress): string {
        if (!value) {
            return '';
        }

        value.segmentA = String.trimOrEmpty(value.segmentA).numeric();
        value.segmentB = String.trimOrEmpty(value.segmentB).numeric();
        value.segmentC = String.trimOrEmpty(value.segmentC).numeric();
        value.segmentD = String.trimOrEmpty(value.segmentD).numeric();

        if (String.isNullOrEmpty(value.segmentA)
            && String.isNullOrEmpty(value.segmentB)
            && String.isNullOrEmpty(value.segmentC)
            && String.isNullOrEmpty(value.segmentD)) {
            return '';
        }

        return `${value.segmentA}.${value.segmentB}.${value.segmentC}.${value.segmentD}`;
    }
}
