import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCountry from './country/country.reducers';
import * as fromStateProvince from './state-province/state-province.reducers';
import * as fromRegion from './region/region.reducers';

export const FEATURE_NAME = 'directoryStore';

export interface FeatureState {
    countryState: fromCountry.State;
    stateProvinceState: fromStateProvince.State;
    regionState: fromRegion.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    countryState: fromCountry.reducer,
    stateProvinceState: fromStateProvince.reducer,
    regionState: fromRegion.reducer
};

export const featureSelector = createFeatureSelector<FeatureState>(FEATURE_NAME);

const getCountryStateSelector = (state: FeatureState) => state.countryState;
export const getCountryState = createSelector(featureSelector, getCountryStateSelector);
export const getCountries = createSelector(getCountryState, s => s.countries);

const getStateProvinceStateSelector = (state: FeatureState) => state.stateProvinceState;
export const getStateProvinceState = createSelector(featureSelector, getStateProvinceStateSelector);
export const getStateProvinces = createSelector(getStateProvinceState, s => s.stateProvinces);

const getRegionStateSelector = (state: FeatureState) => state.regionState;
export const getRegionState = createSelector(featureSelector, getRegionStateSelector);
export const getRegions = createSelector(getRegionState, s => s.regions);


