import { EntityModel } from '../../../../../_sdk/model';
import { CompositeType, DataObject, DataProperty } from '../../../../../_core/type-converter';
import { FilterState } from '../../../../../_sdk/page-state';
import { UserModel } from '../../../../../_sdk/users/user.model';
import { InvoiceStateType } from './enumerations/invoice-state-type.enumeration';
import { HvacInvoiceModel } from './hvac-invoice.model';
import { ProcessingStatusType } from './enumerations/processing-status-type.enumeration';
import { SortDirectionType } from '../../../../../_sdk/sort-direction.enumeration';
import { BillingPeriodModel } from '../../_models/billing-period.model';

import { CostTrendModel } from '../../_models/cost-trend.model';
import { InvoiceStateHistoryModel } from '../../meter-invoicing/_models/invoice-state-history.model';
import { HvacChangeRequestModel } from './hvac-change-request.model';
import { InvoiceStatusType } from './enumerations/invoice-status-type.enumeration';
import { HvacBuildingInternalNotesModel } from './hvac-building-internal-notes.model';

@DataObject()
export class HvacInvoicePeriodModel extends EntityModel {
    ProcessingStatus?: ProcessingStatusType = null;
    ProcessingProgress?: number = null;
    State?: InvoiceStateType = null;
    Status?:InvoiceStatusType = null;
    BuildingId: number = null;
    BuildingName: string = null;
    CustomerId?: number = null;
    CustomerName: string = null;
    BillingPeriodId?: number = null;

    RequestComment?: String = null;
    StartDate: Date = null;
    EndDate : Date = null;
    Label: string = null;
    FilePath: string = null;
    Uri: string = null;

    PreviousPeriodCost: number = null;
    TotalCost: number = null;
    RequestCount: number = null;
    TenantCount: number = null;  

    @DataProperty({instanceType: CostTrendModel})
    CostTrends: Array<CostTrendModel> = [];

    @DataProperty()
    InternalNote: HvacBuildingInternalNotesModel = null;

    @DataProperty()
    Conversation: HvacChangeRequestModel = null;

    @DataProperty()
    BillingPeriod?: BillingPeriodModel = null;

    @DataProperty({instanceType: HvacInvoiceModel})
    Invoices: Array<HvacInvoiceModel> = [];

    @DataProperty({instanceType: InvoiceStateHistoryModel})
    History: Array<InvoiceStateHistoryModel> = [];
    
    CreatedOn: Date = null;

    @DataProperty()
    CreatedBy: UserModel = null;
    ModifiedOn: Date = null;
    
    @DataProperty()
    ModifiedBy: UserModel = null;

    Active: boolean = null;
    Deleted: boolean = null;
}

export interface HvacInvoicePeriodFilter {
    BuildingId: number;
    SortField?: string;
    SortDirection?: SortDirectionType;
}

export type HvacInvoicePeriodFilterState = CompositeType<HvacInvoicePeriodFilter, FilterState>;