import { EntityModel } from '../../../../../_sdk/model';
import { UserModel } from '../../../../../_sdk/users/user.model';
import { InvoiceStateType } from './enumerations/invoice-state-type.enumeration';

export class InvoiceStateHistoryModel extends EntityModel {   
    PreviousState?: InvoiceStateType;
    NextState?: InvoiceStateType;
    ModifiedDate: Date;
    ModifiedBy: UserModel;
    Description: string;
}