import { WeekDay } from '@angular/common';
import { DataObject, DataProperty, TypeConverter } from '../../_core/type-converter';
import { BaseModel } from '../model';
import { ServiceTypeModel } from './service-type.model';
import { TimeSpan, TimeRange } from '../../_core/date';
import { ServiceTimeRange } from '../../_core/date/date.timerange';

@DataObject()
export class ServiceHourModel extends BaseModel {

    public get key(): string {
        const values: Array<string> = [];

        values.push((this.DayOfWeek || '').toString());
        values.push(((this.ServiceType && this.ServiceType.Id) || '').toString());

        const key = values.toDelimitedString('-');

        return key;
    }

    public DayOfWeek: WeekDay = null;

    public OnDemand = false;

    @DataProperty()
    public StartTime: TimeSpan = null;

    @DataProperty()
    public EndTime: TimeSpan = null;

    @DataProperty()
    public ServiceType: ServiceTypeModel = null;

    // Computed
    // Projects a TimeRange instance based on the current lease area hour
    public get timeRangeComputed(): ServiceTimeRange {
        return {
            dayOfWeek: this.DayOfWeek,
            startTime: this.StartTime || TimeSpan.empty(),
            endTime: this.EndTime || TimeSpan.empty(),
            onDemand: this.OnDemand
        };
    }

    // Takes the incoming value and sets various properties
    public set timeRangeComputed(value: ServiceTimeRange) {
        // Default the value...
        value = value || Object.assign(TimeRange.empty(this.DayOfWeek), { onDemand: false });

        // dayOfWeek can equal 0, which is 'falsy', so we need to check if it's in fact null...
        this.DayOfWeek = TypeConverter.isNull(value.dayOfWeek)
            ? this.DayOfWeek
            : value.dayOfWeek;

        this.StartTime = value.startTime || TimeSpan.empty();
        this.EndTime = value.endTime || TimeSpan.empty();
        this.OnDemand = value.onDemand || false;
    }
}
