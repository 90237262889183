import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from '../../../_core/components/base.component';

@Component({
    selector: 'app-action-buttons',
    templateUrl: './action-buttons.component.html',
    styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent extends BaseComponent {

    @Input()
    public formGroup: FormGroup;

    @Input()
    public cancelText: string;

    @Input()
    public isNew: boolean;

    @Input()
    public disableSaveButton: boolean;

    @Input()
    public hideCancelButton: boolean;

    @Input()
    public hideDeleteButton: boolean;

    @Input()
    public hideSaveButton: boolean;

    @Input()
    public hideSaveAndCloseButton: boolean;

    @Output()
    public cancelClick: EventEmitter<any>;

    @Output()
    public deleteClick: EventEmitter<any>;

    @Output()
    public saveClick: EventEmitter<any>;

    @Output()
    public saveAndCloseClick: EventEmitter<any>;

    constructor() {
        super();

        this.cancelClick = new EventEmitter();
        this.deleteClick = new EventEmitter();
        this.saveClick = new EventEmitter();
        this.saveAndCloseClick = new EventEmitter();
    }

    public get saveButtonDisabled(): boolean {
        return this.disableSaveButton ||
            (this.formGroup && (!this.formGroup.valid || (!this.isNew && this.formGroup.pristine)));
    }

    public onClickCancel(e: any): void {
        this.cancelClick.emit(e);
    }

    public onClickDelete(e: any): void {
        this.deleteClick.emit(e);
    }
    
    public onClickSave(e: any): void {
        this.saveClick.emit(e);
    }

    public onClickSaveAndClose(e: any): void {
        this.saveAndCloseClick.emit(e);
    }

    public get showCancelButton(): boolean {
        return this.cancelObserverCount > 0 && !this.hideCancelButton
    }

    public get showDeleteButton(): boolean {
        return this.deleteObserverCount > 0 && !this.hideDeleteButton
    }

    public get showSaveButton(): boolean {
        return this.saveObserverCount > 0 && !this.hideSaveButton
    }

    public get showSaveAndCloseButton(): boolean {
        return this.saveAndCloseObserverCount > 0 && !this.hideSaveAndCloseButton
    }

    private get cancelObserverCount(): number {
        return this.cancelClick.observers.length;
    }

    private get deleteObserverCount(): number {
        return this.deleteClick.observers.length;
    }

    private get saveObserverCount(): number {
        return this.saveClick.observers.length;
    }

    private get saveAndCloseObserverCount(): number {
        return this.saveAndCloseClick.observers.length;
    }
}
