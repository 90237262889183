import { Observable, timer, Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';

export class Toastr {
    type: ToastrType;
    messages: Array<string>;
    autoClose: boolean;
    allowClose: boolean;
}

export enum ToastrType {
    Success,
    Error,
    Info,
    Warning,
    Static
}

export class ToastrWrapper {

    private _subscription: Subscription;

    private readonly _timer$: Observable<number>;
    private readonly _toastr: Toastr;
    private readonly _duration: number; // In seconds

    private _indicator = 0;
    private _pinned = false;
    private _showIndicator = false;

    public expired = new EventEmitter<ToastrWrapper>();

    constructor(toastr: Toastr) {
        this._toastr = toastr;

        if (this._toastr.allowClose && this._toastr.autoClose) {
            const length = toastr.messages.map(p => p.length).sum();
            this._duration = 5000 * (length / 160 + 1);
            this._timer$ = timer(0, 1000);

            this.startTimer();
        }
    }

    public startTimer(): void {
        if (this._toastr.allowClose && this._toastr.autoClose) {
            this._subscription = this._timer$.subscribe(tick => {
                this._indicator = (tick * 1000) / this._duration * 100;

                if (this._indicator > 100) {
                    this.expired.emit(this);
                    this.stopTimer(); // Self-destruct when timer expires!
                }
            });
        }
    }

    public stopTimer(): void {
        if (this._toastr.allowClose && this._toastr.autoClose) {
            this._indicator = 0;

            if (this._subscription) {
                this._subscription.unsubscribe();
            }
        }
    }

    public get toastr(): Toastr {
        return this._toastr;
    }
    public get messages(): Array<string> {
        return this._toastr.messages;
    }
    public get duration(): number {
        return this._duration;
    }
    public get indicator(): number {
        return this._indicator;
    }

    public get allowClose(): boolean {
        return this.toastr.allowClose;
    }

    public get autoClose(): boolean {
        return this.toastr.autoClose;
    }

    public get showIndicator(): boolean {
        return this._showIndicator;
    }
    public set showIndicator(showIndicator: boolean) {
        this._showIndicator = showIndicator;
    }

    public get pinned(): boolean {
        return this._pinned;
    }
    public set pinned(pinned: boolean) {
        this._pinned = pinned;

        if (this._pinned) {
            this.stopTimer();
        } else {
            this.startTimer();
        }
    }
}
