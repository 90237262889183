import { Directive } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { filter, map, take, tap } from "rxjs/operators";
import { BaseDirective } from "../../../_core/components/base.directive";
import { RouteDataService } from "./route-data.service";

@Directive({
    selector: '[geneaRouteData]',
    providers: [
        RouteDataService
    ]
})
export class RouteDataDirective extends BaseDirective {
    constructor(
        readonly routeDataService: RouteDataService,
        readonly route: ActivatedRoute,
        readonly router: Router){
        super();

        // When the page loads, we need to get the current route to seed the subject
        this.addSubscription(
            router.events
                .pipe(
                    filter(event => event instanceof NavigationStart),
                    // Only take 1 and then we're done!
                    take(1),
                    map(() => route),
                    map(route => {
                        while (route.firstChild) {
                            route = route.firstChild;
                        }
                        return route;
                    }),
                    // filter(route => route.outlet === 'primary'),
                    tap(route => {
                        this.routeDataService.setRoute(route);
                    })
                )
        );

        // Each time the navigation changes, once complete, get the current route
        this.addSubscription(
            router.events
                .pipe(
                    filter(event => event instanceof NavigationEnd),
                    map(() => route),
                    map(route => {
                        while (route.firstChild) {
                            route = route.firstChild;
                        }
                        return route;
                    }),
                    // filter(route => route.outlet === 'primary'),
                    tap(route => {
                        this.routeDataService.setRoute(route);
                    })
                )
        );
    }
}