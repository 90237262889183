import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { GlobalConfig } from '../../_core/global.config';
import { TypeConverter } from '../../_core/type-converter';
import { ApiService } from '../api.service';
import { BaseEntityService } from '../base.service';
import { ModelCollection } from '../../_sdk/collection.model';

import { LocaleStringResourceModel, LocaleStringResourceFilterState } from '../../_sdk/localization/locale-string-resource.model';

@Injectable({
    providedIn: 'root'
})
// tslint:disable-next-line:max-line-length
export class LocaleStringResourceService extends BaseEntityService<LocaleStringResourceModel> {

    constructor(
        readonly apiService: ApiService,
        private readonly config: GlobalConfig
    ) {
        super(apiService);
    }

    protected get baseUri(): string {
        return `${this.config.api('portal').baseUri}/locale-resource-string`;
    }

    protected toEntity(source: any): LocaleStringResourceModel {
        return TypeConverter.convert(LocaleStringResourceModel, source);
    }

    public search(request?: LocaleStringResourceFilterState): Observable<ModelCollection<LocaleStringResourceModel>> {
        return super.search(request);
    }
}
