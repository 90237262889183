export interface MacAddress {
    segmentA: string;
    segmentB: string;
    segmentC: string;
    segmentD: string;
    segmentE: string;
    segmentF: string;
}

export class MacAddress {
    public static empty(): MacAddress {
        return {
            segmentA: '',
            segmentB: '',
            segmentC: '',
            segmentD: '',
            segmentE: '',
            segmentF: ''
        };
    }

    public static parse(value?: string): MacAddress {
        const output: MacAddress = MacAddress.empty();

        if (String.isNullOrEmpty(value)) {
            return output;
        }

        const split = value.split('.').map(e => e.trim().alphaNumeric());

        if (split.length > 0) {
            output.segmentA = split[0].substring(0, 2);
        }

        if (split.length > 1) {
            output.segmentB = split[1].substring(0, 2);
        }

        if (split.length > 2) {
            output.segmentC = split[2].substring(0, 2);
        }

        if (split.length > 3) {
            output.segmentD = split[3].substring(0, 2);
        }

        if (split.length > 4) {
            output.segmentD = split[4].substring(0, 2);
        }

        if (split.length > 5) {
            output.segmentD = split[5].substring(0, 2);
        }

        return output;
    }

    public static format(value: MacAddress): string {
        if (!value) {
            return null;
        }

        value.segmentA = String.trimOrEmpty(value.segmentA).alphaNumeric();
        value.segmentB = String.trimOrEmpty(value.segmentB).alphaNumeric();
        value.segmentC = String.trimOrEmpty(value.segmentC).alphaNumeric();
        value.segmentD = String.trimOrEmpty(value.segmentD).alphaNumeric();
        value.segmentE = String.trimOrEmpty(value.segmentE).alphaNumeric();
        value.segmentF = String.trimOrEmpty(value.segmentF).alphaNumeric();

        if (String.isNullOrEmpty(value.segmentA)
            && String.isNullOrEmpty(value.segmentB)
            && String.isNullOrEmpty(value.segmentC)
            && String.isNullOrEmpty(value.segmentD)
            && String.isNullOrEmpty(value.segmentE)
            && String.isNullOrEmpty(value.segmentF)) {
            return null;
        }

        return `${value.segmentA}-${value.segmentB}-${value.segmentC}-${value.segmentD}-${value.segmentE}-${value.segmentF}`;
    }
}
