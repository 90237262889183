import { TimeSpan } from './date.timespan';

export interface UnspecificDateTime {
    Year: number;
    Month: number;
    Day: number;
    Time: TimeSpan;
}

export class UnspecificDateTime {
    public static empty(): UnspecificDateTime {
        return UnspecificDateTime.now();
    }

    public static now(): UnspecificDateTime {
        const now = new Date();

        return {
            Day: now.getDate(),
            Month: now.getMonth(),
            Year: now.getFullYear(),
            Time: TimeSpan.fromDate(now)
        };
    }

    public static isUnspecificDateTime(value: any): value is UnspecificDateTime {
        const cast = <UnspecificDateTime>value;
        return cast.Year !== undefined
            && cast.Month !== undefined
            && cast.Day !== undefined
            && cast.Time !== undefined;
    }
}