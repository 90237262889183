import { CustomerModel } from '../customers/customer.model';
import { ProtocolModel } from './protocol.model';
import { DataObject, DataProperty, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';
import { EntityModel } from '../model';
import { BuildingModel } from '../buildings/building.model';
import { CommandModel } from './command-model.model';
import { ParameterValueModel } from '../parameter-configuration/parameter-value.model';
import { ProtocolTypeModel } from './protocol-type.model';
import { TimeSpan } from '../../_core/date';
import { EmsDeviceControllerModel } from './ems-device-controller.model';

@DataObject()
export class ConnectionModel extends EntityModel {

    public Name: string = null;
    public Description?: string = null;
    public Version?: number = null;
    public Active: boolean = null;

    @DataProperty()
    public ProtocolType: ProtocolTypeModel = null;

    @DataProperty()
    public Protocol: ProtocolModel = null;

    @DataProperty()
    public EmsDeviceController: EmsDeviceControllerModel = null;

    @DataProperty({ instanceType: ParameterValueModel })
    public Parameters: Array<ParameterValueModel> = [];

    @DataProperty({ instanceType: CommandModel })
    public Commands: Array<CommandModel> = [];
}

export interface ConnectionFilter {
    Name?: string;
    Description?: string;
    ProtocolId?: number;
    ProtocolTypeId?: number;
    CustomerId?: number;
    BuildingGroupId?: number;
    BuildingId?: number;
    FilterByBuilding?: boolean;
    EmsDeviceControllerId?: number;
    EmsDeviceControllerName?: string;
}

export type ConnectionFilterState = CompositeType<ConnectionFilter, FilterState>;
