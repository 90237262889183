
import { combineLatest, Observable, BehaviorSubject } from 'rxjs';

import { take, filter, tap, first } from 'rxjs/operators';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { BaseComponent } from '../../../../../_core/components/base.component';
import { notNull } from '../../../../../_core/rxjs.operators';
import { MacAddress } from '../../../../../_core/mac-address';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-mac-address',
    styleUrls: ['form-mac-address.component.scss'],
    template: `
    <ng-container [formGroup]="proxyGroup">
        <div class="input-group flex-nowrap">
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="2" placeholder="A1"
                formControlName="segmentA" numeric />
            <div class="input-group-append"><span class="input-group-text dot">.</span></div>
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="2" placeholder="B2"
                formControlName="segmentB" numeric />
            <div class="input-group-append"><span class="input-group-text dot">.</span></div>
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="2" placeholder="C3"
                formControlName="segmentC" numeric />
            <div class="input-group-append"><span class="input-group-text dot">.</span></div>
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="2" placeholder="D4"
                formControlName="segmentD" numeric />
            <div class="input-group-append"><span class="input-group-text dot">.</span></div>
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="2" placeholder="E5"
                formControlName="segmentE" numeric />
            <div class="input-group-append"><span class="input-group-text dot">.</span></div>
            <input type="text" [ngClass]="[config.cssClass || '']" maxlength="2" placeholder="F6"
                formControlName="segmentF" numeric />
        </div>
    </ng-container>
  `
})
export class FormMacAddressComponent extends BaseComponent implements Field {

    /***********************************************
     * Field interface...
    ***********************************************/

    // Use getter/setter so we can hook into the values being set.
    public get config(): FieldConfig {
        return this.config$.getValue();
    }
    public set config(value: FieldConfig) {
        this.config$.next(value);
    }

    public get group(): FormGroup {
        return this.group$.getValue();
    }
    public set group(value: FormGroup) {
        this.group$.next(value);
    }

    /***********************************************
     * Custom implementation...
    ***********************************************/

    // We're going to use a proxy form group that will broadcast
    // values to the Field form group...
    public proxyGroup: FormGroup;

    private config$: BehaviorSubject<FieldConfig>;
    private group$: BehaviorSubject<FormGroup>;

    constructor(private fb: FormBuilder) {
        super();

        // The form requires a FormGroup with its controls populated... Go ahead and create it...
        this.proxyGroup = this.createGroup();

        this.config$ = new BehaviorSubject(null);
        this.group$ = new BehaviorSubject(null);

        // We're going to listen for both the config and group properties
        // being set. Once they have a value, then we can bind our config properties
        // to our proxy form.
        this.addSubscription(
            combineLatest(
                this.config$.pipe(notNull()),
                this.group$.pipe(notNull())
            ).pipe(
                first(),
                tap(([config, group]) => {
                    // Both values have been set! bind our form...
                    this.bindForm(config, group);
                })
            )
        );
    }

    private bindForm(config: FieldConfig, group: FormGroup) {
        const macAddress: MacAddress = MacAddress.parse(config.value);

        this.proxyGroup.setValue(macAddress, { emitEvent: false });

        // When the proxy changes, notify the original.
        this.addSubscription(
            this.proxyGroup.valueChanges
                .pipe(
                    tap(value => {
                        const newValue = {};
                        newValue[config.name] = MacAddress.format(value);
                        group.patchValue(newValue, { emitEvent: true });
                    })
                )
        );

        // Make sure we set the disabled state from the config
        if (config.disabled) {
            this.proxyGroup.disable();
        }

        // Used to set the enabled/disabled from the original control.
        // When a control is disabled in the original, we need to reflect that in the proxy...
        this.addSubscription(
            group.controls[config.name].statusChanges
                .pipe(
                    filter(v => v !== this.proxyGroup.status), // Note, YOU NEED THIS!
                    tap(v => {
                        const method = String.equals(v, 'disabled', false) ? 'disable' : 'enable';
                        // Remember, this is ONE control.
                        // Even though we're rendering 4 fields, it's still ONE control...
                        // THerefore, when the original form control is enabled/disabled,
                        // we need to enable/disable the GROUP, not just a control...
                        this.proxyGroup[method]();
                    })
                )
        );
    }

    public createGroup() {
        const group = this.fb.group({});

        Object.keys(MacAddress.empty())
            .forEach(key => {
                group.addControl(key, this.createControl({ disabled: false, validator: null, value: null }));
            });

        return group;
    }

    public createControl({ disabled, validator, value }) {
        return this.fb.control({ disabled, value }, validator);
    }
}
