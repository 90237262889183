import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Directive, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';

import { BaseComponent } from './base.component';
import { AuthService } from '../../_services/auth/auth.service';
import { LoadingIndicatorDirective } from '../../_modules/common-ui/loading-indicator/loading-indicator.directive';

@Directive()
export abstract class BaseEditComponent extends BaseComponent {

    @ViewChild(LoadingIndicatorDirective, { static: true })
    protected loadingIndicator: LoadingIndicatorDirective;

    constructor(
        protected readonly location: Location,
        protected readonly router: Router,
        protected readonly route: ActivatedRoute,
        protected readonly authService?: AuthService,
    ) {
        super();
    }

    protected readonly accessCacheMap = new Map<string, boolean>();
    
    public hasPermission(permission: string): boolean {
        return this.accessCacheMap.getValue(permission, () => this.authService.hasPermission(permission));
    }

    public hasRole(role: string): boolean {
        return this.accessCacheMap.getValue(role, () => this.authService.hasRole(role));
    }

    public get isAdmin(): boolean {
        return this.hasRole(this.RoleConstant.Administrators);
    }

    public cancelHandler(): void {
        this.goBack();
    }

    public saveHandler(): void {

    }

    public saveAndCloseHandler(): void {
        this.save(
            _ => this.goBack(),
            _ => this.goBack()
        )
    }

    protected save(
        insertResultHandler: (result: any) => void,
        updateResultHandler: (result: any) => void): void {
    }

    protected goBack(): void {
        this.location.back();
    }

    protected process<T>(stream: Observable<T>): Observable<T> {
        if(this.loadingIndicator) {
            return this.loadingIndicator.process(stream);
        }

        return stream;
    }
}
