import { EntityModel } from '../../../../../_sdk/model';
import { SortDirectionType } from '../../../../../_sdk/sort-direction.enumeration';
import { CompositeType, DataObject, DataProperty } from '../../../../../_core/type-converter';
import { FilterState } from '../../../../../_sdk/page-state';
import { BuildingInvoiceModel } from './building-invoice.model';
import { BuildingInvoiceConfiguredType, InvoiceStatusType } from './enumerations/invoice-status-type.enumeration';
import { ModelCollection } from '../../../../../_sdk/collection.model';

@DataObject()
export class InvoiceStatusModel extends EntityModel {
    public Total: number = 0;
    // public DashboardInvoiceCount: number = 0;
    // public Overdue: number = 0;
    // public Due: number = 0;
    // public InProgress: number = 0;
    // public MetersToBeRead: number = 0;
    // public MeterReadCollected: number = 0;
    //public UtilityBillCollected: number = 0;
    public UtilityBillPendingApproval: number = 0;
    public UtilityBillPendingBills: number = 0;
    //public UtilityBillsToBeSubmit: number = 0;   
    //public PendingApproval: number = 0;
    // public ChangesRequested: number = 0;
    // public Approved: number = 0;

    @DataProperty({ converter: (s) => new ModelCollection(s) })
    public Statuses: ModelCollection<BuildingInvoiceModel> = null;
}

export interface InvoiceStatusFilter {
    CustomerId?: number;
    CustomerName?: string;
    BuildingId?: number;
    BuildingName?: string;
    LastModifiedBy?: string;
    LastInvoice?: Date;
    NextInvoice?: Date;
    Status?: InvoiceStatusType;
    BuildingInvoiceConfigured? : BuildingInvoiceConfiguredType;
    BillingDay?: number;
    SortField?: string;
    SortDirection?: SortDirectionType;
}

export type InvoiceStatusFilterState = CompositeType<InvoiceStatusFilter, FilterState>;