import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';

// import { environment } from '../../../environments/environment';

import { RESTService } from '../rest.service';

declare const environment: any;
declare const environmentResource:any; 

// https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c
// https://github.com/ciena-blueplanet/versiony

@Injectable({
    providedIn: 'root'
})

export class VersionCheckService {
    private static _newVersionAvailable = false;
    public static get newVersionAvailable(): boolean {
        return VersionCheckService._newVersionAvailable;
    }

    // this will be replaced by actual hash post-build.js
    private _currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
    private _hashTokenPattern = 'POST_BUILD_';

    private _interval: number;

    constructor(
        private readonly restService: RESTService,
        private readonly router: Router) {

        if (String.contains(this._currentHash, this._hashTokenPattern)) {
            // ng serve is probably being used because the hash hasn't been set.
            return;
        }

        this.router.events.subscribe(event => {
            if (!VersionCheckService.newVersionAvailable) {
                return;
            }

            if (event instanceof NavigationStart) {
                location.href = `${location.origin}${environmentResource.baseUrl}${event.url}`;
            }
        });
    }

    public get currentVersion(): string {
        if (String.contains(this._currentHash, this._hashTokenPattern)) {
            return '1.0.0';
        }

        return this._currentHash;
    }

    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */
    public runCheckVersion(frequency: number = 1000 * 60 * 30): void {

        if (String.contains(this._currentHash, this._hashTokenPattern)) {
            // ng serve is probably being used because the hash hasn't been set.
            return;
        }

        this._interval = setInterval(<TimerHandler>(() => {
            this.checkVersion();
        }), frequency);
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(): void {
        // timestamp these requests to invalidate caches
        this.restService.request(environment.versionCheckURL + '?t=' + new Date().getTime(), 'get')
            .pipe(
                first(),
                tap((response: any) => {
                    const hash = response.version;
                    const hashChanged = this.hasHashChanged(this._currentHash, hash);

                    // If new version, do something
                    if (hashChanged) {
                        // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
                        VersionCheckService._newVersionAvailable = true;
                        // this.toastrService.static('A new application version is available. Please refresh the page!');
                        clearInterval(this._interval);
                        // location.reload();
                    }
                    // store the new hash so we wouldn't trigger versionChange again
                    // only necessary in case you did not force refresh
                    this._currentHash = hash;
                }),
                catchError(() => {
                    clearInterval(this._interval);
                    return of('Could not get version');
                })
            )
            .subscribe();
    }

    /**
     * Checks if hash has changed.
     * This file has the JS hash, if it is a different one than in the version.json
     * we are dealing with version change
     * @param currentHash
     * @param newHash
     * @returns {boolean}
     */
    private hasHashChanged(currentHash: string, newHash: string): boolean {
        return !String.equals(currentHash, newHash);
    }
}