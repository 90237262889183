import { EntityModel } from '../model';
import { LanguageModel } from './language.model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class LocaleStringResourceModel extends EntityModel {
    public Name: string = null;
    public Value: string = null;
    public Description: string = null;
    public LanguageId: number = null;
    // public Language: LanguageModel = null;
}

export interface LocaleStringResourceFilter {
    LanguageId?: number;
    Name?: string;
    Value?: string;
    Description?: string;
}

export type LocaleStringResourceFilterState = CompositeType<LocaleStringResourceFilter, FilterState>;
