import { BaseComponent } from '../_core/components/base.component';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getExternalUrl, externalUrlQueryStringParam } from '../_core/url.utils';

import { Location } from '@angular/common';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent extends BaseComponent {
    public externalUrl: string;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly location: Location) {
        super();

        this.externalUrl = getExternalUrl(this.route) || this._getExternalUrl();

        if (String.isNullOrEmpty(this.externalUrl)) {
            this.externalUrl = null;
        }

        setTimeout(() => {
            this._doRedirect();
        }, 3000);
    }

    private _doRedirect(): void {
        if (!String.isNullOrEmpty(this.externalUrl)) {
            location.replace(this.externalUrl);
        }
    }

    private _getExternalUrl(): string {
        const url = this.location.path() || '';
        const searchString = `${externalUrlQueryStringParam}=`;
        const index = url.indexOf(searchString);

        let output: string;

        if (index >= 0) {
            output = url.substring(index + searchString.length);
        }

        if (String.isNullOrEmpty(output)) {
            return null;
        }

        return decodeURIComponent(output);
    }
}
