import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { CountryModel } from '../../../_sdk/directory/country.model';
import * as CountryActions from './country.actions';

export interface State {
    countries: ModelCollection<CountryModel>;
}

const initialState: State = {
    countries: new ModelCollection<CountryModel>()
};

export function reducer(
    state: State = initialState,
    action: CountryActions.Actions
): State {

    let countries: ModelCollection<CountryModel>;
    let index: number;

    switch (action.type) {
        case CountryActions.DISPOSE:
            return {
                ...state,
                countries: initialState.countries
            };
        case CountryActions.LOAD_COMPLETE:
            return {
                ...state,
                countries: action.payload
            };
        case CountryActions.LOAD:
        case CountryActions.INSERT:
        case CountryActions.UPDATE:
        case CountryActions.DELETE:
            return state;
        case CountryActions.INSERT_COMPLETE:
            countries = new ModelCollection(state.countries.data
                .map(p => TypeConverter.convert(CountryModel, p)));

            countries.data.push(action.payload);

            return {
                ...state,
                countries: countries
            };

        case CountryActions.UPDATE_COMPLETE:
            countries = new ModelCollection(state.countries.data
                .map(p => TypeConverter.convert(CountryModel, p)));

            index = countries.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                countries.data[index] = action.payload;
            }

            return {
                ...state,
                countries: countries
            };

        case CountryActions.DELETE_COMPLETE:
            countries = new ModelCollection(state.countries.data
                .map(p => TypeConverter.convert(CountryModel, p)));

            index = countries.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                countries.data.splice(index, 1);
            }

            return {
                ...state,
                countries: countries
            };

        default:
            return state;
    }
}
