import { SmallDate } from '../../_core/date';
import { CompositeType, DataObject, DataProperty } from '../../_core/type-converter';
import { BuildingModel } from '../buildings/building.model';
import { AddressModel } from '../common/address.model';
import { CustomerModel } from '../customers/customer.model';
import { EntityModel, CoreEntity } from '../model';
import { FilterState } from '../page-state';
import { TenantModel } from '../tenants/tenant.model';
import { ZoneModel } from '../zones/zone.model';
import { EntityType } from '../search/entity-type.enum';
import { AllowanceModel } from '../../admin/platform/allowance/_models/allowance.model';
import { LeaseSpaceType } from './enumerations/lease-space-type-model';

@DataObject()
export class LeaseModel extends EntityModel implements CoreEntity {
    public readonly EntityType: EntityType = EntityType.Lease;

    public Name: string = null;
    public Description: string = null;
    public AddressId: number = null;
    public Active = false;
    public IsLeaseMonthToMonth = false;
    public IsSeparateInvoice = false;
        
    public LeasePlotSize:number =null;

    public ExternalAccountingLeaseId: string = null;

    @DataProperty()
    public LeaseSpaceType:LeaseSpaceType= null;

    @DataProperty()
    public CommencementDate: SmallDate = null;

    @DataProperty()
    public ExpirationDate: SmallDate = null;

    @DataProperty()
    public Customer: CustomerModel = null;

    @DataProperty()
    public Building: BuildingModel = null;

    @DataProperty()
    public Tenant: TenantModel = null;

    @DataProperty()
    public PhysicalAddress: AddressModel = null;

    @DataProperty()
    public BillToAddress: AddressModel = null;

    @DataProperty({ instanceType: ZoneModel })
    public Zones: Array<ZoneModel> = [];

    @DataProperty()
    public Allowance: AllowanceModel = null;
}

export type LeaseForStorage = Pick<LeaseModel, 'Name' | 'Id'>;

export interface LeaseFilter {
    Id?: number;
    Name?: string;
    Description?: string;
    CustomerName?: string;
    BuildingName?: string;
    TenantName?: string;
    CustomerId?: number;
    BuildingId?: number;
    TenantId?: number;
    IsLeaseMonthToMonth?: boolean;
    ExternalAccountingLeaseId?: string;
}

export type LeaseFilterState = CompositeType<LeaseFilter, FilterState>;
