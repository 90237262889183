import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { BaseComponent } from '../_core/components/base.component';

declare const environmentResource:any; 

@Component({
    selector: 'app-resolve-route-not-found',
    template: '',
    styleUrls: []
})
export class ResolveRouteNotFoundComponent extends BaseComponent {
    public externalUrl: string;

    constructor(
        location: Location,
        router: Router) {
        super();

        var rootPath = location.path(true)
            .split('/')
            .map(p => String.trimOrEmpty(p).toLowerCase())
            .filter(p => !String.isNullOrEmpty(p))
            .firstOrDefault();

        router.navigate(['/404'])
            .catch(reason => {
                console.log(reason);
            });
    }
    
    private _doRedirect(dest: string): void {
        if (!String.isNullOrEmpty(dest)) {
            location.replace(dest);
        }
    }

}
