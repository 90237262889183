import { TypeConverter } from '../../../_core/type-converter';
import { ModelCollection } from '../../../_sdk/collection.model';

import { ParameterOptionModel } from '../../../_sdk/parameter-configuration/parameter-option.model';
import * as ParameterOptionActions from './parameter-option.actions';

export interface State {
    parameterOptions: ModelCollection<ParameterOptionModel>;
}

const initialState: State = {
    parameterOptions: new ModelCollection<ParameterOptionModel>()
};

export function reducer(
    state: State = initialState,
    action: ParameterOptionActions.Actions
): State {

    let parameterOptions: ModelCollection<ParameterOptionModel>;
    let index: number;

    switch (action.type) {
        case ParameterOptionActions.DISPOSE:
            return {
                ...state,
                parameterOptions: initialState.parameterOptions
            };
        case ParameterOptionActions.LOAD_COMPLETE:
            return {
                ...state,
                parameterOptions: action.payload
            };
        case ParameterOptionActions.LOAD:
        case ParameterOptionActions.INSERT:
        case ParameterOptionActions.UPDATE:
        case ParameterOptionActions.DELETE:
            return state;
        case ParameterOptionActions.INSERT_COMPLETE:
            parameterOptions = new ModelCollection(state.parameterOptions.data
                .map(p => TypeConverter.convert(ParameterOptionModel, p)));

            parameterOptions.data.push(action.payload);

            return {
                ...state,
                parameterOptions: parameterOptions
            };

        case ParameterOptionActions.UPDATE_COMPLETE:
            parameterOptions = new ModelCollection(state.parameterOptions.data
                .map(p => TypeConverter.convert(ParameterOptionModel, p)));

            index = parameterOptions.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                parameterOptions.data[index] = action.payload;
            }

            return {
                ...state,
                parameterOptions: parameterOptions
            };

        case ParameterOptionActions.DELETE_COMPLETE:
            parameterOptions = new ModelCollection(state.parameterOptions.data
                .map(p => TypeConverter.convert(ParameterOptionModel, p)));

            index = parameterOptions.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                parameterOptions.data.splice(index, 1);
            }

            return {
                ...state,
                parameterOptions: parameterOptions
            };

        default:
            return state;
    }
}
