export enum BillingDefaultScale {
    Tier1 = 1,
    Tier2 = 2,
    Tier3 = 3
}

export const BillingDefaultScaleNames = new Map<number, string>([
    [BillingDefaultScale.Tier1, 'Tier 1'],
    [BillingDefaultScale.Tier2, 'Tier 2'],
    [BillingDefaultScale.Tier3, 'Tier 3']
 ])

 export enum MeterType {
    Manual = 2,
    Automatic = 4,
    Mixed = 1
}

export const MeterTypeNames = new Map<number, string>([
    [MeterType.Manual, 'Manual'],
    [MeterType.Automatic, 'Automatic'],
    [MeterType.Mixed, 'Mixed']
 ])

 export enum ReaderType {
    CustomerRead = 1,
    GeneaRead = 2,
    NA = 4
}

export const ReaderTypeNames = new Map<number, string>([
    [ReaderType.CustomerRead, 'Customer Read'],
    [ReaderType.GeneaRead, 'Genea Read'],
    [ReaderType.NA, 'NA']
 ])

 export enum BuildingType {
    Office = 1,
    Medical = 2,
    Retail = 3,
    Industrial = 4,
    Residential = 5,
    Other = 6,
    MixedUse = 7

}

export const BuildingTypeNames = new Map<number, string>([
    [BuildingType.Office, 'Office'],
    [BuildingType.Medical, 'Medical'],
    [BuildingType.Retail, 'Retail'],
    [BuildingType.Industrial, 'Industrial/Warehouse'],
    [BuildingType.Residential, 'Residential'],
    [BuildingType.Other, 'Other'],
    [BuildingType.MixedUse, 'Mixed Use']
 ])

 export enum BuildingClassification {
        ClassA = 1,
        ClassB = 2,
        ClassC = 3,
 }

 export const BuildingClassificationNames = new Map<number, string>([
    [BuildingClassification.ClassA, 'Class A'],
    [BuildingClassification.ClassB, 'Class B'],
    [BuildingClassification.ClassC, 'Class C']
 ])