export enum EntityType {
    All = 1,
    Customer = 2,
    BuildingGroup = 3,
    Building = 4,
    Tenant = 5,
    Lease = 6,
    LeaseArea = 7
}


export const EntityTypeNames = new Map<number, string>([
    [EntityType.All, 'All'],
    [EntityType.Customer, 'Customer'],
    [EntityType.BuildingGroup, 'Building Group'],
    [EntityType.Building, 'Building'],
    [EntityType.Tenant, 'Tenant'],
    [EntityType.Lease, 'Lease'],
    [EntityType.LeaseArea, 'Area']
]);
