import { PermissionRecordModel } from '../../../_sdk/security/permission-record.model';
import { ModelCollection } from '../../../_sdk/collection.model';

import * as PermissionRecordActions from './permission-record.actions';
import { TypeConverter } from '../../../_core/type-converter';

export interface State {
    permissionRecords: ModelCollection<PermissionRecordModel>;
}

const initialState: State = {
    permissionRecords: new ModelCollection<PermissionRecordModel>()
};

export function reducer(
    state: State = initialState,
    action: PermissionRecordActions.Actions
): State {

    let permissionRecords: ModelCollection<PermissionRecordModel>;
    let index: number;

    switch (action.type) {
        case PermissionRecordActions.DISPOSE:
            return {
                ...state,
                permissionRecords: initialState.permissionRecords
            };
        case PermissionRecordActions.LOAD_COMPLETE:
            return {
                ...state,
                permissionRecords: action.payload
            };
        case PermissionRecordActions.LOAD:
        case PermissionRecordActions.INSERT:
        case PermissionRecordActions.UPDATE:
        case PermissionRecordActions.DELETE:
            return {
                ...state
            };
        case PermissionRecordActions.INSERT_COMPLETE:
            permissionRecords = new ModelCollection(state.permissionRecords.data
                .map(p => TypeConverter.convert(PermissionRecordModel, p)));

            permissionRecords.data.push(action.payload);

            return {
                ...state,
                permissionRecords: permissionRecords
            };

        case PermissionRecordActions.UPDATE_COMPLETE:
            permissionRecords = new ModelCollection(state.permissionRecords.data
                .map(p => TypeConverter.convert(PermissionRecordModel, p)));

            index = permissionRecords.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                permissionRecords.data[index] = action.payload;
            }

            return {
                ...state,
                permissionRecords: permissionRecords
            };

        case PermissionRecordActions.DELETE_COMPLETE:
            permissionRecords = new ModelCollection(state.permissionRecords.data
                .map(p => TypeConverter.convert(PermissionRecordModel, p)));

            index = permissionRecords.data.findIndex(c => c.Id === action.payload.Id);

            if (index >= 0) {
                permissionRecords.data.splice(index, 1);
            }

            return {
                ...state,
                permissionRecords: permissionRecords
            };

        default:
            return state;
    }
}
