<div class="login-container">
    <div class="content">
        <div class="g-header text-center">
            <span alt="GENEA" class="geneaLogo"></span>
        </div>
        <div class="align-self-center login-box">
            <h1 class="login-text">Reset Password</h1>
            <ng-container *ngIf="user$ | async">
                <div>
                    <div class="row">
                        <label class="login-label">Email: {{ (user$ | async).Email }}</label>
                    </div>
                </div>
                <div>
                    <app-password (changePassword)="onSubmitNewPassword($event)" [showOldPassword]="false">
                    </app-password>
                </div>
            </ng-container>
            <div class="p-2" *ngIf="expirationMessage$ | async">
                <span class="pt-3">
                    {{ expirationMessage$ | async }}
                </span>
            </div>
            <div *ngIf="errorMessage$ | async" class="row pt-2">
                <br />
                <div>
                    <span class="text-danger">{{ errorMessage$ | async }}</span>
                </div>
            </div>
        </div>
    </div>
</div>