import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { NumericFieldConfig } from '../../models/field-config.interface';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-number',
    styleUrls: ['form-number.component.scss'],
    template: `
    <ng-container [formGroup]="group">
        <kendo-numerictextbox
            [format]="config.format"
            [ngClass]="[config.cssClass || '']"
            [placeholder]="config.placeholder"
            [formControlName]="config.name"
            [spinners]="false">
        </kendo-numerictextbox>
    </ng-container>
  `
})
export class FormNumberComponent implements Field {

    config: NumericFieldConfig;
    group: FormGroup;
}
