import { EntityModel } from '../model';

import { ParameterDataTypeModel } from './parameter-data-type.model';
import { EnumerationModel } from '../common/enumeration.model';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class ParameterOptionModel extends EntityModel {
    public Name: string = null;
    public Description: string = null;
    public Active = false;
    public SystemName: string = null;

    @DataProperty()
    public ParameterDataType: ParameterDataTypeModel = null;

    @DataProperty()
    public Enumeration: EnumerationModel = null;
}

export interface ParameterOptionFilter {
    Name?: string;
}

export type ParameterOptionFilterState = CompositeType<ParameterOptionFilter, FilterState>;
