import { BaseModel, EntityModel } from '../../../../../_sdk/model';
import { UserModel } from '../../../../../_sdk/users/user.model';
import { TenantModel } from '../../../../../_sdk/tenants/tenant.model';
import { HvacInvoiceDetailModel } from '../../../../../_sdk/billing/hvac-invoice-detail.model';
import { DataProperty, DataObject } from '../../../../../_core/type-converter';
import { CostTrendModel } from '../../_models/cost-trend.model';

import { HvacInvoicePacketModel } from './hvac-invoice-packet.model';
import { HVACInvoiceAdjustmentModel } from './hvac-invoice-adjustment.model';
import { HvacInvoiceHeader } from './hvac-invoice-header.model';
import { ProcessingStatusType } from './enumerations/processing-status-type.enumeration';

@DataObject()
export class HvacInvoiceModel extends BaseModel {
    public BuildingId: number = null;
    public  BuildingName: string = null;
    public TenantId?: number = null;
    public Title: string = null;
    public Description: string = null;
    public PreviousCost?: number = null;   
    public AllowanceApplied:number=0;
    public TotalCost :number=0;
    public InvoiceLabels: Array<string> =[];
    public CreatedOn: Date = null;    
    public ModifiedOn: Date = null;    
    public Active: boolean = null;
    public Deleted: boolean = null;
    public ProcessingProgress?: number = null;

    /*complex objects*/
    @DataProperty()
    public CreatedBy: UserModel = null;
    @DataProperty()

    public ModifiedBy: UserModel = null;
    @DataProperty()
    public Tenant: TenantModel = null;

    @DataProperty()
    public ProcessingStatus?: ProcessingStatusType = null;
    
    @DataProperty()
    public InvoicePacket: HvacInvoicePacketModel=null;

    @DataProperty({ instanceType: HvacInvoiceHeader })
    public InvoiceHeaders: Array<HvacInvoiceHeader> =[];

    @DataProperty({instanceType:HVACInvoiceAdjustmentModel})
    public InvoiceAdjustments: Array<HVACInvoiceAdjustmentModel> =[];

    @DataProperty({instanceType:CostTrendModel})
    public  CostTrends : Array<CostTrendModel> =[];
}

// This is a helper Type. It allows us to define an enumerable-like type based on the properties of a PointModel
export type HvacLeaseAreaInvoiceLineItemGridColumn = Pick<HvacInvoiceDetailModel, 
    'Id' | 'RequestedByUser' | 'ServiceType'
    | 'RequestedStart' | 'RequestedEnd' | 'RequestedDurationTime' | 'RequestedDurationHours'
    | 'EffectiveStart' | 'EffectiveEnd' | 'EffectiveDurationTime' | 'EffectiveDurationHours'
    | 'LeaseBillableStart' | 'LeaseBillableEnd' | 'LeaseBillableDurationTime' | 'LeaseBillableDurationHours' 
    | 'LeaseAreaBillableStart' | 'LeaseAreaBillableEnd' | 'LeaseAreaBillableDurationTime' | 'LeaseAreaBillableDurationHours' 
    | 'BillableDurationTime' | 'BillableDurationHours'
    | 'Rate' | 'Total'
    | 'AdjustedReason' | 'Comment' | 'Notes'>;

export type HvacLeaseAreaInvoiceLineItemGridColumns = Array<keyof HvacLeaseAreaInvoiceLineItemGridColumn>;

