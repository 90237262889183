
<a #anchor (click)="toggle()" [ngClass]="'btn '+styleClass" > {{title}} <span class="k-icon k-i-arrow-chevron-down" *ngIf="title != 'Unknown'"></span></a>

<kendo-popup
    #popup
    [anchor]="anchor"
    [margin]="{vertical: 7 }"
    [anchorAlign]="anchorAlign"
    [popupAlign]="popupAlign"
    (anchorViewportLeave)="show = false"
    *ngIf="show">
    <ng-content></ng-content>
</kendo-popup>