import { BaseComponent } from '../../../_core/components/base.component';
import { ChangeDetectionStrategy, Component, ContentChild, TemplateRef } from '@angular/core';
import { GeneaFieldsetLegendDirective } from './fieldset-legend.directive';
import { GeneaFieldsetContentDirective } from './fieldset-content.directive';
import { GeneaFieldsetDescriptionDirective } from './fieldset-description.directive';

@Component({
    selector: 'genea-fieldset',
    templateUrl: './fieldset.component.html',
    styleUrls: ['./fieldset.component.scss']
})
export class GeneaFieldsetComponent extends BaseComponent {
    
    @ContentChild(GeneaFieldsetLegendDirective, { read: TemplateRef })
    public geneaFieldsetLegendTemplate: GeneaFieldsetLegendDirective;

    @ContentChild(GeneaFieldsetDescriptionDirective, { read: TemplateRef })
    public geneaFieldsetDescriptionTemplate: GeneaFieldsetDescriptionDirective;

    @ContentChild(GeneaFieldsetContentDirective, { read: TemplateRef })
    public geneaFieldsetContentTemplate: GeneaFieldsetContentDirective;
}