import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from '../../../_core/components/base.component';
import { TypeConverter } from '../../../_core/type-converter';

const defaultCssActiveClass = 'green';
const defaultCssInactiveClass = 'red';
const defaultCssUnknownClass = 'gray';

const defaultActiveTitle = 'Active';
const defaultInactiveTitle = 'Inactive';
const defaultUnknownTitle = 'Unknown';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'active-icon',
    styleUrls: ['./active-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `<i class="active-circle {{ cssClass$ | async }}" title="{{ title$ | async }}"></i>`
})
export class ActiveIconComponent extends BaseComponent {

    private readonly _active$ = new BehaviorSubject<boolean | null>(null);
    public readonly active$ = this._active$.asObservable();

    public readonly title$ = this.active$
        .pipe(
            map(active => {
                return active === true
                    ? this.activeTitle
                    : active === false 
                        ? this.inactiveTitle
                        : this.unknownTitle;
            })
        );

    public readonly cssClass$ = this.active$
        .pipe(
            map(active => {
                return active === true
                    ? this.iconActiveCssClass
                    : active === false 
                        ? this.iconInactiveCssClass
                        : this.iconUnkownCssClass;
            })
        );

    private _cssActiveClass: string;
    private _cssInactiveClass: string;
    private _cssUnknownClass: string;

    private _activeTitle: string;
    private _inactiveTitle: string;
    private _unknownTitle: string;

    @Input()
    public get active(): string | boolean | { Active: boolean } {
        return this._active$.getValue();
    } 
    public set active(value: string | boolean | { Active: boolean }) {
        this._active$.next(this._falsy(value));
    }

    @Input()
    public get iconActiveCssClass(): string {
        return String.isNullOrEmpty(this._cssActiveClass) ? defaultCssActiveClass : this._cssActiveClass;
    }
    public set iconActiveCssClass(value: string) {
        this._cssActiveClass = value;
    }

    @Input()
    public get iconInactiveCssClass(): string {
        return String.isNullOrEmpty(this._cssInactiveClass) ? defaultCssInactiveClass : this._cssInactiveClass;
    }
    public set iconInactiveCssClass(value: string) {
        this._cssInactiveClass = value;
    }

    @Input()
    public get iconUnkownCssClass(): string {
        return String.isNullOrEmpty(this._cssUnknownClass) ? defaultCssUnknownClass : this._cssUnknownClass;
    }
    public set iconUnkownCssClass(value: string) {
        this._cssUnknownClass = value;
    }

    @Input()
    public get activeTitle(): string {
        return String.isNullOrEmpty(this._activeTitle) ? defaultActiveTitle : this._activeTitle;
    }
    public set activeTitle(value: string) {
        this._activeTitle = value;
    }

    @Input()
    public get inactiveTitle(): string {
        return String.isNullOrEmpty(this._inactiveTitle) ? defaultInactiveTitle : this._inactiveTitle;
    }
    public set inactiveTitle(value: string) {
        this._inactiveTitle = value;
    }

    @Input()
    public get unknownTitle(): string {
        return String.isNullOrEmpty(this._unknownTitle) ? defaultUnknownTitle : this._unknownTitle;
    }
    public set unknownTitle(value: string) {
        this._unknownTitle = value;
    }

    private _falsy(value: string | boolean | { Active: boolean }): boolean | null {
        // Boolean and boolean-like values (see 'TypeConverter.toBoolean')
        if (TypeConverter.isBoolean(value)) {
            return TypeConverter.toBoolean(value);
        }

        if(TypeConverter.isString(value)) {
            return TypeConverter.toBoolean(value);
        }

        if (value && value.Active !== undefined) {
            return <boolean>value.Active;
        }

        // "falsy" values... null and undefined will result in FALSE
        return null; //!!value;
    }
}
