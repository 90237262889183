import { EntityModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class ParameterOptionGroupModel extends EntityModel {
    public Name: string = null;
    public Description: string = null;
    public Active = false;
    public SystemName: string = null;
    public DisplayOrder: number = null;
}

export interface ParameterOptionGroupFilter {
    Name?: string;
}

export type ParameterOptionGroupFilterState = CompositeType<ParameterOptionGroupFilter, FilterState>;
