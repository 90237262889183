
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';

import { Observable, Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Toastr, ToastrType } from './toastr.model';

@Injectable({
    providedIn: 'root'
})
export class ToastrService {
    private _subject$: Subject<Toastr> = new Subject<Toastr>();
    private _toastr$ = this._subject$.asObservable();

    private keepAfterRouteChange = false;

    constructor(private readonly router: Router) {
        // clear toastr messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear toastr messages
                    this.clear();
                }
            }
        });
    }

    getToastr(): Observable<any> {
        return this._toastr$;
    }

    success(...messages: string[]) {
        // for (const message of messages) {
        this.toastr(ToastrType.Success, messages, true, true, true);
        // }
    }

    error(...messages: string[]) {
        // for (const message of messages) {
            this.toastr(ToastrType.Error, messages, true, true, true);
        // }
    }

    errors(form: FormGroup) {
        let messages: string[] = [];

        // Collect form errors
        if (form.errors) {
            messages = Object.values(form.errors);
        }

        const controlNames = Object.keys(form.controls);

        // Collect control errors
        for (const controlName of controlNames) {
            const control = form.controls[controlName];

            if (control.invalid && control.errors) {
                const errNames = Object.keys(control.errors);

                for (const errName of errNames) {
                    messages.push(`${controlName}: ${errName}: ${control.errors[errName]}`);
                }
            }
        }

        this.error(...messages);
    }

    info(...messages: string[]) {
        // for (const message of messages) {
        this.toastr(ToastrType.Info, messages, true, true, true);
        // }
    }

    warn(...messages: string[]) {
        // for (const message of messages) {
        this.toastr(ToastrType.Warning, messages, true, true, true);
        // }
    }

    static(...messages: string[]) {
        // for (const message of messages) {
        this.toastr(ToastrType.Static, messages, true, false, false);
        // }
    }

    toastr(type: ToastrType, messages: string[], keepAfterRouteChange = false, allowClose = true, autoClose = true) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this._subject$.next({ type, messages, allowClose, autoClose: autoClose && allowClose });
    }

    clear() {
        // clear toastrs
        this._subject$.next();
    }

    subscribeAndShowResult<T>(
        observable: Observable<T>,
        onSuccess?: (any) => void,
        onError?: (any) => void,
        onComplete?: () => void,
        onCustomMessage?: (any) => string
    ): Subscription {

        const prescription = observable.pipe(finalize(() => prescription.unsubscribe())).subscribe(
            next => {
                // NOTE: The ConnectivityInterceptor handles this better...
                // It uses the localized response from the server.
                // let msg = 'Your request has been processed successfully.';

                // if (onCustomMessage) {
                //     msg = onCustomMessage(next);
                // }

                // this.success(msg);

                if (onSuccess) {
                    onSuccess(next);
                }
            },
            error => {
                const errorHandler = (e: any) => {
                    if (onError) {
                        onError(e);
                    }
                };

                // NOTE: The ConnectivityInterceptor handles this better...
                // It uses the localized response from the server.
                if (error && error.handled) {
                    // The error has already been handled by the interceptor...
                    errorHandler(error && error.innerError.error && error.innerError.error.ModelState);
                    return;
                }

                // This is the default ASP.NET MVC model validation style object.
                // Other custom response types, if any, have to be done differently.
                const modelState = error && error.error && error.error.ModelState;

                if (modelState) {
                    const errors = [];
                    for (const field of Object.keys(modelState)) {
                        errors.push(...modelState[field]);
                    }
                    this.error(...errors);
                }

                errorHandler(modelState);
            },
            () => {
                if (onComplete) {
                    onComplete();
                }
            }
        );

        return prescription;
    }

    subscribeAndShowCustomMessage<T>(observable: Observable<T>, getCustomMessage: (any) => string): Subscription {
        return this.subscribeAndShowResult(observable, null, null, null, getCustomMessage);
    }
}
