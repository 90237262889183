import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { of, interval } from 'rxjs';
import { delay, tap, take } from 'rxjs/operators';

import { BaseComponent } from '../../_core/components/base.component';
import { AuthService } from '../../_services/auth/auth.service';

@Component({
    selector: 'app-auth-check',
    template: `
        <div style="width:100%;margin-top:60px;text-align:center;align-content: center;">
            <span style="border-left:3px solid #003865; padding-left:1rem; font-size: 1.5rem;">Authorizing...</span>
        </div>
    `,
    styles: []
})
export class AuthCheckComponent extends BaseComponent {
    constructor(
        private readonly router: Router,
        private readonly authService: AuthService
    ) {
        super();
    }

    OnInit(): void {
        // If they're already logged in, let's get outta here and go straight to the admin.
        let isAuthenticated = false;
        
        this.addSubscription(
            this.authService.isAuthenticated$
                .pipe(
                    tap(p => isAuthenticated = p)
                )
        );

        if (isAuthenticated) {
            this.router.navigate(['/admin']);
            return;
        }

        this.addSubscription(
            // So it doesn't just flash on screen...
            interval(1000)
                .pipe(
                    take(1),
                    tap(_ => {
                        this.router.navigate(['/login']);
                    })
                )
        );
    }
}
