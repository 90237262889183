import { Injectable, ViewContainerRef } from "@angular/core";

@Injectable()
export class NamedComponentContainerService {
    private static container: ViewContainerRef;

    set container(value: ViewContainerRef) {
        NamedComponentContainerService.container = value;
    }
    get container() {
        return NamedComponentContainerService.container;
    }
}