
import { Observable } from 'rxjs';
import { shareReplay, map, distinctUntilChanged, tap } from 'rxjs/operators';

import { SettingModel } from '../../_sdk/configuration/setting.model';
import { SettingService } from './setting.service';
import { notEmpty } from '../../_core/rxjs.operators';

export abstract class BaseSettings {
    protected readonly abstract prefix: string;
    protected readonly query$: Observable<Array<SettingModel>>;

    constructor(protected readonly settingService: SettingService) {
        this.query$ = this.settingService.search()
            .pipe(
                notEmpty(),
                map(collection => collection.data),
                map(data => data.filter(item => item.Name.startsWith(this.prefix.toLowerCase()))),
                shareReplay(1)
            );
    }

    protected getByKey(key: string): Observable<string> {
        return this.query$
            .pipe(
                map(p => p.find(i => i.Name === `${this.prefix}${key}`.toLowerCase())),
                map(p => p && p.Value),
                distinctUntilChanged()
            );
    }
}

