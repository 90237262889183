import { WeekDay } from '@angular/common';

import * as moment from 'moment';
import * as momentTZ from 'moment-timezone';

import { InvalidOperationException } from '../exceptions/invalid-operation.exception';

import { SmallDate } from './date.smalldate';
import { TimeSpan } from './date.timespan';
import { UnspecificDateTime } from './date.unspecified';
import { getTimeZoneId } from './timezone-names';

const datetimeRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.?(\-?\d+)?$/;
const defaultDateTimeFormat = 'MM/DD/YYYY h:mm A';

export class DateTime {
    public readonly date: SmallDate;
    public readonly timeOfDay: TimeSpan;
    public readonly dayOfWeek: WeekDay;

    constructor(source?: { year?: number, month?: number, day?: number, hour?: number, minute?: number, second?: number, millisecond?: number } | Date) {
        if (!source) {
            return;
        }

        let year: number;
        let month: number;
        let day: number;
        let hour: number;
        let minute: number;
        let second: number;
        let millisecond: number;

        if (source instanceof Date) {
            year = source.getFullYear();
            month = source.getMonth();
            day = source.getDate();
            hour = source.getHours();
            minute = source.getMinutes();
            second = source.getSeconds();
            millisecond = source.getMilliseconds();
        } else {
            year = source.year || 0;
            month = source.month || 0;
            day = source.day || 0;
            hour = source.hour || 0;
            minute = source.minute || 0;
            second = source.second || 0;
            millisecond = source.millisecond || 0;
        }

        this.date = new SmallDate({ year, month, day });
        this.timeOfDay = TimeSpan.from({ hour, minute, second, millisecond });
        this.dayOfWeek = this.date.dayOfWeek;
    }

    public static fromDate(date: Date): DateTime {
        if (!date) {
            return null;
        }

        return new DateTime(date);
    }

    private static fromMoment(source: moment.Moment): DateTime {
        if (!source) {
            return null;
        }

        return new DateTime({ year: source.year(), month: source.month(), day: source.date(), hour: source.hour(), minute: source.minute(), second: source.second(), millisecond: source.millisecond() });
    }

    public static fromSmallDate(source: SmallDate): DateTime {
        if (!source) {
            return null;
        }

        return new DateTime({ year: source.year, month: source.month, day: source.day, hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    public static fromUnspecificDateTime(source: UnspecificDateTime): DateTime {
        if (source.Year + source.Month + source.Day <= 0) {
            // It's not valid...
            return null;
        }

        const date = {
            year: source.Year,
            month: source.Month - 1, // 0 index...
            day: source.Day
        };

        // We cannot add DataProperty attributes to the ScheduleDateTime.
        // Therefore, we need to manually convert it.
        const time = TimeSpan.convert(source.Time);

        return new DateTime({ year: date.year, month: date.month, day: date.day, hour: time.hours, minute: time.minutes, second: time.seconds, millisecond: time.milliseconds });
    }

    public static now(): DateTime {
        return DateTime.fromDate(new Date());
    }

    public static addMilliseconds(date: DateTime, offset: number): DateTime {
        return date.addMilliseconds(offset);
    }

    public static addSeconds(date: DateTime, offset: number): DateTime {
        return date.addSeconds(offset);
    }

    public static addMinutes(date: DateTime, offset: number): DateTime {
        return date.addMinutes(offset);
    }

    public static addHours(date: DateTime, offset: number): DateTime {
        return date.addHours(offset);
    }

    public static addDays(date: DateTime, offset: number): DateTime {
        return date.addDays(offset);
    }

    public static addMonths(date: DateTime, offset: number): DateTime {
        return date.addMonths(offset);
    }

    public static addYears(date: DateTime, offset: number): DateTime {
        return date.addYears(offset);
    }

    public static parse(input: string): DateTime {
        const groups = datetimeRegex.exec(input);

        if (!groups) {
            // Let's fall back and let moment handle the parsing...
            return DateTime.fromMoment(moment(input));
            // throw new InvalidArgumentException(`Input [${input}] could not be parsed as a valid DateTime.`);
        }

        // https://regex101.com/r/2zUX1Q/1
        const year: number = +groups[1];
        let month: number = +groups[2];
        const day: number = +groups[3];
        const hour: number = +groups[4];
        const minute: number = +groups[5];
        const second: number = +groups[6];
        let millisecond: number = +groups[7];

        // milliseconds are in fractions of a second... We need to convert...
        if (!isNaN(millisecond) && millisecond > 0) {
            millisecond = Math.floor(1000 * (+('.' + millisecond)));
        }

        if (isNaN(year) || isNaN(month) || isNaN(day)) {
            throw new InvalidOperationException(`Invalid input: ${input}`);
        }

        // We need to reduce the month by 1 (zero index)
        month = month - 1;

        return new DateTime({ year, month, day, hour, minute, second, millisecond });
    }

    public static convert(value: any): DateTime {
        if (!value) {
            return null;
        }

        if (value instanceof DateTime) {
            return value;
        }

        if (value instanceof SmallDate) {
            return DateTime.fromSmallDate(value);
        }

        if (value instanceof Date) {
            return DateTime.fromDate(value);
        }

        // DateTime, moment
        if (typeof value.toDate === typeof Function) {
            return DateTime.fromDate(value.toDate());
        }

        // UnspecificDateTime
        if (UnspecificDateTime.isUnspecificDateTime(value)) {
            return DateTime.fromUnspecificDateTime(value);
        }

        if (typeof value === typeof '') { // String
            if (Date.isDate(<string>value)) {
                return DateTime.parse(<string>value);
            }

            if (!isNaN(+value)) { // '40000'
                return DateTime.fromDate(new Date(Math.round(+value)));
            }
        }

        if (typeof value === typeof 0) { // 40000
            return DateTime.fromDate(new Date(Math.round(<number>value)));
        }

        return value as DateTime;
    }

    public static format(date: DateTime, formatter?: string): string {
        return date.format(formatter);
    }

    public static equals(a: DateTime, b: DateTime): boolean {
        if ((a === null || a === undefined) && (b === null || b === undefined)) {
            return true;
        }

        if (a === null || a === undefined) {
            return false;
        }

        return a.equals(b);
    }

    private mutator(source?: DateTime): moment.Moment {
        return moment({
            year: (source || this).date.year,
            month: (source || this).date.month,
            day: (source || this).date.day,
            hour: (source || this).timeOfDay.hours,
            minute: (source || this).timeOfDay.minutes,
            second: (source || this).timeOfDay.seconds,
            millisecond: (source || this).timeOfDay.milliseconds
        });
    }

    public get hasValue(): boolean {
        return this.date.hasValue && this.timeOfDay.hasValue;
    }

    public addMilliseconds(offset: number): DateTime {
        if ((offset || 0) === 0) {
            return this;
        }

        const mutate = this.mutator();
        return DateTime.fromMoment(mutate.add(offset, 'ms'));
    }

    public addSeconds(offset: number): DateTime {
        if ((offset || 0) === 0) {
            return this;
        }

        const mutate = this.mutator();
        return DateTime.fromMoment(mutate.add(offset, 's'));
    }

    public addMinutes(offset: number): DateTime {
        if ((offset || 0) === 0) {
            return this;
        }

        const mutate = this.mutator();
        return DateTime.fromMoment(mutate.add(offset, 'm'));
    }

    public addHours(offset: number): DateTime {
        if ((offset || 0) === 0) {
            return this;
        }

        const mutate = this.mutator();
        return DateTime.fromMoment(mutate.add(offset, 'h'));
    }

    public addDays(offset: number): DateTime {
        if ((offset || 0) === 0) {
            return this;
        }

        const mutate = this.mutator();
        return DateTime.fromMoment(mutate.add(offset, 'd'));
    }

    public addWeeks(offset: number): DateTime {
        if ((offset || 0) === 0) {
            return this;
        }

        const mutate = this.mutator();
        return DateTime.fromMoment(mutate.add(offset, 'w'));
    }

    public addMonths(offset: number): DateTime {
        if ((offset || 0) === 0) {
            return this;
        }

        const mutate = this.mutator();
        return DateTime.fromMoment(mutate.add(offset, 'M'));
    }

    public addYears(offset: number): DateTime {
        if ((offset || 0) === 0) {
            return this;
        }

        const mutate = this.mutator();
        return DateTime.fromMoment(mutate.add(offset, 'y'));
    }

    public addTime(offset: TimeSpan): DateTime {
        if ((offset || TimeSpan.empty()).totalMilliseconds === 0) {
            return this;
        }
        var curDate = moment(this.date).isDST()
        var nextDate = moment(this.date).clone().add(1, "day").isDST();
        if(!nextDate && curDate){
            return this.addHours(offset.hours+1).addMinutes(offset.minutes).addSeconds(offset.seconds).addMilliseconds(offset.milliseconds)
        }
        else if(!curDate && nextDate && offset.hours>2){
            return this.addHours(offset.hours-1).addMinutes(offset.minutes).addSeconds(offset.seconds).addMilliseconds(offset.milliseconds)
        }
        return this.addHours(offset.hours).addMinutes(offset.minutes).addSeconds(offset.seconds).addMilliseconds(offset.milliseconds);
    }

    public diff(other: DateTime): TimeSpan {
        const mutate = this.mutator();
        return TimeSpan.fromMilliseconds(mutate.diff(this.mutator(other)));
    }

    public setTime(time: TimeSpan): DateTime {
        return DateTime.fromSmallDate(this.date).addTime(time);
    }

    public timezone(timezone: string): DateTime {
        const timezoneId = getTimeZoneId(timezone);

        if(String.isNullOrEmpty(timezoneId)) {
            // If we get this far, the timezone could not be located... simply return the current value
            return this;
        }
        return DateTime.fromDate(new Date(this.toDate().toLocaleString("en-US", {timeZone: timezoneId})));
    }

    public timezoneISOName(timezone: string): string {
        return getTimeZoneId(timezone);
    }
    
    public timezoneISOAbbr(timezone: string): string {
        return momentTZ().tz(this.timezoneISOName(timezone)).format('z');
    }
    
    public truncateToMinutes(): DateTime {
        return this.setTime(TimeSpan.from({ hour: this.timeOfDay.hours, minute: this.timeOfDay.minutes }));
    }

    public toDate(): Date {
        const mutate = this.mutator();
        return mutate.toDate();
    }

    public format(formatter?: string): string {
        const mutate = this.mutator();
        return mutate.format(formatter);
    }

    public toString(): string {
        return this.format(defaultDateTimeFormat);
    }

    public toISOString(): string {
        return this.toDate().toISOString();
    }

    public toJSON(): string {
        const date = this.date.toJSON();
        const time = this.timeOfDay.toJSON();
        return `${date}T${time}`;
    }

    public equals(other: DateTime): boolean {
        if (other === null || other === undefined) {
            return false;
        }

        return this.date.equals(other.date)
            && this.timeOfDay.equals(other.timeOfDay);
    }
}
