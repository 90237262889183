import { Directive, ElementRef } from "@angular/core";
import { BaseDirective } from '../../../_core/components/base.directive';

@Directive({
    selector: '[geneaFormInputStyle]'
})
export class GeneaFormInputDirective extends BaseDirective {

    public get htmlElement(): HTMLElement {
        return this.hostElement.nativeElement;
    }

    constructor(private readonly hostElement: ElementRef) {
        super();
    }

    public OnInit(): void {
        super.OnInit();

        this.addClass('col-12', 'col-md-6', 'col-xl-8');
    }
    
    private addClass(...tokens: Array<string>): void {
        this.htmlElement.classList.add(...tokens);
    }
}