export enum CommandStatus {
    NotExecuted = 0,
    Failed = 1,
    FailedWarnOnly = 2,
    Succeeded = 3,
    Similated = 4
}
 
export const CommandStatusNames = new Map<number, string>([
    [CommandStatus.NotExecuted, 'Not Executed'],
    [CommandStatus.Failed, 'Failed'],
    [CommandStatus.FailedWarnOnly, 'Failed - Warn Only'],
    [CommandStatus.Succeeded, 'Succeeded'],
    [CommandStatus.Similated, 'Similated']
]);
