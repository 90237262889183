import { Component, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HvacInvoicePeriodService } from '../../../admin/platform/invoicing/hvac-invoicing/_services/hvac-invoice-period.service';
import { BaseEditComponent } from '../../../_core/components/base-edit.component';
import { notNull } from '../../../_core/rxjs.operators';

import { AuthService } from '../../../_services/auth/auth.service';
import { distinctUntilChanged, take, skip, tap,filter, map, first, } from 'rxjs/operators';
import { BlobService } from '../../../_services/blob.service';
import { GlobalConfig } from '../../../_core/global.config';
import { HvacInvoicePeriodModel } from '../../../admin/platform/invoicing/hvac-invoicing/_models/hvac-invoice-period.model';
import { InvoicePacketModel } from '../../../admin/platform/invoicing/meter-invoicing/_models/invoice-packet.model';
import { TypeConverter } from '../../../_core/type-converter';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { HvacBuildingInternalNotesModel } from '../../../admin/platform/invoicing/hvac-invoicing/_models/hvac-building-internal-notes.model';
import { HvacChangeRequestModel } from '../../../admin/platform/invoicing/hvac-invoicing/_models/hvac-change-request.model';
import { Location } from '@angular/common';

import { InvoiceStatusType } from '../../../admin/platform/invoicing/hvac-invoicing/_models/enumerations/invoice-status-type.enumeration';
import { HvacBuildingInvoiceService } from '../../../admin/platform/invoicing/hvac-invoicing/_services/hvac-building-invoice.service';
import { HvacPropertyManagerViewService } from '../../../admin/platform/invoicing/hvac-invoicing/_services/hvac-property-manager-view.service';
import { InvoiceStateType } from '../../../admin/platform/invoicing/hvac-invoicing/_models/enumerations/invoice-state-type.enumeration';
import { ToastrService } from '../../toastr/toastr.service';
import { DialogAction, DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';


// New building invoice preview created specific requirement for HVAC admin invoicing flow 
// currently it's written as per hvac scope. re edit if need to re use for metering accordingly 
// it contains internal note functionality and change request preview (update change request currently not part of scope for admin view)

@Component({
  selector: 'app-building-invoice-preview-page',
  templateUrl: './building-invoice-preview-page.component.html',
  styleUrls: ['./building-invoice-preview-page.component.scss']
})
export class BuildingInvoicePreviewPageComponent  extends BaseEditComponent  {


  public invoiceType:string = null; 
  public invoicePacketId:number = null;
  public drawerExpanded = true;
  public isPM = false;
  public miniValue = true;
  public changeRequestMessage =''

  public _error$ = new BehaviorSubject<{ status: number, errorMessage: string }>(null);
  public error$ = this._error$.asObservable().pipe(notNull());

  private readonly _isLoading$ = new BehaviorSubject<boolean>(true);
  public readonly isLoading$ = this._isLoading$.asObservable().pipe(notNull());

  private readonly _isContentLoading$ = new BehaviorSubject<boolean>(true);
  public readonly isContentLoading$ = this._isContentLoading$.asObservable().pipe(notNull());

  private readonly _disableChangeRequest$ = new BehaviorSubject<boolean>(false);
  public readonly disableChangeRequest$ = this._disableChangeRequest$.asObservable().pipe(notNull());

  private _canChangeRequest: boolean = null;

  protected readonly accessCacheMap = new Map<string, boolean>();

  public expandChangeRequest: boolean = false;

  private readonly _changeRequestLoading$ = new BehaviorSubject<boolean>(false);
  public readonly changeRequestLoading$ = this._changeRequestLoading$.asObservable().pipe(notNull());

  private readonly _hvacInvoiceData$ = new BehaviorSubject<HvacInvoicePeriodModel>(null);
  public readonly hvacInvoiceData$ = this._hvacInvoiceData$.asObservable().pipe(notNull());

  private readonly _meteringInvoiceData$ = new BehaviorSubject<InvoicePacketModel>(null);
  public readonly meteringInvoiceData$ = this._meteringInvoiceData$.asObservable().pipe(notNull());

  
  private readonly _invoiceChangeRequest$ = new BehaviorSubject<HvacChangeRequestModel>(null);
  public readonly invoiceChangeRequest$ = this._invoiceChangeRequest$.asObservable().pipe(notNull());
  
  public _notesUpdated$ = new BehaviorSubject<boolean>(false);
  public readonly notesUpdated$ = this._notesUpdated$.asObservable().pipe(notNull());

  public invoiceChangeRequestPM:HvacChangeRequestModel  = TypeConverter.convert(HvacChangeRequestModel, {})
  
  public buildingNoteForm:FormGroup;   
  public buildingNote: HvacBuildingInternalNotesModel =TypeConverter.convert(HvacBuildingInternalNotesModel, {});

  public hvacInvoiceStatus = InvoiceStatusType;
  

  private get BuildingInternalNoteControl(): AbstractControl {
    return this.buildingNoteForm.get('Note');
  }

  @ViewChild('requestChangeTemplate')
    protected requestChangeTemplate: TemplateRef<any>;

  constructor(
    location:Location,
    route: ActivatedRoute,    
    router:Router,
    private readonly hvacInvoiceService: HvacInvoicePeriodService, 
    private readonly hvacPeriodInvoiceService: HvacBuildingInvoiceService, 
    private readonly pmInvoiceService: HvacPropertyManagerViewService, // incase if need to implement in future
    protected authService: AuthService,
    private readonly blobservice: BlobService,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private readonly config: GlobalConfig) {
      super(location, router, route);

    this.buildingNoteForm = new FormGroup({
      Note: new FormControl()
    });
    
  }


  public OnInit(): void {
     
    //From param feth invoicetype and invoice packet id 
    
    this.route.params
          .pipe(   
            filter((p: {InvoiceType:string, InvoiceId: number}) => p.InvoiceType && p.InvoiceId > 0),    
            tap((p) => 
              {
                this.invoiceType = p.InvoiceType; 
                this.invoicePacketId = p.InvoiceId;
              }
            )
    ).subscribe();
  
    let uri = '';

    //hvac invoice preview implemented //TO DO - in future if needed implement it for meterin
    if(this.invoiceType == 'hvac'){

      //set uri 1st to load invoice pdf blob 
      uri ='invoicing/hvac/period/view/'+this.invoicePacketId;

      //call hvac period api to fetch invoice data 
      if(!this.authService.hasRole(this.RoleConstant.PropertyManager)){
        this.process(this.hvacInvoiceService.getById(this.invoicePacketId)).pipe(
          tap( (data: HvacInvoicePeriodModel) =>{
  
            this._isContentLoading$.next(false);
            let invoiceData = TypeConverter.convert(HvacInvoicePeriodModel, data);
  
            let buildingNoteObject = invoiceData.InternalNote;
            this.buildingNote = buildingNoteObject;
  
            if(buildingNoteObject && buildingNoteObject.Note){
              this.buildingNoteForm.patchValue({
                Note: buildingNoteObject.Note
              });
            }
       
            if(invoiceData &&  invoiceData.Conversation &&  invoiceData.Conversation.Message){
              this._invoiceChangeRequest$.next(invoiceData.Conversation); 
            }          
            
            this._hvacInvoiceData$.next(invoiceData);
          })
        ).subscribe();
      }
    }

    //fetch invoice for the specific invoice id 
    // uri is set above according to product mentioned in route
    this.blobservice.retrieve(uri).pipe(
      notNull(),
      take(1),
      tap(
        data => {
              var myIframe = document.getElementById('pdfviewer'); 

              //older Safari having issue with rendering blob 
              //check if safari browser then  pass base url to load direct else add blob url to iframe src.   
              if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){                   
                  myIframe.setAttribute("data", this.config.api('platform').baseUri+'/'+'invoicing/hvac/period/view/'+this.invoicePacketId + '#zoom=168');
              
              } else {    
                let zoom;
                if(window.innerWidth<1600){
                  zoom = 120;
                } 
                else if(window.innerWidth>1900){
                  zoom = 165
                }                                     
                  myIframe.setAttribute("data", URL.createObjectURL(new Blob([data.body], { type: 'application/pdf' })) + '#zoom=' + zoom);                   
              }  
                            
              this._isLoading$.next(false);              
          },
          (err) => {
            let error: { status: number, errorMessage: string };
            
            if (err.innerError.status == 404) {
              error = {
                errorMessage: "Invoice not found",
                status: 404
              }

            } else {
              error = {
                status: 500,
                errorMessage: "Couldn't load invoice, Please try again"
              }
            }

            this._error$.next(error);
            this._isLoading$.next(false);
          }
        )
    ).subscribe();

    //once note edited enable save note 
    this.addSubscription(
      this.BuildingInternalNoteControl.valueChanges.pipe(
        notNull(),
        distinctUntilChanged(),
        skip(1),
        tap(
          value =>{          
            this._notesUpdated$.next(true);
          }
        )

      )
    )
    if(this.authService.hasRole(this.RoleConstant.PropertyManager)){
      this.isPM = true;
      this.miniValue = false;
      this.pmInvoiceService.getChangeRequestView().subscribe(data=>{
        if(data === true){
          this.drawerExpanded = true;
        }
        else{
          this.drawerExpanded= false;
        }
      })
      this._isContentLoading$.next(true);
      const stream$ = this.pmInvoiceService.propertyManagerData(this.route.snapshot.paramMap.get("InvoiceId"))
      .pipe(
        // Don't use "any"! 
        // "data" SHOULD be of type "HvacInvoicePeriodModel"
        tap((data:HvacInvoicePeriodModel) => { 
          this._hvacInvoiceData$.next(data);
          this._isContentLoading$.next(false);
        })
      );
      this.addSubscription(
        this.hvacInvoiceData$.pipe(
          map(hvacInvoice => hvacInvoice.Conversation),
          notNull(),
          tap(conversation => {
              if(conversation.Message) {
                this.invoiceChangeRequestPM = TypeConverter.convert(HvacChangeRequestModel, conversation);
              }
              this.changeRequestMessage = conversation.Message;
            }
          )
        )
      );
      
      // Kick off our initial stream by subscribing...
      this.addSubscription(stream$);
    }
  }

  // save internal note 
  public saveNote(){

    this._isContentLoading$.next(true);
    let model = TypeConverter.convert(HvacBuildingInternalNotesModel, {});
    
    model.Note = this.buildingNoteForm.value.Note;
    model.BuildingId = this._hvacInvoiceData$.getValue().BuildingId;

    this._notesUpdated$.next(false);
   
    this.process(this.hvacInvoiceService.updateBuildingNote(model)).subscribe(data => {    
        this._isContentLoading$.next(false);
        this.buildingNote = TypeConverter.convert(HvacBuildingInternalNotesModel, data);         
      },
      (error)=>{
        this._isContentLoading$.next(false);
      });
    
  }    

  // update status of the invoice ex: pending for approval -> approved
  public changeInvoice(nextState){
    let invoiceData:any; 
    if(this.invoiceType=='hvac'){
        invoiceData = this._hvacInvoiceData$.getValue(); 
        invoiceData.State = nextState;
        this._isContentLoading$.next(true);
        this.process(this.hvacInvoiceService.updateInvoiceStatus([invoiceData])).pipe(
          tap(data=>{        
            this._isContentLoading$.next(false);
        })
      ).subscribe();
    }    
  }

  // need to implement permissions if the component needs to accessible for property manager role
  public hasPermission(permission: string): boolean {
    return this.accessCacheMap.getValue(permission, () => this.authService.hasPermission(permission));
  }
  public requestInvoice(value){
    
      let dialog = this.dialogService.open({
          title: 'Are you sure you want to suggest these changes?',
          content: this.requestChangeTemplate,
          actions: [
              { text: 'Request Changes', primary: true },
              { text: 'Cancel' }
          ],
          width: 500,
          cssClass:'bottom-popup'
      });

  if (!!dialog) {

      this.addSubscription(
          dialog.result
              .pipe(
                  first(),
                  tap(result => {
                      if (result instanceof DialogCloseResult) {
                          // Do nothing... They closed the window...
                      } else {
                          const action = <DialogAction>result;

                          switch (action.text) {
                              case 'Request Changes':
                                this._isContentLoading$.next(true);
                                let model = TypeConverter.convert(HvacInvoicePeriodModel, this._hvacInvoiceData$.getValue());
                                
                                model.State = InvoiceStateType.ChangesRequested
                                let conversation  = TypeConverter.convert(HvacChangeRequestModel,{});
                                conversation.Message = this.changeRequestMessage
                                conversation.Id = Number(this.route.snapshot.paramMap.get("InvoiceId"))
                                model.Conversation = conversation;
                               
                                this.process(this.hvacPeriodInvoiceService.patchChangeRequest(model, model.BuildingId)).subscribe((data:HvacInvoicePeriodModel) => {    
                                    this.toaster.success("Request Successful")
                                    this._isContentLoading$.next(false);
                                    this._disableChangeRequest$.next(true);
                                    this.invoiceChangeRequestPM = TypeConverter.convert(HvacChangeRequestModel, data.Conversation)        
                                  },
                                  (error)=>{
                                    this._isContentLoading$.next(false);
                                  });
                                  break;
                              default:
                                  break;
                          }
                      }
                  })
              )
      );
  }
    
  }
  goback(){
    this.router.navigate(['admin','platform','invoicing','hvac','pmview'])
  }
}
