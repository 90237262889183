<div class="container">
    <div class="card">
        <div class="g-header card-banner-top text-center">
            <img alt="GENEA" class="genea-logo" />
        </div>
        <div class="card-body">
            <h5 class="card-title">Redirecting...</h5>
            <ng-container *ngIf="externalUrl; else noExternalUrl">
                <p>Please wait while you are redirected</p>
                <ul>
                    <li>
                        URL: {{ externalUrl }}
                    </li>
                </ul>
                <p>Click <a [href]="externalUrl">here</a> if you are not redirected within a few seconds</p>
            </ng-container>

            <ng-template #noExternalUrl>
                <ul>
                    <li class="font-weight-bold font-italic">
                        No redirect found...
                    </li>
                </ul>
                <p>Click <a [routerLink]="['/']">here</a> to go home.</p>
            </ng-template>
        </div>
    </div>
</div>
