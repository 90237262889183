import * as moment from 'moment';

import { Milliseconds } from './date.internal';
import { ArgumentException } from '../exceptions/argument.exception';

const timespanRegex = /^(-)?(\d+\.{1})?(\d{1,2}):(\d{1,2}):(\d{1,2})(\.(\d+))?$/;

export class TimeSpan {

    public readonly ticks: number;

    public readonly totalMilliseconds: number;
    public readonly totalSeconds: number;
    public readonly totalMinutes: number;
    public readonly totalHours: number;
    public readonly totalDays: number;
    public readonly totalYears: number;

    public readonly milliseconds: number;
    public readonly seconds: number;
    public readonly minutes: number;
    public readonly hours: number;
    public readonly days: number;
    public readonly years: number;

    private constructor(milliseconds: number = 0) {
        // Kinda weird, but we need to make sure we use a non-decimal value.
        milliseconds = Math.floor(milliseconds || 0);

        this.ticks = milliseconds / Milliseconds.perTick;

        this.totalMilliseconds = milliseconds;
        this.totalSeconds = milliseconds / Milliseconds.perSecond;
        this.totalMinutes = milliseconds / Milliseconds.perMinute;
        this.totalHours = milliseconds / Milliseconds.perHour;
        this.totalDays = milliseconds / Milliseconds.perDay;
        this.totalYears = milliseconds / Milliseconds.perYear;

        const sign = milliseconds < 0
            ? -1
            : 1;

        milliseconds = Math.abs(milliseconds);

        this.milliseconds = Math.floor(milliseconds % Milliseconds.perSecond) * sign;
        this.seconds = Math.floor((this.totalSeconds) % 60) * sign;
        this.minutes = Math.floor((this.totalMinutes) % 60) * sign;
        this.hours = Math.floor((this.totalHours) % 24) * sign;
        this.days = Math.floor(this.totalDays) * sign;
        this.years = Math.floor(this.totalYears) * sign;
    }

    public static fromTicks(ticks: number): TimeSpan {
        return TimeSpan.fromMilliseconds(ticks * Milliseconds.perTick);
    }

    public static fromMilliseconds(milliseconds: number): TimeSpan {
        return new TimeSpan(Math.round(milliseconds));
    }

    public static fromSeconds(seconds: number): TimeSpan {
        return TimeSpan.fromMilliseconds(seconds * Milliseconds.perSecond);
    }

    public static fromMinutes(minutes: number): TimeSpan {
        return TimeSpan.fromMilliseconds(minutes * Milliseconds.perMinute);
    }

    public static fromHours(hours: number): TimeSpan {
        return TimeSpan.fromMilliseconds(hours * Milliseconds.perHour);
    }

    public static fromDays(days: number): TimeSpan {
        return TimeSpan.fromMilliseconds(days * Milliseconds.perDay);
    }

    public static fromDate(date: Date): TimeSpan {
        if(date === null || date === undefined) {
            return TimeSpan.empty();
        }
        
        const timePart = (date.getHours() * Milliseconds.perHour)
            + (date.getMinutes() * Milliseconds.perMinute)
            + (date.getSeconds() * Milliseconds.perSecond)
            + date.getMilliseconds();

        return TimeSpan.fromMilliseconds(timePart);
    }

    public static from(source: { hour?: number, minute?: number, second?: number, millisecond?: number }): TimeSpan {
        return TimeSpan.fromMilliseconds((source.hour || 0) * Milliseconds.perHour + (source.minute || 0) * Milliseconds.perMinute + (source.second || 0) * Milliseconds.perSecond + (source.millisecond || 0));
    }

    public static hasValue(timespan: TimeSpan): boolean {
        return timespan && timespan.hasValue;
    }

    public static isTimeSpan(input: string): boolean {
        if (String.isNullOrEmpty(input)) {
            return false;
        }

        return timespanRegex.test(input);
    }

    public static parse(input: string): TimeSpan {

        if (!TimeSpan.isTimeSpan(input)) {
            throw new ArgumentException(`Invalid input: [${input}]`);
        }

        // [1] +/-
        // [2] days
        // [3] hours
        // [4] minutes
        // [5] seconds
        // [6] milliseconds
        const groups = timespanRegex.exec(input);

        const negative: boolean = groups[1] === '-';
        const days: number = +groups[2] || 0;
        const hours: number = +groups[3] || 0;
        const minutes: number = +groups[4] || 0;
        const seconds: number = (+groups[5] || 0) + ((+groups[6] || 0));

        let timespan = TimeSpan.empty()
            .addDays(days)
            .addHours(hours)
            .addMinutes(minutes)
            .addSeconds(seconds);

        if (negative) {
            timespan = TimeSpan.fromMilliseconds(timespan.totalMilliseconds * -1);
        }

        return timespan;
    }

    public static now(): TimeSpan {
        return TimeSpan.fromDate(new Date());
    }

    public static empty(): TimeSpan {
        return TimeSpan.fromMilliseconds(0);
    }

    private static normalize(source: TimeSpan | Date): TimeSpan {
        if (!source) {
            return TimeSpan.empty();
        }

        if (source instanceof TimeSpan) {
            return source;
        }

        return Date.timeOfDay(source);
    }

    public static add(left: TimeSpan | Date, right: TimeSpan | Date): TimeSpan {
        return TimeSpan.fromMilliseconds(TimeSpan.normalize(left).totalMilliseconds + TimeSpan.normalize(right).totalMilliseconds);
    }

    public static substract(left: TimeSpan | Date, right: TimeSpan | Date): TimeSpan {
        return TimeSpan.fromMilliseconds(TimeSpan.normalize(left).totalMilliseconds - TimeSpan.normalize(right).totalMilliseconds);
    }

    public static addMilliseconds(timespan: TimeSpan, offset: number): TimeSpan {
        return TimeSpan.fromMilliseconds(timespan.totalMilliseconds + offset);
    }

    public static addSeconds(timespan: TimeSpan, offset: number): TimeSpan {
        return TimeSpan.fromMilliseconds(timespan.totalMilliseconds + offset * Milliseconds.perSecond);
    }

    public static addMinutes(timespan: TimeSpan, offset: number): TimeSpan {
        return TimeSpan.fromMilliseconds(timespan.totalMilliseconds + offset * Milliseconds.perMinute);
    }

    public static addHours(timespan: TimeSpan, offset: number): TimeSpan {
        return TimeSpan.fromMilliseconds(timespan.totalMilliseconds + offset * Milliseconds.perHour);
    }

    public static addDays(timespan: TimeSpan, offset: number): TimeSpan {
        return TimeSpan.fromMilliseconds(timespan.totalMilliseconds + offset * Milliseconds.perDay);
    }

    public static format(timespan: TimeSpan, formatter: string): string {
        const sign = timespan.totalMilliseconds < 0
            ? '-'
            : '';

        let d = '';

        if (Math.abs(timespan.days) > 0) {
            d = `${Math.abs(timespan.days)}.`;
        }

        return `${sign}${d}${moment(new Date(2000, 1, 1, Math.abs(timespan.hours), Math.abs(timespan.minutes), Math.abs(timespan.seconds), Math.abs(timespan.milliseconds))).format(formatter)}`;
    }

    public static convert(value: any): TimeSpan {

        if (!value) {
            return null;
        }

        if (value instanceof TimeSpan) {
            return value;
        }

        if (value instanceof Date) {
            return TimeSpan.fromDate(<Date>value);
        }

        // DateTime, SmallDate, moment
        if (typeof value.toDate === typeof Function) {
            return TimeSpan.fromDate(value.toDate());
        }

        // DateTime
        if (!!(value.timeOfDay)) {
            return <TimeSpan>value.timeOfDay;
        }

        if (typeof value === typeof '') { // String
            if (TimeSpan.isTimeSpan(<string>value)) { // '01:23:23.234'
                return TimeSpan.parse(<string>value);
            }

            if (Date.isDate(<string>value)) {
                return Date.timeOfDay(Date.convert(value));
            }

            if (!isNaN(+value)) { // '40000'
                return TimeSpan.fromMilliseconds(+value);
            }
        }

        if (typeof value === typeof 0) { // 40000
            return TimeSpan.fromMilliseconds(<number>value);
        }

        return value as TimeSpan;
    }

    public static equals(a: TimeSpan, b: TimeSpan): boolean {
        if ((a === null || a === undefined) && (b === null || b === undefined)) {
            return true;
        }

        if (a === null || a === undefined) {
            return false;
        }

        return a.equals(b);
    }

    public static toDate(timespan: TimeSpan): Date {
        const date = new Date(timespan.totalMilliseconds);
        return new Date(date.getTime() + date.getTimezoneOffset() * Milliseconds.perMinute);
    }

    public get hasValue(): boolean {
        return Math.abs(this.ticks) > 0;
    }

    public add(right: TimeSpan | Date): TimeSpan {
        return TimeSpan.add(this, right);
    }

    public substract(right: TimeSpan | Date): TimeSpan {
        return TimeSpan.substract(this, right);
    }

    public addMilliseconds(offset: number): TimeSpan {
        return TimeSpan.addMilliseconds(this, offset);
    }

    public addSeconds(offset: number): TimeSpan {
        return TimeSpan.addSeconds(this, offset);
    }

    public addMinutes(offset: number): TimeSpan {
        return TimeSpan.addMinutes(this, offset);
    }

    public addHours(offset: number): TimeSpan {
        return TimeSpan.addHours(this, offset);
    }

    public addDays(offset: number): TimeSpan {
        return TimeSpan.addDays(this, offset);
    }

    public format(formatter: string): string {
        return TimeSpan.format(this, formatter);
    }

    public toDate(): Date {
        return TimeSpan.toDate(this);
    }

    public toStringOrEmpty(): string {
        if (!this.hasValue) {
            return '';
        }

        return this.toString();
    }

    public toString(): string {
        return this.format('HH:mm:ss.SSS');
    }

    public toJSON(_?: any): string {
        return this.format('HH:mm:ss.SSS');
    }

    public equals(other: TimeSpan): boolean {
        if (other === null || other === undefined) {
            return false;
        }

        return this.totalMilliseconds === other.totalMilliseconds;
    }
}
