import { EntityModel } from '../model';
import { StateProvinceModel } from '../directory/state-province.model';
import { CountryModel } from '../directory/country.model';
import { RegionModel } from '../directory/region.model';
import { DataProperty, DataObject } from '../../_core/type-converter';

@DataObject()
export class AddressModel extends EntityModel {

    public City: string = null;
    public Address1: string = null;
    public Address2: string = null;
    public ZipPostalCode: string = null;
    public PhoneNumber: string = null;
    public FaxNumber: string = null;
    public Longitude: number = null;
    public Latitude: number = null;
    public Subject1: string = null;
    public Subject2: string = null;

    @DataProperty()
    public Country: CountryModel = null;

    @DataProperty()
    public StateProvince: StateProvinceModel = null;

    @DataProperty()
    public Region: RegionModel = null;

    private _formatted: string;
    public format(lineBreak: string = '<br />'): string {

        if (!this._formatted) {
            const output: Array<string> = [];

            output.push(this.Subject1);
            output.push(this.Subject2);
            output.push(this.Address1);
            output.push(this.Address2);

            let cityStateZip = this.City || '';

            const stateProvinceName = (this.StateProvince && this.StateProvince.Name);
            if (!String.isNullOrEmpty(stateProvinceName)) {
                if (cityStateZip.length > 0) {
                    cityStateZip += ', ';
                }

                cityStateZip += stateProvinceName;
            }
            if ((this.ZipPostalCode || '').length > 0) {
                if (cityStateZip.length > 0) {
                    cityStateZip += ' ';
                }
                cityStateZip += this.ZipPostalCode;
            }

            output.push(cityStateZip);

            const countryName = (this.Country && this.Country.Name);

            if (!String.isNullOrEmpty(countryName) 
                && !countryName.looseEquals('usa') 
                && !countryName.looseEquals('us') 
                && !countryName.looseEquals('United States')) {
                output.push(countryName);
            }

            this._formatted = output.toDelimitedString(lineBreak);
        }

        return this._formatted;
    }
}
