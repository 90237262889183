import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { EnumFieldConfig } from '../../models/field-config.interface';
import { DropDownItem } from '../../../../common/inputs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-select',
    styleUrls: ['form-select.component.scss'],
    template: `
    <ng-container [formGroup]="group">
        <kendo-dropdownlist
            [data]="config.options"
            [valuePrimitive]="true"
            [ngClass]="[config.cssClass || '']"
            [defaultItem]="defaultItem"
            [formControlName]="config.name"
            [popupSettings]="config.popupSettings || { width: 'auto'}"
            textField="Name"
            valueField="Id"
            style="min-width: 150px;">
        </kendo-dropdownlist>
    </ng-container>
  `
})
export class FormSelectComponent implements Field {
    config: EnumFieldConfig;
    group: FormGroup;

    public get defaultItem(): DropDownItem {
        return this.config.placeholder
            ? <DropDownItem>{
                Id: null,
                Name: `-- ${this.config.placeholder} --`
            }
            : null;
    }
}
