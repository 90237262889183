import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { SwitchFieldConfig } from '../../models/field-config.interface';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { tap, distinctUntilChanged, filter, first } from 'rxjs/operators';

import { BaseComponent } from '../../../../../_core/components/base.component';
import { TypeConverter } from '../../../../../_core/type-converter';
import { notNull } from '../../../../../_core/rxjs.operators';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-checkbox',
    styleUrls: ['form-checkbox.component.scss'],
    template: `
    <ng-container [formGroup]="proxyGroup">
        <kendo-switch formControlName="switch" [onLabel]="config.onLabel" [offLabel]="config.offLabel"></kendo-switch>
    </ng-container>
  `
})
export class FormCheckboxComponent extends BaseComponent implements Field {
    public proxyGroup: FormGroup;

    private config$: BehaviorSubject<SwitchFieldConfig>;
    private group$: BehaviorSubject<FormGroup>;

    public get config(): SwitchFieldConfig {
        return this.config$.getValue();
    }
    public set config(value: SwitchFieldConfig) {
        if (!value) {
            return;
        }

        if (!TypeConverter.isNull(value)) {
            if (String.isNullOrEmpty(value.onLabel)) {
                value.onLabel = 'Yes';
            }

            if (String.isNullOrEmpty(value.offLabel)) {
                value.offLabel = 'No';
            }
        }

        this.config$.next(value);
    }

    public get group(): FormGroup {
        return this.group$.getValue();
    }
    public set group(value: FormGroup) {
        this.group$.next(value);
    }

    // public group: FormGroup;

    constructor(private fb: FormBuilder) {
        super();

        this.proxyGroup = this.fb.group({
            switch: false
        });

        this.config$ = new BehaviorSubject(null);
        this.group$ = new BehaviorSubject(null);

        // We're going to listen for both the config and group properties
        // being set. Once they have a value, then we can bind our config properties
        // to our proxy form.
        this.addSubscription(
            combineLatest(
                this.config$.pipe(notNull()),
                this.group$.pipe(notNull())
            ).pipe(
                first(),
                tap(([config, group]) => {
                    // Both values have been set! bind our form...
                    this.bindForm(config, group);
                })
            )
        );
    }

    private bindForm(config: SwitchFieldConfig, group: FormGroup) {
        const value: Boolean = Boolean(config.value);

        this.proxyGroup.setValue({ switch: value }, { emitEvent: false });

        // When the proxy changes, notify the original.
        this.addSubscription(
            this.proxyGroup.valueChanges
                .pipe(
                    distinctUntilChanged(),
                    tap(changes => {
                        let proxyValue: any = <Boolean>changes.switch;

                        if (config.substituteTrue !== undefined && proxyValue) {
                            proxyValue = config.substituteTrue;
                        }
                        if (config.substituteFalse !== undefined && !proxyValue) {
                            proxyValue = config.substituteFalse;
                        }

                        const newValue = {};
                        newValue[config.name] = proxyValue;
                        group.patchValue(newValue, { emitEvent: true });
                    })
                )
        );

        // Make sure we set the disabled state from the config
        if (config.disabled) {
            this.proxyGroup.disable();
        }

        // Used to set the enabled/disabled from the original control.
        // When a control is disabled in the original, we need to reflect that in the proxy...
        this.addSubscription(
            group.controls[config.name].statusChanges
                .pipe(
                    filter(v => v !== this.proxyGroup.status), // Note, YOU NEED THIS!
                    distinctUntilChanged(),
                    tap(status => {
                        const method = String.equals(status, 'disabled', false) ? 'disable' : 'enable';
                        // Remember, this is ONE control.
                        // Even though we're rendering 4 fields, it's still ONE control...
                        // THerefore, when the original form control is enabled/disabled,
                        // we need to enable/disable the GROUP, not just a control...
                        this.proxyGroup[method]();
                    })
                )
        );
    }

}
