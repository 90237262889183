export enum HeartbeatStatus {
    Unknown = 0,
    Online = 1,
    Offline = 2,
    Inactive = 3
}

export const HeartbeatStatusNames = new Map<number, string>([
    [HeartbeatStatus.Unknown, 'Unknown'],
    [HeartbeatStatus.Online, 'Online'],
    [HeartbeatStatus.Offline, 'Offline'],
    [HeartbeatStatus.Inactive, 'Inactive']
]);