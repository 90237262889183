import { BaseModel } from '../model';
import { DataObject, TypeConverter } from '../../_core/type-converter';

@DataObject()
export class TemplateOptionModel extends BaseModel {

    public get key(): string {
        const values: Array<string> = [];

        values.push((this.TemplateId || '').toString());
        values.push((this.Id || '').toString());

        const key = values.toDelimitedString('-');

        return key;
    }

    public get isNew(): boolean {
        return TypeConverter.isNull(this.TemplateId);
    }

    public TemplateId: number = null;
    public DisplayOrder = 1;
    public DefaultValue: string = null;
    public Required = true;
}
