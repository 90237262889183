export enum PointHandling {
    None = 0,
    Sequenced = 1,
    AscendingDescending = 2
}

export const PointHandlingNames = new Map<number, string>([
    [PointHandling.None, 'None'],
    [PointHandling.Sequenced, 'Sequenced'],
    [PointHandling.AscendingDescending, 'Ascending / Descending']
]);

export enum PrimaryZoneHandling {
    None = 0,
    CanBeActivatedInSecondaryZones = 1,
    MustBeActivatedLast = 2
}

export const PrimaryZoneHandlingNames = new Map<number, string>([
    [PrimaryZoneHandling.None, 'None'],
    [PrimaryZoneHandling.CanBeActivatedInSecondaryZones, 'Can Be Activated In Secondary Zones'],
    [PrimaryZoneHandling.MustBeActivatedLast, 'Must Be Activated Last']
]);

export enum ZoneType {
    PointZone = 1,
    MinimumLoadZoneGroup = 2,
    MinimumLoadZone = 3,
    SequencedActivationZone = 4
}

export const ZoneTypeNames = new Map<number, string>([
    [ZoneType.PointZone, 'Point Zone'],
    [ZoneType.MinimumLoadZone, 'Minimum Load Zone'],
    [ZoneType.MinimumLoadZoneGroup, 'Minimum Load Zone Group'],
    [ZoneType.SequencedActivationZone, 'Sequenced Activation Zone']
]);

export enum ZoneRole {
    Any = 0,
    Metering = 1,
    Lighting = 2,
    HVAC = 3
}

export const ZoneRoleNames = new Map<number, string>([
    [ZoneRole.Any, 'Any'],
    [ZoneRole.Metering, 'Metering'],
    [ZoneRole.Lighting, 'Lighting'],
    [ZoneRole.HVAC, 'HVAC']
]);
