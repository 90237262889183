import { BaseModel } from '../model';
import { DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class ServiceTypeModel extends BaseModel {

    public get key(): number {
        return this.Id;
    }

    public Id: number = null;
    public Name: string = null;
    public Description: string = null;
    public SystemName: string = null;
}

export interface ServiceTypeFilter {
    Name?: string;
    BuildingId?: number;
}

export type ServiceTypeFilterState = CompositeType<ServiceTypeFilter, FilterState>;
