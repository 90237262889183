import { EntityModel } from '../model';
import { PermissionRecordModel } from '../security/permission-record.model';
import { UserRoleCategoryModel } from './user-role-category.model';
import { DataObject, CompositeType, DataProperty } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class UserRoleModel extends EntityModel {
    public Name: string = null;
    public SystemName: string = null;
    public Active = false;
    public IsSystemRole? = false;
    
    @DataProperty({ instanceType: PermissionRecordModel })
    public PermissionRecords?: PermissionRecordModel[] = null;

    @DataProperty({ instanceType: UserRoleCategoryModel })
    public UserRoleCategories?: UserRoleCategoryModel[] = null;
}

export interface UserRoleFilter {
    Id?: number;
    Name?: string;
    SystemName?: string;
    UserGuid?: string;
}

export type UserRoleFilterState = CompositeType<UserRoleFilter, FilterState>;
