import { EntityModel } from '../../../../../_sdk/model';
import { DataProperty, DataObject } from '../../../../../_core/type-converter';
import { LeaseAreaInvoiceAdjustmentReason } from '../../../../../_sdk/billing/line-item-adjust-reason.enum';
import { UserModel } from '../../../../../_sdk/users/user.model';
import { DateTime } from '../../../../../_core/date';


@DataObject()
export class HVACInvoiceAdjustmentModel extends EntityModel{
    public  InvoiceId:number = null;
    @DataProperty()
    public  AdjustmentReasonType :LeaseAreaInvoiceAdjustmentReason = null;
    public  Down :number = null;
    public Adjustment:number = null;    
    public Description:string = null;
    public Forward: number = null;
    @DataProperty()
    public CreatedByUser:UserModel=null;
    @DataProperty()
    public CreatedOnUtc:DateTime=null;
}