export enum ServiceType {
    //////////////////////////////////////////////////////////////////////////////////////
    // These enum values must have the same ID values from the table "dbo.ServiceType". //
    //////////////////////////////////////////////////////////////////////////////////////
    HvacAndLighting = 1,
    LightingOnly = 2,
    Metering = 3
}

export const ServiceTypeNames = new Map<number, string>([
    [ServiceType.HvacAndLighting, 'HVAC & Lighting'],
    [ServiceType.LightingOnly, 'Lighting Only'],
    [ServiceType.Metering, 'Metering']
]);

export enum ServiceRequestType {
    //////////////////////////////////////////////////////////////////////////////////////
    // These enum values must have the same ID values from the table "dbo.ServiceType". //
    //////////////////////////////////////////////////////////////////////////////////////
    HvacAndLighting = 1,
    LightingOnly = 2
}
export const ServiceRequestTypeNames = new Map<number, string>([
    [ServiceRequestType.HvacAndLighting, 'HVAC & Lighting'],
    [ServiceRequestType.LightingOnly, 'Lighting Only']
]);
