import { Action } from '@ngrx/store';
import { ModelCollection } from '../../../_sdk/collection.model';
import { LocaleStringResourceModel } from '../../../_sdk/localization/locale-string-resource.model';

export const LOAD = 'LOCALE_STRING_RESOURCE_LOAD';
export const LOAD_COMPLETE = 'LOCALE_STRING_RESOURCE_LOAD_COMPLETE';

export const INSERT = 'LOCALE_STRING_RESOURCE_INSERT';
export const INSERT_COMPLETE = 'LOCALE_STRING_RESOURCE_INSERT_COMPLETE';

export const UPDATE = 'LOCALE_STRING_RESOURCE_UPDATE';
export const UPDATE_COMPLETE = 'LOCALE_STRING_RESOURCE_UPDATE_COMPLETE';

export const DELETE = 'LOCALE_STRING_RESOURCE_DELETE';
export const DELETE_COMPLETE = 'LOCALE_STRING_RESOURCE_DELETE_COMPLETE';

export const DISPOSE = 'LOCALE_STRING_RESOURCE_DISPOSE';

export class Load implements Action {
    public readonly type = LOAD;
}

export class LoadComplete implements Action {
    public readonly type = LOAD_COMPLETE;

    constructor(public payload: ModelCollection<LocaleStringResourceModel>) { }
}

export class Insert implements Action {
    public readonly type = INSERT;

    constructor(public payload: LocaleStringResourceModel) { }
}

export class InsertComplete implements Action {
    public readonly type = INSERT_COMPLETE;

    constructor(public payload: LocaleStringResourceModel) { }
}

export class Update implements Action {
    public readonly type = UPDATE;

    constructor(public payload: LocaleStringResourceModel) { }
}

export class UpdateComplete implements Action {
    public readonly type = UPDATE_COMPLETE;

    constructor(public payload: LocaleStringResourceModel) { }
}

export class Delete implements Action {
    public readonly type = DELETE;

    constructor(public payload: LocaleStringResourceModel) { }
}

export class DeleteComplete implements Action {
    public readonly type = DELETE_COMPLETE;

    constructor(public payload: LocaleStringResourceModel) { }
}

export class Dispose implements Action {
    public readonly type = DISPOSE;
}

export type Actions =
    Load | LoadComplete
    | Insert | InsertComplete
    | Update | UpdateComplete
    | Delete | DeleteComplete
    | Dispose;
