
import { Component, ViewChild, ElementRef, ChangeDetectionStrategy, ContentChildren, QueryList, Renderer2, ChangeDetectorRef, Input, ViewEncapsulation, HostListener } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';
import { BaseComponent } from '../../../_core/components/base.component';

@Component({
  selector: 'app-action-menu-dropdown',
  templateUrl: './action-menu-dropdown.component.html',
  styleUrls: ['./action-menu-dropdown.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class ActionMenuDropdownComponent extends BaseComponent {
    public show = false;
    private _open = false;
    private _toggleElement: any;
    private _outsideClickListener: Function;

    public anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
    public popupAlign: Align = { horizontal: 'center', vertical: 'top' };

    @ViewChild('anchor')
    protected anchor: ElementRef;

    @ViewChild('popup', { read: ElementRef })
    protected popup: ElementRef;

    @Input()
    public title:string;


    //close dropdown if clicked inside the target
    @HostListener('document:click', ['$event'])
    public documentClick(event: any): void {
        if (this.contains(event.target)) {
          this.close();
        } 
    }

    private contains(target: any): boolean {
        return this.popup ? this.popup.nativeElement.contains(target) : false;
    }  
        
    
    @Input()
    public styleClass:string;

    private isOpen(): boolean {
        return this._open;
    }

    constructor(
        elementRef: ElementRef,
        private readonly renderer: Renderer2,
        private readonly changeDetector: ChangeDetectorRef) {
        super();
        this._toggleElement = elementRef.nativeElement;
    }

    private open(): void {
        if (!this._open) {
            this._open = true;
            this.show = true;
            // We cache the function "listenGlobal" returns
            this._outsideClickListener = this.renderer.listen('document', 'click', (event) => {
                return this.closeFromOutsideClick(event);
            });
        }
    }

    private close(): void {
        if (this._open) {
            this._open = false;
            this.show = false;
            // Removes "listenGlobal" listener
            this._outsideClickListener();
            try {
                this.changeDetector.detectChanges();
            } catch (_) { }
        }
    }

    public toggle(): void {
        if (this.isOpen()) {
            this.close();
        } else {
            this.open();
        }
    }

    private closeFromOutsideClick($event: any) {
      if (!this._isEventFromToggle($event)) {
          this.close();
      }
    }
    /**
       * @internal
    */
    private set toggleElement(toggleElement: any) { this._toggleElement = toggleElement; }

    private _isEventFromToggle($event) { return !!this._toggleElement && this._toggleElement.contains($event.target); }

    private closeFromOutsideEsc() {
        this.close();
    }

    OnDestroy() {
        this.close();
    }
}
