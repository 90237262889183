import { NgModule } from '@angular/core';

import { GeneaCommonModule } from '../common/common.module';

import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { LoadingIndicatorDirective } from './loading-indicator/loading-indicator.directive';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { ActiveIconComponent } from './active-icon/active-icon.component';
import { VerifiableResultsComponent } from './verifiable-results/verifiable-results.component';
import { VerifiableControlDirective } from './verifiable-results/verifiable-control.directive';
import { GeneaTabStripTabDirective } from './tab-strip/genea-tab-strip-tab.directive';
import { DynamicFormModule } from './dynamic-form';
import { ControlErrorsModule } from './control-error/control-errors.module';
import { YesNoDropDownComponent } from './yes-no-dropdown/yes-no-dropdown.component';
import { GeneaFieldsetModule } from './fieldset/fieldset.module';
import { GeneaTwoColumnLayoutModule } from './two-column-layout/two-column-layout.module';
import { PageHeadingDirective } from './page-heading/page-heading.directive';
import { ContainerLayoutDirective } from './container-layout/container-layout.directive';
import { InvoicePdfViewerComponent } from './invoice-pdf-viewer/invoice-pdf-viewer.component';
import { YearPickerComponent } from './year-picker/year-picker.component';
import { ActionMenuDropdownComponent } from './action-menu-dropdown/action-menu-dropdown.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component'
import { BuildingInvoicePreviewPageComponent } from './building-invoice-preview-page/building-invoice-preview-page.component';

@NgModule({
    declarations: [
        LoadingIndicatorComponent,
        LoadingIndicatorDirective,

        ActiveIconComponent,
        ActionButtonsComponent,

        YesNoDropDownComponent,

        VerifiableResultsComponent,
        VerifiableControlDirective,

        GeneaTabStripTabDirective,

        PageHeadingDirective,

        ContainerLayoutDirective,

        InvoicePdfViewerComponent,
        BuildingInvoicePreviewPageComponent,
        ImageViewerComponent,

        ActionMenuDropdownComponent,

        YearPickerComponent,
    ],
    imports: [
        GeneaCommonModule,

        DynamicFormModule,
        ControlErrorsModule,

        GeneaFieldsetModule,
        GeneaTwoColumnLayoutModule
    ],
    exports: [
        LoadingIndicatorComponent,
        LoadingIndicatorDirective,
        ActionMenuDropdownComponent,
        BuildingInvoicePreviewPageComponent,

        ActiveIconComponent,
        ActionButtonsComponent,

        YesNoDropDownComponent,
        YearPickerComponent,
        VerifiableResultsComponent,
        VerifiableControlDirective,
        
        GeneaTabStripTabDirective,
        PageHeadingDirective,
        ContainerLayoutDirective,

        ...DynamicFormModule.exports(),
        ...ControlErrorsModule.exports(),
        ...GeneaFieldsetModule.exports(),
        
        ...GeneaTwoColumnLayoutModule.exports()
    ],
    entryComponents: [
        LoadingIndicatorComponent
    ]
})
export class GeneaCommonUIModule {

}