import { Directive, TemplateRef } from '@angular/core';
import { BaseDirective } from '../../../_core/components/base.directive';

@Directive({
    selector: '[geneaLayoutColumn]'
})
export class GeneaColumnLayoutColumnDirective extends BaseDirective {
    constructor(public readonly templateRef: TemplateRef<any>) {
        super();
    }
 }
