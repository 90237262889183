import { HttpClient, HttpErrorResponse, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CompositeType } from '../_core/type-converter';
import { toQueryString } from '../_core/url.utils';
import { ModelCollection } from '../_sdk/collection.model';
import { QueryVisitor } from './query.visitor';

export interface RESTResponse {
    Success: boolean;
    Messages?: Array<{ Message: string, MessageType?: number }>;
    Errors?: Array<{ Message: string }>;
    Total: number;
    Data?: Array<any>;
}

export class RESTResponse {
    public static fromHttpEvent(event: HttpEvent<any>): RESTResponse | null {
        if (event instanceof HttpErrorResponse) {
            return (event && event.error) as RESTResponse;
        }

        if (event instanceof HttpResponse) {
            return (event && event.body) as RESTResponse;
        }

        return null;
    }
}

export type RESTDataResponse<T> = CompositeType<RESTResponse, { Data?: Array<T> }>;

export interface ResponseOptions {
    responseType: 'single' | 'collection' | 'array';
}

@Injectable({
    providedIn: 'root'
})
export class RESTService {
    constructor(
        public readonly httpClient: HttpClient, 
        public readonly queryVisitor: QueryVisitor
    ) {
    }

    public get<T>(uri: string, params: any, options: { responseType: 'single' }): Observable<T | never>;
    public get<T>(uri: string, params: any, options: { responseType: 'collection' }): Observable<ModelCollection<T> | never>;
    public get<T>(uri: string, params: any, options: { responseType: 'array' }): Observable<Array<T> | never>;
    public get<T>(uri: string, params: any, options: ResponseOptions = { responseType: 'collection' }): Observable<T | ModelCollection<T> | Array<T> | never> {
        const query = this.queryVisitor.visit(params)
            .pipe(
                map(proxy => {
                    // We need to capture the URI because the stream retriggers when the visitor emits.
                    // When it's retriggered, it keeps appending the query params to the original string instead of re-rendering it...
                    let capturedUri = uri;

                    const queryString = toQueryString(proxy);

                    if (!String.isNullOrEmpty(queryString)) {
                        let appender = '?';

                        if (capturedUri.indexOf('?') >= 0) {
                            appender = '&';
                        }

                        capturedUri = `${capturedUri}${appender}${queryString}`;
                    }

                    return capturedUri;
                }),
                switchMap(url => this.httpClient.get<RESTResponse>(url, { observe: 'response' }))
            );

        return query
            .pipe(
                switchMap((response: HttpResponse<RESTResponse>) => {
                    return this.observeResponse<T>(response, options);
                })
            );
    }

    public post<T>(uri: string, body: any, options: { responseType: 'single' }): Observable<T | never>;
    public post<T>(uri: string, body: any, options: { responseType: 'collection' }): Observable<ModelCollection<T> | never>;
    public post<T>(uri: string, body: any, options: { responseType: 'array' }): Observable<Array<T> | never>;
    public post<T>(uri: string, body: any, options: ResponseOptions = { responseType: 'collection' }): Observable<T | ModelCollection<T> | Array<T> | never> {
        return this.httpClient.post<RESTResponse>(uri, body, { observe: 'response' })
            .pipe(
                switchMap((response: HttpResponse<RESTResponse>) => {
                    // NOTE: Daniel moved this to the auth.interceptor.ts file. It already handles readers...
                    // this.inspectResponse(response);

                    return this.observeResponse<T>(response, options);
                })
            );
    }

    public put<T>(uri: string, body: any, options: { responseType: 'single' }): Observable<T | never>;
    public put<T>(uri: string, body: any, options: { responseType: 'collection' }): Observable<ModelCollection<T> | never>;
    public put<T>(uri: string, body: any, options: { responseType: 'array' }): Observable<Array<T> | never>;
    public put<T>(uri: string, body: any, options: ResponseOptions = { responseType: 'collection' }): Observable<T | ModelCollection<T> | Array<T> | never> {
        return this.httpClient.put<RESTResponse>(uri, body, { observe: 'response' })
            .pipe(
                switchMap((response: HttpResponse<RESTResponse>) => {
                    // NOTE: Daniel moved this to the auth.interceptor.ts file. It already handles readers...
                    // this.inspectResponse(response);

                    return this.observeResponse<T>(response, options);
                })
            );
    }

    public patch<T>(uri: string, body: any, options: { responseType: 'single' }): Observable<T | never>;
    public patch<T>(uri: string, body: any, options: { responseType: 'collection' }): Observable<ModelCollection<T> | never>;
    public patch<T>(uri: string, body: any, options: { responseType: 'array' }): Observable<Array<T> | never>;
    public patch<T>(uri: string, body: any, options: ResponseOptions = { responseType: 'collection' }): Observable<T | ModelCollection<T> | Array<T> | never> {
        return this.httpClient.patch<RESTResponse>(uri, body, { observe: 'response' })
            .pipe(
                switchMap((response: HttpResponse<RESTResponse>) => {
                    
                    return this.observeResponse<T>(response, options);
                })
            );
    }

    public delete<T>(uri: string, options: { responseType: 'single' }): Observable<T | never>;
    public delete<T>(uri: string, options: { responseType: 'collection' }): Observable<ModelCollection<T> | never>;
    public delete<T>(uri: string, options: { responseType: 'array' }): Observable<Array<T> | never>;
    public delete<T>(uri: string, options: ResponseOptions = { responseType: 'collection' }): Observable<T | ModelCollection<T> | Array<T> | never> {
        return this.httpClient.delete<RESTResponse>(uri, { observe: 'response' })
            .pipe(
                switchMap((response: HttpResponse<RESTResponse>) => {
                    // NOTE: Daniel moved this to the auth.interceptor.ts file. It already handles readers...
                    // this.inspectResponse(response);
                    
                    return this.observeResponse<T>(response, options);
                })
            );
    }

    /**
     * Sends a request to a REST service using the provided methed and returns the result
     * @param uri 
     * @param method 
     * @param body 
     */
    public request(uri: string, method: string = 'get', options?: any): Observable<any | never> {
        /* 
        if(String.equals(method, 'get', false)) {
            // Let's pass through to the 'get' method because it handles modifiying our request.
            return this.get<any>(uri)
        }
        */

        return this.httpClient.request(method, uri, options);
    }

    private observeResponse<T>(response: HttpResponse<RESTResponse>, options: ResponseOptions): Observable<T | ModelCollection<T> | Array<T> | never> {
        if (response.body.Success) {
            switch(options.responseType) {
                case 'single':
                    return of(response.body.Data && response.body.Data.first());

                case 'collection':
                    return of(new ModelCollection(
                        response.body.Data,
                        response.body.Total
                    ));

                case 'array': {
                    return of(response.body.Data);
                }
            }
        }

        throw response;
    }
}
