
import { of, Observable } from 'rxjs';
import { Component, Optional, Inject, forwardRef, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS } from '@angular/forms';

import { BaseDropDown } from '../../../_core/components/base-drop-down.conponent';
import { ValidatorArray, AsyncValidatorArray } from '../../../_core/components/forms/validate';
import { DropDownItem } from '../../common/inputs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'yes-no-drop-down',
    templateUrl: '../../templates/drop-down/drop-down.template.html',
    styleUrls: ['../../templates/drop-down/drop-down.template.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => YesNoDropDownComponent),
        multi: true
    }]
})
export class YesNoDropDownComponent extends BaseDropDown {

    constructor(
        @Optional() @Inject(NG_VALIDATORS) validators: ValidatorArray,
        @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: AsyncValidatorArray) {
        super(validators, asyncValidators);
    }

    protected dataProvider(): Observable<Array<DropDownItem>> {
        const selectList: DropDownItem[] = [];

        selectList.push({ Id: true, Name: 'Yes' });
        selectList.push({ Id: false, Name: 'No' });

        return of(selectList);
    }
}
