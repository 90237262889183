export enum BusinessType {
	Retail = 1,
    Office = 2,
    Medical = 3,
    Industrial_WareHouse = 4,
    Residential = 5,
    Parking = 6,
    Other = 7
}

export const BusinessTypeNames = new Map<number, string>([
	[BusinessType.Retail, "Retail"],
	[BusinessType.Office, "Office"],
	[BusinessType.Medical, "Medical"],
	[BusinessType.Industrial_WareHouse, "Industrial/Warehouse"],
	[BusinessType.Residential, "Residential"],
	[BusinessType.Parking, "Parking"],
	[BusinessType.Other, "Other"]
]);

export enum BussinessSubType {
	Other = 1,
	FoodBeverageFast = 2,
	FoodBeverageSitDown = 4,
	SalonBeautyCare = 8,
	FinancialServices = 16,
	DomesticShopping = 32,
	Kiosk = 64
}

export const BussinessSubTypeName = new Map<number, string>([
	[BussinessSubType.FoodBeverageFast, "Food + Beverage - Fast"],
	[BussinessSubType.FoodBeverageSitDown, "Food + Beverage - Sit Down"],
	[BussinessSubType.SalonBeautyCare, "Salon / Beauty Care"],
	[BussinessSubType.FinancialServices, "Financial Services"],
	[BussinessSubType.DomesticShopping, "Domestic Shopping"],
	[BussinessSubType.Other, "Other"],
	[BussinessSubType.Kiosk, "Kiosk"]
]);

export enum LeaseType {
	Seasonal = 1,
	ShortTerm = 2,
	LongTerm = 3,
	Annual = 4,
	ProjectBased = 5
}

export const LeaseTypeNames = new Map<number, string>([
	[LeaseType.Seasonal, "Seasonal"],
	[LeaseType.ShortTerm, "Short Term"],
	[LeaseType.LongTerm, "Long Term"],
	[LeaseType.Annual, "Annual"],
	[LeaseType.ProjectBased,"Project Based"]
]);
