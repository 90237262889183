import { NgModule } from '@angular/core';

import { LocaleResourceDirective } from './localizer.directive';
import { LocaleResourcePipe } from './localizer.pipe';

@NgModule({
    declarations: [
        LocaleResourceDirective,
        LocaleResourcePipe
    ],

    imports: [],

    exports: [
        LocaleResourceDirective,
        LocaleResourcePipe
    ]
})
export class LocalizerModule {
    static exports(): Array<any> {
        return [
            LocaleResourceDirective,
            LocaleResourcePipe
        ];
    }
 }
