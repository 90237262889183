import { Component } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { DateFieldConfig } from '../../models/field-config.interface';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { BaseComponent } from '../../../../../_core/components/base.component';
import { first, tap, map, filter } from 'rxjs/operators';
import { notNull } from '../../../../../_core/rxjs.operators';
import { SmallDate, DateTime } from '../../../../../_core/date';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'form-date',
    styleUrls: ['form-date.component.scss'],
    template: `
    <ng-container [formGroup]="proxyForm">
        <kendo-datepicker
            [format]="config.format"
            [ngClass]="[config.cssClass || '']"
            [placeholder]="config.placeholder"
            [formatPlaceholder]="config.formatPlaceholder"
            formControlName="jsValue">
        </kendo-datepicker>
    </ng-container>
  `
})
export class FormDateComponent extends BaseComponent implements Field {

    public readonly proxyForm: FormGroup;

    private readonly _config$: BehaviorSubject<DateFieldConfig> = new BehaviorSubject(null);
    private readonly _group$: BehaviorSubject<FormGroup> = new BehaviorSubject(null);

    /***********************************************
     * Field interface...
    ***********************************************/

    // Use getter/setter so we can hook into the values being set.
    public get config(): DateFieldConfig {
        return this._config$.getValue();
    }
    public set config(value: DateFieldConfig) {
        this._config$.next(value);
    }

    public get group(): FormGroup {
        return this._group$.getValue();
    }
    public set group(value: FormGroup) {
        this._group$.next(value);
    }

    constructor(private readonly fb: FormBuilder) {
        super();

        // The form requires a FormGroup with its controls populated... Go ahead and create it...
        this.proxyForm = this.fb.group({
            jsValue: null
        });

        // We're going to listen for both the config and group properties
        // being set. Once they have a value, then we can bind our config properties
        // to our proxy form.
        this.addSubscription(
            combineLatest(
                this._config$.pipe(notNull()),
                this._group$.pipe(notNull())
            ).pipe(
                first(),
                tap(([config, group]) => {
                    // Both values have been set! bind our form...
                    this.bindForm(config, group);
                })
            )
        );
    }

    private bindForm(config: DateFieldConfig, group: FormGroup) {
        this.bindValue(config.value);

        // When the proxy changes, notify the original.
        this.addSubscription(
            this.jsValueControl.valueChanges
                .pipe(
                    map(value => this.normalizeValue(value)),
                    tap(value => {
                        const newValue = {};
                        newValue[config.name] = value;
                        group.patchValue(newValue, { emitEvent: true });
                    })
                )
        );

        // Make sure we set the disabled state from the config
        if (config.disabled) {
            this.proxyForm.disable();
        }

        // Used to set the enabled/disabled from the original control.
        // When a control is disabled in the original, we need to reflect that in the proxy...
        this.addSubscription(
            group.controls[config.name].statusChanges
                .pipe(
                    filter(value => value !== this.proxyForm.status), // Note, YOU NEED THIS!
                    tap(value => {
                        const method = String.equals(value, 'disabled', false) ? 'disable' : 'enable';

                        this.proxyForm[method]();
                    })
                )
        );
    }

    private get jsValueControl(): AbstractControl {
        return this.proxyForm.get('jsValue');
    }

    private bindValue(value: any): SmallDate {
        const normalized = this.normalizeValue(value);

        this.jsValueControl.patchValue(normalized && normalized.toDate(), { emitEvent: false });

        return normalized;
    }

    private normalizeValue(value: Date | SmallDate | DateTime | string | number): SmallDate {
        if (!value) {
            return null;
        }

        return SmallDate.convert(value);
    }
}
