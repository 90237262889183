import { cloneDeep } from '../_core/type-converter';

export class ModelCollection<T> {
    private readonly _data: Array<T>;
    private readonly _total: number;

    constructor(data?: T[], total?: number) {
        this._data = data || [];
        this._total = total;
    }

    public static empty<T>(): ModelCollection<T> {
        return new ModelCollection<T>();
    }

    public get data(): T[] {
        return this._data;
    }

    public get total(): number {
        return this._total || (this._data && this._data.length);
    }

    public clear(): void {
        this.data.length = 0;
    }

    public clone(): ModelCollection<T> {
        return new ModelCollection(cloneDeep([], this.data) as T[], this.total);
    }

    public any(): boolean {
        return this.data && this.data.length > 0;
    }

    public addData(model:ModelCollection<T>,newData:T[]): ModelCollection<T>{
        model.data.push(...newData)
        return model
    }
}
