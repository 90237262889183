import { ControlType } from '../../_core/control-type.enum';
import { EntityModel } from '../model';
import { DataType } from './data-type.enum';
import { DataProperty, DataObject, CompositeType } from '../../_core/type-converter';
import { FilterState } from '../page-state';

@DataObject()
export class ParameterDataTypeModel extends EntityModel {
    public Name: string = null;
    public Description: string = null;
    public Active = false;
    public SystemName: string = null;
    public ControlType: ControlType = null;
    public DisplayOrder: number = null;
    public DataType: DataType = null;
}

export interface ParameterDataTypeFilter {
    Name?: string;
}

export type ParameterDataTypeFilterState = CompositeType<ParameterDataTypeFilter, FilterState>;
