import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProtocolType from './protocol-types/protocol-type.reducers';
import * as fromProtocol from './protocols/protocol.reducers';
import * as fromPointType from './point-types/point-type.reducers';
import * as fromCommand from './commands/command.reducers';

export const FEATURE_NAME = 'emsDeviceStore';

export interface FeatureState {
    protocolTypeState: fromProtocolType.State;
    protocolState: fromProtocol.State;
    pointTypeState: fromPointType.State;
    commandState: fromCommand.State;
}

export const reducers: ActionReducerMap<FeatureState> = {
    protocolTypeState: fromProtocolType.reducer,
    protocolState: fromProtocol.reducer,
    pointTypeState: fromPointType.reducer,
    commandState: fromCommand.reducer
};

export const featureSelector = createFeatureSelector<FeatureState>(FEATURE_NAME);

const getProtocolTypeStateSelector = (state: FeatureState) => state.protocolTypeState;
export const getProtocolTypeState = createSelector(featureSelector, getProtocolTypeStateSelector);
export const getProtocolTypes = createSelector(getProtocolTypeState, s => s.protocolTypes);

const getProtocolStateSelector = (state: FeatureState) => state.protocolState;
export const getProtocolState = createSelector(featureSelector, getProtocolStateSelector);
export const getProtocols = createSelector(getProtocolState, s => s.protocolsTypes);

const getPointTypeStateSelector = (state: FeatureState) => state.pointTypeState;
export const getPointTypeState = createSelector(featureSelector, getPointTypeStateSelector);
export const getPointTypes = createSelector(getPointTypeState, s => s.pointTypes);

const getCommandStateSelector = (state: FeatureState) => state.commandState;
export const getCommandState = createSelector(featureSelector, getCommandStateSelector);
export const getCommands = createSelector(getCommandState, s => s.commands);
