import { Pipe, PipeTransform } from '@angular/core';
import { TimeSpan } from '../../../_core/date';

@Pipe({ name: 'concatDateTime' })
export class ConcatDateTimePipe implements PipeTransform {

    transform(date: Date, time: TimeSpan, format: string = null): string {
        const result = date;

        result.setHours(time.hours);
        result.setMinutes(time.minutes);
        result.setSeconds(time.seconds);
        result.setMilliseconds(time.milliseconds);

        return format ? result.format(format) : result.toString();
    }
}
