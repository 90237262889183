import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { JwtHelperService } from '@auth0/angular-jwt';

import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { ApiConfig, GlobalConfig } from '../../_core/global.config';
import { RoleConstant } from '../../_core/role-constant';
import { TypeConverter } from '../../_core/type-converter';
import { getExternalUrl, getReturnUrl } from '../../_core/url.utils';

import { AuthRequestModel } from '../../_sdk/auth/auth.model';
import { ProductEnum } from '../../_sdk/products/product.enum';
import { ProductModel } from '../../_sdk/products/product.model';
import { UserSettingModel } from '../../_sdk/users/user-setting.model';
import { UserModel } from '../../_sdk/users/user.model';

import { StorageService } from '../IO/storage.service';
import { RESTDataResponse, RESTService } from '../rest.service';
import { ContextReferenceModel } from '../../_sdk/users/context-reference.model';
import { SnoozeRequestModel } from '../../_sdk/notification/snooze-notification.model';

const TOKEN_KEY = 'token';
const USER_KEY = 'currentUser';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _apiConfig: ApiConfig;

    private _cachedUser: UserModel;
    private _cachedToken: string;
    private _cachedTokenExpiration: number;
    
    private _cachePermissionMap = new Map<string, boolean>();
    private _cacheRoleMap = new Map<string, boolean>();

    private readonly _jwtHelperService: JwtHelperService = new JwtHelperService();

    private readonly _subscriptions = new Subscription();

    private readonly _token$: Observable<string>;
    private readonly _user$: Observable<UserModel>;

    public readonly isAuthenticated$: Observable<boolean>;
    public readonly authenticatedUser$: Observable<UserModel>;

    public readonly token$: Observable<string>;
    public readonly isPowerUser$: Observable<boolean>;
    public readonly products$: Observable<Array<ProductModel>>;
    public readonly hasOvertimeHVACProduct$: Observable<boolean>;
    public readonly hasSubmeterBillingProduct$: Observable<boolean>;
    public readonly userSettings$: Observable<Array<UserSettingModel>>;
    public readonly userContext$: Observable<ContextReferenceModel>;  
    
    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly restService: RESTService,
        private readonly config: GlobalConfig,
        private readonly storageService: StorageService) {

        this._apiConfig = this.config.api('portal');

        this._token$ = this.storageService.get(TOKEN_KEY);
        this._user$ = this.storageService.get(USER_KEY);

        // Immediately Listen for token changes
        this._subscriptions.add(
            this._token$
                .pipe(
                    tap(token => {
                        if(String.isNullOrEmpty(token)) {
                            this._cachedTokenExpiration = null;
                        }

                        this._cachedToken = token;
                    })
                )
                .subscribe()
        );

        // Immediately Listen for user changes
        this._subscriptions.add(
            this._user$
                .pipe(
                    tap(user => {
                        this._cacheRoleMap.clear();
                        this._cachePermissionMap.clear();
                        
                        if(user) {
                            // Sort and store permission records since they could be a big list.
                            user.PermissionRecords.sort();

                            // Go ahead and handle permissions
                            (user.PermissionRecords || [])
                                .forEach(p => {
                                    this._cachePermissionMap.set(p, true);
                                });

                            // Go ahead and handle roles
                            (user.UserRoles || [])
                                .map(p => p.SystemName)
                                .forEach(p => {
                                    this._cacheRoleMap.set(p, true);
                                })                            
                        }

                        this._cachedUser = user;
                    })
                )
                .subscribe()
        );

        this.isAuthenticated$ = this._token$
            .pipe(
                map(_ =>  !this.isTokenExpired()),
                distinctUntilChanged()
            );

        this.token$ = this.isAuthenticated$
            .pipe(
                switchMap(p => {
                    if(p) {
                        return this._token$
                    }

                    return of(null);
                }),
                distinctUntilChanged(),
                shareReplay(1)
            );
    
        this.authenticatedUser$ = this.isAuthenticated$
            .pipe(
                switchMap(() => this._user$),
                map(user => {
                    return TypeConverter.convert(UserModel, user);
                }),
                shareReplay(1)
            );

        this.userSettings$ = this.authenticatedUser$
            .pipe(
                map(p => (p && p.UserSettings) || [])
            );

        this.userContext$ = this.authenticatedUser$
            .pipe(
                map(p => p && p.Context || new ContextReferenceModel())
            );

        this.products$ = this.authenticatedUser$
            .pipe(
                map(user => {
                    return (user && user.Context && user.Context.Products) || [];
                })
            );

        this.hasOvertimeHVACProduct$ = this.products$
            .pipe(
                map(p => p.any(q => q.Id === ProductEnum.OvertimeHVAC)),
                distinctUntilChanged(),
                shareReplay(1)
            );

        this.hasSubmeterBillingProduct$ = this.products$
            .pipe(
                map(p => p.any(q => q.Id === ProductEnum.SubmeterBillding)),
                distinctUntilChanged(),
                shareReplay(1)
            );

        // Set an interval to refresh the user's permissions every 10 minutes.
        setInterval(() => {
            this.synchronizeUserPermissions()
        }, 1000 * 60 * 10);
    }

    public destroySession(): void {
        this.storeToken(null);
        this.storeUser(null);
    }

    public login(request: AuthRequestModel): Observable<UserModel> {
        return this.restService.request(`${this._apiConfig.baseUri}/auth/login`, 'patch', { body: request, observe: 'response' })
            .pipe(
                tap(response => this.processLoginResponse(response)),
                switchMap(() => this.authenticatedUser$)
            );
    }

    public loginSaml(data):Observable<UserModel>{
        if(data)
         this.storeToken(data);
         return this.restService.request(`${this._apiConfig.baseUri}/auth/refresh?loadContext=true&loadSettings=true`)
         .pipe(
                take(1),
                tap((response) => { 
                    this.storeUser(response.Data.firstOrDefault());
                    this.handleLoggedIn();
                }),
            )
    }

    //  Refresh the currently authenticated user
    public refresh(newHash: string): void {

        if(String.looseEquals(newHash, this._cachedUser?.PermissionHash)) {
            return;
        }

        // We only want to refresh the user ONCE per change. To do that, we need to compare the permission hash
        // If the hash from the server is different, then trigger a refresh, but also save the hash from the server
        // So future refreshes don't happen.
        console.log('Refreshing User Model');
        
        this.storageService.get(USER_KEY)
            .pipe(
                take(1),
                tap((user: UserModel) => {
                    if (user != null) {
                        user.PermissionHash = newHash;
                        this.storeUser(user);
                    }
                })
            )
            .subscribe();

        this.synchronizeUserPermissions();
    }

    //  Logout the current user
    public logout(): void {
        // this.storageService.clear();
        this.destroySession();
        this.handleLoggedOut();
    }

    public synchronizeUserPermissions(): void {
        this.restService.request(`${this._apiConfig.baseUri}/auth/refresh`)
            .pipe(
                tap(response => this.processRefreshResponse(response)),
                take(1),
                tap(_ => {
                    console.log('Refresh Complete');
                })
            )
            .subscribe();
    }

    //#region Role Management

    public isInRoles(...roles: Array<string>): Observable<boolean> {
        return this.isAuthenticated$
            .pipe(
                map(p => p && this.hasAnyRole(...roles))
            );
    }
    public isInRole(role: string): Observable<boolean> {
        return this.isInRoles(role);
    }

    public isInPermissions(...permissions: Array<string>): Observable<boolean> {
        return this.isAuthenticated$
            .pipe(
                map(_ => this.hasAnyPermission(...permissions))
            );
    }
    public isInPermission(permission: string): Observable<boolean> {
        return this.isInPermissions(permission);
    }

    public hasAnyPermission(...permissions: Array<string>): boolean {
        return (permissions || []).any(p => this.hasPermission(p));
    }
    public hasAllPermissions(...permissions: Array<string>): boolean {
        return (permissions || []).all(p => this.hasPermission(p));
    }
    public hasPermission(permission: string): boolean {
        if (this.isTokenExpired() || !this._cachedUser) {
            return false;
        }

        return this._cachePermissionMap.getValue(permission, () => {
            return (this._cachedUser.PermissionRecords || [])
                .any(p => String.looseEquals(p, permission));
        });
    }

    public hasAnyRole(...roles: Array<string>): boolean {
        return (roles || []).any(p => this.hasRole(p));
    }
    public hasAllRoles(...roles: Array<string>): boolean {
        return (roles || []).all(p => this.hasRole(p));
    }
    public hasRole(role: string): boolean {
        if (this.isTokenExpired() || !this._cachedUser) {
            return false;
        }

        return this._cacheRoleMap.getValue(role, () => {
            return this._cachedUser.UserRoles
                .map(p => p.SystemName)
                .any(p => String.looseEquals(p, role));
        })
    }

    //#endregion Role Management

    private isTokenExpired(): boolean {
        if(String.isNullOrEmpty(this._cachedToken)) {
            return true;
        }

        if(TypeConverter.isNull(this._cachedTokenExpiration)) {
            // NOTE: Each time you call "jwtHelperService.isTokenExpired", it goes into a deep process to 
            // base64 decode the token and recursively do some checks.
            // It can cause UI to "glitch" when "hasPermission" is called over and over.
            // To fix this, I set a variable that checks the expiration with the current time.
            // This way, the token is decoded ONCE and the cached value is used.
            const date = this._jwtHelperService.getTokenExpirationDate(this._cachedToken) || new Date(2000, 1);

            this._cachedTokenExpiration = date.getTime();
        }

        return this._cachedTokenExpiration < new Date().getTime();
    }

    public updateLocalUser(email: string, firstName: string, lastName: string, displayName: string, dashboardUrl?:string): void {
        this.storageService.get(USER_KEY)
            .pipe(
                take(1),
                tap((user: UserModel) => {
                    if (user != null) {
                        user.Email = email;
                        user.Username = email;
                        user.FirstName = firstName;
                        user.LastName = lastName;
                        dashboardUrl ? user.DashboardURL = dashboardUrl : user.DashboardURL = null;
                        
                        if (displayName) {
                            user.DisplayName = displayName;
                        } 
                    }

                    this.storeUser(user);
                })
            )
            .subscribe();
    }

    public saveUserSettings(...settings: Array<UserSettingModel>): Observable<Array<UserSettingModel>> {
        if(!this._cachedUser) {
            return of([]);
        }

        if(!(settings || []).length) {
            return of([]);
        }

        const uri = `${this.config.api('portal').baseUri}/auth/user-settings`;

        return this.restService.request(uri, 'patch', { body: settings, observe: 'response' })
            .pipe(
                switchMap(response => {
                    const result = response.body.Data || [];

                    this.storageService.get<UserModel>(USER_KEY)
                        .pipe(
                            take(1),
                            tap(user => {
                                user.UserSettings = result;

                                this.storeUser(user);
                            })
                        )
                        .subscribe();

                    return of(result);
                })
            );
    }

    protected storeToken(token?: string): void {
        this.storageService.put(TOKEN_KEY, token);
    }

    protected storeUser(user?: UserModel): void {
        this.storageService.put(USER_KEY, user);
    }

    private processLoginResponse(response: HttpResponse<RESTDataResponse<UserModel>>): void {
        const token = response.headers.get('access_token');
        const user = response.body.Data.firstOrDefault();

        this.storeToken(token);
        this.storeUser(user);

        this.handleLoggedIn();
    }

    //  Process the response from a user refresh request
    private processRefreshResponse(response: RESTDataResponse<UserModel>): void {
        const user = response.Data.firstOrDefault() as UserModel;

        const __equals = (left: UserModel, right: UserModel) => { 
            if(TypeConverter.isNull(left) && TypeConverter.isNull(right)) { 
                return true;
             } 
            else if (TypeConverter.isNull(left) || TypeConverter.isNull(right)) { 
                return false;
             } 

            return left.PermissionHash === right.PermissionHash 
            && left.DashboardURL === right.DashboardURL 
            && (left.UserRoles && right.UserRoles && left.UserRoles.length === right.UserRoles.length && left.UserRoles.map(p => p.SystemName).except(right.UserRoles.map(p => p.SystemName)).length === 0) 
            && (left.PermissionRecords && right.PermissionRecords && left.PermissionRecords.length === right.PermissionRecords.length && left.PermissionRecords.except(right.PermissionRecords).length === 0) 
        };

        if (user != null)
        {
            this.storageService.get<UserModel>(USER_KEY)
                .pipe(
                    take(1),
                    filter(p => !__equals(user, p)),
                    tap((p: UserModel) => {
                        if (p != null) {
                            p.UserRoles = user.UserRoles;
                            p.PermissionRecords = user.PermissionRecords
                            p.DashboardURL = user.DashboardURL;
                            this.storeUser(p);
                        }
                    })
                )
                .subscribe();
        }
    }

    protected handleLoggedIn(): void {
        const returnUrl = getReturnUrl(this.route);
        const externalUrl = getExternalUrl(this.route);

        if (!String.isNullOrEmpty(externalUrl)) {
            this.router.navigate(['/externalRedirect', { externalUrl: externalUrl }])
                .catch(reason => {
                    console.log(reason);
                });

            return;
        }

        if (!String.isNullOrEmpty(returnUrl)) {
            this.router.navigateByUrl(returnUrl)
                .catch(reason => {
                    console.log(reason);
                });

            return;
        }
        if(window.innerWidth < 960){
            this.router.navigateByUrl('admin/request-list')
                .catch(reason => {
                    console.log(reason);
                });
        }
        else{
            //  Test for Administrator.  Redirect to submeter billing dashboard
            if (this.hasAnyRole(RoleConstant.Administrators)) {
                this.router.navigateByUrl('admin/platform/invoicing/meter')
                    .catch(reason => {
                        console.log(reason);
                    });
    
                return;
            }
    
            //  Test for AMRB User.  Redirect to meter and measurement admin
            if (this.hasAnyRole(RoleConstant.AMRBUser)) {
                this.router.navigateByUrl('admin/platform/metering')
                    .catch(reason => {
                        console.log(reason);
                    });
    
                return;
            }
    
            //  Test for Tenant Manager.  Redirect to virtual meter admin
            if (this.hasAnyRole(RoleConstant.TenantManager)) {
                // this.router.navigateByUrl('admin/portal/users')
                this.router.navigateByUrl('admin/platform/leases')
                    .catch(reason => {
                        console.log(reason);
                    });
    
                return;
            }
    
            //Test for Property Manager and Building Manager. Redirect to Default Page
            if (this.hasAnyRole(RoleConstant.PropertyManager, RoleConstant.BuildingEngineer)) {
                this.router.navigateByUrl('admin')
                    .catch(reason => {
                        console.log(reason);
                    });
    
                return;
            }
    
    
            //  Test for Tenant users.  Redirect to request calendar
            if (this.hasAnyRole(RoleConstant.TenantUser)) {
                this.router.navigateByUrl('admin')
                    .catch(reason => {
                        console.log(reason);
                    });
    
                return;
            }
    
            if(this.hasAnyRole(RoleConstant.Administrators)) {
                this.router.navigateByUrl('admin/platform/customers')
                    .catch(reason => {
                        console.log(reason);
                    });
    
                return;
            }
            
            //  Otherwise, redirect 'Home'
            this.router.navigateByUrl(returnUrl)
                .catch(reason => {
                    console.log(reason);
                });
        }
    }

    protected handleLoggedOut(): void {
        // The auth cookie is an HTTPOnly cookie, therefore, we need to call the API to clear it.
        this.restService.request(`${this._apiConfig.baseUri}/auth/logout`, 'patch')
            .pipe(take(1))
            .subscribe();
    }
    protected get commonNotificationBaseUri(): string {
        return `${this.config.api('subscriptions').baseUri}/api/common`;
    }
    public snoozeNotification(request: SnoozeRequestModel){
        return this.restService.request(`${this.commonNotificationBaseUri}/SnoozeRecipientSubscription`, 'put', { body: request, observe: 'response' })
            .pipe(
                take(1),
                switchMap(response => {
                    return of(response.body);
                })
            );
    }
}
