import { DataObject, DataProperty } from '../../_core/type-converter';
import { DateTime } from '../../_core/date';

@DataObject()
export class ExpandedDateModel {
    public LocalTimeZoneId: string = null;

    public UserTimeZoneId: string = null;

    @DataProperty()
    public Local: DateTime = null; // Time in BUILDING timezone

    @DataProperty()
    public User: DateTime = null; // Time in USER timezone (user.profile.timezoneid)

    @DataProperty()
    public Utc: DateTime = null; // Time in UTC (allows me to convert to CLIENT timezone)
}
