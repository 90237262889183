import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { BaseDirective } from '../../../_core/components/base.directive';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: `
    input[type=text]
    ,input[type=number]
    ,input[type=email]
    ,input[type=password]
    ,input[type=search]
    ,input[type=tel]
    ,input[type=url]
    ,textarea
    ,kendo-maskedtextbox
    ,kendo-multiselect
    ,kendo-dropdownlist
    ,kendo-numerictextbox
    ,kendo-autocomplete
    ,kendo-combobox`
})
export class GeneaInputDirective extends BaseDirective {

    private static readonly TEXT_TYPES = [
        'text',
        'number',
        'email',
        'password',
        'search',
        'tel',
        'url'
    ];

    constructor(
        private host: ElementRef<HTMLElement>,
        private renderer: Renderer2) {
        super();
    }

    private get element(): HTMLElement {
        return this.host.nativeElement;
    }

    private get hasTypeAttribute(): boolean {
        return this.element.hasAttribute('type');
    }

    private get isInput(): boolean {
        return String.looseEquals(this.element.tagName, 'input');
    }

    private get isTextInput(): boolean {
        return this.isInput
            && (
                !this.hasTypeAttribute
                || GeneaInputDirective.TEXT_TYPES.any(p => p.looseEquals(this.element.getAttribute('type')))
            );
    }

    private get isTextArea(): boolean {
        return String.looseEquals(this.element.tagName, 'textarea');
    }

    private get isReadonly(): boolean {
        return this.element.hasAttribute('readonly');
    }

    private get isNumeric(): boolean {
        return String.looseEquals(this.element.tagName, 'kendo-numerictextbox')
            || String.looseEquals(this.element.getAttribute('type'), 'number');
    }

    private get isKendo(): boolean {
        return this.element.tagName.toLocaleLowerCase().startsWith('kendo');
    }

    public AfterViewInit() {
        if (this.isReadonly) {
            this.toggleClass('form-control-plaintext');
        } else {
            this.toggleClass('form-control');

            if (this.isTextInput && !this.isKendo) {
                this.toggleClass('k-textbox');
            }

            if (this.isTextArea && !this.isKendo) {
                this.toggleClass('k-textarea');
            }

            if (this.isNumeric && !this.element.hasAttribute('flexWidth')) {
                this.toggleClass('w-auto');
            }
        }

        if(this.element.hasAttribute('autoWidth')) {
            this.toggleClass('w-auto');
        }

        this.toggleClass('form-control-sm');

        if (!this.isKendo && !this.hasTypeAttribute) {
            // Some form elements don't have a type on them for whatever reason. 
            // They default to "text"
            this.toggleAttribute('type', 'text');
        }
    }

    public OnDestroy() {
        this.renderer = null;
        this.host = null;
    }

    private toggleClass(className: string, add: boolean = true) {
        if (add) {
            this.renderer.addClass(this.element, className);
        } else {
            this.renderer.removeClass(this.element, className);
        }
    }

    private toggleAttribute(attributeName: string, value: string, add: boolean = true) {
        if (add) {
            this.renderer.setAttribute(this.element, attributeName, value);
        } else {
            this.renderer.removeAttribute(this.element, attributeName);
        }
    }
}
