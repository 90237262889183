
import { take } from 'rxjs/operators';
import { PipeTransform, Pipe } from '@angular/core';
import { LocalizationContext } from '../../../_core/localization-context';
import { TypeConverter } from '../../../_core/type-converter';

@Pipe({
    name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
    public static readonly yesResource = { key: 'common.yes', defaultValue: 'Yes' };
    public static readonly noResource = { key: 'common.no', defaultValue: 'No' };

    private _innerValue: string;

    constructor(private readonly localizationContext: LocalizationContext) { }

    transform(value: any): string {
        const resource = this.falsy(value)
            ? YesNoPipe.yesResource
            : YesNoPipe.noResource;

        // I don't want to chain an 'async' pipe to this, so I need to create my own subscription...
        this.localizationContext.resource(resource.key, resource.defaultValue)
            .pipe(
                take(1)
            )
            .subscribe(resourceValue => {
                this._innerValue = resourceValue;
            });

        return this._innerValue;
    }

    private falsy(value: any): boolean {
        // Boolean and boolean-like values (see 'TypeConverter.toBoolean')
        if (TypeConverter.isPrimitive(value) && TypeConverter.toBoolean(value)) {
            return true;
        }

        // "falsy" values... null and undefined will result in FALSE
        return !!value;
    }
}
